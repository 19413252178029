
@media (min-width:992px) {

    div,
    ol,
    pre,
    span,
    ul {
        scrollbar-width: thin;
        scrollbar-color: #eff2f5 transparent
    }

    div::-webkit-scrollbar,
    ol::-webkit-scrollbar,
    pre::-webkit-scrollbar,
    span::-webkit-scrollbar,
    ul::-webkit-scrollbar {
        width: .4rem;
        height: .4rem
    }

    div::-webkit-scrollbar-thumb,
    ol::-webkit-scrollbar-thumb,
    pre::-webkit-scrollbar-thumb,
    span::-webkit-scrollbar-thumb,
    ul::-webkit-scrollbar-thumb {
        background-color: #eff2f5
    }

    div:hover,
    ol:hover,
    pre:hover,
    span:hover,
    ul:hover {
        scrollbar-color: #e9edf1 transparent
    }

    div:hover::-webkit-scrollbar-thumb,
    ol:hover::-webkit-scrollbar-thumb,
    pre:hover::-webkit-scrollbar-thumb,
    span:hover::-webkit-scrollbar-thumb,
    ul:hover::-webkit-scrollbar-thumb {
        background-color: #e9edf1
    }
}

// .scroll {
//     overflow: scroll;
//     position: relative
// }

@media (max-width:991.98px) {
    .scroll {
        overflow: auto
    }
}

.scroll-x {
    overflow-x: scroll;
    position: relative
}

@media (max-width:991.98px) {
    .scroll-x {
        overflow-x: auto
    }
}

.scroll-y {
    overflow-y: scroll;
    position: relative
}

@media (max-width:991.98px) {
    .scroll-y {
        overflow-y: auto
    }
}

.hover-scroll {
    position: relative
}

@media (min-width:992px) {
    .hover-scroll {
        overflow: hidden;
        border-right: .4rem solid transparent;
        border-bottom: .4rem solid transparent;
        margin-right: -.4rem;
        margin-bottom: -.4rem
    }

    .hover-scroll:hover {
        overflow: scroll;
        border-right: 0;
        border-bottom: 0
    }

    @-moz-document url-prefix() {
        .hover-scroll {
            overflow: scroll;
            position: relative;
            border-right: 0;
            border-bottom: 0
        }
    }
}

@media (max-width:991.98px) {
    .hover-scroll {
        overflow: auto
    }
}

.hover-scroll-y {
    position: relative
}

@media (min-width:992px) {
    .hover-scroll-y {
        overflow-y: hidden;
        border-right: .4rem solid transparent;
        margin-right: -.4rem
    }

    .hover-scroll-y:hover {
        overflow-y: scroll;
        border-right: 0
    }

    @-moz-document url-prefix() {
        .hover-scroll-y {
            overflow-y: scroll;
            position: relative;
            border-right: 0
        }
    }
}

@media (max-width:991.98px) {
    .hover-scroll-y {
        overflow-y: auto
    }
}

.hover-scroll-x {
    position: relative
}

@media (min-width:992px) {
    .hover-scroll-x {
        overflow-x: hidden;
        border-bottom: .4rem solid transparent
    }

    .hover-scroll-x:hover {
        overflow-x: scroll;
        border-bottom: 0
    }

    @-moz-document url-prefix() {
        .hover-scroll-x {
            overflow-x: scroll;
            position: relative;
            border-bottom: 0
        }
    }
}

@media (max-width:991.98px) {
    .hover-scroll-x {
        overflow-x: auto
    }
}

.hover-scroll-overlay-y {
    overflow-y: hidden;
    position: relative;
    --scrollbar-space: 0.25rem
}

.hover-scroll-overlay-y::-webkit-scrollbar {
    width: calc(.4rem + var(--scrollbar-space))
}

.hover-scroll-overlay-y::-webkit-scrollbar-thumb {
    background-clip: content-box;
    border-right: var(--scrollbar-space) solid transparent
}

.hover-scroll-overlay-y:hover {
    overflow-y: overlay
}

@-moz-document url-prefix() {
    .hover-scroll-overlay-y {
        overflow-y: scroll;
        position: relative
    }
}