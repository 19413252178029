.wizard.wizard-3 .wizard-nav .wizard-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-right: 1rem;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step:last-child {
    margin-right: 0;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #B5B5C3;
    padding: 2rem 0.5rem;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title span {
    font-size: 2rem;
    margin-right: 0.5rem;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-bar {
    height: 4px;
    width: 100%;
    background-color: #ECF0F3;
    position: relative;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-bar:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: transparent;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label {
    color: #2171c1;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label .wizard-bar:after {
    width: 100%;
    background-color: #2171c1;
}

.wizard.wizard-3 [data-wizard-type="step-info"] {
    display: none;
}

.wizard.wizard-3 [data-wizard-type="step-info"][data-wizard-state="current"] {
    display: block;
}

.wizard.wizard-3 [data-wizard-type="step-content"] {
    display: none;
}

.wizard.wizard-3 [data-wizard-type="step-content"][data-wizard-state="current"] {
    display: block;
}

.wizard.wizard-3 [data-wizard-type="action-prev"] {
    display: none;
}

.wizard.wizard-3 [data-wizard-type="action-next"] {
    display: inline-block;
}

.wizard.wizard-3 [data-wizard-type="action-submit"] {
    display: none;
}

.wizard.wizard-3[data-wizard-state="first"] [data-wizard-type="action-prev"] {
    display: none;
}

.wizard.wizard-3[data-wizard-state="first"] [data-wizard-type="action-next"] {
    display: inline-block;
}

.wizard.wizard-3[data-wizard-state="first"] [data-wizard-type="action-submit"] {
    display: none;
}

.wizard.wizard-3[data-wizard-state="between"] [data-wizard-type="action-prev"] {
    display: inline-block;
}

.wizard.wizard-3[data-wizard-state="between"] [data-wizard-type="action-next"] {
    display: inline-block;
}

.wizard.wizard-3[data-wizard-state="between"] [data-wizard-type="action-submit"] {
    display: none;
}

.wizard.wizard-3[data-wizard-state="last"] [data-wizard-type="action-prev"] {
    display: inline-block;
}

.wizard.wizard-3[data-wizard-state="last"] [data-wizard-type="action-next"] {
    display: none;
}

.wizard.wizard-3[data-wizard-state="last"] [data-wizard-type="action-submit"] {
    display: inline-block;
}

.wizard.wizard-3[data-wizard-clickable="true"] .wizard-step {
    cursor: pointer;
}

@media (max-width: 991.98px) {
    .wizard.wizard-3 .wizard-nav .wizard-steps {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        position: relative;
        width: 100%;
    }

    .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        padding: 1rem 0;
    }
}