.bg-light-orange {
    background-color: #fff2d9 !important;
}

.bg-light-gray {
    background-color: #ECEFF1 !important;
}

.bg-light-green {
    background-color: #E8F5E9 !important;
}

.bg-light-brown {
    background-color: #EFEBE9 !important;
}