@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

$blue-plataforma: #2171c1;

//opcion1
// $red-survey:#A30000;
// $orange-survey:#ef8e30;

// $green-survey:#2A572E;

// // opcion2
// $red-survey:#9E0522;
$orange-survey: #ef8e30;
// $green-survey:#447622;

//opcion3
$red-survey: #d4504c;
// $orange-survey:#E19A3C;
$green-survey: #04874b;

// $orange-survey:#fbaf00;

$olive-drab-7: #443e2fff;
$french-bistre: #71674fff;
$grullo: #9e916eff;
$ecru: #cbba8eff; //principal
$dutch-white: #d7c9a7ff;
$bone: #e2d9c0ff;
$eggshell: #eee8d9ff;

$gunmetal: #2e3642ff;
$black-coral: #4d5a6eff;
$slate-gray: #6c7e99ff;
$wild-blue-yonder: #8ba2c5ff; //principal
$light-steel-blue: #a5b7d2ff;
$periwinkle-crayola: #becbdfff;
$alice-blue: #d8e0ecff;

$oxford-blue: #101d31ff;
$space-cadet: #1b3052ff;
$dark-cornflower-blue: #264373ff;
$bdazzled-blue: #315694ff; //principal
$glaucous: #5f7cacff;
$wild-blue-yonder: #8ca1c3ff;
$light-steel-blue: #bac7dbff;

$eerie-black: #161616ff;
$eerie-black-2: #252525ff;
$jet: #333333ff;
$onyx: #424242ff; //principal
$dim-gray: #6c6c6cff;
$spanish-gray: #969696ff;
$silver: #c0c0c0ff;

$eerie-black: #1c1c1cff;
$jet: #2f2f2fff;
$onyx: #414141ff;
$davys-grey: #545454ff; //principal
$sonic-silver: #7a7a7aff;
$quick-silver: #a0a0a0ff;
$silver: #c6c6c6ff;

// Colores
$dark-blue: #325693;
$blue: #7096c1;
$l-blue: #c7d0df;
$bone: #ecedef;
$gold: #b4a26d;
$gold-20: #b4a26d20;
$gold-40: #b4a26d40;
$gold-60: #b4a26d60;
$gold-80: #b4a26d80;
$bone-20: #ecedef20;
$bone-40: #ecedef40;
$bone-60: #ecedef60;
$bone-80: #ecedef80;
$dark-blue-20: #32569320;
$dark-blue-40: #32569340;
$dark-blue-60: #32569360;
$dark-blue-80: #32569380;
$blue-20: #7096c120;
$blue-40: #7096c140;
$blue-60: #7096c160;
$blue-80: #7096c180;
$l-blue-20: #c7d0df20;
$l-blue-40: #c7d0df40;
$l-blue-60: #c7d0df60;
$l-blue-80: #c7d0df80;

$white-bone: #fdfdfd;
$white-l-blue: #f9fafc;
$white-blue: #f2f5f9;
$white-dark-blue: #eceff5;
$white-gold: #f8f6f1;

$d-white-bone: #f8f8f9;
$d-white-l-blue: #eaedf3;
$d-white-blue: #cbd8e8;
$d-white-dark-blue: #b4c1d7;
$d-white-gold: #e3ddc9;

$black-bone: #161616;
$black-l-blue: #131315;
$black-blue: #0b0e12;
$black-dark-blue: #05080e;
$black-gold: #110f0a;

$l-black-bone: #565757;
$l-black-l-blue: #494c52;
$l-black-blue: #293747;
$l-black-dark-blue: #132036;
$l-black-gold: #423b28;

$scrollbar-color:#252525;
$color-text:#80808F;

body {
  text-transform: uppercase;
  font-family: Poppins, Helvetica, sans-serif;
  background: #eef0f8;
}

input#adjuntoPermiso{
  display: none;
}

.nav-item.nav-link.active{
  background: $scrollbar-color!important;
  color: white;
  &:hover{
      color: $color-text
  }
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-4-5 {
  width: 5.5%;
}

.w-4 {
  width: 4%;
}

.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}

.w-12 {
  width: 12%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.aside-minimize {
  i.home-icon-menu {
      display: none;
  }
}

body::-webkit-scrollbar {
  width: .5rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(46, 46, 46, 0.3);
  background-color: #ECEEF6;
  border-radius: .25rem;
}
 
body::-webkit-scrollbar-thumb {
  background-color: $scrollbar-color;
  border-radius: .25rem;

}

//////////////////////////////////////////////////////////////
////////////////////////Queries///////////////////////////////
// .h-50-encuesta{
//   height: 35vh;
// }
// @media (min-width: 280px) and (max-height: 400px) {
//   .border-top {
//     border-top: 1px solid transparent !important;
//   }
//   #new-body {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: -2;
//     width: 100vw;
//     height: 100vh;
//     background-color: #315694ff !important;
//     overflow: hidden;
//   }
//   #image {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: -2;
//     width: 100vw;
//     height: 100vh;

//     overflow: hidden;
//     background-image: url(./Logo_IM_CMYK-02.png) !important;
//     background-position-y: center;
//     background-position-x: center;
//     opacity: 0.2;
//     // background-blend-mode:luminosity;
//   }

//   #wizardP {
//     background-color: #eef0f8 !important;
//     border: 1px solid #264373ff;
//     height: 83vh !important;
//     border-radius: 3px;
//     padding: 1rem;
//     overflow-y: scroll;
//   }

//   .btn.btn-outline-red {
//     color: $red-survey;
//     background-color: transparent;
//     border-color: $red-survey;
//     i {
//       color: $red-survey;
//     }
//     .svg-icon svg g [fill] {
//       -webkit-transition: fill 0.3s ease;
//       transition: fill 0.3s ease;
//       fill: $red-survey;
//     }
//     .svg-icon svg:hover g [fill] {
//       -webkit-transition: fill 0.3s ease;
//       transition: fill 0.3s ease;
//     }
//     &.dropdown-toggle:after {
//       color: $red-survey;
//     }
//   }

//   .marginTop {
//     margin-bottom: -4rem !important;
//     padding-bottom: -4rem !important;
//   }
//   .cinco-botones {
//     display: flex;
//     button {
//       width: 20% !important;
//       margin-right: 0.5rem;
//       &:last-of-type {
//         margin: 0;
//       }
//     }
//   }
//   .width-hr {
//     display: none;
//   }

//   .cinco2 {
//     display: flex;
//     justify-content: space-between;
//     button {
//       width: 40px !important;
//       height: 40px !important;

//       margin-right: 0.5rem;
//       &:last-of-type {
//         margin: 0;
//       }
//     }
//   }

//   .recomendar {
//     width: 10% !important;
//     height: 2.5rem;
//     font-size: 1rem;
//     padding: 0;
//   }

//   .titulo-sugerencias {
//     h6 {
//       font-size: 0.9rem !important;
//     }
//   }

//   .stay-bottom {
//     position: fixed;
//     bottom: 2vh;
//     width: 93%;
//     margin: 0 auto !important;
//   }
// }

// ////////////////////////////////////////////////////////////

// @media (min-width: 280px) and (min-height: 401px) {
//   // input{font-size: 2rem!important;}
//   .btn.btn-primary{
//     background-color: $ecru!important;
//   }
//   .h-50-encuesta{
//     height: 40vh;
//   }
//   .border-top {
//     border-top: 1px solid transparent !important;
//   }
//   #new-body {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: -2;
//     width: 100vw;
//     height: 100vh;
//     background-color: #315694ff !important;
//     overflow: hidden;
//   }
//   #image {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: -2;
//     width: 100vw;
//     height: 100vh;

//     overflow: hidden;
//     background-image: url(./Logo_IM_CMYK-02.png) !important;
//     background-position-y: center;
//     background-position-x: center;
//     opacity: 0.2;
//     // background-blend-mode:luminosity;
//   }

//   #wizardP {
//     background-color: #eef0f8 !important;
//     border: 1px solid #264373ff;
//     height: 85vh !important;
//     border-radius: 3px;
//     padding: 1rem;
//     overflow-y: scroll;
//   }

//   .btn.btn-outline-red {
//     color: $red-survey;
//     background-color: transparent;
//     border-color: $red-survey;
//     i {
//       color: $red-survey;
//     }
//     .svg-icon svg g [fill] {
//       -webkit-transition: fill 0.3s ease;
//       transition: fill 0.3s ease;
//       fill: $red-survey;
//     }
//     .svg-icon svg:hover g [fill] {
//       -webkit-transition: fill 0.3s ease;
//       transition: fill 0.3s ease;
//     }
//     &.dropdown-toggle:after {
//       color: $red-survey;
//     }
//   }

//   .marginTop {
//     margin-bottom: -4rem !important;
//     padding-bottom: -4rem !important;
//   }
//   .cinco-botones {
//     display: flex;
//     button {
//       width: 20% !important;
//       margin-right: 0.5rem;
//       &:last-of-type {
//         margin: 0;
//       }
//     }
//   }
//   .width-hr {
//     // width:80%;
//     // margin: 0 auto 1rem;
//     display: none;
//   }

//   .cinco2 {
//     display: flex;
//     justify-content: space-between;
//     button {
//       width: 40px !important;
//       height: 40px !important;

//       margin-right: 0.5rem;
//       &:last-of-type {
//         margin: 0;
//       }
//     }
//   }

//   .recomendar {
//     width: 10% !important;
//     height: 2.5rem;
//     font-size: 1rem;
//     padding: 0;
//   }

//   .titulo-sugerencias {
//     h6 {
//       font-size: 0.9rem !important;
//     }
//   }

//   .stay-bottom {
//     position: fixed;
//     bottom: 2vh;
//     width: 85%;
//     margin: 0 auto !important;
//     // bottom:2rem;
//     // padding: 0 .5rem;
//     // width: 92%;
//     // margin:0 auto;
//     // &:first-child{
//     //   margin-right: 1rem;
//     // }
//   }
// }

// @media (min-width: 280px) and (min-height: 520px) {
//   .h-50-encuesta{
//     height: 37vh;
//   }
//   // .btn.btn-primary{
//   //   background-color: $slate-gray!important;
//   // }
.form-group label{
  // font-size: 1.15rem;
  font-size: 12px;
  color: #8BA2C5 !important;

}
// #wizardP {
//     height: 89vh !important;
//   }
// }
// //   .stay-bottom {
// //     bottom: 1.5vh;
// //   }
// // }
//  @media (min-width: 280px) and (min-height: 720px){
//   .form-group label{
//     font-size: 1.5rem;
//   }
//   #wizardP {
//     height: 97vh !important;
//   }
//   .h-50-encuesta.mt-2{
//     margin-top: 5rem!important;
//     height: 35vh;
//   }
// }

// // @media (min-width: 280px) and (min-height: 1080px) {
// // }
// // @media (min-width: 480px) and (min-height: 360px){}
// // @media (min-width: 480px) and (min-height: 720px){}
// // @media (min-width: 480px) and (min-height: 1080px){}

// // @media (min-width: 960px) and (min-height: 360px) {
// // }
// @media (min-width: 720px) and (min-height: 720px) {
//   #wizardP {
//     height: 97.5vh !important;
//   }
//   .stay-bottom {
//     position: fixed;
//     bottom: 3vh;
//     width: 97%;
//     margin: 0 auto !important;
//   }
// }
// // @media (min-width: 960px) and (min-height: 1080px) {
// // }
// // @media (min-width: 1440px) and (min-height: 360px) {
// // }
// // @media (min-width: 1440px) and (min-height: 720px) {
// // }
// // @media (min-width: 1440px) and (min-height: 1080px) {
// // }
// // @media (min-width: 1920px) and (min-height: 360px) {
// // }
// // @media (min-width: 1920px) and (min-height: 720px) {
// // }
// // @media (min-width: 1920px) and (min-height: 1080px) {
// // }

/////////////////////////////////////////////////////////////

.btn.btn-outline-red:hover:not(.btn-text),
.btn.btn-outline-red:focus:not(.btn-text),
.btn.btn-outline-red.focus:not(.btn-text) {
  color: #ffffff;
  background-color: $red-survey;
  border-color: $red-survey;
}

.btn.btn-outline-red:hover:not(.btn-text) i,
.btn.btn-outline-red:focus:not(.btn-text) i,
.btn.btn-outline-red.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-outline-red:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-outline-red:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-outline-red.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-red:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-outline-red:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-outline-red.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-red:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-outline-red:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-outline-red.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-red.disabled,
.btn.btn-outline-red:disabled {
  color: $red-survey;
  background-color: transparent;
  border-color: $red-survey;
}

.btn.btn-outline-red.disabled i,
.btn.btn-outline-red:disabled i {
  color: $red-survey;
}

.btn.btn-outline-red.disabled .svg-icon svg g [fill],
.btn.btn-outline-red:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: $red-survey;
}

.btn.btn-outline-red.disabled .svg-icon svg:hover g [fill],
.btn.btn-outline-red:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-red.disabled.dropdown-toggle:after,
.btn.btn-outline-red:disabled.dropdown-toggle:after {
  color: $red-survey;
}

.btn.btn-outline-red:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-outline-red:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-red.dropdown-toggle,
.show .btn.btn-outline-red.btn-dropdown {
  color: #ffffff;
  background-color: $red-survey;
  border-color: $red-survey;
}

.btn.btn-outline-red:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-outline-red:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-red.dropdown-toggle i,
.show .btn.btn-outline-red.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-outline-red:not(:disabled):not(.disabled):active:not(.btn-text)
  .svg-icon
  svg
  g
  [fill],
.btn.btn-outline-red:not(:disabled):not(.disabled).active
  .svg-icon
  svg
  g
  [fill],
.show > .btn.btn-outline-red.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-red.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-red:not(:disabled):not(.disabled):active:not(.btn-text)
  .svg-icon
  svg:hover
  g
  [fill],
.btn.btn-outline-red:not(:disabled):not(.disabled).active
  .svg-icon
  svg:hover
  g
  [fill],
.show > .btn.btn-outline-red.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-red.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-red:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-outline-red:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-red.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-red.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-orange {
  color: $orange-survey;
  background-color: transparent;
  border-color: $orange-survey;
  i {
    color: $orange-survey;
  }
  .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: $orange-survey;
  }
  .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  &.dropdown-toggle:after {
    color: $orange-survey;
  }
}

.btn.btn-outline-orange:hover:not(.btn-text),
.btn.btn-outline-orange:focus:not(.btn-text),
.btn.btn-outline-orange.focus:not(.btn-text) {
  color: #ffffff;
  background-color: $orange-survey;
  border-color: $orange-survey;
}

.btn.btn-outline-orange:hover:not(.btn-text) i,
.btn.btn-outline-orange:focus:not(.btn-text) i,
.btn.btn-outline-orange.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-outline-orange:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-outline-orange:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-outline-orange.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-orange:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-outline-orange:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-outline-orange.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-orange:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-outline-orange:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-outline-orange.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-orange.disabled,
.btn.btn-outline-orange:disabled {
  color: $orange-survey;
  background-color: transparent;
  border-color: $orange-survey;
}

.btn.btn-outline-orange.disabled i,
.btn.btn-outline-orange:disabled i {
  color: $orange-survey;
}

.btn.btn-outline-orange.disabled .svg-icon svg g [fill],
.btn.btn-outline-orange:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: $orange-survey;
}

.btn.btn-outline-orange.disabled .svg-icon svg:hover g [fill],
.btn.btn-outline-orange:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-orange.disabled.dropdown-toggle:after,
.btn.btn-outline-orange:disabled.dropdown-toggle:after {
  color: $orange-survey;
}

.btn.btn-outline-orange:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-outline-orange:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-orange.dropdown-toggle,
.show .btn.btn-outline-orange.btn-dropdown {
  color: #ffffff;
  background-color: $orange-survey;
  border-color: $orange-survey;
}

.btn.btn-outline-orange:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-outline-orange:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-orange.dropdown-toggle i,
.show .btn.btn-outline-orange.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-outline-orange:not(:disabled):not(.disabled):active:not(.btn-text)
  .svg-icon
  svg
  g
  [fill],
.btn.btn-outline-orange:not(:disabled):not(.disabled).active
  .svg-icon
  svg
  g
  [fill],
.show > .btn.btn-outline-orange.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-orange.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-orange:not(:disabled):not(.disabled):active:not(.btn-text)
  .svg-icon
  svg:hover
  g
  [fill],
.btn.btn-outline-orange:not(:disabled):not(.disabled).active
  .svg-icon
  svg:hover
  g
  [fill],
.show > .btn.btn-outline-orange.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-orange.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-orange:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-outline-orange:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-orange.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-orange.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-green {
  color: $green-survey;
  background-color: transparent;
  border-color: $green-survey;
  i {
    color: $green-survey;
  }
  .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: $green-survey;
  }
  .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  &.dropdown-toggle:after {
    color: $green-survey;
  }
}

.btn.btn-outline-green:hover:not(.btn-text),
.btn.btn-outline-green:focus:not(.btn-text),
.btn.btn-outline-green.focus:not(.btn-text) {
  color: #ffffff;
  background-color: $green-survey;
  border-color: $green-survey;
}

.btn.btn-outline-green:hover:not(.btn-text) i,
.btn.btn-outline-green:focus:not(.btn-text) i,
.btn.btn-outline-green.focus:not(.btn-text) i {
  color: #ffffff;
}

.btn.btn-outline-green:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-outline-green:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-outline-green.focus:not(.btn-text) .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-green:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-outline-green:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-outline-green.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-green:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-outline-green:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-outline-green.focus:not(.btn-text).dropdown-toggle:after {
  color: #ffffff;
}

.btn.btn-outline-green.disabled,
.btn.btn-outline-green:disabled {
  color: $green-survey;
  background-color: transparent;
  border-color: $green-survey;
}

.btn.btn-outline-green.disabled i,
.btn.btn-outline-green:disabled i {
  color: $green-survey;
}

.btn.btn-outline-green.disabled .svg-icon svg g [fill],
.btn.btn-outline-green:disabled .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: $green-survey;
}

.btn.btn-outline-green.disabled .svg-icon svg:hover g [fill],
.btn.btn-outline-green:disabled .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-green.disabled.dropdown-toggle:after,
.btn.btn-outline-green:disabled.dropdown-toggle:after {
  color: $green-survey;
}

.btn.btn-outline-green:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-outline-green:not(:disabled):not(.disabled).active,
.show > .btn.btn-outline-green.dropdown-toggle,
.show .btn.btn-outline-green.btn-dropdown {
  color: #ffffff;
  background-color: $green-survey;
  border-color: $green-survey;
}

.btn.btn-outline-green:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-outline-green:not(:disabled):not(.disabled).active i,
.show > .btn.btn-outline-green.dropdown-toggle i,
.show .btn.btn-outline-green.btn-dropdown i {
  color: #ffffff;
}

.btn.btn-outline-green:not(:disabled):not(.disabled):active:not(.btn-text)
  .svg-icon
  svg
  g
  [fill],
.btn.btn-outline-green:not(:disabled):not(.disabled).active
  .svg-icon
  svg
  g
  [fill],
.show > .btn.btn-outline-green.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-outline-green.btn-dropdown .svg-icon svg g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #ffffff;
}

.btn.btn-outline-green:not(:disabled):not(.disabled):active:not(.btn-text)
  .svg-icon
  svg:hover
  g
  [fill],
.btn.btn-outline-green:not(:disabled):not(.disabled).active
  .svg-icon
  svg:hover
  g
  [fill],
.show > .btn.btn-outline-green.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-outline-green.btn-dropdown .svg-icon svg:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.btn.btn-outline-green:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-outline-green:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show > .btn.btn-outline-green.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-outline-green.btn-dropdown.dropdown-toggle:after {
  color: #ffffff;
}

///////////////////////////////////////////////////////////////

.pdfview {
  @include screen($lg) {
    // width: 275px;
    height: 418.984px;
  }

  @include screen($xxl) {
    // width: 468px;
    height: 418.984px;
  }

  width: 88%;
  height: 418.984px;
}

.text-align-last-center {
  text-align-last: center;
}

.cursor-pointer-hover:hover {
  cursor: pointer;
}

.w-max-content {
  width: max-content;
}

.object-fit-cover {
  object-fit: cover;
}

//Se quita el icono de los inputs
.sin_icono {
  background-image: none !important;
  padding-right: 0 !important;
}

.aside-minimize {
  i.home-icon-menu {
    display: none;
  }
}

.text-dark-60 {
  color: #686871 !important;
}

.justify-self-center {
  justify-self: center;
}

#adjunto {
  display: none !important;
}

.drop-files {
  background-color: #f1faff;
  border-style: dashed;
  border-radius: 0.475rem;
  border-color: #009ef7;
  border-width: 1px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}

.place-items-center {
  place-items: center;
}

.Toastify__toast-container {
  .Toastify__toast--default {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: 700;
    background-color: rgb(47, 47, 54);
  }
}

.notif-card {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgb(47, 47, 54);
  font-weight: 700;
  .date {
    font-weight: 100;
  }
}
.white-space-nowrap {
  white-space: nowrap;
}
.text-transform-none {
  text-transform: none;
}
.padding-popup {
  padding: 1.25em !important;
}
