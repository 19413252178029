.btn_custom-alert{
    font-size: 1rem!important;
    padding: .625em 1.1em;
}
.origin-confirm{
    background-color: #2d52a3!important;
}
.btn-cancel-alert{
    font-size: 1rem!important;
    background-color: #e3e5e7!important;
    color: #616161!important;
    padding: .625em 1.1em;
}
.solicitud-llamada-confirm{
    background-color: #b47cbd!important;
}
.solicitud-llamada-text{
    color: #b47cbd;
    font-weight: 600;
}
.cita-confirm{
    background-color: #2866be!important;
}
.relaciones-publicas-confirm{
    background-color: #6f47c8!important;
}
.relaciones-publicas-text{
    color: #6f47c8;
    font-weight: 600;
}
.delete-user-confirm{
    background-color: #b71c1c!important;
}
.delete-user-text{
    color: #b71c1c;
    font-weight: 600;
}
.swal2-progress-steps{
    justify-content: center;
}
.detenidos-confirm{
    background-color: #cb882d!important;
}
.detenidos-text{
    color: #cb882d;
    font-weight: 600;
}
.solicitar-cita-confirm{
    background-color: #ed7d2b!important;
}

.solicitar-cita-text{
    color: #ed7d2b!important;
}
.border-alert-cita{
    border-color: #e3e5e7;
}
.question-mail-confirm{ 
    background-color: #3c83f3!important;
}
.text-project-name {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1b9386;
}

.caja-chica-confirm{
    background-color: #455a64!important;
}
.delete-confirm{
    background-color: #e34646!important;
}