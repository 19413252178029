.text-dark-50 {
  color: #80808F !important;
}

.text-dark-75 {
  color: #464E5F !important;
}

.text-orange {
  color: #D08905 !important;
}

a.text-hover-orange:hover,
.text-hover-orange:hover {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  color: #D08905 !important;
}

.text-gray {
  color: #5F6A6A !important;
}

.text-green {
  color: #388E3C !important;
}

.text-hover {
  &:hover {
    cursor: pointer;
  }
}

.text-brown {
  color: #5D4037 !important;
}