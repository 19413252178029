/* General */

.link {
    color: #4154f1;
    text-decoration: none;
}

.link:hover {
    color: #717ff5;
    text-decoration: none;
}

/* Sections */
section {
    padding: 100px 0;
    overflow: hidden;
    background: white;
}

.section {
    padding: 100px 0;
    overflow: hidden;
    background: white;
}

.section-header {
    text-align: center;
    padding-bottom: 40px;
}

.section-header h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: #4154f1;
    text-transform: uppercase;
}

.section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: #012970;
}

@media (max-width: 768px) {
    .section-header p {
        font-size: 28px;
        line-height: 32px;
    }
}

/* Header */
.header-cliente {
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
}

.header-cliente.header-scrolled {
    background: #fff;
    padding: 15px 0;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.img-logo {
    max-height: 3.5rem;
    margin-right: 6px;
}

.padding-col-7 {
    padding-left: 5rem;
}

/* Navigation Menu */
/* Desktop Navigation */
.navbar-cliente {
    padding: 0;
}

.navbar-cliente ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar-cliente li {
    position: relative;
}

a.nav-cliente.nav-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 10px 10px;
    font-size: 1.3rem;
    font-weight: 500;
    color: #013289;
    white-space: nowrap;
    transition: 0.3s;

    &:hover {
        cursor: pointer;
    }
}

.navbar-cliente a i,
.navbar-cliente a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

a.nav-cliente.nav-link:hover,
a.nav-cliente.nav-link:active,
a.nav-cliente.nav-link:focus,
.navbar-cliente li:hover>a
{
    font-weight: 600;
}

.navbar-cliente .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
}

.navbar-cliente .dropdown ul li {
    min-width: 200px;
}

.navbar-cliente .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 600;
}

.navbar-cliente .dropdown ul a i {
    font-size: 12px;
}

.navbar-cliente.dropdown ul a:hover,
.navbar-cliente.dropdown ul .active:hover,
.navbar-cliente.dropdown ul li:hover>a {
    color: #4154f1;
}

.navbar-cliente.dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar-cliente.dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar-cliente.dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar-cliente.dropdown .dropdown ul {
        left: -90%;
    }

    .navbar-cliente.dropdown .dropdown:hover>ul {
        left: -100%;
    }
}

/* Mobile Navigation */
.mobile-nav-toggle {
    color: #012970;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
    color: #fff;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar-clienteul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(1, 22, 61, 0.9);
    transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a {
    padding: 10px 20px;
    font-size: 15px;
    color: #012970;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: #4154f1;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    color: #4154f1;
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}

/* Inicio Section */
.bienvenida-cliente {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-repeat: no-repeat;
}

.bienvenida-cliente h1 {
    margin: 0;
    font-size: 2.3rem;
    font-weight: 700;
    color: #012970;
}

.margin-y-30px {
    margin: 30px 0 30px 0;
}

.bienvenida-cliente h2 {
    color: #444444;
    font-size: 1.8rem;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.bienvenida-cliente h3 {
    font-weight: 600;
    font-size: 1.5rem;
    letter-spacing: 1px;
}

.bienvenida-cliente h4 {
    font-weight: 100;
    font-size: 1.2rem;
    text-align: justify;
    line-height: 1.8;
}

.bienvenida-cliente .btn-get-started {
    margin-top: 40px;
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #4154f1;
    box-shadow: 0px 5px 30px rgba(65, 84, 241, 0.4);
}

.padding-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.bienvenida-cliente .btn-get-started span {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}

.bienvenida-cliente .btn-get-started i {
    margin-left: 5px;
    font-size: 18px;
    transition: 0.3s;
}

.bienvenida-cliente .btn-get-started:hover i {
    transform: translateX(5px);
}

.bienvenida-cliente .bienvenida-img {
    text-align: right;
}

@media (min-width: 1024px) {
    .bienvenida-cliente {
        background-attachment: fixed;
    }
}

@media (max-width: 991px) {
    .bienvenida-cliente {
        height: auto;
        padding: 120px 0 0 0;
    }

    .img-logo {
        max-height: 45px;
    }

    .padding-container {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    .padding-col-7 {
        padding-left: 0
    }
}

@media (max-width: 768px) {
    .bienvenida-cliente {
        text-align: center;
    }

    .bienvenida-cliente .bienvenida-img img {
        width: 100%;
    }
}

/* Avances */
.avances .box {
    padding: 30px;
    box-shadow: 0px 0 5px rgba(1, 41, 112, 0.08);
    text-align: center;
    transition: 0.3s;
    height: 100%;
    background: #fafafa;
    border-bottom: 4px solid #3443c1;
    border-top: 1px solid #e9e9e9;
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
}

.avances .box img {
    padding: 30px 50px;
    transition: 0.5s;
    transform: scale(1.1);
}

.avances .box a {
    font-size: 2rem;
    color: #3443c1;
    font-weight: 700;
    margin-bottom: 18px;
    display: block;

}

.avances .box a:hover {
    text-decoration: underline !important;

}

.avances .box:hover {
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
}

.avances .box:hover img {
    transform: scale(1);
}

.avances .box p {
    font-weight: 100;
    white-space: pre-line;
    text-align: justify;
    margin-bottom: 0px;
    font-size: 1.1rem
}

.avances .box:hover {
    a {
        color: #455a64
    }

    border-bottom: 4px solid #455a64;
}

/* informacion */
.informacion .feature-box {
    padding: 24px 20px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
    transition: 0.3s;
    height: 100%;
}

.informacion .feature-box h3 {
    font-size: 18px;
    color: #012970;
    font-weight: 700;
    margin: 0;
}

.informacion .feature-box i {
    line-height: 0;
    background: #ecf3ff;
    padding: 4px;
    margin-right: 10px;
    font-size: 24px;
    border-radius: 3px;
    transition: 0.3s;
}

.informacion .feature-box:hover i {
    background: #4154f1;
    color: #fff;
}

.informacion .feture-tabs {
    margin-top: 120px;
}

.informacion .feture-tabs h3 {
    color: #012970;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .informacion .feture-tabs h3 {
        font-size: 28px;
    }
}

.informacion .feture-tabs .nav-pills {
    border-bottom: 1px solid #eee;
}

.informacion .feture-tabs .nav-link {
    background: none;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    color: #012970;
    padding: 12px 0;
    margin-right: 25px;
    margin-bottom: -2px;
    border-radius: 0;
}

.informacion .feture-tabs .nav-link.active {
    color: #4154f1;
    border-bottom: 3px solid #4154f1;
}

.informacion .feture-tabs .tab-content h4 {
    font-size: 18px;
    margin: 0;
    font-weight: 700;
    color: #012970;
}

.informacion .feture-tabs .tab-content i {
    font-size: 24px;
    line-height: 0;
    margin-right: 8px;
    color: #4154f1;
}

.informacion .feature-icons h3 {
    color: #012970;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
}

.informacion .feature-icons .content .icon-box {
    display: flex;
}

.informacion .feature-icons .content .icon-box h4 {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0 0 10px 0;
    color: #012970;
}

.informacion .feature-icons .content .icon-box i {
    font-size: 3rem;
    line-height: 44px;
    color: #0245bc;
    margin-right: 15px;
}

.informacion .feature-icons .content .icon-box p {
    font-size: 1.1rem;
    color: #464E5F;
    margin-bottom: 0;
    font-weight: 100;
}

.title-proyecto {
    color: #012970;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
}

.bg-blue-proyecto {
    background: #f6f9ff;
}

.navi .navi-item .navi-link.bg-active.active {
    background-color: white
}

.btn.btn-light-proyecto {
    background-color: #f6f9ff;
    border-color: #f6f9ff;
}

.box-shadow-button:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
}

.text-proyecto {
    color: #012970
}

.border-y-blue {
    border-top: 1px solid #e1ecff;
    border-bottom: 1px solid #e1ecff;
}

a.ml-0.proyecto.dropdown-item.active {
    color: #2171c1;
}

a.ml-0.proyecto.dropdown-item {
    color: #212121;
}


.col-md-6.icon-box.align-items-center.mb-7.fadeInUp:last-child {
    margin-bottom: 0px !important;
}

.bg-form{
    background-color: #f6f9ff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.bg-presupuestos{
    background: #F5FAFF;
}
/* Back to top button*/
.back-to-top {
    position: fixed;
    visibility: visible;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: #4154f1;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.back-to-top i {
    font-size: 2rem;
    color: #fff;
    line-height: 0;
}

.back-to-top:hover {
    background: #6776f4;
    color: #fff;
}

#table-tickets{
    // background: #f6f9ff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    thead{
        background: #cfd7e4!important;
    }
    tr {
        border-bottom-width: 1px;
        border-bottom-style: dashed;
        border-bottom-color: #e4e6ef;
    }
    tr:last-child{
        border-bottom: none;
    }
    tbody{
        tr:hover{
            background-color: #f3f6f9;
        }
    }
}
#table-mantenimientos{
    background: #f6f9ff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    thead{
        background: white;
        border: 10px solid #f6f9ff;
    }
    tr {
        border-bottom-width: 1px;
        border-bottom-style: dashed;
        border-bottom-color: #e4e6ef;
    }
    tr:last-child{
        border-bottom: none;
    }
    tr:hover{
        background-color: white;
    }
}
.header-section {
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: 600;
    margin: 0;
    color: #4154f1;
    text-align: center;
    margin-bottom: 0.6rem;
}
.span-nav{
    background: whitesmoke;
    padding: 0 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 5px;
}
.pt-13px{
    padding-top: 13px;
}

.link-durante-obra{
    color: #0097A7!important;
}
.link-termino-obra{
    color: #ff725e!important;
}
.nav-durante-obra, .nav-termino-obra{
    display: inline-flex;
}
.div-nav{
    display: block;
    text-align: center;
}
.nav-general-termino, .nav-general-durante{
    text-align: center;
}
@media (max-width: 768px) {
    .nav-durante-obra, .nav-termino-obra{
        display: inline-block;
    }
    .div-nav{
        display: block;
        text-align: left;
        background: whitesmoke;
        padding: 6px 3px;
        margin-bottom: 6px;
    }
    .header-cliente-mobile, .container-mobile{
        background: white;
    }
    .nav-general-termino, .nav-general-durante{
        text-align: left;
    }
}
.img-logo{
    object-fit: scale-down;
    background-size: contain;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px)  {
    a.nav-cliente.nav-link{
        font-size: 1rem!important;
    }
    .img-logo {
        max-height: 4rem;
        margin-right: 6px
    }
}
.header-scrolled{
    .header-proyecto{
        text-align: center;
        color: #444444;
        font-size: 1.1rem;
        font-weight: 500;
        letter-spacing: 1px;
        display: block!important;
    }
}
.a-name-planos:hover{
    font-weight: 500!important;
}

.listado-planos::before, .listado-planos::after{
    content: '';
    width: 12px;
    height: 2px;
    background: #2171c1;
    display: inline-block;
    margin: 0px 9px;
}

/* -------------- BUTTONS GROUPS --------------*/

/* PRESUPUESTOS */

// Reset
.btn-fill-vert:before, .btn-fill-vert:after{
    transition: all 0.3s;
}

.btn-fill-vert {
    border: 1px solid #dbdbe5;
    color: #212121;
    background-color: #dbdbe5;
    overflow: hidden;
    letter-spacing: 0.2px;
    font-weight: 300;
    text-transform: uppercase;
}
.btn-fill-vert:before, .btn-fill-vert:after {
    content: "";
    width: 100%;
    height: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    z-index: -1;
}
.btn-fill-vert:before {
    top: 50%;
}
.btn-fill-vert:after {
    bottom: 50%;
}
.btn-fill-vert:hover {
    color: #fff;
    font-weight: 500;
}
.btn-fill-vert:hover:before, .btn-fill-vert:hover:after {
    height: 50%;
    opacity: 1;
}
.btn-fill-vert.btn-group-presupuestos:before, .btn-fill-vert.btn-group-presupuestos:after {
    background-color: #0f9dac;
}
.btn-fill-vert.btn-group-presupuestos:hover {
    border-color: #0f9dac;
}

button.btn.btn-fill-vert.btn-group-presupuestos.active{
    background-color: #0f9dac;
    border-color: #0f9dac;
    color: white;
    font-weight: 500;
}


/* TICKETS */


.btn-fill-vert.btn-group-tickets:before, .btn-fill-vert.btn-group-tickets:after {
    background-color: #ff735f;
}
.btn-fill-vert.btn-group-tickets:hover {
    border-color: #ff735f;
}

button.btn.btn-fill-vert.btn-group-tickets.active{
    background-color: #ff735f;
    border-color: #ff735f;
    color: white;
    font-weight: 500;
}

