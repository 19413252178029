.logo-empresa {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.div-logo-empresa {
    height: 4rem;
    @include down-screen($md) {
        height: 3rem;
    }
}
div#symbol-total-contactos {
    @include down-screen($md) {
        text-align: center;
        margin-top: 11px;

        span.ml-4 {
            margin-left: 0px !important;
            display: block;
            margin-top: 8px;
        }
    }
}
.label-status{
    display:inline-flex;
    padding: 0.4rem 0.5rem;
    font-size: .65rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    border: transparent;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.475rem;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.3px;
}
.color-text-origenes{
    color: #6e7583;
}
.label.label-light-negociacion {
    color: #5D4037;
    background-color: #EFEBE9;
}
#rechazoForm, #canceladoForm{
    .form-check-label {
        font-size: 1.04rem;
    }
}
.bg-light-pink {
    background-color: #fbeff7 !important;
}

.text-pink {
    color: #e44e93 !important;
}

.label-origen{
    background-color: #f3f6f9;
    color: #80808F;
    border: transparent;
    padding: 2.8px 5.6px;
    width: auto;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 10.7px;
    font-weight: 500;
}
.w-isotipo{
    max-height: 3rem;
    width: auto;
    object-fit: cover;
    background-size: cover;
}
.table-crm-buscar{
    tbody{
        tr{
            border-bottom: 1px dashed #d3d6dc8a;
            &:last-child{
                border-bottom: none;
            }
        }
    }
}
.pagination-ul{
    ul.pagination{
        margin-bottom: 0;
    }
}

.title-info-info{
    color: #007af0!important;
    font-weight: 600!important;
}
// TABLE ACTIVIDADES CRM - COTIZACIÓN DE DISEÑO

.table-actividades, .table-semanas{
    tbody td{
        border-top: 1px dashed #ecf0f3!important;
    }
}

.p-sticky-card{
    position: sticky;
    top: 130px;
    z-index: 1;
}
// TABLE DATOS CP CLIENTE
.data-cp-table{
    .border-top-2px {
        border-top: 2px solid #e3e6e8
    }
    tr:nth-child(2n+0) {
        background-color: #f3f6f9f5;
    }
}
// BORDER - CONTRARAR LEAD - INFO
.border-nav {
    border: 3px solid #F3F6F9;
    padding: 1.75rem 1.5rem;
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
}
.label-origenes{
    border-radius: 0.42rem;
    background-color: #f3f6f9;
    color: #80808F;
    border: transparent;
    padding: 2.8px 5.6px;
    width: auto;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 10.7px;
    font-weight: 500;
}

.bg-table-contacto{
    background-color:#ebf5ff!important;
}
.text-contacto{
    color:#318ce9!important;
}

// NAV DE COTIZACIONES DE DISEÑO

.navi .navi-item .navi-link.active .text-hover-warning {
    color: #ffa800;
    border-left: 3px solid #ffa800;
    padding-left: 10px;
}

.navi .navi-item .navi-link.active .text-hover-info {
    color: #8950FC;
    border-left: 3px solid #8950FC;
    padding-left: 10px;
}

.navi .navi-item .navi-link.active .text-hover-primary {
    color: #2171c1;
    border-left: 3px solid #2171c1;
    padding-left: 10px;
}

.navi.navi-active .navi-item .bg-navi-light-info.active {
    background-color: #f4efff;
    border-radius: 0;
}

.navi.navi-active .navi-item .bg-navi-light-primary.active {
    background-color: #ebf5ff;
    border-radius: 0;
}

.navi.navi-active .navi-item .bg-navi-light-warning.active {
    background-color: #fff7e8;
    border-radius: 0;
}


// INFO PROYECTO

.info-proyecto h2 {
    color: #000000;
    font-size: 36px;
    margin-bottom: 30px;
}

.info-proyecto svg {
    position: absolute;
    margin-left: -100px;
    margin-top: 100px;
}

@media (max-width: 767px) {
    .info-proyecto svg {
        margin: 0 auto;
        margin-top: 200px;
    }
}

.info-proyecto .col-cards {
    padding: 10px 100px;
}

@media (max-width: 767px) {
    .info-proyecto .col-cards {
        padding: 10px 0;
    }
}

.info-proyecto .col-cards .row .col-md-6:first-child {
    margin-top: 150px;
}

.info-proyecto .col-cards .card-principal {
    background: #fff;
    margin: 30px 5px;
    padding: 20px 10px;
    border-radius: 20px;
    box-shadow: 0 15px 40px 0 rgba(0, 0, 0, 0.08);
}

.info-proyecto .col-cards .card-principal .oval {
    background: linear-gradient(to right, #f1f6fd, #f0fbfe);
    padding: 10px;
    width: 150px;
    border-radius: 50%;
    margin: auto;
    transform: rotate(20deg);
    margin-top: 20px;
    height: 105px;
}

.info-proyecto .col-cards .card-principal .card-img {
    max-width: 0.8in;
    margin: 0 auto;
    transform: rotate(-20deg);
}

.info-proyecto .col-cards .card-principal h3 {
    font-size: 1.4rem;
    color: #0073f0;
}

.info-proyecto .col-cards .card-principal p {
    font-size: 16px;
}

.info-proyecto-list {
    margin-top: 100px;
}

.info-proyecto-list .media {
    margin: 20px 0;
}

.info-proyecto-list .oval {
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
    height: 60px;
    width: 60px;
}
@media (max-width: 750px) {
    .info-proyecto .col-cards .card{
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    }
    .info-proyecto svg{
        display:none;
    }
    .info-proyecto .col-cards .row .col-md-6:first-child{
        margin-top: 0 !important;
    }
}

.info-proyecto-list .oval .align-self-start {
    width: 100%;
    padding: 3px;
}

.info-proyecto-list .media-body {
    font-size: 16px;
    color: #666666;
    padding-right: 20px;
}

.info-proyecto-list .media-body h5 {
    margin-bottom: 20px !important;
    font-size: 22px;
}

.tb-contacto{
    td{
        padding-top: 0;
        padding-bottom: 0;
        height:2rem;
    }
}