
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue {
    border-bottom-color: transparent !important;
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link:hover:not(.disabled),
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link.active,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .show>.nav-link {
    border-bottom-color: #007af0;
    color: #007af0;
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link:hover:not(.disabled) .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link.active .nav-text,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .show>.nav-link .nav-text {
    color: #007af0;
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link:hover:not(.disabled) .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link.active .nav-icon i,
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .show>.nav-link .nav-icon i {
    color: #007af0;
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link.active .nav-icon .svg-icon svg g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .show>.nav-link .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #007af0;
}

.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link:hover:not(.disabled) .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .nav-link.active .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-blue .show>.nav-link .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}