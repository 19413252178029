.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 0.75rem !important;
}

.mt-3,
.my-3 {
    margin-top: 0.75rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 0.75rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 0.75rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 0.75rem !important;
}

.m-4 {
    margin: 1rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1rem !important;
}

.m-5 {
    margin: 1.25rem !important;
}

.mt-5,
.my-5 {
    margin-top: 1.25rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 1.25rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 1.25rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 1.25rem !important;
}

.m-6 {
    margin: 1.5rem !important;
}

.mt-6,
.my-6 {
    margin-top: 1.5rem !important;
}

.mr-6,
.mx-6 {
    margin-right: 1.5rem !important;
}

.mb-6,
.my-6 {
    margin-bottom: 1.5rem !important;
}

.ml-6,
.mx-6 {
    margin-left: 1.5rem !important;
}

.m-7 {
    margin: 1.75rem !important;
}

.mt-7,
.my-7 {
    margin-top: 1.75rem !important;
}

.mr-7,
.mx-7 {
    margin-right: 1.75rem !important;
}

.mb-7,
.my-7 {
    margin-bottom: 1.75rem !important;
}

.ml-7,
.mx-7 {
    margin-left: 1.75rem !important;
}

.m-8 {
    margin: 2rem !important;
}

.mt-8,
.my-8 {
    margin-top: 2rem !important;
}

.mr-8,
.mx-8 {
    margin-right: 2rem !important;
}

.mb-8,
.my-8 {
    margin-bottom: 2rem !important;
}

.ml-8,
.mx-8 {
    margin-left: 2rem !important;
}

.m-9 {
    margin: 2.25rem !important;
}

.mt-9,
.my-9 {
    margin-top: 2.25rem !important;
}

.mr-9,
.mx-9 {
    margin-right: 2.25rem !important;
}

.mb-9,
.my-9 {
    margin-bottom: 2.25rem !important;
}

.ml-9,
.mx-9 {
    margin-left: 2.25rem !important;
}

.m-10 {
    margin: 2.5rem !important;
}

.mt-10,
.my-10 {
    margin-top: 2.5rem !important;
}

.mr-10,
.mx-10 {
    margin-right: 2.5rem !important;
}

.mb-10,
.my-10 {
    margin-bottom: 2.5rem !important;
}

.ml-10,
.mx-10 {
    margin-left: 2.5rem !important;
}

.m-11 {
    margin: 2.75rem !important;
}

.mt-11,
.my-11 {
    margin-top: 2.75rem !important;
}

.mr-11,
.mx-11 {
    margin-right: 2.75rem !important;
}

.mb-11,
.my-11 {
    margin-bottom: 2.75rem !important;
}

.ml-11,
.mx-11 {
    margin-left: 2.75rem !important;
}

.m-12 {
    margin: 3rem !important;
}

.mt-12,
.my-12 {
    margin-top: 3rem !important;
}

.mr-12,
.mx-12 {
    margin-right: 3rem !important;
}

.mb-12,
.my-12 {
    margin-bottom: 3rem !important;
}

.ml-12,
.mx-12 {
    margin-left: 3rem !important;
}

.m-13 {
    margin: 3.25rem !important;
}

.mt-13,
.my-13 {
    margin-top: 3.25rem !important;
}

.mr-13,
.mx-13 {
    margin-right: 3.25rem !important;
}

.mb-13,
.my-13 {
    margin-bottom: 3.25rem !important;
}

.ml-13,
.mx-13 {
    margin-left: 3.25rem !important;
}

.m-14 {
    margin: 3.5rem !important;
}

.mt-14,
.my-14 {
    margin-top: 3.5rem !important;
}

.mr-14,
.mx-14 {
    margin-right: 3.5rem !important;
}

.mb-14,
.my-14 {
    margin-bottom: 3.5rem !important;
}

.ml-14,
.mx-14 {
    margin-left: 3.5rem !important;
}

.m-15 {
    margin: 3.75rem !important;
}

.mt-15,
.my-15 {
    margin-top: 3.75rem !important;
}

.mr-15,
.mx-15 {
    margin-right: 3.75rem !important;
}

.mb-15,
.my-15 {
    margin-bottom: 3.75rem !important;
}

.ml-15,
.mx-15 {
    margin-left: 3.75rem !important;
}

.m-16 {
    margin: 4rem !important;
}

.mt-16,
.my-16 {
    margin-top: 4rem !important;
}

.mr-16,
.mx-16 {
    margin-right: 4rem !important;
}

.mb-16,
.my-16 {
    margin-bottom: 4rem !important;
}

.ml-16,
.mx-16 {
    margin-left: 4rem !important;
}

.m-17 {
    margin: 4.25rem !important;
}

.mt-17,
.my-17 {
    margin-top: 4.25rem !important;
}

.mr-17,
.mx-17 {
    margin-right: 4.25rem !important;
}

.mb-17,
.my-17 {
    margin-bottom: 4.25rem !important;
}

.ml-17,
.mx-17 {
    margin-left: 4.25rem !important;
}

.m-18 {
    margin: 4.5rem !important;
}

.mt-18,
.my-18 {
    margin-top: 4.5rem !important;
}

.mr-18,
.mx-18 {
    margin-right: 4.5rem !important;
}

.mb-18,
.my-18 {
    margin-bottom: 4.5rem !important;
}

.ml-18,
.mx-18 {
    margin-left: 4.5rem !important;
}

.m-19 {
    margin: 4.75rem !important;
}

.mt-19,
.my-19 {
    margin-top: 4.75rem !important;
}

.mr-19,
.mx-19 {
    margin-right: 4.75rem !important;
}

.mb-19,
.my-19 {
    margin-bottom: 4.75rem !important;
}

.ml-19,
.mx-19 {
    margin-left: 4.75rem !important;
}

.m-20 {
    margin: 5rem !important;
}

.mt-20,
.my-20 {
    margin-top: 5rem !important;
}

.mr-20,
.mx-20 {
    margin-right: 5rem !important;
}

.mb-20,
.my-20 {
    margin-bottom: 5rem !important;
}

.ml-20,
.mx-20 {
    margin-left: 5rem !important;
}

.m-21 {
    margin: 5.25rem !important;
}

.mt-21,
.my-21 {
    margin-top: 5.25rem !important;
}

.mr-21,
.mx-21 {
    margin-right: 5.25rem !important;
}

.mb-21,
.my-21 {
    margin-bottom: 5.25rem !important;
}

.ml-21,
.mx-21 {
    margin-left: 5.25rem !important;
}

.m-22 {
    margin: 5.5rem !important;
}

.mt-22,
.my-22 {
    margin-top: 5.5rem !important;
}

.mr-22,
.mx-22 {
    margin-right: 5.5rem !important;
}

.mb-22,
.my-22 {
    margin-bottom: 5.5rem !important;
}

.ml-22,
.mx-22 {
    margin-left: 5.5rem !important;
}

.m-23 {
    margin: 5.75rem !important;
}

.mt-23,
.my-23 {
    margin-top: 5.75rem !important;
}

.mr-23,
.mx-23 {
    margin-right: 5.75rem !important;
}

.mb-23,
.my-23 {
    margin-bottom: 5.75rem !important;
}

.ml-23,
.mx-23 {
    margin-left: 5.75rem !important;
}

.m-24 {
    margin: 6rem !important;
}

.mt-24,
.my-24 {
    margin-top: 6rem !important;
}

.mr-24,
.mx-24 {
    margin-right: 6rem !important;
}

.mb-24,
.my-24 {
    margin-bottom: 6rem !important;
}

.ml-24,
.mx-24 {
    margin-left: 6rem !important;
}

.m-25 {
    margin: 6.25rem !important;
}

.mt-25,
.my-25 {
    margin-top: 6.25rem !important;
}

.mr-25,
.mx-25 {
    margin-right: 6.25rem !important;
}

.mb-25,
.my-25 {
    margin-bottom: 6.25rem !important;
}

.ml-25,
.mx-25 {
    margin-left: 6.25rem !important;
}

.m-26 {
    margin: 6.5rem !important;
}

.mt-26,
.my-26 {
    margin-top: 6.5rem !important;
}

.mr-26,
.mx-26 {
    margin-right: 6.5rem !important;
}

.mb-26,
.my-26 {
    margin-bottom: 6.5rem !important;
}

.ml-26,
.mx-26 {
    margin-left: 6.5rem !important;
}

.m-27 {
    margin: 6.75rem !important;
}

.mt-27,
.my-27 {
    margin-top: 6.75rem !important;
}

.mr-27,
.mx-27 {
    margin-right: 6.75rem !important;
}

.mb-27,
.my-27 {
    margin-bottom: 6.75rem !important;
}

.ml-27,
.mx-27 {
    margin-left: 6.75rem !important;
}

.m-38 {
    margin: 7rem !important;
}

.mt-38,
.my-38 {
    margin-top: 7rem !important;
}

.mr-38,
.mx-38 {
    margin-right: 7rem !important;
}

.mb-38,
.my-38 {
    margin-bottom: 7rem !important;
}

.ml-38,
.mx-38 {
    margin-left: 7rem !important;
}

.m-29 {
    margin: 7.25rem !important;
}

.mt-29,
.my-29 {
    margin-top: 7.25rem !important;
}

.mr-29,
.mx-29 {
    margin-right: 7.25rem !important;
}

.mb-29,
.my-29 {
    margin-bottom: 7.25rem !important;
}

.ml-29,
.mx-29 {
    margin-left: 7.25rem !important;
}

.m-30 {
    margin: 7.5rem !important;
}

.mt-30,
.my-30 {
    margin-top: 7.5rem !important;
}

.mr-30,
.mx-30 {
    margin-right: 7.5rem !important;
}

.mb-30,
.my-30 {
    margin-bottom: 7.5rem !important;
}

.ml-30,
.mx-30 {
    margin-left: 7.5rem !important;
}

.m-31 {
    margin: 7.75rem !important;
}

.mt-31,
.my-31 {
    margin-top: 7.75rem !important;
}

.mr-31,
.mx-31 {
    margin-right: 7.75rem !important;
}

.mb-31,
.my-31 {
    margin-bottom: 7.75rem !important;
}

.ml-31,
.mx-31 {
    margin-left: 7.75rem !important;
}

.m-32 {
    margin: 8rem !important;
}

.mt-32,
.my-32 {
    margin-top: 8rem !important;
}

.mr-32,
.mx-32 {
    margin-right: 8rem !important;
}

.mb-32,
.my-32 {
    margin-bottom: 8rem !important;
}

.ml-32,
.mx-32 {
    margin-left: 8rem !important;
}

.m-33 {
    margin: 8.25rem !important;
}

.mt-33,
.my-33 {
    margin-top: 8.25rem !important;
}

.mr-33,
.mx-33 {
    margin-right: 8.25rem !important;
}

.mb-33,
.my-33 {
    margin-bottom: 8.25rem !important;
}

.ml-33,
.mx-33 {
    margin-left: 8.25rem !important;
}

.m-34 {
    margin: 8.5rem !important;
}

.mt-34,
.my-34 {
    margin-top: 8.5rem !important;
}

.mr-34,
.mx-34 {
    margin-right: 8.5rem !important;
}

.mb-34,
.my-34 {
    margin-bottom: 8.5rem !important;
}

.ml-34,
.mx-34 {
    margin-left: 8.5rem !important;
}

.m-35 {
    margin: 8.75rem !important;
}

.mt-35,
.my-35 {
    margin-top: 8.75rem !important;
}

.mr-35,
.mx-35 {
    margin-right: 8.75rem !important;
}

.mb-35,
.my-35 {
    margin-bottom: 8.75rem !important;
}

.ml-35,
.mx-35 {
    margin-left: 8.75rem !important;
}

.m-36 {
    margin: 9rem !important;
}

.mt-36,
.my-36 {
    margin-top: 9rem !important;
}

.mr-36,
.mx-36 {
    margin-right: 9rem !important;
}

.mb-36,
.my-36 {
    margin-bottom: 9rem !important;
}

.ml-36,
.mx-36 {
    margin-left: 9rem !important;
}

.m-37 {
    margin: 9.25rem !important;
}

.mt-37,
.my-37 {
    margin-top: 9.25rem !important;
}

.mr-37,
.mx-37 {
    margin-right: 9.25rem !important;
}

.mb-37,
.my-37 {
    margin-bottom: 9.25rem !important;
}

.ml-37,
.mx-37 {
    margin-left: 9.25rem !important;
}

.m-48 {
    margin: 9.5rem !important;
}

.mt-48,
.my-48 {
    margin-top: 9.5rem !important;
}

.mr-48,
.mx-48 {
    margin-right: 9.5rem !important;
}

.mb-48,
.my-48 {
    margin-bottom: 9.5rem !important;
}

.ml-48,
.mx-48 {
    margin-left: 9.5rem !important;
}

.m-39 {
    margin: 9.75rem !important;
}

.mt-39,
.my-39 {
    margin-top: 9.75rem !important;
}

.mr-39,
.mx-39 {
    margin-right: 9.75rem !important;
}

.mb-39,
.my-39 {
    margin-bottom: 9.75rem !important;
}

.ml-39,
.mx-39 {
    margin-left: 9.75rem !important;
}

.m-40 {
    margin: 10rem !important;
}

.mt-40,
.my-40 {
    margin-top: 10rem !important;
}

.mr-40,
.mx-40 {
    margin-right: 10rem !important;
}

.mb-40,
.my-40 {
    margin-bottom: 10rem !important;
}

.ml-40,
.mx-40 {
    margin-left: 10rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 0.75rem !important;
}

.pt-3,
.py-3 {
    padding-top: 0.75rem !important;
}

.pr-3,
.px-3 {
    padding-right: 0.75rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 0.75rem !important;
}

.pl-3,
.px-3 {
    padding-left: 0.75rem !important;
}

.p-4 {
    padding: 1rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1rem !important;
}

.p-5 {
    padding: 1.25rem !important;
}

.pt-5,
.py-5 {
    padding-top: 1.25rem !important;
}

.pr-5,
.px-5 {
    padding-right: 1.25rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 1.25rem !important;
}

.pl-5,
.px-5 {
    padding-left: 1.25rem !important;
}

.p-6 {
    padding: 1.5rem !important;
}

.pt-6,
.py-6 {
    padding-top: 1.5rem !important;
}

.pr-6,
.px-6 {
    padding-right: 1.5rem !important;
}

.pb-6,
.py-6 {
    padding-bottom: 1.5rem !important;
}

.pl-6,
.px-6 {
    padding-left: 1.5rem !important;
}

.p-7 {
    padding: 1.75rem !important;
}

.pt-7,
.py-7 {
    padding-top: 1.75rem !important;
}

.pr-7,
.px-7 {
    padding-right: 1.75rem !important;
}

.pb-7,
.py-7 {
    padding-bottom: 1.75rem !important;
}

.pl-7,
.px-7 {
    padding-left: 1.75rem !important;
}

.p-8 {
    padding: 2rem !important;
}

.pt-8,
.py-8 {
    padding-top: 2rem !important;
}

.pr-8,
.px-8 {
    padding-right: 2rem !important;
}

.pb-8,
.py-8 {
    padding-bottom: 2rem !important;
}

.pl-8,
.px-8 {
    padding-left: 2rem !important;
}

.p-9 {
    padding: 2.25rem !important;
}

.pt-9,
.py-9 {
    padding-top: 2.25rem !important;
}

.pr-9,
.px-9 {
    padding-right: 2.25rem !important;
}

.pb-9,
.py-9 {
    padding-bottom: 2.25rem !important;
}

.pl-9,
.px-9 {
    padding-left: 2.25rem !important;
}

.p-10 {
    padding: 2.5rem !important;
}

.pt-10,
.py-10 {
    padding-top: 2.5rem !important;
}

.pr-10,
.px-10 {
    padding-right: 2.5rem !important;
}

.pb-10,
.py-10 {
    padding-bottom: 2.5rem !important;
}

.pl-10,
.px-10 {
    padding-left: 2.5rem !important;
}

.p-11 {
    padding: 2.75rem !important;
}

.pt-11,
.py-11 {
    padding-top: 2.75rem !important;
}

.pr-11,
.px-11 {
    padding-right: 2.75rem !important;
}

.pb-11,
.py-11 {
    padding-bottom: 2.75rem !important;
}

.pl-11,
.px-11 {
    padding-left: 2.75rem !important;
}

.p-12 {
    padding: 3rem !important;
}

.pt-12,
.py-12 {
    padding-top: 3rem !important;
}

.pr-12,
.px-12 {
    padding-right: 3rem !important;
}

.pb-12,
.py-12 {
    padding-bottom: 3rem !important;
}

.pl-12,
.px-12 {
    padding-left: 3rem !important;
}

.p-13 {
    padding: 3.25rem !important;
}

.pt-13,
.py-13 {
    padding-top: 3.25rem !important;
}

.pr-13,
.px-13 {
    padding-right: 3.25rem !important;
}

.pb-13,
.py-13 {
    padding-bottom: 3.25rem !important;
}

.pl-13,
.px-13 {
    padding-left: 3.25rem !important;
}

.p-14 {
    padding: 3.5rem !important;
}

.pt-14,
.py-14 {
    padding-top: 3.5rem !important;
}

.pr-14,
.px-14 {
    padding-right: 3.5rem !important;
}

.pb-14,
.py-14 {
    padding-bottom: 3.5rem !important;
}

.pl-14,
.px-14 {
    padding-left: 3.5rem !important;
}

.p-15 {
    padding: 3.75rem !important;
}

.pt-15,
.py-15 {
    padding-top: 3.75rem !important;
}

.pr-15,
.px-15 {
    padding-right: 3.75rem !important;
}

.pb-15,
.py-15 {
    padding-bottom: 3.75rem !important;
}

.pl-15,
.px-15 {
    padding-left: 3.75rem !important;
}

.p-16 {
    padding: 4rem !important;
}

.pt-16,
.py-16 {
    padding-top: 4rem !important;
}

.pr-16,
.px-16 {
    padding-right: 4rem !important;
}

.pb-16,
.py-16 {
    padding-bottom: 4rem !important;
}

.pl-16,
.px-16 {
    padding-left: 4rem !important;
}

.p-17 {
    padding: 4.25rem !important;
}

.pt-17,
.py-17 {
    padding-top: 4.25rem !important;
}

.pr-17,
.px-17 {
    padding-right: 4.25rem !important;
}

.pb-17,
.py-17 {
    padding-bottom: 4.25rem !important;
}

.pl-17,
.px-17 {
    padding-left: 4.25rem !important;
}

.p-18 {
    padding: 4.5rem !important;
}

.pt-18,
.py-18 {
    padding-top: 4.5rem !important;
}

.pr-18,
.px-18 {
    padding-right: 4.5rem !important;
}

.pb-18,
.py-18 {
    padding-bottom: 4.5rem !important;
}

.pl-18,
.px-18 {
    padding-left: 4.5rem !important;
}

.p-19 {
    padding: 4.75rem !important;
}

.pt-19,
.py-19 {
    padding-top: 4.75rem !important;
}

.pr-19,
.px-19 {
    padding-right: 4.75rem !important;
}

.pb-19,
.py-19 {
    padding-bottom: 4.75rem !important;
}

.pl-19,
.px-19 {
    padding-left: 4.75rem !important;
}

.p-20 {
    padding: 5rem !important;
}

.pt-20,
.py-20 {
    padding-top: 5rem !important;
}

.pr-20,
.px-20 {
    padding-right: 5rem !important;
}

.pb-20,
.py-20 {
    padding-bottom: 5rem !important;
}

.pl-20,
.px-20 {
    padding-left: 5rem !important;
}

.p-21 {
    padding: 5.25rem !important;
}

.pt-21,
.py-21 {
    padding-top: 5.25rem !important;
}

.pr-21,
.px-21 {
    padding-right: 5.25rem !important;
}

.pb-21,
.py-21 {
    padding-bottom: 5.25rem !important;
}

.pl-21,
.px-21 {
    padding-left: 5.25rem !important;
}

.p-22 {
    padding: 5.5rem !important;
}

.pt-22,
.py-22 {
    padding-top: 5.5rem !important;
}

.pr-22,
.px-22 {
    padding-right: 5.5rem !important;
}

.pb-22,
.py-22 {
    padding-bottom: 5.5rem !important;
}

.pl-22,
.px-22 {
    padding-left: 5.5rem !important;
}

.p-23 {
    padding: 5.75rem !important;
}

.pt-23,
.py-23 {
    padding-top: 5.75rem !important;
}

.pr-23,
.px-23 {
    padding-right: 5.75rem !important;
}

.pb-23,
.py-23 {
    padding-bottom: 5.75rem !important;
}

.pl-23,
.px-23 {
    padding-left: 5.75rem !important;
}

.p-24 {
    padding: 6rem !important;
}

.pt-24,
.py-24 {
    padding-top: 6rem !important;
}

.pr-24,
.px-24 {
    padding-right: 6rem !important;
}

.pb-24,
.py-24 {
    padding-bottom: 6rem !important;
}

.pl-24,
.px-24 {
    padding-left: 6rem !important;
}

.p-25 {
    padding: 6.25rem !important;
}

.pt-25,
.py-25 {
    padding-top: 6.25rem !important;
}

.pr-25,
.px-25 {
    padding-right: 6.25rem !important;
}

.pb-25,
.py-25 {
    padding-bottom: 6.25rem !important;
}

.pl-25,
.px-25 {
    padding-left: 6.25rem !important;
}

.p-26 {
    padding: 6.5rem !important;
}

.pt-26,
.py-26 {
    padding-top: 6.5rem !important;
}

.pr-26,
.px-26 {
    padding-right: 6.5rem !important;
}

.pb-26,
.py-26 {
    padding-bottom: 6.5rem !important;
}

.pl-26,
.px-26 {
    padding-left: 6.5rem !important;
}

.p-27 {
    padding: 6.75rem !important;
}

.pt-27,
.py-27 {
    padding-top: 6.75rem !important;
}

.pr-27,
.px-27 {
    padding-right: 6.75rem !important;
}

.pb-27,
.py-27 {
    padding-bottom: 6.75rem !important;
}

.pl-27,
.px-27 {
    padding-left: 6.75rem !important;
}

.p-38 {
    padding: 7rem !important;
}

.pt-38,
.py-38 {
    padding-top: 7rem !important;
}

.pr-38,
.px-38 {
    padding-right: 7rem !important;
}

.pb-38,
.py-38 {
    padding-bottom: 7rem !important;
}

.pl-38,
.px-38 {
    padding-left: 7rem !important;
}

.p-29 {
    padding: 7.25rem !important;
}

.pt-29,
.py-29 {
    padding-top: 7.25rem !important;
}

.pr-29,
.px-29 {
    padding-right: 7.25rem !important;
}

.pb-29,
.py-29 {
    padding-bottom: 7.25rem !important;
}

.pl-29,
.px-29 {
    padding-left: 7.25rem !important;
}

.p-30 {
    padding: 7.5rem !important;
}

.pt-30,
.py-30 {
    padding-top: 7.5rem !important;
}

.pr-30,
.px-30 {
    padding-right: 7.5rem !important;
}

.pb-30,
.py-30 {
    padding-bottom: 7.5rem !important;
}

.pl-30,
.px-30 {
    padding-left: 7.5rem !important;
}

.p-31 {
    padding: 7.75rem !important;
}

.pt-31,
.py-31 {
    padding-top: 7.75rem !important;
}

.pr-31,
.px-31 {
    padding-right: 7.75rem !important;
}

.pb-31,
.py-31 {
    padding-bottom: 7.75rem !important;
}

.pl-31,
.px-31 {
    padding-left: 7.75rem !important;
}

.p-32 {
    padding: 8rem !important;
}

.pt-32,
.py-32 {
    padding-top: 8rem !important;
}

.pr-32,
.px-32 {
    padding-right: 8rem !important;
}

.pb-32,
.py-32 {
    padding-bottom: 8rem !important;
}

.pl-32,
.px-32 {
    padding-left: 8rem !important;
}

.p-33 {
    padding: 8.25rem !important;
}

.pt-33,
.py-33 {
    padding-top: 8.25rem !important;
}

.pr-33,
.px-33 {
    padding-right: 8.25rem !important;
}

.pb-33,
.py-33 {
    padding-bottom: 8.25rem !important;
}

.pl-33,
.px-33 {
    padding-left: 8.25rem !important;
}

.p-34 {
    padding: 8.5rem !important;
}

.pt-34,
.py-34 {
    padding-top: 8.5rem !important;
}

.pr-34,
.px-34 {
    padding-right: 8.5rem !important;
}

.pb-34,
.py-34 {
    padding-bottom: 8.5rem !important;
}

.pl-34,
.px-34 {
    padding-left: 8.5rem !important;
}

.p-35 {
    padding: 8.75rem !important;
}

.pt-35,
.py-35 {
    padding-top: 8.75rem !important;
}

.pr-35,
.px-35 {
    padding-right: 8.75rem !important;
}

.pb-35,
.py-35 {
    padding-bottom: 8.75rem !important;
}

.pl-35,
.px-35 {
    padding-left: 8.75rem !important;
}

.p-36 {
    padding: 9rem !important;
}

.pt-36,
.py-36 {
    padding-top: 9rem !important;
}

.pr-36,
.px-36 {
    padding-right: 9rem !important;
}

.pb-36,
.py-36 {
    padding-bottom: 9rem !important;
}

.pl-36,
.px-36 {
    padding-left: 9rem !important;
}

.p-37 {
    padding: 9.25rem !important;
}

.pt-37,
.py-37 {
    padding-top: 9.25rem !important;
}

.pr-37,
.px-37 {
    padding-right: 9.25rem !important;
}

.pb-37,
.py-37 {
    padding-bottom: 9.25rem !important;
}

.pl-37,
.px-37 {
    padding-left: 9.25rem !important;
}

.p-48 {
    padding: 9.5rem !important;
}

.pt-48,
.py-48 {
    padding-top: 9.5rem !important;
}

.pr-48,
.px-48 {
    padding-right: 9.5rem !important;
}

.pb-48,
.py-48 {
    padding-bottom: 9.5rem !important;
}

.pl-48,
.px-48 {
    padding-left: 9.5rem !important;
}

.p-39 {
    padding: 9.75rem !important;
}

.pt-39,
.py-39 {
    padding-top: 9.75rem !important;
}

.pr-39,
.px-39 {
    padding-right: 9.75rem !important;
}

.pb-39,
.py-39 {
    padding-bottom: 9.75rem !important;
}

.pl-39,
.px-39 {
    padding-left: 9.75rem !important;
}

.p-40 {
    padding: 10rem !important;
}

.pt-40,
.py-40 {
    padding-top: 10rem !important;
}

.pr-40,
.px-40 {
    padding-right: 10rem !important;
}

.pb-40,
.py-40 {
    padding-bottom: 10rem !important;
}

.pl-40,
.px-40 {
    padding-left: 10rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -0.75rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -0.75rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -0.75rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -0.75rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -0.75rem !important;
}

.m-n4 {
    margin: -1rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1rem !important;
}

.m-n5 {
    margin: -1.25rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -1.25rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -1.25rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -1.25rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -1.25rem !important;
}

.m-n6 {
    margin: -1.5rem !important;
}

.mt-n6,
.my-n6 {
    margin-top: -1.5rem !important;
}

.mr-n6,
.mx-n6 {
    margin-right: -1.5rem !important;
}

.mb-n6,
.my-n6 {
    margin-bottom: -1.5rem !important;
}

.ml-n6,
.mx-n6 {
    margin-left: -1.5rem !important;
}

.m-n7 {
    margin: -1.75rem !important;
}

.mt-n7,
.my-n7 {
    margin-top: -1.75rem !important;
}

.mr-n7,
.mx-n7 {
    margin-right: -1.75rem !important;
}

.mb-n7,
.my-n7 {
    margin-bottom: -1.75rem !important;
}

.ml-n7,
.mx-n7 {
    margin-left: -1.75rem !important;
}

.m-n8 {
    margin: -2rem !important;
}

.mt-n8,
.my-n8 {
    margin-top: -2rem !important;
}

.mr-n8,
.mx-n8 {
    margin-right: -2rem !important;
}

.mb-n8,
.my-n8 {
    margin-bottom: -2rem !important;
}

.ml-n8,
.mx-n8 {
    margin-left: -2rem !important;
}

.m-n9 {
    margin: -2.25rem !important;
}

.mt-n9,
.my-n9 {
    margin-top: -2.25rem !important;
}

.mr-n9,
.mx-n9 {
    margin-right: -2.25rem !important;
}

.mb-n9,
.my-n9 {
    margin-bottom: -2.25rem !important;
}

.ml-n9,
.mx-n9 {
    margin-left: -2.25rem !important;
}

.m-n10 {
    margin: -2.5rem !important;
}

.mt-n10,
.my-n10 {
    margin-top: -2.5rem !important;
}

.mr-n10,
.mx-n10 {
    margin-right: -2.5rem !important;
}

.mb-n10,
.my-n10 {
    margin-bottom: -2.5rem !important;
}

.ml-n10,
.mx-n10 {
    margin-left: -2.5rem !important;
}

.m-n11 {
    margin: -2.75rem !important;
}

.mt-n11,
.my-n11 {
    margin-top: -2.75rem !important;
}

.mr-n11,
.mx-n11 {
    margin-right: -2.75rem !important;
}

.mb-n11,
.my-n11 {
    margin-bottom: -2.75rem !important;
}

.ml-n11,
.mx-n11 {
    margin-left: -2.75rem !important;
}

.m-n12 {
    margin: -3rem !important;
}

.mt-n12,
.my-n12 {
    margin-top: -3rem !important;
}

.mr-n12,
.mx-n12 {
    margin-right: -3rem !important;
}

.mb-n12,
.my-n12 {
    margin-bottom: -3rem !important;
}

.ml-n12,
.mx-n12 {
    margin-left: -3rem !important;
}

.m-n13 {
    margin: -3.25rem !important;
}

.mt-n13,
.my-n13 {
    margin-top: -3.25rem !important;
}

.mr-n13,
.mx-n13 {
    margin-right: -3.25rem !important;
}

.mb-n13,
.my-n13 {
    margin-bottom: -3.25rem !important;
}

.ml-n13,
.mx-n13 {
    margin-left: -3.25rem !important;
}

.m-n14 {
    margin: -3.5rem !important;
}

.mt-n14,
.my-n14 {
    margin-top: -3.5rem !important;
}

.mr-n14,
.mx-n14 {
    margin-right: -3.5rem !important;
}

.mb-n14,
.my-n14 {
    margin-bottom: -3.5rem !important;
}

.ml-n14,
.mx-n14 {
    margin-left: -3.5rem !important;
}

.m-n15 {
    margin: -3.75rem !important;
}

.mt-n15,
.my-n15 {
    margin-top: -3.75rem !important;
}

.mr-n15,
.mx-n15 {
    margin-right: -3.75rem !important;
}

.mb-n15,
.my-n15 {
    margin-bottom: -3.75rem !important;
}

.ml-n15,
.mx-n15 {
    margin-left: -3.75rem !important;
}

.m-n16 {
    margin: -4rem !important;
}

.mt-n16,
.my-n16 {
    margin-top: -4rem !important;
}

.mr-n16,
.mx-n16 {
    margin-right: -4rem !important;
}

.mb-n16,
.my-n16 {
    margin-bottom: -4rem !important;
}

.ml-n16,
.mx-n16 {
    margin-left: -4rem !important;
}

.m-n17 {
    margin: -4.25rem !important;
}

.mt-n17,
.my-n17 {
    margin-top: -4.25rem !important;
}

.mr-n17,
.mx-n17 {
    margin-right: -4.25rem !important;
}

.mb-n17,
.my-n17 {
    margin-bottom: -4.25rem !important;
}

.ml-n17,
.mx-n17 {
    margin-left: -4.25rem !important;
}

.m-n18 {
    margin: -4.5rem !important;
}

.mt-n18,
.my-n18 {
    margin-top: -4.5rem !important;
}

.mr-n18,
.mx-n18 {
    margin-right: -4.5rem !important;
}

.mb-n18,
.my-n18 {
    margin-bottom: -4.5rem !important;
}

.ml-n18,
.mx-n18 {
    margin-left: -4.5rem !important;
}

.m-n19 {
    margin: -4.75rem !important;
}

.mt-n19,
.my-n19 {
    margin-top: -4.75rem !important;
}

.mr-n19,
.mx-n19 {
    margin-right: -4.75rem !important;
}

.mb-n19,
.my-n19 {
    margin-bottom: -4.75rem !important;
}

.ml-n19,
.mx-n19 {
    margin-left: -4.75rem !important;
}

.m-n20 {
    margin: -5rem !important;
}

.mt-n20,
.my-n20 {
    margin-top: -5rem !important;
}

.mr-n20,
.mx-n20 {
    margin-right: -5rem !important;
}

.mb-n20,
.my-n20 {
    margin-bottom: -5rem !important;
}

.ml-n20,
.mx-n20 {
    margin-left: -5rem !important;
}

.m-n21 {
    margin: -5.25rem !important;
}

.mt-n21,
.my-n21 {
    margin-top: -5.25rem !important;
}

.mr-n21,
.mx-n21 {
    margin-right: -5.25rem !important;
}

.mb-n21,
.my-n21 {
    margin-bottom: -5.25rem !important;
}

.ml-n21,
.mx-n21 {
    margin-left: -5.25rem !important;
}

.m-n22 {
    margin: -5.5rem !important;
}

.mt-n22,
.my-n22 {
    margin-top: -5.5rem !important;
}

.mr-n22,
.mx-n22 {
    margin-right: -5.5rem !important;
}

.mb-n22,
.my-n22 {
    margin-bottom: -5.5rem !important;
}

.ml-n22,
.mx-n22 {
    margin-left: -5.5rem !important;
}

.m-n23 {
    margin: -5.75rem !important;
}

.mt-n23,
.my-n23 {
    margin-top: -5.75rem !important;
}

.mr-n23,
.mx-n23 {
    margin-right: -5.75rem !important;
}

.mb-n23,
.my-n23 {
    margin-bottom: -5.75rem !important;
}

.ml-n23,
.mx-n23 {
    margin-left: -5.75rem !important;
}

.m-n24 {
    margin: -6rem !important;
}

.mt-n24,
.my-n24 {
    margin-top: -6rem !important;
}

.mr-n24,
.mx-n24 {
    margin-right: -6rem !important;
}

.mb-n24,
.my-n24 {
    margin-bottom: -6rem !important;
}

.ml-n24,
.mx-n24 {
    margin-left: -6rem !important;
}

.m-n25 {
    margin: -6.25rem !important;
}

.mt-n25,
.my-n25 {
    margin-top: -6.25rem !important;
}

.mr-n25,
.mx-n25 {
    margin-right: -6.25rem !important;
}

.mb-n25,
.my-n25 {
    margin-bottom: -6.25rem !important;
}

.ml-n25,
.mx-n25 {
    margin-left: -6.25rem !important;
}

.m-n26 {
    margin: -6.5rem !important;
}

.mt-n26,
.my-n26 {
    margin-top: -6.5rem !important;
}

.mr-n26,
.mx-n26 {
    margin-right: -6.5rem !important;
}

.mb-n26,
.my-n26 {
    margin-bottom: -6.5rem !important;
}

.ml-n26,
.mx-n26 {
    margin-left: -6.5rem !important;
}

.m-n27 {
    margin: -6.75rem !important;
}

.mt-n27,
.my-n27 {
    margin-top: -6.75rem !important;
}

.mr-n27,
.mx-n27 {
    margin-right: -6.75rem !important;
}

.mb-n27,
.my-n27 {
    margin-bottom: -6.75rem !important;
}

.ml-n27,
.mx-n27 {
    margin-left: -6.75rem !important;
}

.m-n38 {
    margin: -7rem !important;
}

.mt-n38,
.my-n38 {
    margin-top: -7rem !important;
}

.mr-n38,
.mx-n38 {
    margin-right: -7rem !important;
}

.mb-n38,
.my-n38 {
    margin-bottom: -7rem !important;
}

.ml-n38,
.mx-n38 {
    margin-left: -7rem !important;
}

.m-n29 {
    margin: -7.25rem !important;
}

.mt-n29,
.my-n29 {
    margin-top: -7.25rem !important;
}

.mr-n29,
.mx-n29 {
    margin-right: -7.25rem !important;
}

.mb-n29,
.my-n29 {
    margin-bottom: -7.25rem !important;
}

.ml-n29,
.mx-n29 {
    margin-left: -7.25rem !important;
}

.m-n30 {
    margin: -7.5rem !important;
}

.mt-n30,
.my-n30 {
    margin-top: -7.5rem !important;
}

.mr-n30,
.mx-n30 {
    margin-right: -7.5rem !important;
}

.mb-n30,
.my-n30 {
    margin-bottom: -7.5rem !important;
}

.ml-n30,
.mx-n30 {
    margin-left: -7.5rem !important;
}

.m-n31 {
    margin: -7.75rem !important;
}

.mt-n31,
.my-n31 {
    margin-top: -7.75rem !important;
}

.mr-n31,
.mx-n31 {
    margin-right: -7.75rem !important;
}

.mb-n31,
.my-n31 {
    margin-bottom: -7.75rem !important;
}

.ml-n31,
.mx-n31 {
    margin-left: -7.75rem !important;
}

.m-n32 {
    margin: -8rem !important;
}

.mt-n32,
.my-n32 {
    margin-top: -8rem !important;
}

.mr-n32,
.mx-n32 {
    margin-right: -8rem !important;
}

.mb-n32,
.my-n32 {
    margin-bottom: -8rem !important;
}

.ml-n32,
.mx-n32 {
    margin-left: -8rem !important;
}

.m-n33 {
    margin: -8.25rem !important;
}

.mt-n33,
.my-n33 {
    margin-top: -8.25rem !important;
}

.mr-n33,
.mx-n33 {
    margin-right: -8.25rem !important;
}

.mb-n33,
.my-n33 {
    margin-bottom: -8.25rem !important;
}

.ml-n33,
.mx-n33 {
    margin-left: -8.25rem !important;
}

.m-n34 {
    margin: -8.5rem !important;
}

.mt-n34,
.my-n34 {
    margin-top: -8.5rem !important;
}

.mr-n34,
.mx-n34 {
    margin-right: -8.5rem !important;
}

.mb-n34,
.my-n34 {
    margin-bottom: -8.5rem !important;
}

.ml-n34,
.mx-n34 {
    margin-left: -8.5rem !important;
}

.m-n35 {
    margin: -8.75rem !important;
}

.mt-n35,
.my-n35 {
    margin-top: -8.75rem !important;
}

.mr-n35,
.mx-n35 {
    margin-right: -8.75rem !important;
}

.mb-n35,
.my-n35 {
    margin-bottom: -8.75rem !important;
}

.ml-n35,
.mx-n35 {
    margin-left: -8.75rem !important;
}

.m-n36 {
    margin: -9rem !important;
}

.mt-n36,
.my-n36 {
    margin-top: -9rem !important;
}

.mr-n36,
.mx-n36 {
    margin-right: -9rem !important;
}

.mb-n36,
.my-n36 {
    margin-bottom: -9rem !important;
}

.ml-n36,
.mx-n36 {
    margin-left: -9rem !important;
}

.m-n37 {
    margin: -9.25rem !important;
}

.mt-n37,
.my-n37 {
    margin-top: -9.25rem !important;
}

.mr-n37,
.mx-n37 {
    margin-right: -9.25rem !important;
}

.mb-n37,
.my-n37 {
    margin-bottom: -9.25rem !important;
}

.ml-n37,
.mx-n37 {
    margin-left: -9.25rem !important;
}

.m-n48 {
    margin: -9.5rem !important;
}

.mt-n48,
.my-n48 {
    margin-top: -9.5rem !important;
}

.mr-n48,
.mx-n48 {
    margin-right: -9.5rem !important;
}

.mb-n48,
.my-n48 {
    margin-bottom: -9.5rem !important;
}

.ml-n48,
.mx-n48 {
    margin-left: -9.5rem !important;
}

.m-n39 {
    margin: -9.75rem !important;
}

.mt-n39,
.my-n39 {
    margin-top: -9.75rem !important;
}

.mr-n39,
.mx-n39 {
    margin-right: -9.75rem !important;
}

.mb-n39,
.my-n39 {
    margin-bottom: -9.75rem !important;
}

.ml-n39,
.mx-n39 {
    margin-left: -9.75rem !important;
}

.m-n40 {
    margin: -10rem !important;
}

.mt-n40,
.my-n40 {
    margin-top: -10rem !important;
}

.mr-n40,
.mx-n40 {
    margin-right: -10rem !important;
}

.mb-n40,
.my-n40 {
    margin-bottom: -10rem !important;
}

.ml-n40,
.mx-n40 {
    margin-left: -10rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 0.75rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 0.75rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 0.75rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 0.75rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 0.75rem !important;
    }

    .m-sm-4 {
        margin: 1rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1rem !important;
    }

    .m-sm-5 {
        margin: 1.25rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 1.25rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 1.25rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 1.25rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 1.25rem !important;
    }

    .m-sm-6 {
        margin: 1.5rem !important;
    }

    .mt-sm-6,
    .my-sm-6 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-6,
    .mx-sm-6 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-6,
    .my-sm-6 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-6,
    .mx-sm-6 {
        margin-left: 1.5rem !important;
    }

    .m-sm-7 {
        margin: 1.75rem !important;
    }

    .mt-sm-7,
    .my-sm-7 {
        margin-top: 1.75rem !important;
    }

    .mr-sm-7,
    .mx-sm-7 {
        margin-right: 1.75rem !important;
    }

    .mb-sm-7,
    .my-sm-7 {
        margin-bottom: 1.75rem !important;
    }

    .ml-sm-7,
    .mx-sm-7 {
        margin-left: 1.75rem !important;
    }

    .m-sm-8 {
        margin: 2rem !important;
    }

    .mt-sm-8,
    .my-sm-8 {
        margin-top: 2rem !important;
    }

    .mr-sm-8,
    .mx-sm-8 {
        margin-right: 2rem !important;
    }

    .mb-sm-8,
    .my-sm-8 {
        margin-bottom: 2rem !important;
    }

    .ml-sm-8,
    .mx-sm-8 {
        margin-left: 2rem !important;
    }

    .m-sm-9 {
        margin: 2.25rem !important;
    }

    .mt-sm-9,
    .my-sm-9 {
        margin-top: 2.25rem !important;
    }

    .mr-sm-9,
    .mx-sm-9 {
        margin-right: 2.25rem !important;
    }

    .mb-sm-9,
    .my-sm-9 {
        margin-bottom: 2.25rem !important;
    }

    .ml-sm-9,
    .mx-sm-9 {
        margin-left: 2.25rem !important;
    }

    .m-sm-10 {
        margin: 2.5rem !important;
    }

    .mt-sm-10,
    .my-sm-10 {
        margin-top: 2.5rem !important;
    }

    .mr-sm-10,
    .mx-sm-10 {
        margin-right: 2.5rem !important;
    }

    .mb-sm-10,
    .my-sm-10 {
        margin-bottom: 2.5rem !important;
    }

    .ml-sm-10,
    .mx-sm-10 {
        margin-left: 2.5rem !important;
    }

    .m-sm-11 {
        margin: 2.75rem !important;
    }

    .mt-sm-11,
    .my-sm-11 {
        margin-top: 2.75rem !important;
    }

    .mr-sm-11,
    .mx-sm-11 {
        margin-right: 2.75rem !important;
    }

    .mb-sm-11,
    .my-sm-11 {
        margin-bottom: 2.75rem !important;
    }

    .ml-sm-11,
    .mx-sm-11 {
        margin-left: 2.75rem !important;
    }

    .m-sm-12 {
        margin: 3rem !important;
    }

    .mt-sm-12,
    .my-sm-12 {
        margin-top: 3rem !important;
    }

    .mr-sm-12,
    .mx-sm-12 {
        margin-right: 3rem !important;
    }

    .mb-sm-12,
    .my-sm-12 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-12,
    .mx-sm-12 {
        margin-left: 3rem !important;
    }

    .m-sm-13 {
        margin: 3.25rem !important;
    }

    .mt-sm-13,
    .my-sm-13 {
        margin-top: 3.25rem !important;
    }

    .mr-sm-13,
    .mx-sm-13 {
        margin-right: 3.25rem !important;
    }

    .mb-sm-13,
    .my-sm-13 {
        margin-bottom: 3.25rem !important;
    }

    .ml-sm-13,
    .mx-sm-13 {
        margin-left: 3.25rem !important;
    }

    .m-sm-14 {
        margin: 3.5rem !important;
    }

    .mt-sm-14,
    .my-sm-14 {
        margin-top: 3.5rem !important;
    }

    .mr-sm-14,
    .mx-sm-14 {
        margin-right: 3.5rem !important;
    }

    .mb-sm-14,
    .my-sm-14 {
        margin-bottom: 3.5rem !important;
    }

    .ml-sm-14,
    .mx-sm-14 {
        margin-left: 3.5rem !important;
    }

    .m-sm-15 {
        margin: 3.75rem !important;
    }

    .mt-sm-15,
    .my-sm-15 {
        margin-top: 3.75rem !important;
    }

    .mr-sm-15,
    .mx-sm-15 {
        margin-right: 3.75rem !important;
    }

    .mb-sm-15,
    .my-sm-15 {
        margin-bottom: 3.75rem !important;
    }

    .ml-sm-15,
    .mx-sm-15 {
        margin-left: 3.75rem !important;
    }

    .m-sm-16 {
        margin: 4rem !important;
    }

    .mt-sm-16,
    .my-sm-16 {
        margin-top: 4rem !important;
    }

    .mr-sm-16,
    .mx-sm-16 {
        margin-right: 4rem !important;
    }

    .mb-sm-16,
    .my-sm-16 {
        margin-bottom: 4rem !important;
    }

    .ml-sm-16,
    .mx-sm-16 {
        margin-left: 4rem !important;
    }

    .m-sm-17 {
        margin: 4.25rem !important;
    }

    .mt-sm-17,
    .my-sm-17 {
        margin-top: 4.25rem !important;
    }

    .mr-sm-17,
    .mx-sm-17 {
        margin-right: 4.25rem !important;
    }

    .mb-sm-17,
    .my-sm-17 {
        margin-bottom: 4.25rem !important;
    }

    .ml-sm-17,
    .mx-sm-17 {
        margin-left: 4.25rem !important;
    }

    .m-sm-18 {
        margin: 4.5rem !important;
    }

    .mt-sm-18,
    .my-sm-18 {
        margin-top: 4.5rem !important;
    }

    .mr-sm-18,
    .mx-sm-18 {
        margin-right: 4.5rem !important;
    }

    .mb-sm-18,
    .my-sm-18 {
        margin-bottom: 4.5rem !important;
    }

    .ml-sm-18,
    .mx-sm-18 {
        margin-left: 4.5rem !important;
    }

    .m-sm-19 {
        margin: 4.75rem !important;
    }

    .mt-sm-19,
    .my-sm-19 {
        margin-top: 4.75rem !important;
    }

    .mr-sm-19,
    .mx-sm-19 {
        margin-right: 4.75rem !important;
    }

    .mb-sm-19,
    .my-sm-19 {
        margin-bottom: 4.75rem !important;
    }

    .ml-sm-19,
    .mx-sm-19 {
        margin-left: 4.75rem !important;
    }

    .m-sm-20 {
        margin: 5rem !important;
    }

    .mt-sm-20,
    .my-sm-20 {
        margin-top: 5rem !important;
    }

    .mr-sm-20,
    .mx-sm-20 {
        margin-right: 5rem !important;
    }

    .mb-sm-20,
    .my-sm-20 {
        margin-bottom: 5rem !important;
    }

    .ml-sm-20,
    .mx-sm-20 {
        margin-left: 5rem !important;
    }

    .m-sm-21 {
        margin: 5.25rem !important;
    }

    .mt-sm-21,
    .my-sm-21 {
        margin-top: 5.25rem !important;
    }

    .mr-sm-21,
    .mx-sm-21 {
        margin-right: 5.25rem !important;
    }

    .mb-sm-21,
    .my-sm-21 {
        margin-bottom: 5.25rem !important;
    }

    .ml-sm-21,
    .mx-sm-21 {
        margin-left: 5.25rem !important;
    }

    .m-sm-22 {
        margin: 5.5rem !important;
    }

    .mt-sm-22,
    .my-sm-22 {
        margin-top: 5.5rem !important;
    }

    .mr-sm-22,
    .mx-sm-22 {
        margin-right: 5.5rem !important;
    }

    .mb-sm-22,
    .my-sm-22 {
        margin-bottom: 5.5rem !important;
    }

    .ml-sm-22,
    .mx-sm-22 {
        margin-left: 5.5rem !important;
    }

    .m-sm-23 {
        margin: 5.75rem !important;
    }

    .mt-sm-23,
    .my-sm-23 {
        margin-top: 5.75rem !important;
    }

    .mr-sm-23,
    .mx-sm-23 {
        margin-right: 5.75rem !important;
    }

    .mb-sm-23,
    .my-sm-23 {
        margin-bottom: 5.75rem !important;
    }

    .ml-sm-23,
    .mx-sm-23 {
        margin-left: 5.75rem !important;
    }

    .m-sm-24 {
        margin: 6rem !important;
    }

    .mt-sm-24,
    .my-sm-24 {
        margin-top: 6rem !important;
    }

    .mr-sm-24,
    .mx-sm-24 {
        margin-right: 6rem !important;
    }

    .mb-sm-24,
    .my-sm-24 {
        margin-bottom: 6rem !important;
    }

    .ml-sm-24,
    .mx-sm-24 {
        margin-left: 6rem !important;
    }

    .m-sm-25 {
        margin: 6.25rem !important;
    }

    .mt-sm-25,
    .my-sm-25 {
        margin-top: 6.25rem !important;
    }

    .mr-sm-25,
    .mx-sm-25 {
        margin-right: 6.25rem !important;
    }

    .mb-sm-25,
    .my-sm-25 {
        margin-bottom: 6.25rem !important;
    }

    .ml-sm-25,
    .mx-sm-25 {
        margin-left: 6.25rem !important;
    }

    .m-sm-26 {
        margin: 6.5rem !important;
    }

    .mt-sm-26,
    .my-sm-26 {
        margin-top: 6.5rem !important;
    }

    .mr-sm-26,
    .mx-sm-26 {
        margin-right: 6.5rem !important;
    }

    .mb-sm-26,
    .my-sm-26 {
        margin-bottom: 6.5rem !important;
    }

    .ml-sm-26,
    .mx-sm-26 {
        margin-left: 6.5rem !important;
    }

    .m-sm-27 {
        margin: 6.75rem !important;
    }

    .mt-sm-27,
    .my-sm-27 {
        margin-top: 6.75rem !important;
    }

    .mr-sm-27,
    .mx-sm-27 {
        margin-right: 6.75rem !important;
    }

    .mb-sm-27,
    .my-sm-27 {
        margin-bottom: 6.75rem !important;
    }

    .ml-sm-27,
    .mx-sm-27 {
        margin-left: 6.75rem !important;
    }

    .m-sm-38 {
        margin: 7rem !important;
    }

    .mt-sm-38,
    .my-sm-38 {
        margin-top: 7rem !important;
    }

    .mr-sm-38,
    .mx-sm-38 {
        margin-right: 7rem !important;
    }

    .mb-sm-38,
    .my-sm-38 {
        margin-bottom: 7rem !important;
    }

    .ml-sm-38,
    .mx-sm-38 {
        margin-left: 7rem !important;
    }

    .m-sm-29 {
        margin: 7.25rem !important;
    }

    .mt-sm-29,
    .my-sm-29 {
        margin-top: 7.25rem !important;
    }

    .mr-sm-29,
    .mx-sm-29 {
        margin-right: 7.25rem !important;
    }

    .mb-sm-29,
    .my-sm-29 {
        margin-bottom: 7.25rem !important;
    }

    .ml-sm-29,
    .mx-sm-29 {
        margin-left: 7.25rem !important;
    }

    .m-sm-30 {
        margin: 7.5rem !important;
    }

    .mt-sm-30,
    .my-sm-30 {
        margin-top: 7.5rem !important;
    }

    .mr-sm-30,
    .mx-sm-30 {
        margin-right: 7.5rem !important;
    }

    .mb-sm-30,
    .my-sm-30 {
        margin-bottom: 7.5rem !important;
    }

    .ml-sm-30,
    .mx-sm-30 {
        margin-left: 7.5rem !important;
    }

    .m-sm-31 {
        margin: 7.75rem !important;
    }

    .mt-sm-31,
    .my-sm-31 {
        margin-top: 7.75rem !important;
    }

    .mr-sm-31,
    .mx-sm-31 {
        margin-right: 7.75rem !important;
    }

    .mb-sm-31,
    .my-sm-31 {
        margin-bottom: 7.75rem !important;
    }

    .ml-sm-31,
    .mx-sm-31 {
        margin-left: 7.75rem !important;
    }

    .m-sm-32 {
        margin: 8rem !important;
    }

    .mt-sm-32,
    .my-sm-32 {
        margin-top: 8rem !important;
    }

    .mr-sm-32,
    .mx-sm-32 {
        margin-right: 8rem !important;
    }

    .mb-sm-32,
    .my-sm-32 {
        margin-bottom: 8rem !important;
    }

    .ml-sm-32,
    .mx-sm-32 {
        margin-left: 8rem !important;
    }

    .m-sm-33 {
        margin: 8.25rem !important;
    }

    .mt-sm-33,
    .my-sm-33 {
        margin-top: 8.25rem !important;
    }

    .mr-sm-33,
    .mx-sm-33 {
        margin-right: 8.25rem !important;
    }

    .mb-sm-33,
    .my-sm-33 {
        margin-bottom: 8.25rem !important;
    }

    .ml-sm-33,
    .mx-sm-33 {
        margin-left: 8.25rem !important;
    }

    .m-sm-34 {
        margin: 8.5rem !important;
    }

    .mt-sm-34,
    .my-sm-34 {
        margin-top: 8.5rem !important;
    }

    .mr-sm-34,
    .mx-sm-34 {
        margin-right: 8.5rem !important;
    }

    .mb-sm-34,
    .my-sm-34 {
        margin-bottom: 8.5rem !important;
    }

    .ml-sm-34,
    .mx-sm-34 {
        margin-left: 8.5rem !important;
    }

    .m-sm-35 {
        margin: 8.75rem !important;
    }

    .mt-sm-35,
    .my-sm-35 {
        margin-top: 8.75rem !important;
    }

    .mr-sm-35,
    .mx-sm-35 {
        margin-right: 8.75rem !important;
    }

    .mb-sm-35,
    .my-sm-35 {
        margin-bottom: 8.75rem !important;
    }

    .ml-sm-35,
    .mx-sm-35 {
        margin-left: 8.75rem !important;
    }

    .m-sm-36 {
        margin: 9rem !important;
    }

    .mt-sm-36,
    .my-sm-36 {
        margin-top: 9rem !important;
    }

    .mr-sm-36,
    .mx-sm-36 {
        margin-right: 9rem !important;
    }

    .mb-sm-36,
    .my-sm-36 {
        margin-bottom: 9rem !important;
    }

    .ml-sm-36,
    .mx-sm-36 {
        margin-left: 9rem !important;
    }

    .m-sm-37 {
        margin: 9.25rem !important;
    }

    .mt-sm-37,
    .my-sm-37 {
        margin-top: 9.25rem !important;
    }

    .mr-sm-37,
    .mx-sm-37 {
        margin-right: 9.25rem !important;
    }

    .mb-sm-37,
    .my-sm-37 {
        margin-bottom: 9.25rem !important;
    }

    .ml-sm-37,
    .mx-sm-37 {
        margin-left: 9.25rem !important;
    }

    .m-sm-48 {
        margin: 9.5rem !important;
    }

    .mt-sm-48,
    .my-sm-48 {
        margin-top: 9.5rem !important;
    }

    .mr-sm-48,
    .mx-sm-48 {
        margin-right: 9.5rem !important;
    }

    .mb-sm-48,
    .my-sm-48 {
        margin-bottom: 9.5rem !important;
    }

    .ml-sm-48,
    .mx-sm-48 {
        margin-left: 9.5rem !important;
    }

    .m-sm-39 {
        margin: 9.75rem !important;
    }

    .mt-sm-39,
    .my-sm-39 {
        margin-top: 9.75rem !important;
    }

    .mr-sm-39,
    .mx-sm-39 {
        margin-right: 9.75rem !important;
    }

    .mb-sm-39,
    .my-sm-39 {
        margin-bottom: 9.75rem !important;
    }

    .ml-sm-39,
    .mx-sm-39 {
        margin-left: 9.75rem !important;
    }

    .m-sm-40 {
        margin: 10rem !important;
    }

    .mt-sm-40,
    .my-sm-40 {
        margin-top: 10rem !important;
    }

    .mr-sm-40,
    .mx-sm-40 {
        margin-right: 10rem !important;
    }

    .mb-sm-40,
    .my-sm-40 {
        margin-bottom: 10rem !important;
    }

    .ml-sm-40,
    .mx-sm-40 {
        margin-left: 10rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 0.75rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 0.75rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 0.75rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 0.75rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 0.75rem !important;
    }

    .p-sm-4 {
        padding: 1rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1rem !important;
    }

    .p-sm-5 {
        padding: 1.25rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 1.25rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 1.25rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 1.25rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 1.25rem !important;
    }

    .p-sm-6 {
        padding: 1.5rem !important;
    }

    .pt-sm-6,
    .py-sm-6 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-6,
    .px-sm-6 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-6,
    .py-sm-6 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-6,
    .px-sm-6 {
        padding-left: 1.5rem !important;
    }

    .p-sm-7 {
        padding: 1.75rem !important;
    }

    .pt-sm-7,
    .py-sm-7 {
        padding-top: 1.75rem !important;
    }

    .pr-sm-7,
    .px-sm-7 {
        padding-right: 1.75rem !important;
    }

    .pb-sm-7,
    .py-sm-7 {
        padding-bottom: 1.75rem !important;
    }

    .pl-sm-7,
    .px-sm-7 {
        padding-left: 1.75rem !important;
    }

    .p-sm-8 {
        padding: 2rem !important;
    }

    .pt-sm-8,
    .py-sm-8 {
        padding-top: 2rem !important;
    }

    .pr-sm-8,
    .px-sm-8 {
        padding-right: 2rem !important;
    }

    .pb-sm-8,
    .py-sm-8 {
        padding-bottom: 2rem !important;
    }

    .pl-sm-8,
    .px-sm-8 {
        padding-left: 2rem !important;
    }

    .p-sm-9 {
        padding: 2.25rem !important;
    }

    .pt-sm-9,
    .py-sm-9 {
        padding-top: 2.25rem !important;
    }

    .pr-sm-9,
    .px-sm-9 {
        padding-right: 2.25rem !important;
    }

    .pb-sm-9,
    .py-sm-9 {
        padding-bottom: 2.25rem !important;
    }

    .pl-sm-9,
    .px-sm-9 {
        padding-left: 2.25rem !important;
    }

    .p-sm-10 {
        padding: 2.5rem !important;
    }

    .pt-sm-10,
    .py-sm-10 {
        padding-top: 2.5rem !important;
    }

    .pr-sm-10,
    .px-sm-10 {
        padding-right: 2.5rem !important;
    }

    .pb-sm-10,
    .py-sm-10 {
        padding-bottom: 2.5rem !important;
    }

    .pl-sm-10,
    .px-sm-10 {
        padding-left: 2.5rem !important;
    }

    .p-sm-11 {
        padding: 2.75rem !important;
    }

    .pt-sm-11,
    .py-sm-11 {
        padding-top: 2.75rem !important;
    }

    .pr-sm-11,
    .px-sm-11 {
        padding-right: 2.75rem !important;
    }

    .pb-sm-11,
    .py-sm-11 {
        padding-bottom: 2.75rem !important;
    }

    .pl-sm-11,
    .px-sm-11 {
        padding-left: 2.75rem !important;
    }

    .p-sm-12 {
        padding: 3rem !important;
    }

    .pt-sm-12,
    .py-sm-12 {
        padding-top: 3rem !important;
    }

    .pr-sm-12,
    .px-sm-12 {
        padding-right: 3rem !important;
    }

    .pb-sm-12,
    .py-sm-12 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-12,
    .px-sm-12 {
        padding-left: 3rem !important;
    }

    .p-sm-13 {
        padding: 3.25rem !important;
    }

    .pt-sm-13,
    .py-sm-13 {
        padding-top: 3.25rem !important;
    }

    .pr-sm-13,
    .px-sm-13 {
        padding-right: 3.25rem !important;
    }

    .pb-sm-13,
    .py-sm-13 {
        padding-bottom: 3.25rem !important;
    }

    .pl-sm-13,
    .px-sm-13 {
        padding-left: 3.25rem !important;
    }

    .p-sm-14 {
        padding: 3.5rem !important;
    }

    .pt-sm-14,
    .py-sm-14 {
        padding-top: 3.5rem !important;
    }

    .pr-sm-14,
    .px-sm-14 {
        padding-right: 3.5rem !important;
    }

    .pb-sm-14,
    .py-sm-14 {
        padding-bottom: 3.5rem !important;
    }

    .pl-sm-14,
    .px-sm-14 {
        padding-left: 3.5rem !important;
    }

    .p-sm-15 {
        padding: 3.75rem !important;
    }

    .pt-sm-15,
    .py-sm-15 {
        padding-top: 3.75rem !important;
    }

    .pr-sm-15,
    .px-sm-15 {
        padding-right: 3.75rem !important;
    }

    .pb-sm-15,
    .py-sm-15 {
        padding-bottom: 3.75rem !important;
    }

    .pl-sm-15,
    .px-sm-15 {
        padding-left: 3.75rem !important;
    }

    .p-sm-16 {
        padding: 4rem !important;
    }

    .pt-sm-16,
    .py-sm-16 {
        padding-top: 4rem !important;
    }

    .pr-sm-16,
    .px-sm-16 {
        padding-right: 4rem !important;
    }

    .pb-sm-16,
    .py-sm-16 {
        padding-bottom: 4rem !important;
    }

    .pl-sm-16,
    .px-sm-16 {
        padding-left: 4rem !important;
    }

    .p-sm-17 {
        padding: 4.25rem !important;
    }

    .pt-sm-17,
    .py-sm-17 {
        padding-top: 4.25rem !important;
    }

    .pr-sm-17,
    .px-sm-17 {
        padding-right: 4.25rem !important;
    }

    .pb-sm-17,
    .py-sm-17 {
        padding-bottom: 4.25rem !important;
    }

    .pl-sm-17,
    .px-sm-17 {
        padding-left: 4.25rem !important;
    }

    .p-sm-18 {
        padding: 4.5rem !important;
    }

    .pt-sm-18,
    .py-sm-18 {
        padding-top: 4.5rem !important;
    }

    .pr-sm-18,
    .px-sm-18 {
        padding-right: 4.5rem !important;
    }

    .pb-sm-18,
    .py-sm-18 {
        padding-bottom: 4.5rem !important;
    }

    .pl-sm-18,
    .px-sm-18 {
        padding-left: 4.5rem !important;
    }

    .p-sm-19 {
        padding: 4.75rem !important;
    }

    .pt-sm-19,
    .py-sm-19 {
        padding-top: 4.75rem !important;
    }

    .pr-sm-19,
    .px-sm-19 {
        padding-right: 4.75rem !important;
    }

    .pb-sm-19,
    .py-sm-19 {
        padding-bottom: 4.75rem !important;
    }

    .pl-sm-19,
    .px-sm-19 {
        padding-left: 4.75rem !important;
    }

    .p-sm-20 {
        padding: 5rem !important;
    }

    .pt-sm-20,
    .py-sm-20 {
        padding-top: 5rem !important;
    }

    .pr-sm-20,
    .px-sm-20 {
        padding-right: 5rem !important;
    }

    .pb-sm-20,
    .py-sm-20 {
        padding-bottom: 5rem !important;
    }

    .pl-sm-20,
    .px-sm-20 {
        padding-left: 5rem !important;
    }

    .p-sm-21 {
        padding: 5.25rem !important;
    }

    .pt-sm-21,
    .py-sm-21 {
        padding-top: 5.25rem !important;
    }

    .pr-sm-21,
    .px-sm-21 {
        padding-right: 5.25rem !important;
    }

    .pb-sm-21,
    .py-sm-21 {
        padding-bottom: 5.25rem !important;
    }

    .pl-sm-21,
    .px-sm-21 {
        padding-left: 5.25rem !important;
    }

    .p-sm-22 {
        padding: 5.5rem !important;
    }

    .pt-sm-22,
    .py-sm-22 {
        padding-top: 5.5rem !important;
    }

    .pr-sm-22,
    .px-sm-22 {
        padding-right: 5.5rem !important;
    }

    .pb-sm-22,
    .py-sm-22 {
        padding-bottom: 5.5rem !important;
    }

    .pl-sm-22,
    .px-sm-22 {
        padding-left: 5.5rem !important;
    }

    .p-sm-23 {
        padding: 5.75rem !important;
    }

    .pt-sm-23,
    .py-sm-23 {
        padding-top: 5.75rem !important;
    }

    .pr-sm-23,
    .px-sm-23 {
        padding-right: 5.75rem !important;
    }

    .pb-sm-23,
    .py-sm-23 {
        padding-bottom: 5.75rem !important;
    }

    .pl-sm-23,
    .px-sm-23 {
        padding-left: 5.75rem !important;
    }

    .p-sm-24 {
        padding: 6rem !important;
    }

    .pt-sm-24,
    .py-sm-24 {
        padding-top: 6rem !important;
    }

    .pr-sm-24,
    .px-sm-24 {
        padding-right: 6rem !important;
    }

    .pb-sm-24,
    .py-sm-24 {
        padding-bottom: 6rem !important;
    }

    .pl-sm-24,
    .px-sm-24 {
        padding-left: 6rem !important;
    }

    .p-sm-25 {
        padding: 6.25rem !important;
    }

    .pt-sm-25,
    .py-sm-25 {
        padding-top: 6.25rem !important;
    }

    .pr-sm-25,
    .px-sm-25 {
        padding-right: 6.25rem !important;
    }

    .pb-sm-25,
    .py-sm-25 {
        padding-bottom: 6.25rem !important;
    }

    .pl-sm-25,
    .px-sm-25 {
        padding-left: 6.25rem !important;
    }

    .p-sm-26 {
        padding: 6.5rem !important;
    }

    .pt-sm-26,
    .py-sm-26 {
        padding-top: 6.5rem !important;
    }

    .pr-sm-26,
    .px-sm-26 {
        padding-right: 6.5rem !important;
    }

    .pb-sm-26,
    .py-sm-26 {
        padding-bottom: 6.5rem !important;
    }

    .pl-sm-26,
    .px-sm-26 {
        padding-left: 6.5rem !important;
    }

    .p-sm-27 {
        padding: 6.75rem !important;
    }

    .pt-sm-27,
    .py-sm-27 {
        padding-top: 6.75rem !important;
    }

    .pr-sm-27,
    .px-sm-27 {
        padding-right: 6.75rem !important;
    }

    .pb-sm-27,
    .py-sm-27 {
        padding-bottom: 6.75rem !important;
    }

    .pl-sm-27,
    .px-sm-27 {
        padding-left: 6.75rem !important;
    }

    .p-sm-38 {
        padding: 7rem !important;
    }

    .pt-sm-38,
    .py-sm-38 {
        padding-top: 7rem !important;
    }

    .pr-sm-38,
    .px-sm-38 {
        padding-right: 7rem !important;
    }

    .pb-sm-38,
    .py-sm-38 {
        padding-bottom: 7rem !important;
    }

    .pl-sm-38,
    .px-sm-38 {
        padding-left: 7rem !important;
    }

    .p-sm-29 {
        padding: 7.25rem !important;
    }

    .pt-sm-29,
    .py-sm-29 {
        padding-top: 7.25rem !important;
    }

    .pr-sm-29,
    .px-sm-29 {
        padding-right: 7.25rem !important;
    }

    .pb-sm-29,
    .py-sm-29 {
        padding-bottom: 7.25rem !important;
    }

    .pl-sm-29,
    .px-sm-29 {
        padding-left: 7.25rem !important;
    }

    .p-sm-30 {
        padding: 7.5rem !important;
    }

    .pt-sm-30,
    .py-sm-30 {
        padding-top: 7.5rem !important;
    }

    .pr-sm-30,
    .px-sm-30 {
        padding-right: 7.5rem !important;
    }

    .pb-sm-30,
    .py-sm-30 {
        padding-bottom: 7.5rem !important;
    }

    .pl-sm-30,
    .px-sm-30 {
        padding-left: 7.5rem !important;
    }

    .p-sm-31 {
        padding: 7.75rem !important;
    }

    .pt-sm-31,
    .py-sm-31 {
        padding-top: 7.75rem !important;
    }

    .pr-sm-31,
    .px-sm-31 {
        padding-right: 7.75rem !important;
    }

    .pb-sm-31,
    .py-sm-31 {
        padding-bottom: 7.75rem !important;
    }

    .pl-sm-31,
    .px-sm-31 {
        padding-left: 7.75rem !important;
    }

    .p-sm-32 {
        padding: 8rem !important;
    }

    .pt-sm-32,
    .py-sm-32 {
        padding-top: 8rem !important;
    }

    .pr-sm-32,
    .px-sm-32 {
        padding-right: 8rem !important;
    }

    .pb-sm-32,
    .py-sm-32 {
        padding-bottom: 8rem !important;
    }

    .pl-sm-32,
    .px-sm-32 {
        padding-left: 8rem !important;
    }

    .p-sm-33 {
        padding: 8.25rem !important;
    }

    .pt-sm-33,
    .py-sm-33 {
        padding-top: 8.25rem !important;
    }

    .pr-sm-33,
    .px-sm-33 {
        padding-right: 8.25rem !important;
    }

    .pb-sm-33,
    .py-sm-33 {
        padding-bottom: 8.25rem !important;
    }

    .pl-sm-33,
    .px-sm-33 {
        padding-left: 8.25rem !important;
    }

    .p-sm-34 {
        padding: 8.5rem !important;
    }

    .pt-sm-34,
    .py-sm-34 {
        padding-top: 8.5rem !important;
    }

    .pr-sm-34,
    .px-sm-34 {
        padding-right: 8.5rem !important;
    }

    .pb-sm-34,
    .py-sm-34 {
        padding-bottom: 8.5rem !important;
    }

    .pl-sm-34,
    .px-sm-34 {
        padding-left: 8.5rem !important;
    }

    .p-sm-35 {
        padding: 8.75rem !important;
    }

    .pt-sm-35,
    .py-sm-35 {
        padding-top: 8.75rem !important;
    }

    .pr-sm-35,
    .px-sm-35 {
        padding-right: 8.75rem !important;
    }

    .pb-sm-35,
    .py-sm-35 {
        padding-bottom: 8.75rem !important;
    }

    .pl-sm-35,
    .px-sm-35 {
        padding-left: 8.75rem !important;
    }

    .p-sm-36 {
        padding: 9rem !important;
    }

    .pt-sm-36,
    .py-sm-36 {
        padding-top: 9rem !important;
    }

    .pr-sm-36,
    .px-sm-36 {
        padding-right: 9rem !important;
    }

    .pb-sm-36,
    .py-sm-36 {
        padding-bottom: 9rem !important;
    }

    .pl-sm-36,
    .px-sm-36 {
        padding-left: 9rem !important;
    }

    .p-sm-37 {
        padding: 9.25rem !important;
    }

    .pt-sm-37,
    .py-sm-37 {
        padding-top: 9.25rem !important;
    }

    .pr-sm-37,
    .px-sm-37 {
        padding-right: 9.25rem !important;
    }

    .pb-sm-37,
    .py-sm-37 {
        padding-bottom: 9.25rem !important;
    }

    .pl-sm-37,
    .px-sm-37 {
        padding-left: 9.25rem !important;
    }

    .p-sm-48 {
        padding: 9.5rem !important;
    }

    .pt-sm-48,
    .py-sm-48 {
        padding-top: 9.5rem !important;
    }

    .pr-sm-48,
    .px-sm-48 {
        padding-right: 9.5rem !important;
    }

    .pb-sm-48,
    .py-sm-48 {
        padding-bottom: 9.5rem !important;
    }

    .pl-sm-48,
    .px-sm-48 {
        padding-left: 9.5rem !important;
    }

    .p-sm-39 {
        padding: 9.75rem !important;
    }

    .pt-sm-39,
    .py-sm-39 {
        padding-top: 9.75rem !important;
    }

    .pr-sm-39,
    .px-sm-39 {
        padding-right: 9.75rem !important;
    }

    .pb-sm-39,
    .py-sm-39 {
        padding-bottom: 9.75rem !important;
    }

    .pl-sm-39,
    .px-sm-39 {
        padding-left: 9.75rem !important;
    }

    .p-sm-40 {
        padding: 10rem !important;
    }

    .pt-sm-40,
    .py-sm-40 {
        padding-top: 10rem !important;
    }

    .pr-sm-40,
    .px-sm-40 {
        padding-right: 10rem !important;
    }

    .pb-sm-40,
    .py-sm-40 {
        padding-bottom: 10rem !important;
    }

    .pl-sm-40,
    .px-sm-40 {
        padding-left: 10rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -0.75rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -0.75rem !important;
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -0.75rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -0.75rem !important;
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -0.75rem !important;
    }

    .m-sm-n4 {
        margin: -1rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1rem !important;
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1rem !important;
    }

    .m-sm-n5 {
        margin: -1.25rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -1.25rem !important;
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -1.25rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -1.25rem !important;
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -1.25rem !important;
    }

    .m-sm-n6 {
        margin: -1.5rem !important;
    }

    .mt-sm-n6,
    .my-sm-n6 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n6,
    .mx-sm-n6 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n6,
    .my-sm-n6 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n6,
    .mx-sm-n6 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n7 {
        margin: -1.75rem !important;
    }

    .mt-sm-n7,
    .my-sm-n7 {
        margin-top: -1.75rem !important;
    }

    .mr-sm-n7,
    .mx-sm-n7 {
        margin-right: -1.75rem !important;
    }

    .mb-sm-n7,
    .my-sm-n7 {
        margin-bottom: -1.75rem !important;
    }

    .ml-sm-n7,
    .mx-sm-n7 {
        margin-left: -1.75rem !important;
    }

    .m-sm-n8 {
        margin: -2rem !important;
    }

    .mt-sm-n8,
    .my-sm-n8 {
        margin-top: -2rem !important;
    }

    .mr-sm-n8,
    .mx-sm-n8 {
        margin-right: -2rem !important;
    }

    .mb-sm-n8,
    .my-sm-n8 {
        margin-bottom: -2rem !important;
    }

    .ml-sm-n8,
    .mx-sm-n8 {
        margin-left: -2rem !important;
    }

    .m-sm-n9 {
        margin: -2.25rem !important;
    }

    .mt-sm-n9,
    .my-sm-n9 {
        margin-top: -2.25rem !important;
    }

    .mr-sm-n9,
    .mx-sm-n9 {
        margin-right: -2.25rem !important;
    }

    .mb-sm-n9,
    .my-sm-n9 {
        margin-bottom: -2.25rem !important;
    }

    .ml-sm-n9,
    .mx-sm-n9 {
        margin-left: -2.25rem !important;
    }

    .m-sm-n10 {
        margin: -2.5rem !important;
    }

    .mt-sm-n10,
    .my-sm-n10 {
        margin-top: -2.5rem !important;
    }

    .mr-sm-n10,
    .mx-sm-n10 {
        margin-right: -2.5rem !important;
    }

    .mb-sm-n10,
    .my-sm-n10 {
        margin-bottom: -2.5rem !important;
    }

    .ml-sm-n10,
    .mx-sm-n10 {
        margin-left: -2.5rem !important;
    }

    .m-sm-n11 {
        margin: -2.75rem !important;
    }

    .mt-sm-n11,
    .my-sm-n11 {
        margin-top: -2.75rem !important;
    }

    .mr-sm-n11,
    .mx-sm-n11 {
        margin-right: -2.75rem !important;
    }

    .mb-sm-n11,
    .my-sm-n11 {
        margin-bottom: -2.75rem !important;
    }

    .ml-sm-n11,
    .mx-sm-n11 {
        margin-left: -2.75rem !important;
    }

    .m-sm-n12 {
        margin: -3rem !important;
    }

    .mt-sm-n12,
    .my-sm-n12 {
        margin-top: -3rem !important;
    }

    .mr-sm-n12,
    .mx-sm-n12 {
        margin-right: -3rem !important;
    }

    .mb-sm-n12,
    .my-sm-n12 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n12,
    .mx-sm-n12 {
        margin-left: -3rem !important;
    }

    .m-sm-n13 {
        margin: -3.25rem !important;
    }

    .mt-sm-n13,
    .my-sm-n13 {
        margin-top: -3.25rem !important;
    }

    .mr-sm-n13,
    .mx-sm-n13 {
        margin-right: -3.25rem !important;
    }

    .mb-sm-n13,
    .my-sm-n13 {
        margin-bottom: -3.25rem !important;
    }

    .ml-sm-n13,
    .mx-sm-n13 {
        margin-left: -3.25rem !important;
    }

    .m-sm-n14 {
        margin: -3.5rem !important;
    }

    .mt-sm-n14,
    .my-sm-n14 {
        margin-top: -3.5rem !important;
    }

    .mr-sm-n14,
    .mx-sm-n14 {
        margin-right: -3.5rem !important;
    }

    .mb-sm-n14,
    .my-sm-n14 {
        margin-bottom: -3.5rem !important;
    }

    .ml-sm-n14,
    .mx-sm-n14 {
        margin-left: -3.5rem !important;
    }

    .m-sm-n15 {
        margin: -3.75rem !important;
    }

    .mt-sm-n15,
    .my-sm-n15 {
        margin-top: -3.75rem !important;
    }

    .mr-sm-n15,
    .mx-sm-n15 {
        margin-right: -3.75rem !important;
    }

    .mb-sm-n15,
    .my-sm-n15 {
        margin-bottom: -3.75rem !important;
    }

    .ml-sm-n15,
    .mx-sm-n15 {
        margin-left: -3.75rem !important;
    }

    .m-sm-n16 {
        margin: -4rem !important;
    }

    .mt-sm-n16,
    .my-sm-n16 {
        margin-top: -4rem !important;
    }

    .mr-sm-n16,
    .mx-sm-n16 {
        margin-right: -4rem !important;
    }

    .mb-sm-n16,
    .my-sm-n16 {
        margin-bottom: -4rem !important;
    }

    .ml-sm-n16,
    .mx-sm-n16 {
        margin-left: -4rem !important;
    }

    .m-sm-n17 {
        margin: -4.25rem !important;
    }

    .mt-sm-n17,
    .my-sm-n17 {
        margin-top: -4.25rem !important;
    }

    .mr-sm-n17,
    .mx-sm-n17 {
        margin-right: -4.25rem !important;
    }

    .mb-sm-n17,
    .my-sm-n17 {
        margin-bottom: -4.25rem !important;
    }

    .ml-sm-n17,
    .mx-sm-n17 {
        margin-left: -4.25rem !important;
    }

    .m-sm-n18 {
        margin: -4.5rem !important;
    }

    .mt-sm-n18,
    .my-sm-n18 {
        margin-top: -4.5rem !important;
    }

    .mr-sm-n18,
    .mx-sm-n18 {
        margin-right: -4.5rem !important;
    }

    .mb-sm-n18,
    .my-sm-n18 {
        margin-bottom: -4.5rem !important;
    }

    .ml-sm-n18,
    .mx-sm-n18 {
        margin-left: -4.5rem !important;
    }

    .m-sm-n19 {
        margin: -4.75rem !important;
    }

    .mt-sm-n19,
    .my-sm-n19 {
        margin-top: -4.75rem !important;
    }

    .mr-sm-n19,
    .mx-sm-n19 {
        margin-right: -4.75rem !important;
    }

    .mb-sm-n19,
    .my-sm-n19 {
        margin-bottom: -4.75rem !important;
    }

    .ml-sm-n19,
    .mx-sm-n19 {
        margin-left: -4.75rem !important;
    }

    .m-sm-n20 {
        margin: -5rem !important;
    }

    .mt-sm-n20,
    .my-sm-n20 {
        margin-top: -5rem !important;
    }

    .mr-sm-n20,
    .mx-sm-n20 {
        margin-right: -5rem !important;
    }

    .mb-sm-n20,
    .my-sm-n20 {
        margin-bottom: -5rem !important;
    }

    .ml-sm-n20,
    .mx-sm-n20 {
        margin-left: -5rem !important;
    }

    .m-sm-n21 {
        margin: -5.25rem !important;
    }

    .mt-sm-n21,
    .my-sm-n21 {
        margin-top: -5.25rem !important;
    }

    .mr-sm-n21,
    .mx-sm-n21 {
        margin-right: -5.25rem !important;
    }

    .mb-sm-n21,
    .my-sm-n21 {
        margin-bottom: -5.25rem !important;
    }

    .ml-sm-n21,
    .mx-sm-n21 {
        margin-left: -5.25rem !important;
    }

    .m-sm-n22 {
        margin: -5.5rem !important;
    }

    .mt-sm-n22,
    .my-sm-n22 {
        margin-top: -5.5rem !important;
    }

    .mr-sm-n22,
    .mx-sm-n22 {
        margin-right: -5.5rem !important;
    }

    .mb-sm-n22,
    .my-sm-n22 {
        margin-bottom: -5.5rem !important;
    }

    .ml-sm-n22,
    .mx-sm-n22 {
        margin-left: -5.5rem !important;
    }

    .m-sm-n23 {
        margin: -5.75rem !important;
    }

    .mt-sm-n23,
    .my-sm-n23 {
        margin-top: -5.75rem !important;
    }

    .mr-sm-n23,
    .mx-sm-n23 {
        margin-right: -5.75rem !important;
    }

    .mb-sm-n23,
    .my-sm-n23 {
        margin-bottom: -5.75rem !important;
    }

    .ml-sm-n23,
    .mx-sm-n23 {
        margin-left: -5.75rem !important;
    }

    .m-sm-n24 {
        margin: -6rem !important;
    }

    .mt-sm-n24,
    .my-sm-n24 {
        margin-top: -6rem !important;
    }

    .mr-sm-n24,
    .mx-sm-n24 {
        margin-right: -6rem !important;
    }

    .mb-sm-n24,
    .my-sm-n24 {
        margin-bottom: -6rem !important;
    }

    .ml-sm-n24,
    .mx-sm-n24 {
        margin-left: -6rem !important;
    }

    .m-sm-n25 {
        margin: -6.25rem !important;
    }

    .mt-sm-n25,
    .my-sm-n25 {
        margin-top: -6.25rem !important;
    }

    .mr-sm-n25,
    .mx-sm-n25 {
        margin-right: -6.25rem !important;
    }

    .mb-sm-n25,
    .my-sm-n25 {
        margin-bottom: -6.25rem !important;
    }

    .ml-sm-n25,
    .mx-sm-n25 {
        margin-left: -6.25rem !important;
    }

    .m-sm-n26 {
        margin: -6.5rem !important;
    }

    .mt-sm-n26,
    .my-sm-n26 {
        margin-top: -6.5rem !important;
    }

    .mr-sm-n26,
    .mx-sm-n26 {
        margin-right: -6.5rem !important;
    }

    .mb-sm-n26,
    .my-sm-n26 {
        margin-bottom: -6.5rem !important;
    }

    .ml-sm-n26,
    .mx-sm-n26 {
        margin-left: -6.5rem !important;
    }

    .m-sm-n27 {
        margin: -6.75rem !important;
    }

    .mt-sm-n27,
    .my-sm-n27 {
        margin-top: -6.75rem !important;
    }

    .mr-sm-n27,
    .mx-sm-n27 {
        margin-right: -6.75rem !important;
    }

    .mb-sm-n27,
    .my-sm-n27 {
        margin-bottom: -6.75rem !important;
    }

    .ml-sm-n27,
    .mx-sm-n27 {
        margin-left: -6.75rem !important;
    }

    .m-sm-n38 {
        margin: -7rem !important;
    }

    .mt-sm-n38,
    .my-sm-n38 {
        margin-top: -7rem !important;
    }

    .mr-sm-n38,
    .mx-sm-n38 {
        margin-right: -7rem !important;
    }

    .mb-sm-n38,
    .my-sm-n38 {
        margin-bottom: -7rem !important;
    }

    .ml-sm-n38,
    .mx-sm-n38 {
        margin-left: -7rem !important;
    }

    .m-sm-n29 {
        margin: -7.25rem !important;
    }

    .mt-sm-n29,
    .my-sm-n29 {
        margin-top: -7.25rem !important;
    }

    .mr-sm-n29,
    .mx-sm-n29 {
        margin-right: -7.25rem !important;
    }

    .mb-sm-n29,
    .my-sm-n29 {
        margin-bottom: -7.25rem !important;
    }

    .ml-sm-n29,
    .mx-sm-n29 {
        margin-left: -7.25rem !important;
    }

    .m-sm-n30 {
        margin: -7.5rem !important;
    }

    .mt-sm-n30,
    .my-sm-n30 {
        margin-top: -7.5rem !important;
    }

    .mr-sm-n30,
    .mx-sm-n30 {
        margin-right: -7.5rem !important;
    }

    .mb-sm-n30,
    .my-sm-n30 {
        margin-bottom: -7.5rem !important;
    }

    .ml-sm-n30,
    .mx-sm-n30 {
        margin-left: -7.5rem !important;
    }

    .m-sm-n31 {
        margin: -7.75rem !important;
    }

    .mt-sm-n31,
    .my-sm-n31 {
        margin-top: -7.75rem !important;
    }

    .mr-sm-n31,
    .mx-sm-n31 {
        margin-right: -7.75rem !important;
    }

    .mb-sm-n31,
    .my-sm-n31 {
        margin-bottom: -7.75rem !important;
    }

    .ml-sm-n31,
    .mx-sm-n31 {
        margin-left: -7.75rem !important;
    }

    .m-sm-n32 {
        margin: -8rem !important;
    }

    .mt-sm-n32,
    .my-sm-n32 {
        margin-top: -8rem !important;
    }

    .mr-sm-n32,
    .mx-sm-n32 {
        margin-right: -8rem !important;
    }

    .mb-sm-n32,
    .my-sm-n32 {
        margin-bottom: -8rem !important;
    }

    .ml-sm-n32,
    .mx-sm-n32 {
        margin-left: -8rem !important;
    }

    .m-sm-n33 {
        margin: -8.25rem !important;
    }

    .mt-sm-n33,
    .my-sm-n33 {
        margin-top: -8.25rem !important;
    }

    .mr-sm-n33,
    .mx-sm-n33 {
        margin-right: -8.25rem !important;
    }

    .mb-sm-n33,
    .my-sm-n33 {
        margin-bottom: -8.25rem !important;
    }

    .ml-sm-n33,
    .mx-sm-n33 {
        margin-left: -8.25rem !important;
    }

    .m-sm-n34 {
        margin: -8.5rem !important;
    }

    .mt-sm-n34,
    .my-sm-n34 {
        margin-top: -8.5rem !important;
    }

    .mr-sm-n34,
    .mx-sm-n34 {
        margin-right: -8.5rem !important;
    }

    .mb-sm-n34,
    .my-sm-n34 {
        margin-bottom: -8.5rem !important;
    }

    .ml-sm-n34,
    .mx-sm-n34 {
        margin-left: -8.5rem !important;
    }

    .m-sm-n35 {
        margin: -8.75rem !important;
    }

    .mt-sm-n35,
    .my-sm-n35 {
        margin-top: -8.75rem !important;
    }

    .mr-sm-n35,
    .mx-sm-n35 {
        margin-right: -8.75rem !important;
    }

    .mb-sm-n35,
    .my-sm-n35 {
        margin-bottom: -8.75rem !important;
    }

    .ml-sm-n35,
    .mx-sm-n35 {
        margin-left: -8.75rem !important;
    }

    .m-sm-n36 {
        margin: -9rem !important;
    }

    .mt-sm-n36,
    .my-sm-n36 {
        margin-top: -9rem !important;
    }

    .mr-sm-n36,
    .mx-sm-n36 {
        margin-right: -9rem !important;
    }

    .mb-sm-n36,
    .my-sm-n36 {
        margin-bottom: -9rem !important;
    }

    .ml-sm-n36,
    .mx-sm-n36 {
        margin-left: -9rem !important;
    }

    .m-sm-n37 {
        margin: -9.25rem !important;
    }

    .mt-sm-n37,
    .my-sm-n37 {
        margin-top: -9.25rem !important;
    }

    .mr-sm-n37,
    .mx-sm-n37 {
        margin-right: -9.25rem !important;
    }

    .mb-sm-n37,
    .my-sm-n37 {
        margin-bottom: -9.25rem !important;
    }

    .ml-sm-n37,
    .mx-sm-n37 {
        margin-left: -9.25rem !important;
    }

    .m-sm-n48 {
        margin: -9.5rem !important;
    }

    .mt-sm-n48,
    .my-sm-n48 {
        margin-top: -9.5rem !important;
    }

    .mr-sm-n48,
    .mx-sm-n48 {
        margin-right: -9.5rem !important;
    }

    .mb-sm-n48,
    .my-sm-n48 {
        margin-bottom: -9.5rem !important;
    }

    .ml-sm-n48,
    .mx-sm-n48 {
        margin-left: -9.5rem !important;
    }

    .m-sm-n39 {
        margin: -9.75rem !important;
    }

    .mt-sm-n39,
    .my-sm-n39 {
        margin-top: -9.75rem !important;
    }

    .mr-sm-n39,
    .mx-sm-n39 {
        margin-right: -9.75rem !important;
    }

    .mb-sm-n39,
    .my-sm-n39 {
        margin-bottom: -9.75rem !important;
    }

    .ml-sm-n39,
    .mx-sm-n39 {
        margin-left: -9.75rem !important;
    }

    .m-sm-n40 {
        margin: -10rem !important;
    }

    .mt-sm-n40,
    .my-sm-n40 {
        margin-top: -10rem !important;
    }

    .mr-sm-n40,
    .mx-sm-n40 {
        margin-right: -10rem !important;
    }

    .mb-sm-n40,
    .my-sm-n40 {
        margin-bottom: -10rem !important;
    }

    .ml-sm-n40,
    .mx-sm-n40 {
        margin-left: -10rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 0.75rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 0.75rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 0.75rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 0.75rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 0.75rem !important;
    }

    .m-md-4 {
        margin: 1rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1rem !important;
    }

    .m-md-5 {
        margin: 1.25rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 1.25rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 1.25rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 1.25rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 1.25rem !important;
    }

    .m-md-6 {
        margin: 1.5rem !important;
    }

    .mt-md-6,
    .my-md-6 {
        margin-top: 1.5rem !important;
    }

    .mr-md-6,
    .mx-md-6 {
        margin-right: 1.5rem !important;
    }

    .mb-md-6,
    .my-md-6 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-6,
    .mx-md-6 {
        margin-left: 1.5rem !important;
    }

    .m-md-7 {
        margin: 1.75rem !important;
    }

    .mt-md-7,
    .my-md-7 {
        margin-top: 1.75rem !important;
    }

    .mr-md-7,
    .mx-md-7 {
        margin-right: 1.75rem !important;
    }

    .mb-md-7,
    .my-md-7 {
        margin-bottom: 1.75rem !important;
    }

    .ml-md-7,
    .mx-md-7 {
        margin-left: 1.75rem !important;
    }

    .m-md-8 {
        margin: 2rem !important;
    }

    .mt-md-8,
    .my-md-8 {
        margin-top: 2rem !important;
    }

    .mr-md-8,
    .mx-md-8 {
        margin-right: 2rem !important;
    }

    .mb-md-8,
    .my-md-8 {
        margin-bottom: 2rem !important;
    }

    .ml-md-8,
    .mx-md-8 {
        margin-left: 2rem !important;
    }

    .m-md-9 {
        margin: 2.25rem !important;
    }

    .mt-md-9,
    .my-md-9 {
        margin-top: 2.25rem !important;
    }

    .mr-md-9,
    .mx-md-9 {
        margin-right: 2.25rem !important;
    }

    .mb-md-9,
    .my-md-9 {
        margin-bottom: 2.25rem !important;
    }

    .ml-md-9,
    .mx-md-9 {
        margin-left: 2.25rem !important;
    }

    .m-md-10 {
        margin: 2.5rem !important;
    }

    .mt-md-10,
    .my-md-10 {
        margin-top: 2.5rem !important;
    }

    .mr-md-10,
    .mx-md-10 {
        margin-right: 2.5rem !important;
    }

    .mb-md-10,
    .my-md-10 {
        margin-bottom: 2.5rem !important;
    }

    .ml-md-10,
    .mx-md-10 {
        margin-left: 2.5rem !important;
    }

    .m-md-11 {
        margin: 2.75rem !important;
    }

    .mt-md-11,
    .my-md-11 {
        margin-top: 2.75rem !important;
    }

    .mr-md-11,
    .mx-md-11 {
        margin-right: 2.75rem !important;
    }

    .mb-md-11,
    .my-md-11 {
        margin-bottom: 2.75rem !important;
    }

    .ml-md-11,
    .mx-md-11 {
        margin-left: 2.75rem !important;
    }

    .m-md-12 {
        margin: 3rem !important;
    }

    .mt-md-12,
    .my-md-12 {
        margin-top: 3rem !important;
    }

    .mr-md-12,
    .mx-md-12 {
        margin-right: 3rem !important;
    }

    .mb-md-12,
    .my-md-12 {
        margin-bottom: 3rem !important;
    }

    .ml-md-12,
    .mx-md-12 {
        margin-left: 3rem !important;
    }

    .m-md-13 {
        margin: 3.25rem !important;
    }

    .mt-md-13,
    .my-md-13 {
        margin-top: 3.25rem !important;
    }

    .mr-md-13,
    .mx-md-13 {
        margin-right: 3.25rem !important;
    }

    .mb-md-13,
    .my-md-13 {
        margin-bottom: 3.25rem !important;
    }

    .ml-md-13,
    .mx-md-13 {
        margin-left: 3.25rem !important;
    }

    .m-md-14 {
        margin: 3.5rem !important;
    }

    .mt-md-14,
    .my-md-14 {
        margin-top: 3.5rem !important;
    }

    .mr-md-14,
    .mx-md-14 {
        margin-right: 3.5rem !important;
    }

    .mb-md-14,
    .my-md-14 {
        margin-bottom: 3.5rem !important;
    }

    .ml-md-14,
    .mx-md-14 {
        margin-left: 3.5rem !important;
    }

    .m-md-15 {
        margin: 3.75rem !important;
    }

    .mt-md-15,
    .my-md-15 {
        margin-top: 3.75rem !important;
    }

    .mr-md-15,
    .mx-md-15 {
        margin-right: 3.75rem !important;
    }

    .mb-md-15,
    .my-md-15 {
        margin-bottom: 3.75rem !important;
    }

    .ml-md-15,
    .mx-md-15 {
        margin-left: 3.75rem !important;
    }

    .m-md-16 {
        margin: 4rem !important;
    }

    .mt-md-16,
    .my-md-16 {
        margin-top: 4rem !important;
    }

    .mr-md-16,
    .mx-md-16 {
        margin-right: 4rem !important;
    }

    .mb-md-16,
    .my-md-16 {
        margin-bottom: 4rem !important;
    }

    .ml-md-16,
    .mx-md-16 {
        margin-left: 4rem !important;
    }

    .m-md-17 {
        margin: 4.25rem !important;
    }

    .mt-md-17,
    .my-md-17 {
        margin-top: 4.25rem !important;
    }

    .mr-md-17,
    .mx-md-17 {
        margin-right: 4.25rem !important;
    }

    .mb-md-17,
    .my-md-17 {
        margin-bottom: 4.25rem !important;
    }

    .ml-md-17,
    .mx-md-17 {
        margin-left: 4.25rem !important;
    }

    .m-md-18 {
        margin: 4.5rem !important;
    }

    .mt-md-18,
    .my-md-18 {
        margin-top: 4.5rem !important;
    }

    .mr-md-18,
    .mx-md-18 {
        margin-right: 4.5rem !important;
    }

    .mb-md-18,
    .my-md-18 {
        margin-bottom: 4.5rem !important;
    }

    .ml-md-18,
    .mx-md-18 {
        margin-left: 4.5rem !important;
    }

    .m-md-19 {
        margin: 4.75rem !important;
    }

    .mt-md-19,
    .my-md-19 {
        margin-top: 4.75rem !important;
    }

    .mr-md-19,
    .mx-md-19 {
        margin-right: 4.75rem !important;
    }

    .mb-md-19,
    .my-md-19 {
        margin-bottom: 4.75rem !important;
    }

    .ml-md-19,
    .mx-md-19 {
        margin-left: 4.75rem !important;
    }

    .m-md-20 {
        margin: 5rem !important;
    }

    .mt-md-20,
    .my-md-20 {
        margin-top: 5rem !important;
    }

    .mr-md-20,
    .mx-md-20 {
        margin-right: 5rem !important;
    }

    .mb-md-20,
    .my-md-20 {
        margin-bottom: 5rem !important;
    }

    .ml-md-20,
    .mx-md-20 {
        margin-left: 5rem !important;
    }

    .m-md-21 {
        margin: 5.25rem !important;
    }

    .mt-md-21,
    .my-md-21 {
        margin-top: 5.25rem !important;
    }

    .mr-md-21,
    .mx-md-21 {
        margin-right: 5.25rem !important;
    }

    .mb-md-21,
    .my-md-21 {
        margin-bottom: 5.25rem !important;
    }

    .ml-md-21,
    .mx-md-21 {
        margin-left: 5.25rem !important;
    }

    .m-md-22 {
        margin: 5.5rem !important;
    }

    .mt-md-22,
    .my-md-22 {
        margin-top: 5.5rem !important;
    }

    .mr-md-22,
    .mx-md-22 {
        margin-right: 5.5rem !important;
    }

    .mb-md-22,
    .my-md-22 {
        margin-bottom: 5.5rem !important;
    }

    .ml-md-22,
    .mx-md-22 {
        margin-left: 5.5rem !important;
    }

    .m-md-23 {
        margin: 5.75rem !important;
    }

    .mt-md-23,
    .my-md-23 {
        margin-top: 5.75rem !important;
    }

    .mr-md-23,
    .mx-md-23 {
        margin-right: 5.75rem !important;
    }

    .mb-md-23,
    .my-md-23 {
        margin-bottom: 5.75rem !important;
    }

    .ml-md-23,
    .mx-md-23 {
        margin-left: 5.75rem !important;
    }

    .m-md-24 {
        margin: 6rem !important;
    }

    .mt-md-24,
    .my-md-24 {
        margin-top: 6rem !important;
    }

    .mr-md-24,
    .mx-md-24 {
        margin-right: 6rem !important;
    }

    .mb-md-24,
    .my-md-24 {
        margin-bottom: 6rem !important;
    }

    .ml-md-24,
    .mx-md-24 {
        margin-left: 6rem !important;
    }

    .m-md-25 {
        margin: 6.25rem !important;
    }

    .mt-md-25,
    .my-md-25 {
        margin-top: 6.25rem !important;
    }

    .mr-md-25,
    .mx-md-25 {
        margin-right: 6.25rem !important;
    }

    .mb-md-25,
    .my-md-25 {
        margin-bottom: 6.25rem !important;
    }

    .ml-md-25,
    .mx-md-25 {
        margin-left: 6.25rem !important;
    }

    .m-md-26 {
        margin: 6.5rem !important;
    }

    .mt-md-26,
    .my-md-26 {
        margin-top: 6.5rem !important;
    }

    .mr-md-26,
    .mx-md-26 {
        margin-right: 6.5rem !important;
    }

    .mb-md-26,
    .my-md-26 {
        margin-bottom: 6.5rem !important;
    }

    .ml-md-26,
    .mx-md-26 {
        margin-left: 6.5rem !important;
    }

    .m-md-27 {
        margin: 6.75rem !important;
    }

    .mt-md-27,
    .my-md-27 {
        margin-top: 6.75rem !important;
    }

    .mr-md-27,
    .mx-md-27 {
        margin-right: 6.75rem !important;
    }

    .mb-md-27,
    .my-md-27 {
        margin-bottom: 6.75rem !important;
    }

    .ml-md-27,
    .mx-md-27 {
        margin-left: 6.75rem !important;
    }

    .m-md-38 {
        margin: 7rem !important;
    }

    .mt-md-38,
    .my-md-38 {
        margin-top: 7rem !important;
    }

    .mr-md-38,
    .mx-md-38 {
        margin-right: 7rem !important;
    }

    .mb-md-38,
    .my-md-38 {
        margin-bottom: 7rem !important;
    }

    .ml-md-38,
    .mx-md-38 {
        margin-left: 7rem !important;
    }

    .m-md-29 {
        margin: 7.25rem !important;
    }

    .mt-md-29,
    .my-md-29 {
        margin-top: 7.25rem !important;
    }

    .mr-md-29,
    .mx-md-29 {
        margin-right: 7.25rem !important;
    }

    .mb-md-29,
    .my-md-29 {
        margin-bottom: 7.25rem !important;
    }

    .ml-md-29,
    .mx-md-29 {
        margin-left: 7.25rem !important;
    }

    .m-md-30 {
        margin: 7.5rem !important;
    }

    .mt-md-30,
    .my-md-30 {
        margin-top: 7.5rem !important;
    }

    .mr-md-30,
    .mx-md-30 {
        margin-right: 7.5rem !important;
    }

    .mb-md-30,
    .my-md-30 {
        margin-bottom: 7.5rem !important;
    }

    .ml-md-30,
    .mx-md-30 {
        margin-left: 7.5rem !important;
    }

    .m-md-31 {
        margin: 7.75rem !important;
    }

    .mt-md-31,
    .my-md-31 {
        margin-top: 7.75rem !important;
    }

    .mr-md-31,
    .mx-md-31 {
        margin-right: 7.75rem !important;
    }

    .mb-md-31,
    .my-md-31 {
        margin-bottom: 7.75rem !important;
    }

    .ml-md-31,
    .mx-md-31 {
        margin-left: 7.75rem !important;
    }

    .m-md-32 {
        margin: 8rem !important;
    }

    .mt-md-32,
    .my-md-32 {
        margin-top: 8rem !important;
    }

    .mr-md-32,
    .mx-md-32 {
        margin-right: 8rem !important;
    }

    .mb-md-32,
    .my-md-32 {
        margin-bottom: 8rem !important;
    }

    .ml-md-32,
    .mx-md-32 {
        margin-left: 8rem !important;
    }

    .m-md-33 {
        margin: 8.25rem !important;
    }

    .mt-md-33,
    .my-md-33 {
        margin-top: 8.25rem !important;
    }

    .mr-md-33,
    .mx-md-33 {
        margin-right: 8.25rem !important;
    }

    .mb-md-33,
    .my-md-33 {
        margin-bottom: 8.25rem !important;
    }

    .ml-md-33,
    .mx-md-33 {
        margin-left: 8.25rem !important;
    }

    .m-md-34 {
        margin: 8.5rem !important;
    }

    .mt-md-34,
    .my-md-34 {
        margin-top: 8.5rem !important;
    }

    .mr-md-34,
    .mx-md-34 {
        margin-right: 8.5rem !important;
    }

    .mb-md-34,
    .my-md-34 {
        margin-bottom: 8.5rem !important;
    }

    .ml-md-34,
    .mx-md-34 {
        margin-left: 8.5rem !important;
    }

    .m-md-35 {
        margin: 8.75rem !important;
    }

    .mt-md-35,
    .my-md-35 {
        margin-top: 8.75rem !important;
    }

    .mr-md-35,
    .mx-md-35 {
        margin-right: 8.75rem !important;
    }

    .mb-md-35,
    .my-md-35 {
        margin-bottom: 8.75rem !important;
    }

    .ml-md-35,
    .mx-md-35 {
        margin-left: 8.75rem !important;
    }

    .m-md-36 {
        margin: 9rem !important;
    }

    .mt-md-36,
    .my-md-36 {
        margin-top: 9rem !important;
    }

    .mr-md-36,
    .mx-md-36 {
        margin-right: 9rem !important;
    }

    .mb-md-36,
    .my-md-36 {
        margin-bottom: 9rem !important;
    }

    .ml-md-36,
    .mx-md-36 {
        margin-left: 9rem !important;
    }

    .m-md-37 {
        margin: 9.25rem !important;
    }

    .mt-md-37,
    .my-md-37 {
        margin-top: 9.25rem !important;
    }

    .mr-md-37,
    .mx-md-37 {
        margin-right: 9.25rem !important;
    }

    .mb-md-37,
    .my-md-37 {
        margin-bottom: 9.25rem !important;
    }

    .ml-md-37,
    .mx-md-37 {
        margin-left: 9.25rem !important;
    }

    .m-md-48 {
        margin: 9.5rem !important;
    }

    .mt-md-48,
    .my-md-48 {
        margin-top: 9.5rem !important;
    }

    .mr-md-48,
    .mx-md-48 {
        margin-right: 9.5rem !important;
    }

    .mb-md-48,
    .my-md-48 {
        margin-bottom: 9.5rem !important;
    }

    .ml-md-48,
    .mx-md-48 {
        margin-left: 9.5rem !important;
    }

    .m-md-39 {
        margin: 9.75rem !important;
    }

    .mt-md-39,
    .my-md-39 {
        margin-top: 9.75rem !important;
    }

    .mr-md-39,
    .mx-md-39 {
        margin-right: 9.75rem !important;
    }

    .mb-md-39,
    .my-md-39 {
        margin-bottom: 9.75rem !important;
    }

    .ml-md-39,
    .mx-md-39 {
        margin-left: 9.75rem !important;
    }

    .m-md-40 {
        margin: 10rem !important;
    }

    .mt-md-40,
    .my-md-40 {
        margin-top: 10rem !important;
    }

    .mr-md-40,
    .mx-md-40 {
        margin-right: 10rem !important;
    }

    .mb-md-40,
    .my-md-40 {
        margin-bottom: 10rem !important;
    }

    .ml-md-40,
    .mx-md-40 {
        margin-left: 10rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 0.75rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 0.75rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 0.75rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 0.75rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 0.75rem !important;
    }

    .p-md-4 {
        padding: 1rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1rem !important;
    }

    .p-md-5 {
        padding: 1.25rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 1.25rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 1.25rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 1.25rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 1.25rem !important;
    }

    .p-md-6 {
        padding: 1.5rem !important;
    }

    .pt-md-6,
    .py-md-6 {
        padding-top: 1.5rem !important;
    }

    .pr-md-6,
    .px-md-6 {
        padding-right: 1.5rem !important;
    }

    .pb-md-6,
    .py-md-6 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-6,
    .px-md-6 {
        padding-left: 1.5rem !important;
    }

    .p-md-7 {
        padding: 1.75rem !important;
    }

    .pt-md-7,
    .py-md-7 {
        padding-top: 1.75rem !important;
    }

    .pr-md-7,
    .px-md-7 {
        padding-right: 1.75rem !important;
    }

    .pb-md-7,
    .py-md-7 {
        padding-bottom: 1.75rem !important;
    }

    .pl-md-7,
    .px-md-7 {
        padding-left: 1.75rem !important;
    }

    .p-md-8 {
        padding: 2rem !important;
    }

    .pt-md-8,
    .py-md-8 {
        padding-top: 2rem !important;
    }

    .pr-md-8,
    .px-md-8 {
        padding-right: 2rem !important;
    }

    .pb-md-8,
    .py-md-8 {
        padding-bottom: 2rem !important;
    }

    .pl-md-8,
    .px-md-8 {
        padding-left: 2rem !important;
    }

    .p-md-9 {
        padding: 2.25rem !important;
    }

    .pt-md-9,
    .py-md-9 {
        padding-top: 2.25rem !important;
    }

    .pr-md-9,
    .px-md-9 {
        padding-right: 2.25rem !important;
    }

    .pb-md-9,
    .py-md-9 {
        padding-bottom: 2.25rem !important;
    }

    .pl-md-9,
    .px-md-9 {
        padding-left: 2.25rem !important;
    }

    .p-md-10 {
        padding: 2.5rem !important;
    }

    .pt-md-10,
    .py-md-10 {
        padding-top: 2.5rem !important;
    }

    .pr-md-10,
    .px-md-10 {
        padding-right: 2.5rem !important;
    }

    .pb-md-10,
    .py-md-10 {
        padding-bottom: 2.5rem !important;
    }

    .pl-md-10,
    .px-md-10 {
        padding-left: 2.5rem !important;
    }

    .p-md-11 {
        padding: 2.75rem !important;
    }

    .pt-md-11,
    .py-md-11 {
        padding-top: 2.75rem !important;
    }

    .pr-md-11,
    .px-md-11 {
        padding-right: 2.75rem !important;
    }

    .pb-md-11,
    .py-md-11 {
        padding-bottom: 2.75rem !important;
    }

    .pl-md-11,
    .px-md-11 {
        padding-left: 2.75rem !important;
    }

    .p-md-12 {
        padding: 3rem !important;
    }

    .pt-md-12,
    .py-md-12 {
        padding-top: 3rem !important;
    }

    .pr-md-12,
    .px-md-12 {
        padding-right: 3rem !important;
    }

    .pb-md-12,
    .py-md-12 {
        padding-bottom: 3rem !important;
    }

    .pl-md-12,
    .px-md-12 {
        padding-left: 3rem !important;
    }

    .p-md-13 {
        padding: 3.25rem !important;
    }

    .pt-md-13,
    .py-md-13 {
        padding-top: 3.25rem !important;
    }

    .pr-md-13,
    .px-md-13 {
        padding-right: 3.25rem !important;
    }

    .pb-md-13,
    .py-md-13 {
        padding-bottom: 3.25rem !important;
    }

    .pl-md-13,
    .px-md-13 {
        padding-left: 3.25rem !important;
    }

    .p-md-14 {
        padding: 3.5rem !important;
    }

    .pt-md-14,
    .py-md-14 {
        padding-top: 3.5rem !important;
    }

    .pr-md-14,
    .px-md-14 {
        padding-right: 3.5rem !important;
    }

    .pb-md-14,
    .py-md-14 {
        padding-bottom: 3.5rem !important;
    }

    .pl-md-14,
    .px-md-14 {
        padding-left: 3.5rem !important;
    }

    .p-md-15 {
        padding: 3.75rem !important;
    }

    .pt-md-15,
    .py-md-15 {
        padding-top: 3.75rem !important;
    }

    .pr-md-15,
    .px-md-15 {
        padding-right: 3.75rem !important;
    }

    .pb-md-15,
    .py-md-15 {
        padding-bottom: 3.75rem !important;
    }

    .pl-md-15,
    .px-md-15 {
        padding-left: 3.75rem !important;
    }

    .p-md-16 {
        padding: 4rem !important;
    }

    .pt-md-16,
    .py-md-16 {
        padding-top: 4rem !important;
    }

    .pr-md-16,
    .px-md-16 {
        padding-right: 4rem !important;
    }

    .pb-md-16,
    .py-md-16 {
        padding-bottom: 4rem !important;
    }

    .pl-md-16,
    .px-md-16 {
        padding-left: 4rem !important;
    }

    .p-md-17 {
        padding: 4.25rem !important;
    }

    .pt-md-17,
    .py-md-17 {
        padding-top: 4.25rem !important;
    }

    .pr-md-17,
    .px-md-17 {
        padding-right: 4.25rem !important;
    }

    .pb-md-17,
    .py-md-17 {
        padding-bottom: 4.25rem !important;
    }

    .pl-md-17,
    .px-md-17 {
        padding-left: 4.25rem !important;
    }

    .p-md-18 {
        padding: 4.5rem !important;
    }

    .pt-md-18,
    .py-md-18 {
        padding-top: 4.5rem !important;
    }

    .pr-md-18,
    .px-md-18 {
        padding-right: 4.5rem !important;
    }

    .pb-md-18,
    .py-md-18 {
        padding-bottom: 4.5rem !important;
    }

    .pl-md-18,
    .px-md-18 {
        padding-left: 4.5rem !important;
    }

    .p-md-19 {
        padding: 4.75rem !important;
    }

    .pt-md-19,
    .py-md-19 {
        padding-top: 4.75rem !important;
    }

    .pr-md-19,
    .px-md-19 {
        padding-right: 4.75rem !important;
    }

    .pb-md-19,
    .py-md-19 {
        padding-bottom: 4.75rem !important;
    }

    .pl-md-19,
    .px-md-19 {
        padding-left: 4.75rem !important;
    }

    .p-md-20 {
        padding: 5rem !important;
    }

    .pt-md-20,
    .py-md-20 {
        padding-top: 5rem !important;
    }

    .pr-md-20,
    .px-md-20 {
        padding-right: 5rem !important;
    }

    .pb-md-20,
    .py-md-20 {
        padding-bottom: 5rem !important;
    }

    .pl-md-20,
    .px-md-20 {
        padding-left: 5rem !important;
    }

    .p-md-21 {
        padding: 5.25rem !important;
    }

    .pt-md-21,
    .py-md-21 {
        padding-top: 5.25rem !important;
    }

    .pr-md-21,
    .px-md-21 {
        padding-right: 5.25rem !important;
    }

    .pb-md-21,
    .py-md-21 {
        padding-bottom: 5.25rem !important;
    }

    .pl-md-21,
    .px-md-21 {
        padding-left: 5.25rem !important;
    }

    .p-md-22 {
        padding: 5.5rem !important;
    }

    .pt-md-22,
    .py-md-22 {
        padding-top: 5.5rem !important;
    }

    .pr-md-22,
    .px-md-22 {
        padding-right: 5.5rem !important;
    }

    .pb-md-22,
    .py-md-22 {
        padding-bottom: 5.5rem !important;
    }

    .pl-md-22,
    .px-md-22 {
        padding-left: 5.5rem !important;
    }

    .p-md-23 {
        padding: 5.75rem !important;
    }

    .pt-md-23,
    .py-md-23 {
        padding-top: 5.75rem !important;
    }

    .pr-md-23,
    .px-md-23 {
        padding-right: 5.75rem !important;
    }

    .pb-md-23,
    .py-md-23 {
        padding-bottom: 5.75rem !important;
    }

    .pl-md-23,
    .px-md-23 {
        padding-left: 5.75rem !important;
    }

    .p-md-24 {
        padding: 6rem !important;
    }

    .pt-md-24,
    .py-md-24 {
        padding-top: 6rem !important;
    }

    .pr-md-24,
    .px-md-24 {
        padding-right: 6rem !important;
    }

    .pb-md-24,
    .py-md-24 {
        padding-bottom: 6rem !important;
    }

    .pl-md-24,
    .px-md-24 {
        padding-left: 6rem !important;
    }

    .p-md-25 {
        padding: 6.25rem !important;
    }

    .pt-md-25,
    .py-md-25 {
        padding-top: 6.25rem !important;
    }

    .pr-md-25,
    .px-md-25 {
        padding-right: 6.25rem !important;
    }

    .pb-md-25,
    .py-md-25 {
        padding-bottom: 6.25rem !important;
    }

    .pl-md-25,
    .px-md-25 {
        padding-left: 6.25rem !important;
    }

    .p-md-26 {
        padding: 6.5rem !important;
    }

    .pt-md-26,
    .py-md-26 {
        padding-top: 6.5rem !important;
    }

    .pr-md-26,
    .px-md-26 {
        padding-right: 6.5rem !important;
    }

    .pb-md-26,
    .py-md-26 {
        padding-bottom: 6.5rem !important;
    }

    .pl-md-26,
    .px-md-26 {
        padding-left: 6.5rem !important;
    }

    .p-md-27 {
        padding: 6.75rem !important;
    }

    .pt-md-27,
    .py-md-27 {
        padding-top: 6.75rem !important;
    }

    .pr-md-27,
    .px-md-27 {
        padding-right: 6.75rem !important;
    }

    .pb-md-27,
    .py-md-27 {
        padding-bottom: 6.75rem !important;
    }

    .pl-md-27,
    .px-md-27 {
        padding-left: 6.75rem !important;
    }

    .p-md-38 {
        padding: 7rem !important;
    }

    .pt-md-38,
    .py-md-38 {
        padding-top: 7rem !important;
    }

    .pr-md-38,
    .px-md-38 {
        padding-right: 7rem !important;
    }

    .pb-md-38,
    .py-md-38 {
        padding-bottom: 7rem !important;
    }

    .pl-md-38,
    .px-md-38 {
        padding-left: 7rem !important;
    }

    .p-md-29 {
        padding: 7.25rem !important;
    }

    .pt-md-29,
    .py-md-29 {
        padding-top: 7.25rem !important;
    }

    .pr-md-29,
    .px-md-29 {
        padding-right: 7.25rem !important;
    }

    .pb-md-29,
    .py-md-29 {
        padding-bottom: 7.25rem !important;
    }

    .pl-md-29,
    .px-md-29 {
        padding-left: 7.25rem !important;
    }

    .p-md-30 {
        padding: 7.5rem !important;
    }

    .pt-md-30,
    .py-md-30 {
        padding-top: 7.5rem !important;
    }

    .pr-md-30,
    .px-md-30 {
        padding-right: 7.5rem !important;
    }

    .pb-md-30,
    .py-md-30 {
        padding-bottom: 7.5rem !important;
    }

    .pl-md-30,
    .px-md-30 {
        padding-left: 7.5rem !important;
    }

    .p-md-31 {
        padding: 7.75rem !important;
    }

    .pt-md-31,
    .py-md-31 {
        padding-top: 7.75rem !important;
    }

    .pr-md-31,
    .px-md-31 {
        padding-right: 7.75rem !important;
    }

    .pb-md-31,
    .py-md-31 {
        padding-bottom: 7.75rem !important;
    }

    .pl-md-31,
    .px-md-31 {
        padding-left: 7.75rem !important;
    }

    .p-md-32 {
        padding: 8rem !important;
    }

    .pt-md-32,
    .py-md-32 {
        padding-top: 8rem !important;
    }

    .pr-md-32,
    .px-md-32 {
        padding-right: 8rem !important;
    }

    .pb-md-32,
    .py-md-32 {
        padding-bottom: 8rem !important;
    }

    .pl-md-32,
    .px-md-32 {
        padding-left: 8rem !important;
    }

    .p-md-33 {
        padding: 8.25rem !important;
    }

    .pt-md-33,
    .py-md-33 {
        padding-top: 8.25rem !important;
    }

    .pr-md-33,
    .px-md-33 {
        padding-right: 8.25rem !important;
    }

    .pb-md-33,
    .py-md-33 {
        padding-bottom: 8.25rem !important;
    }

    .pl-md-33,
    .px-md-33 {
        padding-left: 8.25rem !important;
    }

    .p-md-34 {
        padding: 8.5rem !important;
    }

    .pt-md-34,
    .py-md-34 {
        padding-top: 8.5rem !important;
    }

    .pr-md-34,
    .px-md-34 {
        padding-right: 8.5rem !important;
    }

    .pb-md-34,
    .py-md-34 {
        padding-bottom: 8.5rem !important;
    }

    .pl-md-34,
    .px-md-34 {
        padding-left: 8.5rem !important;
    }

    .p-md-35 {
        padding: 8.75rem !important;
    }

    .pt-md-35,
    .py-md-35 {
        padding-top: 8.75rem !important;
    }

    .pr-md-35,
    .px-md-35 {
        padding-right: 8.75rem !important;
    }

    .pb-md-35,
    .py-md-35 {
        padding-bottom: 8.75rem !important;
    }

    .pl-md-35,
    .px-md-35 {
        padding-left: 8.75rem !important;
    }

    .p-md-36 {
        padding: 9rem !important;
    }

    .pt-md-36,
    .py-md-36 {
        padding-top: 9rem !important;
    }

    .pr-md-36,
    .px-md-36 {
        padding-right: 9rem !important;
    }

    .pb-md-36,
    .py-md-36 {
        padding-bottom: 9rem !important;
    }

    .pl-md-36,
    .px-md-36 {
        padding-left: 9rem !important;
    }

    .p-md-37 {
        padding: 9.25rem !important;
    }

    .pt-md-37,
    .py-md-37 {
        padding-top: 9.25rem !important;
    }

    .pr-md-37,
    .px-md-37 {
        padding-right: 9.25rem !important;
    }

    .pb-md-37,
    .py-md-37 {
        padding-bottom: 9.25rem !important;
    }

    .pl-md-37,
    .px-md-37 {
        padding-left: 9.25rem !important;
    }

    .p-md-48 {
        padding: 9.5rem !important;
    }

    .pt-md-48,
    .py-md-48 {
        padding-top: 9.5rem !important;
    }

    .pr-md-48,
    .px-md-48 {
        padding-right: 9.5rem !important;
    }

    .pb-md-48,
    .py-md-48 {
        padding-bottom: 9.5rem !important;
    }

    .pl-md-48,
    .px-md-48 {
        padding-left: 9.5rem !important;
    }

    .p-md-39 {
        padding: 9.75rem !important;
    }

    .pt-md-39,
    .py-md-39 {
        padding-top: 9.75rem !important;
    }

    .pr-md-39,
    .px-md-39 {
        padding-right: 9.75rem !important;
    }

    .pb-md-39,
    .py-md-39 {
        padding-bottom: 9.75rem !important;
    }

    .pl-md-39,
    .px-md-39 {
        padding-left: 9.75rem !important;
    }

    .p-md-40 {
        padding: 10rem !important;
    }

    .pt-md-40,
    .py-md-40 {
        padding-top: 10rem !important;
    }

    .pr-md-40,
    .px-md-40 {
        padding-right: 10rem !important;
    }

    .pb-md-40,
    .py-md-40 {
        padding-bottom: 10rem !important;
    }

    .pl-md-40,
    .px-md-40 {
        padding-left: 10rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -0.75rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -0.75rem !important;
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -0.75rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -0.75rem !important;
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -0.75rem !important;
    }

    .m-md-n4 {
        margin: -1rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1rem !important;
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1rem !important;
    }

    .m-md-n5 {
        margin: -1.25rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -1.25rem !important;
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -1.25rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -1.25rem !important;
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -1.25rem !important;
    }

    .m-md-n6 {
        margin: -1.5rem !important;
    }

    .mt-md-n6,
    .my-md-n6 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n6,
    .mx-md-n6 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n6,
    .my-md-n6 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n6,
    .mx-md-n6 {
        margin-left: -1.5rem !important;
    }

    .m-md-n7 {
        margin: -1.75rem !important;
    }

    .mt-md-n7,
    .my-md-n7 {
        margin-top: -1.75rem !important;
    }

    .mr-md-n7,
    .mx-md-n7 {
        margin-right: -1.75rem !important;
    }

    .mb-md-n7,
    .my-md-n7 {
        margin-bottom: -1.75rem !important;
    }

    .ml-md-n7,
    .mx-md-n7 {
        margin-left: -1.75rem !important;
    }

    .m-md-n8 {
        margin: -2rem !important;
    }

    .mt-md-n8,
    .my-md-n8 {
        margin-top: -2rem !important;
    }

    .mr-md-n8,
    .mx-md-n8 {
        margin-right: -2rem !important;
    }

    .mb-md-n8,
    .my-md-n8 {
        margin-bottom: -2rem !important;
    }

    .ml-md-n8,
    .mx-md-n8 {
        margin-left: -2rem !important;
    }

    .m-md-n9 {
        margin: -2.25rem !important;
    }

    .mt-md-n9,
    .my-md-n9 {
        margin-top: -2.25rem !important;
    }

    .mr-md-n9,
    .mx-md-n9 {
        margin-right: -2.25rem !important;
    }

    .mb-md-n9,
    .my-md-n9 {
        margin-bottom: -2.25rem !important;
    }

    .ml-md-n9,
    .mx-md-n9 {
        margin-left: -2.25rem !important;
    }

    .m-md-n10 {
        margin: -2.5rem !important;
    }

    .mt-md-n10,
    .my-md-n10 {
        margin-top: -2.5rem !important;
    }

    .mr-md-n10,
    .mx-md-n10 {
        margin-right: -2.5rem !important;
    }

    .mb-md-n10,
    .my-md-n10 {
        margin-bottom: -2.5rem !important;
    }

    .ml-md-n10,
    .mx-md-n10 {
        margin-left: -2.5rem !important;
    }

    .m-md-n11 {
        margin: -2.75rem !important;
    }

    .mt-md-n11,
    .my-md-n11 {
        margin-top: -2.75rem !important;
    }

    .mr-md-n11,
    .mx-md-n11 {
        margin-right: -2.75rem !important;
    }

    .mb-md-n11,
    .my-md-n11 {
        margin-bottom: -2.75rem !important;
    }

    .ml-md-n11,
    .mx-md-n11 {
        margin-left: -2.75rem !important;
    }

    .m-md-n12 {
        margin: -3rem !important;
    }

    .mt-md-n12,
    .my-md-n12 {
        margin-top: -3rem !important;
    }

    .mr-md-n12,
    .mx-md-n12 {
        margin-right: -3rem !important;
    }

    .mb-md-n12,
    .my-md-n12 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n12,
    .mx-md-n12 {
        margin-left: -3rem !important;
    }

    .m-md-n13 {
        margin: -3.25rem !important;
    }

    .mt-md-n13,
    .my-md-n13 {
        margin-top: -3.25rem !important;
    }

    .mr-md-n13,
    .mx-md-n13 {
        margin-right: -3.25rem !important;
    }

    .mb-md-n13,
    .my-md-n13 {
        margin-bottom: -3.25rem !important;
    }

    .ml-md-n13,
    .mx-md-n13 {
        margin-left: -3.25rem !important;
    }

    .m-md-n14 {
        margin: -3.5rem !important;
    }

    .mt-md-n14,
    .my-md-n14 {
        margin-top: -3.5rem !important;
    }

    .mr-md-n14,
    .mx-md-n14 {
        margin-right: -3.5rem !important;
    }

    .mb-md-n14,
    .my-md-n14 {
        margin-bottom: -3.5rem !important;
    }

    .ml-md-n14,
    .mx-md-n14 {
        margin-left: -3.5rem !important;
    }

    .m-md-n15 {
        margin: -3.75rem !important;
    }

    .mt-md-n15,
    .my-md-n15 {
        margin-top: -3.75rem !important;
    }

    .mr-md-n15,
    .mx-md-n15 {
        margin-right: -3.75rem !important;
    }

    .mb-md-n15,
    .my-md-n15 {
        margin-bottom: -3.75rem !important;
    }

    .ml-md-n15,
    .mx-md-n15 {
        margin-left: -3.75rem !important;
    }

    .m-md-n16 {
        margin: -4rem !important;
    }

    .mt-md-n16,
    .my-md-n16 {
        margin-top: -4rem !important;
    }

    .mr-md-n16,
    .mx-md-n16 {
        margin-right: -4rem !important;
    }

    .mb-md-n16,
    .my-md-n16 {
        margin-bottom: -4rem !important;
    }

    .ml-md-n16,
    .mx-md-n16 {
        margin-left: -4rem !important;
    }

    .m-md-n17 {
        margin: -4.25rem !important;
    }

    .mt-md-n17,
    .my-md-n17 {
        margin-top: -4.25rem !important;
    }

    .mr-md-n17,
    .mx-md-n17 {
        margin-right: -4.25rem !important;
    }

    .mb-md-n17,
    .my-md-n17 {
        margin-bottom: -4.25rem !important;
    }

    .ml-md-n17,
    .mx-md-n17 {
        margin-left: -4.25rem !important;
    }

    .m-md-n18 {
        margin: -4.5rem !important;
    }

    .mt-md-n18,
    .my-md-n18 {
        margin-top: -4.5rem !important;
    }

    .mr-md-n18,
    .mx-md-n18 {
        margin-right: -4.5rem !important;
    }

    .mb-md-n18,
    .my-md-n18 {
        margin-bottom: -4.5rem !important;
    }

    .ml-md-n18,
    .mx-md-n18 {
        margin-left: -4.5rem !important;
    }

    .m-md-n19 {
        margin: -4.75rem !important;
    }

    .mt-md-n19,
    .my-md-n19 {
        margin-top: -4.75rem !important;
    }

    .mr-md-n19,
    .mx-md-n19 {
        margin-right: -4.75rem !important;
    }

    .mb-md-n19,
    .my-md-n19 {
        margin-bottom: -4.75rem !important;
    }

    .ml-md-n19,
    .mx-md-n19 {
        margin-left: -4.75rem !important;
    }

    .m-md-n20 {
        margin: -5rem !important;
    }

    .mt-md-n20,
    .my-md-n20 {
        margin-top: -5rem !important;
    }

    .mr-md-n20,
    .mx-md-n20 {
        margin-right: -5rem !important;
    }

    .mb-md-n20,
    .my-md-n20 {
        margin-bottom: -5rem !important;
    }

    .ml-md-n20,
    .mx-md-n20 {
        margin-left: -5rem !important;
    }

    .m-md-n21 {
        margin: -5.25rem !important;
    }

    .mt-md-n21,
    .my-md-n21 {
        margin-top: -5.25rem !important;
    }

    .mr-md-n21,
    .mx-md-n21 {
        margin-right: -5.25rem !important;
    }

    .mb-md-n21,
    .my-md-n21 {
        margin-bottom: -5.25rem !important;
    }

    .ml-md-n21,
    .mx-md-n21 {
        margin-left: -5.25rem !important;
    }

    .m-md-n22 {
        margin: -5.5rem !important;
    }

    .mt-md-n22,
    .my-md-n22 {
        margin-top: -5.5rem !important;
    }

    .mr-md-n22,
    .mx-md-n22 {
        margin-right: -5.5rem !important;
    }

    .mb-md-n22,
    .my-md-n22 {
        margin-bottom: -5.5rem !important;
    }

    .ml-md-n22,
    .mx-md-n22 {
        margin-left: -5.5rem !important;
    }

    .m-md-n23 {
        margin: -5.75rem !important;
    }

    .mt-md-n23,
    .my-md-n23 {
        margin-top: -5.75rem !important;
    }

    .mr-md-n23,
    .mx-md-n23 {
        margin-right: -5.75rem !important;
    }

    .mb-md-n23,
    .my-md-n23 {
        margin-bottom: -5.75rem !important;
    }

    .ml-md-n23,
    .mx-md-n23 {
        margin-left: -5.75rem !important;
    }

    .m-md-n24 {
        margin: -6rem !important;
    }

    .mt-md-n24,
    .my-md-n24 {
        margin-top: -6rem !important;
    }

    .mr-md-n24,
    .mx-md-n24 {
        margin-right: -6rem !important;
    }

    .mb-md-n24,
    .my-md-n24 {
        margin-bottom: -6rem !important;
    }

    .ml-md-n24,
    .mx-md-n24 {
        margin-left: -6rem !important;
    }

    .m-md-n25 {
        margin: -6.25rem !important;
    }

    .mt-md-n25,
    .my-md-n25 {
        margin-top: -6.25rem !important;
    }

    .mr-md-n25,
    .mx-md-n25 {
        margin-right: -6.25rem !important;
    }

    .mb-md-n25,
    .my-md-n25 {
        margin-bottom: -6.25rem !important;
    }

    .ml-md-n25,
    .mx-md-n25 {
        margin-left: -6.25rem !important;
    }

    .m-md-n26 {
        margin: -6.5rem !important;
    }

    .mt-md-n26,
    .my-md-n26 {
        margin-top: -6.5rem !important;
    }

    .mr-md-n26,
    .mx-md-n26 {
        margin-right: -6.5rem !important;
    }

    .mb-md-n26,
    .my-md-n26 {
        margin-bottom: -6.5rem !important;
    }

    .ml-md-n26,
    .mx-md-n26 {
        margin-left: -6.5rem !important;
    }

    .m-md-n27 {
        margin: -6.75rem !important;
    }

    .mt-md-n27,
    .my-md-n27 {
        margin-top: -6.75rem !important;
    }

    .mr-md-n27,
    .mx-md-n27 {
        margin-right: -6.75rem !important;
    }

    .mb-md-n27,
    .my-md-n27 {
        margin-bottom: -6.75rem !important;
    }

    .ml-md-n27,
    .mx-md-n27 {
        margin-left: -6.75rem !important;
    }

    .m-md-n38 {
        margin: -7rem !important;
    }

    .mt-md-n38,
    .my-md-n38 {
        margin-top: -7rem !important;
    }

    .mr-md-n38,
    .mx-md-n38 {
        margin-right: -7rem !important;
    }

    .mb-md-n38,
    .my-md-n38 {
        margin-bottom: -7rem !important;
    }

    .ml-md-n38,
    .mx-md-n38 {
        margin-left: -7rem !important;
    }

    .m-md-n29 {
        margin: -7.25rem !important;
    }

    .mt-md-n29,
    .my-md-n29 {
        margin-top: -7.25rem !important;
    }

    .mr-md-n29,
    .mx-md-n29 {
        margin-right: -7.25rem !important;
    }

    .mb-md-n29,
    .my-md-n29 {
        margin-bottom: -7.25rem !important;
    }

    .ml-md-n29,
    .mx-md-n29 {
        margin-left: -7.25rem !important;
    }

    .m-md-n30 {
        margin: -7.5rem !important;
    }

    .mt-md-n30,
    .my-md-n30 {
        margin-top: -7.5rem !important;
    }

    .mr-md-n30,
    .mx-md-n30 {
        margin-right: -7.5rem !important;
    }

    .mb-md-n30,
    .my-md-n30 {
        margin-bottom: -7.5rem !important;
    }

    .ml-md-n30,
    .mx-md-n30 {
        margin-left: -7.5rem !important;
    }

    .m-md-n31 {
        margin: -7.75rem !important;
    }

    .mt-md-n31,
    .my-md-n31 {
        margin-top: -7.75rem !important;
    }

    .mr-md-n31,
    .mx-md-n31 {
        margin-right: -7.75rem !important;
    }

    .mb-md-n31,
    .my-md-n31 {
        margin-bottom: -7.75rem !important;
    }

    .ml-md-n31,
    .mx-md-n31 {
        margin-left: -7.75rem !important;
    }

    .m-md-n32 {
        margin: -8rem !important;
    }

    .mt-md-n32,
    .my-md-n32 {
        margin-top: -8rem !important;
    }

    .mr-md-n32,
    .mx-md-n32 {
        margin-right: -8rem !important;
    }

    .mb-md-n32,
    .my-md-n32 {
        margin-bottom: -8rem !important;
    }

    .ml-md-n32,
    .mx-md-n32 {
        margin-left: -8rem !important;
    }

    .m-md-n33 {
        margin: -8.25rem !important;
    }

    .mt-md-n33,
    .my-md-n33 {
        margin-top: -8.25rem !important;
    }

    .mr-md-n33,
    .mx-md-n33 {
        margin-right: -8.25rem !important;
    }

    .mb-md-n33,
    .my-md-n33 {
        margin-bottom: -8.25rem !important;
    }

    .ml-md-n33,
    .mx-md-n33 {
        margin-left: -8.25rem !important;
    }

    .m-md-n34 {
        margin: -8.5rem !important;
    }

    .mt-md-n34,
    .my-md-n34 {
        margin-top: -8.5rem !important;
    }

    .mr-md-n34,
    .mx-md-n34 {
        margin-right: -8.5rem !important;
    }

    .mb-md-n34,
    .my-md-n34 {
        margin-bottom: -8.5rem !important;
    }

    .ml-md-n34,
    .mx-md-n34 {
        margin-left: -8.5rem !important;
    }

    .m-md-n35 {
        margin: -8.75rem !important;
    }

    .mt-md-n35,
    .my-md-n35 {
        margin-top: -8.75rem !important;
    }

    .mr-md-n35,
    .mx-md-n35 {
        margin-right: -8.75rem !important;
    }

    .mb-md-n35,
    .my-md-n35 {
        margin-bottom: -8.75rem !important;
    }

    .ml-md-n35,
    .mx-md-n35 {
        margin-left: -8.75rem !important;
    }

    .m-md-n36 {
        margin: -9rem !important;
    }

    .mt-md-n36,
    .my-md-n36 {
        margin-top: -9rem !important;
    }

    .mr-md-n36,
    .mx-md-n36 {
        margin-right: -9rem !important;
    }

    .mb-md-n36,
    .my-md-n36 {
        margin-bottom: -9rem !important;
    }

    .ml-md-n36,
    .mx-md-n36 {
        margin-left: -9rem !important;
    }

    .m-md-n37 {
        margin: -9.25rem !important;
    }

    .mt-md-n37,
    .my-md-n37 {
        margin-top: -9.25rem !important;
    }

    .mr-md-n37,
    .mx-md-n37 {
        margin-right: -9.25rem !important;
    }

    .mb-md-n37,
    .my-md-n37 {
        margin-bottom: -9.25rem !important;
    }

    .ml-md-n37,
    .mx-md-n37 {
        margin-left: -9.25rem !important;
    }

    .m-md-n48 {
        margin: -9.5rem !important;
    }

    .mt-md-n48,
    .my-md-n48 {
        margin-top: -9.5rem !important;
    }

    .mr-md-n48,
    .mx-md-n48 {
        margin-right: -9.5rem !important;
    }

    .mb-md-n48,
    .my-md-n48 {
        margin-bottom: -9.5rem !important;
    }

    .ml-md-n48,
    .mx-md-n48 {
        margin-left: -9.5rem !important;
    }

    .m-md-n39 {
        margin: -9.75rem !important;
    }

    .mt-md-n39,
    .my-md-n39 {
        margin-top: -9.75rem !important;
    }

    .mr-md-n39,
    .mx-md-n39 {
        margin-right: -9.75rem !important;
    }

    .mb-md-n39,
    .my-md-n39 {
        margin-bottom: -9.75rem !important;
    }

    .ml-md-n39,
    .mx-md-n39 {
        margin-left: -9.75rem !important;
    }

    .m-md-n40 {
        margin: -10rem !important;
    }

    .mt-md-n40,
    .my-md-n40 {
        margin-top: -10rem !important;
    }

    .mr-md-n40,
    .mx-md-n40 {
        margin-right: -10rem !important;
    }

    .mb-md-n40,
    .my-md-n40 {
        margin-bottom: -10rem !important;
    }

    .ml-md-n40,
    .mx-md-n40 {
        margin-left: -10rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 0.75rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 0.75rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 0.75rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 0.75rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 0.75rem !important;
    }

    .m-lg-4 {
        margin: 1rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1rem !important;
    }

    .m-lg-5 {
        margin: 1.25rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 1.25rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 1.25rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 1.25rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 1.25rem !important;
    }

    .m-lg-6 {
        margin: 1.5rem !important;
    }

    .mt-lg-6,
    .my-lg-6 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-6,
    .mx-lg-6 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-6,
    .my-lg-6 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-6,
    .mx-lg-6 {
        margin-left: 1.5rem !important;
    }

    .m-lg-7 {
        margin: 1.75rem !important;
    }

    .mt-lg-7,
    .my-lg-7 {
        margin-top: 1.75rem !important;
    }

    .mr-lg-7,
    .mx-lg-7 {
        margin-right: 1.75rem !important;
    }

    .mb-lg-7,
    .my-lg-7 {
        margin-bottom: 1.75rem !important;
    }

    .ml-lg-7,
    .mx-lg-7 {
        margin-left: 1.75rem !important;
    }

    .m-lg-8 {
        margin: 2rem !important;
    }

    .mt-lg-8,
    .my-lg-8 {
        margin-top: 2rem !important;
    }

    .mr-lg-8,
    .mx-lg-8 {
        margin-right: 2rem !important;
    }

    .mb-lg-8,
    .my-lg-8 {
        margin-bottom: 2rem !important;
    }

    .ml-lg-8,
    .mx-lg-8 {
        margin-left: 2rem !important;
    }

    .m-lg-9 {
        margin: 2.25rem !important;
    }

    .mt-lg-9,
    .my-lg-9 {
        margin-top: 2.25rem !important;
    }

    .mr-lg-9,
    .mx-lg-9 {
        margin-right: 2.25rem !important;
    }

    .mb-lg-9,
    .my-lg-9 {
        margin-bottom: 2.25rem !important;
    }

    .ml-lg-9,
    .mx-lg-9 {
        margin-left: 2.25rem !important;
    }

    .m-lg-10 {
        margin: 2.5rem !important;
    }

    .mt-lg-10,
    .my-lg-10 {
        margin-top: 2.5rem !important;
    }

    .mr-lg-10,
    .mx-lg-10 {
        margin-right: 2.5rem !important;
    }

    .mb-lg-10,
    .my-lg-10 {
        margin-bottom: 2.5rem !important;
    }

    .ml-lg-10,
    .mx-lg-10 {
        margin-left: 2.5rem !important;
    }

    .m-lg-11 {
        margin: 2.75rem !important;
    }

    .mt-lg-11,
    .my-lg-11 {
        margin-top: 2.75rem !important;
    }

    .mr-lg-11,
    .mx-lg-11 {
        margin-right: 2.75rem !important;
    }

    .mb-lg-11,
    .my-lg-11 {
        margin-bottom: 2.75rem !important;
    }

    .ml-lg-11,
    .mx-lg-11 {
        margin-left: 2.75rem !important;
    }

    .m-lg-12 {
        margin: 3rem !important;
    }

    .mt-lg-12,
    .my-lg-12 {
        margin-top: 3rem !important;
    }

    .mr-lg-12,
    .mx-lg-12 {
        margin-right: 3rem !important;
    }

    .mb-lg-12,
    .my-lg-12 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-12,
    .mx-lg-12 {
        margin-left: 3rem !important;
    }

    .m-lg-13 {
        margin: 3.25rem !important;
    }

    .mt-lg-13,
    .my-lg-13 {
        margin-top: 3.25rem !important;
    }

    .mr-lg-13,
    .mx-lg-13 {
        margin-right: 3.25rem !important;
    }

    .mb-lg-13,
    .my-lg-13 {
        margin-bottom: 3.25rem !important;
    }

    .ml-lg-13,
    .mx-lg-13 {
        margin-left: 3.25rem !important;
    }

    .m-lg-14 {
        margin: 3.5rem !important;
    }

    .mt-lg-14,
    .my-lg-14 {
        margin-top: 3.5rem !important;
    }

    .mr-lg-14,
    .mx-lg-14 {
        margin-right: 3.5rem !important;
    }

    .mb-lg-14,
    .my-lg-14 {
        margin-bottom: 3.5rem !important;
    }

    .ml-lg-14,
    .mx-lg-14 {
        margin-left: 3.5rem !important;
    }

    .m-lg-15 {
        margin: 3.75rem !important;
    }

    .mt-lg-15,
    .my-lg-15 {
        margin-top: 3.75rem !important;
    }

    .mr-lg-15,
    .mx-lg-15 {
        margin-right: 3.75rem !important;
    }

    .mb-lg-15,
    .my-lg-15 {
        margin-bottom: 3.75rem !important;
    }

    .ml-lg-15,
    .mx-lg-15 {
        margin-left: 3.75rem !important;
    }

    .m-lg-16 {
        margin: 4rem !important;
    }

    .mt-lg-16,
    .my-lg-16 {
        margin-top: 4rem !important;
    }

    .mr-lg-16,
    .mx-lg-16 {
        margin-right: 4rem !important;
    }

    .mb-lg-16,
    .my-lg-16 {
        margin-bottom: 4rem !important;
    }

    .ml-lg-16,
    .mx-lg-16 {
        margin-left: 4rem !important;
    }

    .m-lg-17 {
        margin: 4.25rem !important;
    }

    .mt-lg-17,
    .my-lg-17 {
        margin-top: 4.25rem !important;
    }

    .mr-lg-17,
    .mx-lg-17 {
        margin-right: 4.25rem !important;
    }

    .mb-lg-17,
    .my-lg-17 {
        margin-bottom: 4.25rem !important;
    }

    .ml-lg-17,
    .mx-lg-17 {
        margin-left: 4.25rem !important;
    }

    .m-lg-18 {
        margin: 4.5rem !important;
    }

    .mt-lg-18,
    .my-lg-18 {
        margin-top: 4.5rem !important;
    }

    .mr-lg-18,
    .mx-lg-18 {
        margin-right: 4.5rem !important;
    }

    .mb-lg-18,
    .my-lg-18 {
        margin-bottom: 4.5rem !important;
    }

    .ml-lg-18,
    .mx-lg-18 {
        margin-left: 4.5rem !important;
    }

    .m-lg-19 {
        margin: 4.75rem !important;
    }

    .mt-lg-19,
    .my-lg-19 {
        margin-top: 4.75rem !important;
    }

    .mr-lg-19,
    .mx-lg-19 {
        margin-right: 4.75rem !important;
    }

    .mb-lg-19,
    .my-lg-19 {
        margin-bottom: 4.75rem !important;
    }

    .ml-lg-19,
    .mx-lg-19 {
        margin-left: 4.75rem !important;
    }

    .m-lg-20 {
        margin: 5rem !important;
    }

    .mt-lg-20,
    .my-lg-20 {
        margin-top: 5rem !important;
    }

    .mr-lg-20,
    .mx-lg-20 {
        margin-right: 5rem !important;
    }

    .mb-lg-20,
    .my-lg-20 {
        margin-bottom: 5rem !important;
    }

    .ml-lg-20,
    .mx-lg-20 {
        margin-left: 5rem !important;
    }

    .m-lg-21 {
        margin: 5.25rem !important;
    }

    .mt-lg-21,
    .my-lg-21 {
        margin-top: 5.25rem !important;
    }

    .mr-lg-21,
    .mx-lg-21 {
        margin-right: 5.25rem !important;
    }

    .mb-lg-21,
    .my-lg-21 {
        margin-bottom: 5.25rem !important;
    }

    .ml-lg-21,
    .mx-lg-21 {
        margin-left: 5.25rem !important;
    }

    .m-lg-22 {
        margin: 5.5rem !important;
    }

    .mt-lg-22,
    .my-lg-22 {
        margin-top: 5.5rem !important;
    }

    .mr-lg-22,
    .mx-lg-22 {
        margin-right: 5.5rem !important;
    }

    .mb-lg-22,
    .my-lg-22 {
        margin-bottom: 5.5rem !important;
    }

    .ml-lg-22,
    .mx-lg-22 {
        margin-left: 5.5rem !important;
    }

    .m-lg-23 {
        margin: 5.75rem !important;
    }

    .mt-lg-23,
    .my-lg-23 {
        margin-top: 5.75rem !important;
    }

    .mr-lg-23,
    .mx-lg-23 {
        margin-right: 5.75rem !important;
    }

    .mb-lg-23,
    .my-lg-23 {
        margin-bottom: 5.75rem !important;
    }

    .ml-lg-23,
    .mx-lg-23 {
        margin-left: 5.75rem !important;
    }

    .m-lg-24 {
        margin: 6rem !important;
    }

    .mt-lg-24,
    .my-lg-24 {
        margin-top: 6rem !important;
    }

    .mr-lg-24,
    .mx-lg-24 {
        margin-right: 6rem !important;
    }

    .mb-lg-24,
    .my-lg-24 {
        margin-bottom: 6rem !important;
    }

    .ml-lg-24,
    .mx-lg-24 {
        margin-left: 6rem !important;
    }

    .m-lg-25 {
        margin: 6.25rem !important;
    }

    .mt-lg-25,
    .my-lg-25 {
        margin-top: 6.25rem !important;
    }

    .mr-lg-25,
    .mx-lg-25 {
        margin-right: 6.25rem !important;
    }

    .mb-lg-25,
    .my-lg-25 {
        margin-bottom: 6.25rem !important;
    }

    .ml-lg-25,
    .mx-lg-25 {
        margin-left: 6.25rem !important;
    }

    .m-lg-26 {
        margin: 6.5rem !important;
    }

    .mt-lg-26,
    .my-lg-26 {
        margin-top: 6.5rem !important;
    }

    .mr-lg-26,
    .mx-lg-26 {
        margin-right: 6.5rem !important;
    }

    .mb-lg-26,
    .my-lg-26 {
        margin-bottom: 6.5rem !important;
    }

    .ml-lg-26,
    .mx-lg-26 {
        margin-left: 6.5rem !important;
    }

    .m-lg-27 {
        margin: 6.75rem !important;
    }

    .mt-lg-27,
    .my-lg-27 {
        margin-top: 6.75rem !important;
    }

    .mr-lg-27,
    .mx-lg-27 {
        margin-right: 6.75rem !important;
    }

    .mb-lg-27,
    .my-lg-27 {
        margin-bottom: 6.75rem !important;
    }

    .ml-lg-27,
    .mx-lg-27 {
        margin-left: 6.75rem !important;
    }

    .m-lg-38 {
        margin: 7rem !important;
    }

    .mt-lg-38,
    .my-lg-38 {
        margin-top: 7rem !important;
    }

    .mr-lg-38,
    .mx-lg-38 {
        margin-right: 7rem !important;
    }

    .mb-lg-38,
    .my-lg-38 {
        margin-bottom: 7rem !important;
    }

    .ml-lg-38,
    .mx-lg-38 {
        margin-left: 7rem !important;
    }

    .m-lg-29 {
        margin: 7.25rem !important;
    }

    .mt-lg-29,
    .my-lg-29 {
        margin-top: 7.25rem !important;
    }

    .mr-lg-29,
    .mx-lg-29 {
        margin-right: 7.25rem !important;
    }

    .mb-lg-29,
    .my-lg-29 {
        margin-bottom: 7.25rem !important;
    }

    .ml-lg-29,
    .mx-lg-29 {
        margin-left: 7.25rem !important;
    }

    .m-lg-30 {
        margin: 7.5rem !important;
    }

    .mt-lg-30,
    .my-lg-30 {
        margin-top: 7.5rem !important;
    }

    .mr-lg-30,
    .mx-lg-30 {
        margin-right: 7.5rem !important;
    }

    .mb-lg-30,
    .my-lg-30 {
        margin-bottom: 7.5rem !important;
    }

    .ml-lg-30,
    .mx-lg-30 {
        margin-left: 7.5rem !important;
    }

    .m-lg-31 {
        margin: 7.75rem !important;
    }

    .mt-lg-31,
    .my-lg-31 {
        margin-top: 7.75rem !important;
    }

    .mr-lg-31,
    .mx-lg-31 {
        margin-right: 7.75rem !important;
    }

    .mb-lg-31,
    .my-lg-31 {
        margin-bottom: 7.75rem !important;
    }

    .ml-lg-31,
    .mx-lg-31 {
        margin-left: 7.75rem !important;
    }

    .m-lg-32 {
        margin: 8rem !important;
    }

    .mt-lg-32,
    .my-lg-32 {
        margin-top: 8rem !important;
    }

    .mr-lg-32,
    .mx-lg-32 {
        margin-right: 8rem !important;
    }

    .mb-lg-32,
    .my-lg-32 {
        margin-bottom: 8rem !important;
    }

    .ml-lg-32,
    .mx-lg-32 {
        margin-left: 8rem !important;
    }

    .m-lg-33 {
        margin: 8.25rem !important;
    }

    .mt-lg-33,
    .my-lg-33 {
        margin-top: 8.25rem !important;
    }

    .mr-lg-33,
    .mx-lg-33 {
        margin-right: 8.25rem !important;
    }

    .mb-lg-33,
    .my-lg-33 {
        margin-bottom: 8.25rem !important;
    }

    .ml-lg-33,
    .mx-lg-33 {
        margin-left: 8.25rem !important;
    }

    .m-lg-34 {
        margin: 8.5rem !important;
    }

    .mt-lg-34,
    .my-lg-34 {
        margin-top: 8.5rem !important;
    }

    .mr-lg-34,
    .mx-lg-34 {
        margin-right: 8.5rem !important;
    }

    .mb-lg-34,
    .my-lg-34 {
        margin-bottom: 8.5rem !important;
    }

    .ml-lg-34,
    .mx-lg-34 {
        margin-left: 8.5rem !important;
    }

    .m-lg-35 {
        margin: 8.75rem !important;
    }

    .mt-lg-35,
    .my-lg-35 {
        margin-top: 8.75rem !important;
    }

    .mr-lg-35,
    .mx-lg-35 {
        margin-right: 8.75rem !important;
    }

    .mb-lg-35,
    .my-lg-35 {
        margin-bottom: 8.75rem !important;
    }

    .ml-lg-35,
    .mx-lg-35 {
        margin-left: 8.75rem !important;
    }

    .m-lg-36 {
        margin: 9rem !important;
    }

    .mt-lg-36,
    .my-lg-36 {
        margin-top: 9rem !important;
    }

    .mr-lg-36,
    .mx-lg-36 {
        margin-right: 9rem !important;
    }

    .mb-lg-36,
    .my-lg-36 {
        margin-bottom: 9rem !important;
    }

    .ml-lg-36,
    .mx-lg-36 {
        margin-left: 9rem !important;
    }

    .m-lg-37 {
        margin: 9.25rem !important;
    }

    .mt-lg-37,
    .my-lg-37 {
        margin-top: 9.25rem !important;
    }

    .mr-lg-37,
    .mx-lg-37 {
        margin-right: 9.25rem !important;
    }

    .mb-lg-37,
    .my-lg-37 {
        margin-bottom: 9.25rem !important;
    }

    .ml-lg-37,
    .mx-lg-37 {
        margin-left: 9.25rem !important;
    }

    .m-lg-48 {
        margin: 9.5rem !important;
    }

    .mt-lg-48,
    .my-lg-48 {
        margin-top: 9.5rem !important;
    }

    .mr-lg-48,
    .mx-lg-48 {
        margin-right: 9.5rem !important;
    }

    .mb-lg-48,
    .my-lg-48 {
        margin-bottom: 9.5rem !important;
    }

    .ml-lg-48,
    .mx-lg-48 {
        margin-left: 9.5rem !important;
    }

    .m-lg-39 {
        margin: 9.75rem !important;
    }

    .mt-lg-39,
    .my-lg-39 {
        margin-top: 9.75rem !important;
    }

    .mr-lg-39,
    .mx-lg-39 {
        margin-right: 9.75rem !important;
    }

    .mb-lg-39,
    .my-lg-39 {
        margin-bottom: 9.75rem !important;
    }

    .ml-lg-39,
    .mx-lg-39 {
        margin-left: 9.75rem !important;
    }

    .m-lg-40 {
        margin: 10rem !important;
    }

    .mt-lg-40,
    .my-lg-40 {
        margin-top: 10rem !important;
    }

    .mr-lg-40,
    .mx-lg-40 {
        margin-right: 10rem !important;
    }

    .mb-lg-40,
    .my-lg-40 {
        margin-bottom: 10rem !important;
    }

    .ml-lg-40,
    .mx-lg-40 {
        margin-left: 10rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 0.75rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 0.75rem !important;
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 0.75rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 0.75rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 0.75rem !important;
    }

    .p-lg-4 {
        padding: 1rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1rem !important;
    }

    .p-lg-5 {
        padding: 1.25rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 1.25rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 1.25rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 1.25rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 1.25rem !important;
    }

    .p-lg-6 {
        padding: 1.5rem !important;
    }

    .pt-lg-6,
    .py-lg-6 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-6,
    .px-lg-6 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-6,
    .py-lg-6 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-6,
    .px-lg-6 {
        padding-left: 1.5rem !important;
    }

    .p-lg-7 {
        padding: 1.75rem !important;
    }

    .pt-lg-7,
    .py-lg-7 {
        padding-top: 1.75rem !important;
    }

    .pr-lg-7,
    .px-lg-7 {
        padding-right: 1.75rem !important;
    }

    .pb-lg-7,
    .py-lg-7 {
        padding-bottom: 1.75rem !important;
    }

    .pl-lg-7,
    .px-lg-7 {
        padding-left: 1.75rem !important;
    }

    .p-lg-8 {
        padding: 2rem !important;
    }

    .pt-lg-8,
    .py-lg-8 {
        padding-top: 2rem !important;
    }

    .pr-lg-8,
    .px-lg-8 {
        padding-right: 2rem !important;
    }

    .pb-lg-8,
    .py-lg-8 {
        padding-bottom: 2rem !important;
    }

    .pl-lg-8,
    .px-lg-8 {
        padding-left: 2rem !important;
    }

    .p-lg-9 {
        padding: 2.25rem !important;
    }

    .pt-lg-9,
    .py-lg-9 {
        padding-top: 2.25rem !important;
    }

    .pr-lg-9,
    .px-lg-9 {
        padding-right: 2.25rem !important;
    }

    .pb-lg-9,
    .py-lg-9 {
        padding-bottom: 2.25rem !important;
    }

    .pl-lg-9,
    .px-lg-9 {
        padding-left: 2.25rem !important;
    }

    .p-lg-10 {
        padding: 2.5rem !important;
    }

    .pt-lg-10,
    .py-lg-10 {
        padding-top: 2.5rem !important;
    }

    .pr-lg-10,
    .px-lg-10 {
        padding-right: 2.5rem !important;
    }

    .pb-lg-10,
    .py-lg-10 {
        padding-bottom: 2.5rem !important;
    }

    .pl-lg-10,
    .px-lg-10 {
        padding-left: 2.5rem !important;
    }

    .p-lg-11 {
        padding: 2.75rem !important;
    }

    .pt-lg-11,
    .py-lg-11 {
        padding-top: 2.75rem !important;
    }

    .pr-lg-11,
    .px-lg-11 {
        padding-right: 2.75rem !important;
    }

    .pb-lg-11,
    .py-lg-11 {
        padding-bottom: 2.75rem !important;
    }

    .pl-lg-11,
    .px-lg-11 {
        padding-left: 2.75rem !important;
    }

    .p-lg-12 {
        padding: 3rem !important;
    }

    .pt-lg-12,
    .py-lg-12 {
        padding-top: 3rem !important;
    }

    .pr-lg-12,
    .px-lg-12 {
        padding-right: 3rem !important;
    }

    .pb-lg-12,
    .py-lg-12 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-12,
    .px-lg-12 {
        padding-left: 3rem !important;
    }

    .p-lg-13 {
        padding: 3.25rem !important;
    }

    .pt-lg-13,
    .py-lg-13 {
        padding-top: 3.25rem !important;
    }

    .pr-lg-13,
    .px-lg-13 {
        padding-right: 3.25rem !important;
    }

    .pb-lg-13,
    .py-lg-13 {
        padding-bottom: 3.25rem !important;
    }

    .pl-lg-13,
    .px-lg-13 {
        padding-left: 3.25rem !important;
    }

    .p-lg-14 {
        padding: 3.5rem !important;
    }

    .pt-lg-14,
    .py-lg-14 {
        padding-top: 3.5rem !important;
    }

    .pr-lg-14,
    .px-lg-14 {
        padding-right: 3.5rem !important;
    }

    .pb-lg-14,
    .py-lg-14 {
        padding-bottom: 3.5rem !important;
    }

    .pl-lg-14,
    .px-lg-14 {
        padding-left: 3.5rem !important;
    }

    .p-lg-15 {
        padding: 3.75rem !important;
    }

    .pt-lg-15,
    .py-lg-15 {
        padding-top: 3.75rem !important;
    }

    .pr-lg-15,
    .px-lg-15 {
        padding-right: 3.75rem !important;
    }

    .pb-lg-15,
    .py-lg-15 {
        padding-bottom: 3.75rem !important;
    }

    .pl-lg-15,
    .px-lg-15 {
        padding-left: 3.75rem !important;
    }

    .p-lg-16 {
        padding: 4rem !important;
    }

    .pt-lg-16,
    .py-lg-16 {
        padding-top: 4rem !important;
    }

    .pr-lg-16,
    .px-lg-16 {
        padding-right: 4rem !important;
    }

    .pb-lg-16,
    .py-lg-16 {
        padding-bottom: 4rem !important;
    }

    .pl-lg-16,
    .px-lg-16 {
        padding-left: 4rem !important;
    }

    .p-lg-17 {
        padding: 4.25rem !important;
    }

    .pt-lg-17,
    .py-lg-17 {
        padding-top: 4.25rem !important;
    }

    .pr-lg-17,
    .px-lg-17 {
        padding-right: 4.25rem !important;
    }

    .pb-lg-17,
    .py-lg-17 {
        padding-bottom: 4.25rem !important;
    }

    .pl-lg-17,
    .px-lg-17 {
        padding-left: 4.25rem !important;
    }

    .p-lg-18 {
        padding: 4.5rem !important;
    }

    .pt-lg-18,
    .py-lg-18 {
        padding-top: 4.5rem !important;
    }

    .pr-lg-18,
    .px-lg-18 {
        padding-right: 4.5rem !important;
    }

    .pb-lg-18,
    .py-lg-18 {
        padding-bottom: 4.5rem !important;
    }

    .pl-lg-18,
    .px-lg-18 {
        padding-left: 4.5rem !important;
    }

    .p-lg-19 {
        padding: 4.75rem !important;
    }

    .pt-lg-19,
    .py-lg-19 {
        padding-top: 4.75rem !important;
    }

    .pr-lg-19,
    .px-lg-19 {
        padding-right: 4.75rem !important;
    }

    .pb-lg-19,
    .py-lg-19 {
        padding-bottom: 4.75rem !important;
    }

    .pl-lg-19,
    .px-lg-19 {
        padding-left: 4.75rem !important;
    }

    .p-lg-20 {
        padding: 5rem !important;
    }

    .pt-lg-20,
    .py-lg-20 {
        padding-top: 5rem !important;
    }

    .pr-lg-20,
    .px-lg-20 {
        padding-right: 5rem !important;
    }

    .pb-lg-20,
    .py-lg-20 {
        padding-bottom: 5rem !important;
    }

    .pl-lg-20,
    .px-lg-20 {
        padding-left: 5rem !important;
    }

    .p-lg-21 {
        padding: 5.25rem !important;
    }

    .pt-lg-21,
    .py-lg-21 {
        padding-top: 5.25rem !important;
    }

    .pr-lg-21,
    .px-lg-21 {
        padding-right: 5.25rem !important;
    }

    .pb-lg-21,
    .py-lg-21 {
        padding-bottom: 5.25rem !important;
    }

    .pl-lg-21,
    .px-lg-21 {
        padding-left: 5.25rem !important;
    }

    .p-lg-22 {
        padding: 5.5rem !important;
    }

    .pt-lg-22,
    .py-lg-22 {
        padding-top: 5.5rem !important;
    }

    .pr-lg-22,
    .px-lg-22 {
        padding-right: 5.5rem !important;
    }

    .pb-lg-22,
    .py-lg-22 {
        padding-bottom: 5.5rem !important;
    }

    .pl-lg-22,
    .px-lg-22 {
        padding-left: 5.5rem !important;
    }

    .p-lg-23 {
        padding: 5.75rem !important;
    }

    .pt-lg-23,
    .py-lg-23 {
        padding-top: 5.75rem !important;
    }

    .pr-lg-23,
    .px-lg-23 {
        padding-right: 5.75rem !important;
    }

    .pb-lg-23,
    .py-lg-23 {
        padding-bottom: 5.75rem !important;
    }

    .pl-lg-23,
    .px-lg-23 {
        padding-left: 5.75rem !important;
    }

    .p-lg-24 {
        padding: 6rem !important;
    }

    .pt-lg-24,
    .py-lg-24 {
        padding-top: 6rem !important;
    }

    .pr-lg-24,
    .px-lg-24 {
        padding-right: 6rem !important;
    }

    .pb-lg-24,
    .py-lg-24 {
        padding-bottom: 6rem !important;
    }

    .pl-lg-24,
    .px-lg-24 {
        padding-left: 6rem !important;
    }

    .p-lg-25 {
        padding: 6.25rem !important;
    }

    .pt-lg-25,
    .py-lg-25 {
        padding-top: 6.25rem !important;
    }

    .pr-lg-25,
    .px-lg-25 {
        padding-right: 6.25rem !important;
    }

    .pb-lg-25,
    .py-lg-25 {
        padding-bottom: 6.25rem !important;
    }

    .pl-lg-25,
    .px-lg-25 {
        padding-left: 6.25rem !important;
    }

    .p-lg-26 {
        padding: 6.5rem !important;
    }

    .pt-lg-26,
    .py-lg-26 {
        padding-top: 6.5rem !important;
    }

    .pr-lg-26,
    .px-lg-26 {
        padding-right: 6.5rem !important;
    }

    .pb-lg-26,
    .py-lg-26 {
        padding-bottom: 6.5rem !important;
    }

    .pl-lg-26,
    .px-lg-26 {
        padding-left: 6.5rem !important;
    }

    .p-lg-27 {
        padding: 6.75rem !important;
    }

    .pt-lg-27,
    .py-lg-27 {
        padding-top: 6.75rem !important;
    }

    .pr-lg-27,
    .px-lg-27 {
        padding-right: 6.75rem !important;
    }

    .pb-lg-27,
    .py-lg-27 {
        padding-bottom: 6.75rem !important;
    }

    .pl-lg-27,
    .px-lg-27 {
        padding-left: 6.75rem !important;
    }

    .p-lg-38 {
        padding: 7rem !important;
    }

    .pt-lg-38,
    .py-lg-38 {
        padding-top: 7rem !important;
    }

    .pr-lg-38,
    .px-lg-38 {
        padding-right: 7rem !important;
    }

    .pb-lg-38,
    .py-lg-38 {
        padding-bottom: 7rem !important;
    }

    .pl-lg-38,
    .px-lg-38 {
        padding-left: 7rem !important;
    }

    .p-lg-29 {
        padding: 7.25rem !important;
    }

    .pt-lg-29,
    .py-lg-29 {
        padding-top: 7.25rem !important;
    }

    .pr-lg-29,
    .px-lg-29 {
        padding-right: 7.25rem !important;
    }

    .pb-lg-29,
    .py-lg-29 {
        padding-bottom: 7.25rem !important;
    }

    .pl-lg-29,
    .px-lg-29 {
        padding-left: 7.25rem !important;
    }

    .p-lg-30 {
        padding: 7.5rem !important;
    }

    .pt-lg-30,
    .py-lg-30 {
        padding-top: 7.5rem !important;
    }

    .pr-lg-30,
    .px-lg-30 {
        padding-right: 7.5rem !important;
    }

    .pb-lg-30,
    .py-lg-30 {
        padding-bottom: 7.5rem !important;
    }

    .pl-lg-30,
    .px-lg-30 {
        padding-left: 7.5rem !important;
    }

    .p-lg-31 {
        padding: 7.75rem !important;
    }

    .pt-lg-31,
    .py-lg-31 {
        padding-top: 7.75rem !important;
    }

    .pr-lg-31,
    .px-lg-31 {
        padding-right: 7.75rem !important;
    }

    .pb-lg-31,
    .py-lg-31 {
        padding-bottom: 7.75rem !important;
    }

    .pl-lg-31,
    .px-lg-31 {
        padding-left: 7.75rem !important;
    }

    .p-lg-32 {
        padding: 8rem !important;
    }

    .pt-lg-32,
    .py-lg-32 {
        padding-top: 8rem !important;
    }

    .pr-lg-32,
    .px-lg-32 {
        padding-right: 8rem !important;
    }

    .pb-lg-32,
    .py-lg-32 {
        padding-bottom: 8rem !important;
    }

    .pl-lg-32,
    .px-lg-32 {
        padding-left: 8rem !important;
    }

    .p-lg-33 {
        padding: 8.25rem !important;
    }

    .pt-lg-33,
    .py-lg-33 {
        padding-top: 8.25rem !important;
    }

    .pr-lg-33,
    .px-lg-33 {
        padding-right: 8.25rem !important;
    }

    .pb-lg-33,
    .py-lg-33 {
        padding-bottom: 8.25rem !important;
    }

    .pl-lg-33,
    .px-lg-33 {
        padding-left: 8.25rem !important;
    }

    .p-lg-34 {
        padding: 8.5rem !important;
    }

    .pt-lg-34,
    .py-lg-34 {
        padding-top: 8.5rem !important;
    }

    .pr-lg-34,
    .px-lg-34 {
        padding-right: 8.5rem !important;
    }

    .pb-lg-34,
    .py-lg-34 {
        padding-bottom: 8.5rem !important;
    }

    .pl-lg-34,
    .px-lg-34 {
        padding-left: 8.5rem !important;
    }

    .p-lg-35 {
        padding: 8.75rem !important;
    }

    .pt-lg-35,
    .py-lg-35 {
        padding-top: 8.75rem !important;
    }

    .pr-lg-35,
    .px-lg-35 {
        padding-right: 8.75rem !important;
    }

    .pb-lg-35,
    .py-lg-35 {
        padding-bottom: 8.75rem !important;
    }

    .pl-lg-35,
    .px-lg-35 {
        padding-left: 8.75rem !important;
    }

    .p-lg-36 {
        padding: 9rem !important;
    }

    .pt-lg-36,
    .py-lg-36 {
        padding-top: 9rem !important;
    }

    .pr-lg-36,
    .px-lg-36 {
        padding-right: 9rem !important;
    }

    .pb-lg-36,
    .py-lg-36 {
        padding-bottom: 9rem !important;
    }

    .pl-lg-36,
    .px-lg-36 {
        padding-left: 9rem !important;
    }

    .p-lg-37 {
        padding: 9.25rem !important;
    }

    .pt-lg-37,
    .py-lg-37 {
        padding-top: 9.25rem !important;
    }

    .pr-lg-37,
    .px-lg-37 {
        padding-right: 9.25rem !important;
    }

    .pb-lg-37,
    .py-lg-37 {
        padding-bottom: 9.25rem !important;
    }

    .pl-lg-37,
    .px-lg-37 {
        padding-left: 9.25rem !important;
    }

    .p-lg-48 {
        padding: 9.5rem !important;
    }

    .pt-lg-48,
    .py-lg-48 {
        padding-top: 9.5rem !important;
    }

    .pr-lg-48,
    .px-lg-48 {
        padding-right: 9.5rem !important;
    }

    .pb-lg-48,
    .py-lg-48 {
        padding-bottom: 9.5rem !important;
    }

    .pl-lg-48,
    .px-lg-48 {
        padding-left: 9.5rem !important;
    }

    .p-lg-39 {
        padding: 9.75rem !important;
    }

    .pt-lg-39,
    .py-lg-39 {
        padding-top: 9.75rem !important;
    }

    .pr-lg-39,
    .px-lg-39 {
        padding-right: 9.75rem !important;
    }

    .pb-lg-39,
    .py-lg-39 {
        padding-bottom: 9.75rem !important;
    }

    .pl-lg-39,
    .px-lg-39 {
        padding-left: 9.75rem !important;
    }

    .p-lg-40 {
        padding: 10rem !important;
    }

    .pt-lg-40,
    .py-lg-40 {
        padding-top: 10rem !important;
    }

    .pr-lg-40,
    .px-lg-40 {
        padding-right: 10rem !important;
    }

    .pb-lg-40,
    .py-lg-40 {
        padding-bottom: 10rem !important;
    }

    .pl-lg-40,
    .px-lg-40 {
        padding-left: 10rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -0.75rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -0.75rem !important;
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -0.75rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -0.75rem !important;
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -0.75rem !important;
    }

    .m-lg-n4 {
        margin: -1rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1rem !important;
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1rem !important;
    }

    .m-lg-n5 {
        margin: -1.25rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -1.25rem !important;
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -1.25rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -1.25rem !important;
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -1.25rem !important;
    }

    .m-lg-n6 {
        margin: -1.5rem !important;
    }

    .mt-lg-n6,
    .my-lg-n6 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n6,
    .mx-lg-n6 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n6,
    .my-lg-n6 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n6,
    .mx-lg-n6 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n7 {
        margin: -1.75rem !important;
    }

    .mt-lg-n7,
    .my-lg-n7 {
        margin-top: -1.75rem !important;
    }

    .mr-lg-n7,
    .mx-lg-n7 {
        margin-right: -1.75rem !important;
    }

    .mb-lg-n7,
    .my-lg-n7 {
        margin-bottom: -1.75rem !important;
    }

    .ml-lg-n7,
    .mx-lg-n7 {
        margin-left: -1.75rem !important;
    }

    .m-lg-n8 {
        margin: -2rem !important;
    }

    .mt-lg-n8,
    .my-lg-n8 {
        margin-top: -2rem !important;
    }

    .mr-lg-n8,
    .mx-lg-n8 {
        margin-right: -2rem !important;
    }

    .mb-lg-n8,
    .my-lg-n8 {
        margin-bottom: -2rem !important;
    }

    .ml-lg-n8,
    .mx-lg-n8 {
        margin-left: -2rem !important;
    }

    .m-lg-n9 {
        margin: -2.25rem !important;
    }

    .mt-lg-n9,
    .my-lg-n9 {
        margin-top: -2.25rem !important;
    }

    .mr-lg-n9,
    .mx-lg-n9 {
        margin-right: -2.25rem !important;
    }

    .mb-lg-n9,
    .my-lg-n9 {
        margin-bottom: -2.25rem !important;
    }

    .ml-lg-n9,
    .mx-lg-n9 {
        margin-left: -2.25rem !important;
    }

    .m-lg-n10 {
        margin: -2.5rem !important;
    }

    .mt-lg-n10,
    .my-lg-n10 {
        margin-top: -2.5rem !important;
    }

    .mr-lg-n10,
    .mx-lg-n10 {
        margin-right: -2.5rem !important;
    }

    .mb-lg-n10,
    .my-lg-n10 {
        margin-bottom: -2.5rem !important;
    }

    .ml-lg-n10,
    .mx-lg-n10 {
        margin-left: -2.5rem !important;
    }

    .m-lg-n11 {
        margin: -2.75rem !important;
    }

    .mt-lg-n11,
    .my-lg-n11 {
        margin-top: -2.75rem !important;
    }

    .mr-lg-n11,
    .mx-lg-n11 {
        margin-right: -2.75rem !important;
    }

    .mb-lg-n11,
    .my-lg-n11 {
        margin-bottom: -2.75rem !important;
    }

    .ml-lg-n11,
    .mx-lg-n11 {
        margin-left: -2.75rem !important;
    }

    .m-lg-n12 {
        margin: -3rem !important;
    }

    .mt-lg-n12,
    .my-lg-n12 {
        margin-top: -3rem !important;
    }

    .mr-lg-n12,
    .mx-lg-n12 {
        margin-right: -3rem !important;
    }

    .mb-lg-n12,
    .my-lg-n12 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n12,
    .mx-lg-n12 {
        margin-left: -3rem !important;
    }

    .m-lg-n13 {
        margin: -3.25rem !important;
    }

    .mt-lg-n13,
    .my-lg-n13 {
        margin-top: -3.25rem !important;
    }

    .mr-lg-n13,
    .mx-lg-n13 {
        margin-right: -3.25rem !important;
    }

    .mb-lg-n13,
    .my-lg-n13 {
        margin-bottom: -3.25rem !important;
    }

    .ml-lg-n13,
    .mx-lg-n13 {
        margin-left: -3.25rem !important;
    }

    .m-lg-n14 {
        margin: -3.5rem !important;
    }

    .mt-lg-n14,
    .my-lg-n14 {
        margin-top: -3.5rem !important;
    }

    .mr-lg-n14,
    .mx-lg-n14 {
        margin-right: -3.5rem !important;
    }

    .mb-lg-n14,
    .my-lg-n14 {
        margin-bottom: -3.5rem !important;
    }

    .ml-lg-n14,
    .mx-lg-n14 {
        margin-left: -3.5rem !important;
    }

    .m-lg-n15 {
        margin: -3.75rem !important;
    }

    .mt-lg-n15,
    .my-lg-n15 {
        margin-top: -3.75rem !important;
    }

    .mr-lg-n15,
    .mx-lg-n15 {
        margin-right: -3.75rem !important;
    }

    .mb-lg-n15,
    .my-lg-n15 {
        margin-bottom: -3.75rem !important;
    }

    .ml-lg-n15,
    .mx-lg-n15 {
        margin-left: -3.75rem !important;
    }

    .m-lg-n16 {
        margin: -4rem !important;
    }

    .mt-lg-n16,
    .my-lg-n16 {
        margin-top: -4rem !important;
    }

    .mr-lg-n16,
    .mx-lg-n16 {
        margin-right: -4rem !important;
    }

    .mb-lg-n16,
    .my-lg-n16 {
        margin-bottom: -4rem !important;
    }

    .ml-lg-n16,
    .mx-lg-n16 {
        margin-left: -4rem !important;
    }

    .m-lg-n17 {
        margin: -4.25rem !important;
    }

    .mt-lg-n17,
    .my-lg-n17 {
        margin-top: -4.25rem !important;
    }

    .mr-lg-n17,
    .mx-lg-n17 {
        margin-right: -4.25rem !important;
    }

    .mb-lg-n17,
    .my-lg-n17 {
        margin-bottom: -4.25rem !important;
    }

    .ml-lg-n17,
    .mx-lg-n17 {
        margin-left: -4.25rem !important;
    }

    .m-lg-n18 {
        margin: -4.5rem !important;
    }

    .mt-lg-n18,
    .my-lg-n18 {
        margin-top: -4.5rem !important;
    }

    .mr-lg-n18,
    .mx-lg-n18 {
        margin-right: -4.5rem !important;
    }

    .mb-lg-n18,
    .my-lg-n18 {
        margin-bottom: -4.5rem !important;
    }

    .ml-lg-n18,
    .mx-lg-n18 {
        margin-left: -4.5rem !important;
    }

    .m-lg-n19 {
        margin: -4.75rem !important;
    }

    .mt-lg-n19,
    .my-lg-n19 {
        margin-top: -4.75rem !important;
    }

    .mr-lg-n19,
    .mx-lg-n19 {
        margin-right: -4.75rem !important;
    }

    .mb-lg-n19,
    .my-lg-n19 {
        margin-bottom: -4.75rem !important;
    }

    .ml-lg-n19,
    .mx-lg-n19 {
        margin-left: -4.75rem !important;
    }

    .m-lg-n20 {
        margin: -5rem !important;
    }

    .mt-lg-n20,
    .my-lg-n20 {
        margin-top: -5rem !important;
    }

    .mr-lg-n20,
    .mx-lg-n20 {
        margin-right: -5rem !important;
    }

    .mb-lg-n20,
    .my-lg-n20 {
        margin-bottom: -5rem !important;
    }

    .ml-lg-n20,
    .mx-lg-n20 {
        margin-left: -5rem !important;
    }

    .m-lg-n21 {
        margin: -5.25rem !important;
    }

    .mt-lg-n21,
    .my-lg-n21 {
        margin-top: -5.25rem !important;
    }

    .mr-lg-n21,
    .mx-lg-n21 {
        margin-right: -5.25rem !important;
    }

    .mb-lg-n21,
    .my-lg-n21 {
        margin-bottom: -5.25rem !important;
    }

    .ml-lg-n21,
    .mx-lg-n21 {
        margin-left: -5.25rem !important;
    }

    .m-lg-n22 {
        margin: -5.5rem !important;
    }

    .mt-lg-n22,
    .my-lg-n22 {
        margin-top: -5.5rem !important;
    }

    .mr-lg-n22,
    .mx-lg-n22 {
        margin-right: -5.5rem !important;
    }

    .mb-lg-n22,
    .my-lg-n22 {
        margin-bottom: -5.5rem !important;
    }

    .ml-lg-n22,
    .mx-lg-n22 {
        margin-left: -5.5rem !important;
    }

    .m-lg-n23 {
        margin: -5.75rem !important;
    }

    .mt-lg-n23,
    .my-lg-n23 {
        margin-top: -5.75rem !important;
    }

    .mr-lg-n23,
    .mx-lg-n23 {
        margin-right: -5.75rem !important;
    }

    .mb-lg-n23,
    .my-lg-n23 {
        margin-bottom: -5.75rem !important;
    }

    .ml-lg-n23,
    .mx-lg-n23 {
        margin-left: -5.75rem !important;
    }

    .m-lg-n24 {
        margin: -6rem !important;
    }

    .mt-lg-n24,
    .my-lg-n24 {
        margin-top: -6rem !important;
    }

    .mr-lg-n24,
    .mx-lg-n24 {
        margin-right: -6rem !important;
    }

    .mb-lg-n24,
    .my-lg-n24 {
        margin-bottom: -6rem !important;
    }

    .ml-lg-n24,
    .mx-lg-n24 {
        margin-left: -6rem !important;
    }

    .m-lg-n25 {
        margin: -6.25rem !important;
    }

    .mt-lg-n25,
    .my-lg-n25 {
        margin-top: -6.25rem !important;
    }

    .mr-lg-n25,
    .mx-lg-n25 {
        margin-right: -6.25rem !important;
    }

    .mb-lg-n25,
    .my-lg-n25 {
        margin-bottom: -6.25rem !important;
    }

    .ml-lg-n25,
    .mx-lg-n25 {
        margin-left: -6.25rem !important;
    }

    .m-lg-n26 {
        margin: -6.5rem !important;
    }

    .mt-lg-n26,
    .my-lg-n26 {
        margin-top: -6.5rem !important;
    }

    .mr-lg-n26,
    .mx-lg-n26 {
        margin-right: -6.5rem !important;
    }

    .mb-lg-n26,
    .my-lg-n26 {
        margin-bottom: -6.5rem !important;
    }

    .ml-lg-n26,
    .mx-lg-n26 {
        margin-left: -6.5rem !important;
    }

    .m-lg-n27 {
        margin: -6.75rem !important;
    }

    .mt-lg-n27,
    .my-lg-n27 {
        margin-top: -6.75rem !important;
    }

    .mr-lg-n27,
    .mx-lg-n27 {
        margin-right: -6.75rem !important;
    }

    .mb-lg-n27,
    .my-lg-n27 {
        margin-bottom: -6.75rem !important;
    }

    .ml-lg-n27,
    .mx-lg-n27 {
        margin-left: -6.75rem !important;
    }

    .m-lg-n38 {
        margin: -7rem !important;
    }

    .mt-lg-n38,
    .my-lg-n38 {
        margin-top: -7rem !important;
    }

    .mr-lg-n38,
    .mx-lg-n38 {
        margin-right: -7rem !important;
    }

    .mb-lg-n38,
    .my-lg-n38 {
        margin-bottom: -7rem !important;
    }

    .ml-lg-n38,
    .mx-lg-n38 {
        margin-left: -7rem !important;
    }

    .m-lg-n29 {
        margin: -7.25rem !important;
    }

    .mt-lg-n29,
    .my-lg-n29 {
        margin-top: -7.25rem !important;
    }

    .mr-lg-n29,
    .mx-lg-n29 {
        margin-right: -7.25rem !important;
    }

    .mb-lg-n29,
    .my-lg-n29 {
        margin-bottom: -7.25rem !important;
    }

    .ml-lg-n29,
    .mx-lg-n29 {
        margin-left: -7.25rem !important;
    }

    .m-lg-n30 {
        margin: -7.5rem !important;
    }

    .mt-lg-n30,
    .my-lg-n30 {
        margin-top: -7.5rem !important;
    }

    .mr-lg-n30,
    .mx-lg-n30 {
        margin-right: -7.5rem !important;
    }

    .mb-lg-n30,
    .my-lg-n30 {
        margin-bottom: -7.5rem !important;
    }

    .ml-lg-n30,
    .mx-lg-n30 {
        margin-left: -7.5rem !important;
    }

    .m-lg-n31 {
        margin: -7.75rem !important;
    }

    .mt-lg-n31,
    .my-lg-n31 {
        margin-top: -7.75rem !important;
    }

    .mr-lg-n31,
    .mx-lg-n31 {
        margin-right: -7.75rem !important;
    }

    .mb-lg-n31,
    .my-lg-n31 {
        margin-bottom: -7.75rem !important;
    }

    .ml-lg-n31,
    .mx-lg-n31 {
        margin-left: -7.75rem !important;
    }

    .m-lg-n32 {
        margin: -8rem !important;
    }

    .mt-lg-n32,
    .my-lg-n32 {
        margin-top: -8rem !important;
    }

    .mr-lg-n32,
    .mx-lg-n32 {
        margin-right: -8rem !important;
    }

    .mb-lg-n32,
    .my-lg-n32 {
        margin-bottom: -8rem !important;
    }

    .ml-lg-n32,
    .mx-lg-n32 {
        margin-left: -8rem !important;
    }

    .m-lg-n33 {
        margin: -8.25rem !important;
    }

    .mt-lg-n33,
    .my-lg-n33 {
        margin-top: -8.25rem !important;
    }

    .mr-lg-n33,
    .mx-lg-n33 {
        margin-right: -8.25rem !important;
    }

    .mb-lg-n33,
    .my-lg-n33 {
        margin-bottom: -8.25rem !important;
    }

    .ml-lg-n33,
    .mx-lg-n33 {
        margin-left: -8.25rem !important;
    }

    .m-lg-n34 {
        margin: -8.5rem !important;
    }

    .mt-lg-n34,
    .my-lg-n34 {
        margin-top: -8.5rem !important;
    }

    .mr-lg-n34,
    .mx-lg-n34 {
        margin-right: -8.5rem !important;
    }

    .mb-lg-n34,
    .my-lg-n34 {
        margin-bottom: -8.5rem !important;
    }

    .ml-lg-n34,
    .mx-lg-n34 {
        margin-left: -8.5rem !important;
    }

    .m-lg-n35 {
        margin: -8.75rem !important;
    }

    .mt-lg-n35,
    .my-lg-n35 {
        margin-top: -8.75rem !important;
    }

    .mr-lg-n35,
    .mx-lg-n35 {
        margin-right: -8.75rem !important;
    }

    .mb-lg-n35,
    .my-lg-n35 {
        margin-bottom: -8.75rem !important;
    }

    .ml-lg-n35,
    .mx-lg-n35 {
        margin-left: -8.75rem !important;
    }

    .m-lg-n36 {
        margin: -9rem !important;
    }

    .mt-lg-n36,
    .my-lg-n36 {
        margin-top: -9rem !important;
    }

    .mr-lg-n36,
    .mx-lg-n36 {
        margin-right: -9rem !important;
    }

    .mb-lg-n36,
    .my-lg-n36 {
        margin-bottom: -9rem !important;
    }

    .ml-lg-n36,
    .mx-lg-n36 {
        margin-left: -9rem !important;
    }

    .m-lg-n37 {
        margin: -9.25rem !important;
    }

    .mt-lg-n37,
    .my-lg-n37 {
        margin-top: -9.25rem !important;
    }

    .mr-lg-n37,
    .mx-lg-n37 {
        margin-right: -9.25rem !important;
    }

    .mb-lg-n37,
    .my-lg-n37 {
        margin-bottom: -9.25rem !important;
    }

    .ml-lg-n37,
    .mx-lg-n37 {
        margin-left: -9.25rem !important;
    }

    .m-lg-n48 {
        margin: -9.5rem !important;
    }

    .mt-lg-n48,
    .my-lg-n48 {
        margin-top: -9.5rem !important;
    }

    .mr-lg-n48,
    .mx-lg-n48 {
        margin-right: -9.5rem !important;
    }

    .mb-lg-n48,
    .my-lg-n48 {
        margin-bottom: -9.5rem !important;
    }

    .ml-lg-n48,
    .mx-lg-n48 {
        margin-left: -9.5rem !important;
    }

    .m-lg-n39 {
        margin: -9.75rem !important;
    }

    .mt-lg-n39,
    .my-lg-n39 {
        margin-top: -9.75rem !important;
    }

    .mr-lg-n39,
    .mx-lg-n39 {
        margin-right: -9.75rem !important;
    }

    .mb-lg-n39,
    .my-lg-n39 {
        margin-bottom: -9.75rem !important;
    }

    .ml-lg-n39,
    .mx-lg-n39 {
        margin-left: -9.75rem !important;
    }

    .m-lg-n40 {
        margin: -10rem !important;
    }

    .mt-lg-n40,
    .my-lg-n40 {
        margin-top: -10rem !important;
    }

    .mr-lg-n40,
    .mx-lg-n40 {
        margin-right: -10rem !important;
    }

    .mb-lg-n40,
    .my-lg-n40 {
        margin-bottom: -10rem !important;
    }

    .ml-lg-n40,
    .mx-lg-n40 {
        margin-left: -10rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 0.75rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 0.75rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 0.75rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 0.75rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 0.75rem !important;
    }

    .m-xl-4 {
        margin: 1rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1rem !important;
    }

    .m-xl-5 {
        margin: 1.25rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 1.25rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 1.25rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 1.25rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 1.25rem !important;
    }

    .m-xl-6 {
        margin: 1.5rem !important;
    }

    .mt-xl-6,
    .my-xl-6 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-6,
    .mx-xl-6 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-6,
    .my-xl-6 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-6,
    .mx-xl-6 {
        margin-left: 1.5rem !important;
    }

    .m-xl-7 {
        margin: 1.75rem !important;
    }

    .mt-xl-7,
    .my-xl-7 {
        margin-top: 1.75rem !important;
    }

    .mr-xl-7,
    .mx-xl-7 {
        margin-right: 1.75rem !important;
    }

    .mb-xl-7,
    .my-xl-7 {
        margin-bottom: 1.75rem !important;
    }

    .ml-xl-7,
    .mx-xl-7 {
        margin-left: 1.75rem !important;
    }

    .m-xl-8 {
        margin: 2rem !important;
    }

    .mt-xl-8,
    .my-xl-8 {
        margin-top: 2rem !important;
    }

    .mr-xl-8,
    .mx-xl-8 {
        margin-right: 2rem !important;
    }

    .mb-xl-8,
    .my-xl-8 {
        margin-bottom: 2rem !important;
    }

    .ml-xl-8,
    .mx-xl-8 {
        margin-left: 2rem !important;
    }

    .m-xl-9 {
        margin: 2.25rem !important;
    }

    .mt-xl-9,
    .my-xl-9 {
        margin-top: 2.25rem !important;
    }

    .mr-xl-9,
    .mx-xl-9 {
        margin-right: 2.25rem !important;
    }

    .mb-xl-9,
    .my-xl-9 {
        margin-bottom: 2.25rem !important;
    }

    .ml-xl-9,
    .mx-xl-9 {
        margin-left: 2.25rem !important;
    }

    .m-xl-10 {
        margin: 2.5rem !important;
    }

    .mt-xl-10,
    .my-xl-10 {
        margin-top: 2.5rem !important;
    }

    .mr-xl-10,
    .mx-xl-10 {
        margin-right: 2.5rem !important;
    }

    .mb-xl-10,
    .my-xl-10 {
        margin-bottom: 2.5rem !important;
    }

    .ml-xl-10,
    .mx-xl-10 {
        margin-left: 2.5rem !important;
    }

    .m-xl-11 {
        margin: 2.75rem !important;
    }

    .mt-xl-11,
    .my-xl-11 {
        margin-top: 2.75rem !important;
    }

    .mr-xl-11,
    .mx-xl-11 {
        margin-right: 2.75rem !important;
    }

    .mb-xl-11,
    .my-xl-11 {
        margin-bottom: 2.75rem !important;
    }

    .ml-xl-11,
    .mx-xl-11 {
        margin-left: 2.75rem !important;
    }

    .m-xl-12 {
        margin: 3rem !important;
    }

    .mt-xl-12,
    .my-xl-12 {
        margin-top: 3rem !important;
    }

    .mr-xl-12,
    .mx-xl-12 {
        margin-right: 3rem !important;
    }

    .mb-xl-12,
    .my-xl-12 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-12,
    .mx-xl-12 {
        margin-left: 3rem !important;
    }

    .m-xl-13 {
        margin: 3.25rem !important;
    }

    .mt-xl-13,
    .my-xl-13 {
        margin-top: 3.25rem !important;
    }

    .mr-xl-13,
    .mx-xl-13 {
        margin-right: 3.25rem !important;
    }

    .mb-xl-13,
    .my-xl-13 {
        margin-bottom: 3.25rem !important;
    }

    .ml-xl-13,
    .mx-xl-13 {
        margin-left: 3.25rem !important;
    }

    .m-xl-14 {
        margin: 3.5rem !important;
    }

    .mt-xl-14,
    .my-xl-14 {
        margin-top: 3.5rem !important;
    }

    .mr-xl-14,
    .mx-xl-14 {
        margin-right: 3.5rem !important;
    }

    .mb-xl-14,
    .my-xl-14 {
        margin-bottom: 3.5rem !important;
    }

    .ml-xl-14,
    .mx-xl-14 {
        margin-left: 3.5rem !important;
    }

    .m-xl-15 {
        margin: 3.75rem !important;
    }

    .mt-xl-15,
    .my-xl-15 {
        margin-top: 3.75rem !important;
    }

    .mr-xl-15,
    .mx-xl-15 {
        margin-right: 3.75rem !important;
    }

    .mb-xl-15,
    .my-xl-15 {
        margin-bottom: 3.75rem !important;
    }

    .ml-xl-15,
    .mx-xl-15 {
        margin-left: 3.75rem !important;
    }

    .m-xl-16 {
        margin: 4rem !important;
    }

    .mt-xl-16,
    .my-xl-16 {
        margin-top: 4rem !important;
    }

    .mr-xl-16,
    .mx-xl-16 {
        margin-right: 4rem !important;
    }

    .mb-xl-16,
    .my-xl-16 {
        margin-bottom: 4rem !important;
    }

    .ml-xl-16,
    .mx-xl-16 {
        margin-left: 4rem !important;
    }

    .m-xl-17 {
        margin: 4.25rem !important;
    }

    .mt-xl-17,
    .my-xl-17 {
        margin-top: 4.25rem !important;
    }

    .mr-xl-17,
    .mx-xl-17 {
        margin-right: 4.25rem !important;
    }

    .mb-xl-17,
    .my-xl-17 {
        margin-bottom: 4.25rem !important;
    }

    .ml-xl-17,
    .mx-xl-17 {
        margin-left: 4.25rem !important;
    }

    .m-xl-18 {
        margin: 4.5rem !important;
    }

    .mt-xl-18,
    .my-xl-18 {
        margin-top: 4.5rem !important;
    }

    .mr-xl-18,
    .mx-xl-18 {
        margin-right: 4.5rem !important;
    }

    .mb-xl-18,
    .my-xl-18 {
        margin-bottom: 4.5rem !important;
    }

    .ml-xl-18,
    .mx-xl-18 {
        margin-left: 4.5rem !important;
    }

    .m-xl-19 {
        margin: 4.75rem !important;
    }

    .mt-xl-19,
    .my-xl-19 {
        margin-top: 4.75rem !important;
    }

    .mr-xl-19,
    .mx-xl-19 {
        margin-right: 4.75rem !important;
    }

    .mb-xl-19,
    .my-xl-19 {
        margin-bottom: 4.75rem !important;
    }

    .ml-xl-19,
    .mx-xl-19 {
        margin-left: 4.75rem !important;
    }

    .m-xl-20 {
        margin: 5rem !important;
    }

    .mt-xl-20,
    .my-xl-20 {
        margin-top: 5rem !important;
    }

    .mr-xl-20,
    .mx-xl-20 {
        margin-right: 5rem !important;
    }

    .mb-xl-20,
    .my-xl-20 {
        margin-bottom: 5rem !important;
    }

    .ml-xl-20,
    .mx-xl-20 {
        margin-left: 5rem !important;
    }

    .m-xl-21 {
        margin: 5.25rem !important;
    }

    .mt-xl-21,
    .my-xl-21 {
        margin-top: 5.25rem !important;
    }

    .mr-xl-21,
    .mx-xl-21 {
        margin-right: 5.25rem !important;
    }

    .mb-xl-21,
    .my-xl-21 {
        margin-bottom: 5.25rem !important;
    }

    .ml-xl-21,
    .mx-xl-21 {
        margin-left: 5.25rem !important;
    }

    .m-xl-22 {
        margin: 5.5rem !important;
    }

    .mt-xl-22,
    .my-xl-22 {
        margin-top: 5.5rem !important;
    }

    .mr-xl-22,
    .mx-xl-22 {
        margin-right: 5.5rem !important;
    }

    .mb-xl-22,
    .my-xl-22 {
        margin-bottom: 5.5rem !important;
    }

    .ml-xl-22,
    .mx-xl-22 {
        margin-left: 5.5rem !important;
    }

    .m-xl-23 {
        margin: 5.75rem !important;
    }

    .mt-xl-23,
    .my-xl-23 {
        margin-top: 5.75rem !important;
    }

    .mr-xl-23,
    .mx-xl-23 {
        margin-right: 5.75rem !important;
    }

    .mb-xl-23,
    .my-xl-23 {
        margin-bottom: 5.75rem !important;
    }

    .ml-xl-23,
    .mx-xl-23 {
        margin-left: 5.75rem !important;
    }

    .m-xl-24 {
        margin: 6rem !important;
    }

    .mt-xl-24,
    .my-xl-24 {
        margin-top: 6rem !important;
    }

    .mr-xl-24,
    .mx-xl-24 {
        margin-right: 6rem !important;
    }

    .mb-xl-24,
    .my-xl-24 {
        margin-bottom: 6rem !important;
    }

    .ml-xl-24,
    .mx-xl-24 {
        margin-left: 6rem !important;
    }

    .m-xl-25 {
        margin: 6.25rem !important;
    }

    .mt-xl-25,
    .my-xl-25 {
        margin-top: 6.25rem !important;
    }

    .mr-xl-25,
    .mx-xl-25 {
        margin-right: 6.25rem !important;
    }

    .mb-xl-25,
    .my-xl-25 {
        margin-bottom: 6.25rem !important;
    }

    .ml-xl-25,
    .mx-xl-25 {
        margin-left: 6.25rem !important;
    }

    .m-xl-26 {
        margin: 6.5rem !important;
    }

    .mt-xl-26,
    .my-xl-26 {
        margin-top: 6.5rem !important;
    }

    .mr-xl-26,
    .mx-xl-26 {
        margin-right: 6.5rem !important;
    }

    .mb-xl-26,
    .my-xl-26 {
        margin-bottom: 6.5rem !important;
    }

    .ml-xl-26,
    .mx-xl-26 {
        margin-left: 6.5rem !important;
    }

    .m-xl-27 {
        margin: 6.75rem !important;
    }

    .mt-xl-27,
    .my-xl-27 {
        margin-top: 6.75rem !important;
    }

    .mr-xl-27,
    .mx-xl-27 {
        margin-right: 6.75rem !important;
    }

    .mb-xl-27,
    .my-xl-27 {
        margin-bottom: 6.75rem !important;
    }

    .ml-xl-27,
    .mx-xl-27 {
        margin-left: 6.75rem !important;
    }

    .m-xl-38 {
        margin: 7rem !important;
    }

    .mt-xl-38,
    .my-xl-38 {
        margin-top: 7rem !important;
    }

    .mr-xl-38,
    .mx-xl-38 {
        margin-right: 7rem !important;
    }

    .mb-xl-38,
    .my-xl-38 {
        margin-bottom: 7rem !important;
    }

    .ml-xl-38,
    .mx-xl-38 {
        margin-left: 7rem !important;
    }

    .m-xl-29 {
        margin: 7.25rem !important;
    }

    .mt-xl-29,
    .my-xl-29 {
        margin-top: 7.25rem !important;
    }

    .mr-xl-29,
    .mx-xl-29 {
        margin-right: 7.25rem !important;
    }

    .mb-xl-29,
    .my-xl-29 {
        margin-bottom: 7.25rem !important;
    }

    .ml-xl-29,
    .mx-xl-29 {
        margin-left: 7.25rem !important;
    }

    .m-xl-30 {
        margin: 7.5rem !important;
    }

    .mt-xl-30,
    .my-xl-30 {
        margin-top: 7.5rem !important;
    }

    .mr-xl-30,
    .mx-xl-30 {
        margin-right: 7.5rem !important;
    }

    .mb-xl-30,
    .my-xl-30 {
        margin-bottom: 7.5rem !important;
    }

    .ml-xl-30,
    .mx-xl-30 {
        margin-left: 7.5rem !important;
    }

    .m-xl-31 {
        margin: 7.75rem !important;
    }

    .mt-xl-31,
    .my-xl-31 {
        margin-top: 7.75rem !important;
    }

    .mr-xl-31,
    .mx-xl-31 {
        margin-right: 7.75rem !important;
    }

    .mb-xl-31,
    .my-xl-31 {
        margin-bottom: 7.75rem !important;
    }

    .ml-xl-31,
    .mx-xl-31 {
        margin-left: 7.75rem !important;
    }

    .m-xl-32 {
        margin: 8rem !important;
    }

    .mt-xl-32,
    .my-xl-32 {
        margin-top: 8rem !important;
    }

    .mr-xl-32,
    .mx-xl-32 {
        margin-right: 8rem !important;
    }

    .mb-xl-32,
    .my-xl-32 {
        margin-bottom: 8rem !important;
    }

    .ml-xl-32,
    .mx-xl-32 {
        margin-left: 8rem !important;
    }

    .m-xl-33 {
        margin: 8.25rem !important;
    }

    .mt-xl-33,
    .my-xl-33 {
        margin-top: 8.25rem !important;
    }

    .mr-xl-33,
    .mx-xl-33 {
        margin-right: 8.25rem !important;
    }

    .mb-xl-33,
    .my-xl-33 {
        margin-bottom: 8.25rem !important;
    }

    .ml-xl-33,
    .mx-xl-33 {
        margin-left: 8.25rem !important;
    }

    .m-xl-34 {
        margin: 8.5rem !important;
    }

    .mt-xl-34,
    .my-xl-34 {
        margin-top: 8.5rem !important;
    }

    .mr-xl-34,
    .mx-xl-34 {
        margin-right: 8.5rem !important;
    }

    .mb-xl-34,
    .my-xl-34 {
        margin-bottom: 8.5rem !important;
    }

    .ml-xl-34,
    .mx-xl-34 {
        margin-left: 8.5rem !important;
    }

    .m-xl-35 {
        margin: 8.75rem !important;
    }

    .mt-xl-35,
    .my-xl-35 {
        margin-top: 8.75rem !important;
    }

    .mr-xl-35,
    .mx-xl-35 {
        margin-right: 8.75rem !important;
    }

    .mb-xl-35,
    .my-xl-35 {
        margin-bottom: 8.75rem !important;
    }

    .ml-xl-35,
    .mx-xl-35 {
        margin-left: 8.75rem !important;
    }

    .m-xl-36 {
        margin: 9rem !important;
    }

    .mt-xl-36,
    .my-xl-36 {
        margin-top: 9rem !important;
    }

    .mr-xl-36,
    .mx-xl-36 {
        margin-right: 9rem !important;
    }

    .mb-xl-36,
    .my-xl-36 {
        margin-bottom: 9rem !important;
    }

    .ml-xl-36,
    .mx-xl-36 {
        margin-left: 9rem !important;
    }

    .m-xl-37 {
        margin: 9.25rem !important;
    }

    .mt-xl-37,
    .my-xl-37 {
        margin-top: 9.25rem !important;
    }

    .mr-xl-37,
    .mx-xl-37 {
        margin-right: 9.25rem !important;
    }

    .mb-xl-37,
    .my-xl-37 {
        margin-bottom: 9.25rem !important;
    }

    .ml-xl-37,
    .mx-xl-37 {
        margin-left: 9.25rem !important;
    }

    .m-xl-48 {
        margin: 9.5rem !important;
    }

    .mt-xl-48,
    .my-xl-48 {
        margin-top: 9.5rem !important;
    }

    .mr-xl-48,
    .mx-xl-48 {
        margin-right: 9.5rem !important;
    }

    .mb-xl-48,
    .my-xl-48 {
        margin-bottom: 9.5rem !important;
    }

    .ml-xl-48,
    .mx-xl-48 {
        margin-left: 9.5rem !important;
    }

    .m-xl-39 {
        margin: 9.75rem !important;
    }

    .mt-xl-39,
    .my-xl-39 {
        margin-top: 9.75rem !important;
    }

    .mr-xl-39,
    .mx-xl-39 {
        margin-right: 9.75rem !important;
    }

    .mb-xl-39,
    .my-xl-39 {
        margin-bottom: 9.75rem !important;
    }

    .ml-xl-39,
    .mx-xl-39 {
        margin-left: 9.75rem !important;
    }

    .m-xl-40 {
        margin: 10rem !important;
    }

    .mt-xl-40,
    .my-xl-40 {
        margin-top: 10rem !important;
    }

    .mr-xl-40,
    .mx-xl-40 {
        margin-right: 10rem !important;
    }

    .mb-xl-40,
    .my-xl-40 {
        margin-bottom: 10rem !important;
    }

    .ml-xl-40,
    .mx-xl-40 {
        margin-left: 10rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 0.75rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 0.75rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 0.75rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 0.75rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 0.75rem !important;
    }

    .p-xl-4 {
        padding: 1rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1rem !important;
    }

    .p-xl-5 {
        padding: 1.25rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 1.25rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 1.25rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 1.25rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 1.25rem !important;
    }

    .p-xl-6 {
        padding: 1.5rem !important;
    }

    .pt-xl-6,
    .py-xl-6 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-6,
    .px-xl-6 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-6,
    .py-xl-6 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-6,
    .px-xl-6 {
        padding-left: 1.5rem !important;
    }

    .p-xl-7 {
        padding: 1.75rem !important;
    }

    .pt-xl-7,
    .py-xl-7 {
        padding-top: 1.75rem !important;
    }

    .pr-xl-7,
    .px-xl-7 {
        padding-right: 1.75rem !important;
    }

    .pb-xl-7,
    .py-xl-7 {
        padding-bottom: 1.75rem !important;
    }

    .pl-xl-7,
    .px-xl-7 {
        padding-left: 1.75rem !important;
    }

    .p-xl-8 {
        padding: 2rem !important;
    }

    .pt-xl-8,
    .py-xl-8 {
        padding-top: 2rem !important;
    }

    .pr-xl-8,
    .px-xl-8 {
        padding-right: 2rem !important;
    }

    .pb-xl-8,
    .py-xl-8 {
        padding-bottom: 2rem !important;
    }

    .pl-xl-8,
    .px-xl-8 {
        padding-left: 2rem !important;
    }

    .p-xl-9 {
        padding: 2.25rem !important;
    }

    .pt-xl-9,
    .py-xl-9 {
        padding-top: 2.25rem !important;
    }

    .pr-xl-9,
    .px-xl-9 {
        padding-right: 2.25rem !important;
    }

    .pb-xl-9,
    .py-xl-9 {
        padding-bottom: 2.25rem !important;
    }

    .pl-xl-9,
    .px-xl-9 {
        padding-left: 2.25rem !important;
    }

    .p-xl-10 {
        padding: 2.5rem !important;
    }

    .pt-xl-10,
    .py-xl-10 {
        padding-top: 2.5rem !important;
    }

    .pr-xl-10,
    .px-xl-10 {
        padding-right: 2.5rem !important;
    }

    .pb-xl-10,
    .py-xl-10 {
        padding-bottom: 2.5rem !important;
    }

    .pl-xl-10,
    .px-xl-10 {
        padding-left: 2.5rem !important;
    }

    .p-xl-11 {
        padding: 2.75rem !important;
    }

    .pt-xl-11,
    .py-xl-11 {
        padding-top: 2.75rem !important;
    }

    .pr-xl-11,
    .px-xl-11 {
        padding-right: 2.75rem !important;
    }

    .pb-xl-11,
    .py-xl-11 {
        padding-bottom: 2.75rem !important;
    }

    .pl-xl-11,
    .px-xl-11 {
        padding-left: 2.75rem !important;
    }

    .p-xl-12 {
        padding: 3rem !important;
    }

    .pt-xl-12,
    .py-xl-12 {
        padding-top: 3rem !important;
    }

    .pr-xl-12,
    .px-xl-12 {
        padding-right: 3rem !important;
    }

    .pb-xl-12,
    .py-xl-12 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-12,
    .px-xl-12 {
        padding-left: 3rem !important;
    }

    .p-xl-13 {
        padding: 3.25rem !important;
    }

    .pt-xl-13,
    .py-xl-13 {
        padding-top: 3.25rem !important;
    }

    .pr-xl-13,
    .px-xl-13 {
        padding-right: 3.25rem !important;
    }

    .pb-xl-13,
    .py-xl-13 {
        padding-bottom: 3.25rem !important;
    }

    .pl-xl-13,
    .px-xl-13 {
        padding-left: 3.25rem !important;
    }

    .p-xl-14 {
        padding: 3.5rem !important;
    }

    .pt-xl-14,
    .py-xl-14 {
        padding-top: 3.5rem !important;
    }

    .pr-xl-14,
    .px-xl-14 {
        padding-right: 3.5rem !important;
    }

    .pb-xl-14,
    .py-xl-14 {
        padding-bottom: 3.5rem !important;
    }

    .pl-xl-14,
    .px-xl-14 {
        padding-left: 3.5rem !important;
    }

    .p-xl-15 {
        padding: 3.75rem !important;
    }

    .pt-xl-15,
    .py-xl-15 {
        padding-top: 3.75rem !important;
    }

    .pr-xl-15,
    .px-xl-15 {
        padding-right: 3.75rem !important;
    }

    .pb-xl-15,
    .py-xl-15 {
        padding-bottom: 3.75rem !important;
    }

    .pl-xl-15,
    .px-xl-15 {
        padding-left: 3.75rem !important;
    }

    .p-xl-16 {
        padding: 4rem !important;
    }

    .pt-xl-16,
    .py-xl-16 {
        padding-top: 4rem !important;
    }

    .pr-xl-16,
    .px-xl-16 {
        padding-right: 4rem !important;
    }

    .pb-xl-16,
    .py-xl-16 {
        padding-bottom: 4rem !important;
    }

    .pl-xl-16,
    .px-xl-16 {
        padding-left: 4rem !important;
    }

    .p-xl-17 {
        padding: 4.25rem !important;
    }

    .pt-xl-17,
    .py-xl-17 {
        padding-top: 4.25rem !important;
    }

    .pr-xl-17,
    .px-xl-17 {
        padding-right: 4.25rem !important;
    }

    .pb-xl-17,
    .py-xl-17 {
        padding-bottom: 4.25rem !important;
    }

    .pl-xl-17,
    .px-xl-17 {
        padding-left: 4.25rem !important;
    }

    .p-xl-18 {
        padding: 4.5rem !important;
    }

    .pt-xl-18,
    .py-xl-18 {
        padding-top: 4.5rem !important;
    }

    .pr-xl-18,
    .px-xl-18 {
        padding-right: 4.5rem !important;
    }

    .pb-xl-18,
    .py-xl-18 {
        padding-bottom: 4.5rem !important;
    }

    .pl-xl-18,
    .px-xl-18 {
        padding-left: 4.5rem !important;
    }

    .p-xl-19 {
        padding: 4.75rem !important;
    }

    .pt-xl-19,
    .py-xl-19 {
        padding-top: 4.75rem !important;
    }

    .pr-xl-19,
    .px-xl-19 {
        padding-right: 4.75rem !important;
    }

    .pb-xl-19,
    .py-xl-19 {
        padding-bottom: 4.75rem !important;
    }

    .pl-xl-19,
    .px-xl-19 {
        padding-left: 4.75rem !important;
    }

    .p-xl-20 {
        padding: 5rem !important;
    }

    .pt-xl-20,
    .py-xl-20 {
        padding-top: 5rem !important;
    }

    .pr-xl-20,
    .px-xl-20 {
        padding-right: 5rem !important;
    }

    .pb-xl-20,
    .py-xl-20 {
        padding-bottom: 5rem !important;
    }

    .pl-xl-20,
    .px-xl-20 {
        padding-left: 5rem !important;
    }

    .p-xl-21 {
        padding: 5.25rem !important;
    }

    .pt-xl-21,
    .py-xl-21 {
        padding-top: 5.25rem !important;
    }

    .pr-xl-21,
    .px-xl-21 {
        padding-right: 5.25rem !important;
    }

    .pb-xl-21,
    .py-xl-21 {
        padding-bottom: 5.25rem !important;
    }

    .pl-xl-21,
    .px-xl-21 {
        padding-left: 5.25rem !important;
    }

    .p-xl-22 {
        padding: 5.5rem !important;
    }

    .pt-xl-22,
    .py-xl-22 {
        padding-top: 5.5rem !important;
    }

    .pr-xl-22,
    .px-xl-22 {
        padding-right: 5.5rem !important;
    }

    .pb-xl-22,
    .py-xl-22 {
        padding-bottom: 5.5rem !important;
    }

    .pl-xl-22,
    .px-xl-22 {
        padding-left: 5.5rem !important;
    }

    .p-xl-23 {
        padding: 5.75rem !important;
    }

    .pt-xl-23,
    .py-xl-23 {
        padding-top: 5.75rem !important;
    }

    .pr-xl-23,
    .px-xl-23 {
        padding-right: 5.75rem !important;
    }

    .pb-xl-23,
    .py-xl-23 {
        padding-bottom: 5.75rem !important;
    }

    .pl-xl-23,
    .px-xl-23 {
        padding-left: 5.75rem !important;
    }

    .p-xl-24 {
        padding: 6rem !important;
    }

    .pt-xl-24,
    .py-xl-24 {
        padding-top: 6rem !important;
    }

    .pr-xl-24,
    .px-xl-24 {
        padding-right: 6rem !important;
    }

    .pb-xl-24,
    .py-xl-24 {
        padding-bottom: 6rem !important;
    }

    .pl-xl-24,
    .px-xl-24 {
        padding-left: 6rem !important;
    }

    .p-xl-25 {
        padding: 6.25rem !important;
    }

    .pt-xl-25,
    .py-xl-25 {
        padding-top: 6.25rem !important;
    }

    .pr-xl-25,
    .px-xl-25 {
        padding-right: 6.25rem !important;
    }

    .pb-xl-25,
    .py-xl-25 {
        padding-bottom: 6.25rem !important;
    }

    .pl-xl-25,
    .px-xl-25 {
        padding-left: 6.25rem !important;
    }

    .p-xl-26 {
        padding: 6.5rem !important;
    }

    .pt-xl-26,
    .py-xl-26 {
        padding-top: 6.5rem !important;
    }

    .pr-xl-26,
    .px-xl-26 {
        padding-right: 6.5rem !important;
    }

    .pb-xl-26,
    .py-xl-26 {
        padding-bottom: 6.5rem !important;
    }

    .pl-xl-26,
    .px-xl-26 {
        padding-left: 6.5rem !important;
    }

    .p-xl-27 {
        padding: 6.75rem !important;
    }

    .pt-xl-27,
    .py-xl-27 {
        padding-top: 6.75rem !important;
    }

    .pr-xl-27,
    .px-xl-27 {
        padding-right: 6.75rem !important;
    }

    .pb-xl-27,
    .py-xl-27 {
        padding-bottom: 6.75rem !important;
    }

    .pl-xl-27,
    .px-xl-27 {
        padding-left: 6.75rem !important;
    }

    .p-xl-38 {
        padding: 7rem !important;
    }

    .pt-xl-38,
    .py-xl-38 {
        padding-top: 7rem !important;
    }

    .pr-xl-38,
    .px-xl-38 {
        padding-right: 7rem !important;
    }

    .pb-xl-38,
    .py-xl-38 {
        padding-bottom: 7rem !important;
    }

    .pl-xl-38,
    .px-xl-38 {
        padding-left: 7rem !important;
    }

    .p-xl-29 {
        padding: 7.25rem !important;
    }

    .pt-xl-29,
    .py-xl-29 {
        padding-top: 7.25rem !important;
    }

    .pr-xl-29,
    .px-xl-29 {
        padding-right: 7.25rem !important;
    }

    .pb-xl-29,
    .py-xl-29 {
        padding-bottom: 7.25rem !important;
    }

    .pl-xl-29,
    .px-xl-29 {
        padding-left: 7.25rem !important;
    }

    .p-xl-30 {
        padding: 7.5rem !important;
    }

    .pt-xl-30,
    .py-xl-30 {
        padding-top: 7.5rem !important;
    }

    .pr-xl-30,
    .px-xl-30 {
        padding-right: 7.5rem !important;
    }

    .pb-xl-30,
    .py-xl-30 {
        padding-bottom: 7.5rem !important;
    }

    .pl-xl-30,
    .px-xl-30 {
        padding-left: 7.5rem !important;
    }

    .p-xl-31 {
        padding: 7.75rem !important;
    }

    .pt-xl-31,
    .py-xl-31 {
        padding-top: 7.75rem !important;
    }

    .pr-xl-31,
    .px-xl-31 {
        padding-right: 7.75rem !important;
    }

    .pb-xl-31,
    .py-xl-31 {
        padding-bottom: 7.75rem !important;
    }

    .pl-xl-31,
    .px-xl-31 {
        padding-left: 7.75rem !important;
    }

    .p-xl-32 {
        padding: 8rem !important;
    }

    .pt-xl-32,
    .py-xl-32 {
        padding-top: 8rem !important;
    }

    .pr-xl-32,
    .px-xl-32 {
        padding-right: 8rem !important;
    }

    .pb-xl-32,
    .py-xl-32 {
        padding-bottom: 8rem !important;
    }

    .pl-xl-32,
    .px-xl-32 {
        padding-left: 8rem !important;
    }

    .p-xl-33 {
        padding: 8.25rem !important;
    }

    .pt-xl-33,
    .py-xl-33 {
        padding-top: 8.25rem !important;
    }

    .pr-xl-33,
    .px-xl-33 {
        padding-right: 8.25rem !important;
    }

    .pb-xl-33,
    .py-xl-33 {
        padding-bottom: 8.25rem !important;
    }

    .pl-xl-33,
    .px-xl-33 {
        padding-left: 8.25rem !important;
    }

    .p-xl-34 {
        padding: 8.5rem !important;
    }

    .pt-xl-34,
    .py-xl-34 {
        padding-top: 8.5rem !important;
    }

    .pr-xl-34,
    .px-xl-34 {
        padding-right: 8.5rem !important;
    }

    .pb-xl-34,
    .py-xl-34 {
        padding-bottom: 8.5rem !important;
    }

    .pl-xl-34,
    .px-xl-34 {
        padding-left: 8.5rem !important;
    }

    .p-xl-35 {
        padding: 8.75rem !important;
    }

    .pt-xl-35,
    .py-xl-35 {
        padding-top: 8.75rem !important;
    }

    .pr-xl-35,
    .px-xl-35 {
        padding-right: 8.75rem !important;
    }

    .pb-xl-35,
    .py-xl-35 {
        padding-bottom: 8.75rem !important;
    }

    .pl-xl-35,
    .px-xl-35 {
        padding-left: 8.75rem !important;
    }

    .p-xl-36 {
        padding: 9rem !important;
    }

    .pt-xl-36,
    .py-xl-36 {
        padding-top: 9rem !important;
    }

    .pr-xl-36,
    .px-xl-36 {
        padding-right: 9rem !important;
    }

    .pb-xl-36,
    .py-xl-36 {
        padding-bottom: 9rem !important;
    }

    .pl-xl-36,
    .px-xl-36 {
        padding-left: 9rem !important;
    }

    .p-xl-37 {
        padding: 9.25rem !important;
    }

    .pt-xl-37,
    .py-xl-37 {
        padding-top: 9.25rem !important;
    }

    .pr-xl-37,
    .px-xl-37 {
        padding-right: 9.25rem !important;
    }

    .pb-xl-37,
    .py-xl-37 {
        padding-bottom: 9.25rem !important;
    }

    .pl-xl-37,
    .px-xl-37 {
        padding-left: 9.25rem !important;
    }

    .p-xl-48 {
        padding: 9.5rem !important;
    }

    .pt-xl-48,
    .py-xl-48 {
        padding-top: 9.5rem !important;
    }

    .pr-xl-48,
    .px-xl-48 {
        padding-right: 9.5rem !important;
    }

    .pb-xl-48,
    .py-xl-48 {
        padding-bottom: 9.5rem !important;
    }

    .pl-xl-48,
    .px-xl-48 {
        padding-left: 9.5rem !important;
    }

    .p-xl-39 {
        padding: 9.75rem !important;
    }

    .pt-xl-39,
    .py-xl-39 {
        padding-top: 9.75rem !important;
    }

    .pr-xl-39,
    .px-xl-39 {
        padding-right: 9.75rem !important;
    }

    .pb-xl-39,
    .py-xl-39 {
        padding-bottom: 9.75rem !important;
    }

    .pl-xl-39,
    .px-xl-39 {
        padding-left: 9.75rem !important;
    }

    .p-xl-40 {
        padding: 10rem !important;
    }

    .pt-xl-40,
    .py-xl-40 {
        padding-top: 10rem !important;
    }

    .pr-xl-40,
    .px-xl-40 {
        padding-right: 10rem !important;
    }

    .pb-xl-40,
    .py-xl-40 {
        padding-bottom: 10rem !important;
    }

    .pl-xl-40,
    .px-xl-40 {
        padding-left: 10rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -0.75rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -0.75rem !important;
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -0.75rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -0.75rem !important;
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -0.75rem !important;
    }

    .m-xl-n4 {
        margin: -1rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1rem !important;
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1rem !important;
    }

    .m-xl-n5 {
        margin: -1.25rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -1.25rem !important;
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -1.25rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -1.25rem !important;
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -1.25rem !important;
    }

    .m-xl-n6 {
        margin: -1.5rem !important;
    }

    .mt-xl-n6,
    .my-xl-n6 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n6,
    .mx-xl-n6 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n6,
    .my-xl-n6 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n6,
    .mx-xl-n6 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n7 {
        margin: -1.75rem !important;
    }

    .mt-xl-n7,
    .my-xl-n7 {
        margin-top: -1.75rem !important;
    }

    .mr-xl-n7,
    .mx-xl-n7 {
        margin-right: -1.75rem !important;
    }

    .mb-xl-n7,
    .my-xl-n7 {
        margin-bottom: -1.75rem !important;
    }

    .ml-xl-n7,
    .mx-xl-n7 {
        margin-left: -1.75rem !important;
    }

    .m-xl-n8 {
        margin: -2rem !important;
    }

    .mt-xl-n8,
    .my-xl-n8 {
        margin-top: -2rem !important;
    }

    .mr-xl-n8,
    .mx-xl-n8 {
        margin-right: -2rem !important;
    }

    .mb-xl-n8,
    .my-xl-n8 {
        margin-bottom: -2rem !important;
    }

    .ml-xl-n8,
    .mx-xl-n8 {
        margin-left: -2rem !important;
    }

    .m-xl-n9 {
        margin: -2.25rem !important;
    }

    .mt-xl-n9,
    .my-xl-n9 {
        margin-top: -2.25rem !important;
    }

    .mr-xl-n9,
    .mx-xl-n9 {
        margin-right: -2.25rem !important;
    }

    .mb-xl-n9,
    .my-xl-n9 {
        margin-bottom: -2.25rem !important;
    }

    .ml-xl-n9,
    .mx-xl-n9 {
        margin-left: -2.25rem !important;
    }

    .m-xl-n10 {
        margin: -2.5rem !important;
    }

    .mt-xl-n10,
    .my-xl-n10 {
        margin-top: -2.5rem !important;
    }

    .mr-xl-n10,
    .mx-xl-n10 {
        margin-right: -2.5rem !important;
    }

    .mb-xl-n10,
    .my-xl-n10 {
        margin-bottom: -2.5rem !important;
    }

    .ml-xl-n10,
    .mx-xl-n10 {
        margin-left: -2.5rem !important;
    }

    .m-xl-n11 {
        margin: -2.75rem !important;
    }

    .mt-xl-n11,
    .my-xl-n11 {
        margin-top: -2.75rem !important;
    }

    .mr-xl-n11,
    .mx-xl-n11 {
        margin-right: -2.75rem !important;
    }

    .mb-xl-n11,
    .my-xl-n11 {
        margin-bottom: -2.75rem !important;
    }

    .ml-xl-n11,
    .mx-xl-n11 {
        margin-left: -2.75rem !important;
    }

    .m-xl-n12 {
        margin: -3rem !important;
    }

    .mt-xl-n12,
    .my-xl-n12 {
        margin-top: -3rem !important;
    }

    .mr-xl-n12,
    .mx-xl-n12 {
        margin-right: -3rem !important;
    }

    .mb-xl-n12,
    .my-xl-n12 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n12,
    .mx-xl-n12 {
        margin-left: -3rem !important;
    }

    .m-xl-n13 {
        margin: -3.25rem !important;
    }

    .mt-xl-n13,
    .my-xl-n13 {
        margin-top: -3.25rem !important;
    }

    .mr-xl-n13,
    .mx-xl-n13 {
        margin-right: -3.25rem !important;
    }

    .mb-xl-n13,
    .my-xl-n13 {
        margin-bottom: -3.25rem !important;
    }

    .ml-xl-n13,
    .mx-xl-n13 {
        margin-left: -3.25rem !important;
    }

    .m-xl-n14 {
        margin: -3.5rem !important;
    }

    .mt-xl-n14,
    .my-xl-n14 {
        margin-top: -3.5rem !important;
    }

    .mr-xl-n14,
    .mx-xl-n14 {
        margin-right: -3.5rem !important;
    }

    .mb-xl-n14,
    .my-xl-n14 {
        margin-bottom: -3.5rem !important;
    }

    .ml-xl-n14,
    .mx-xl-n14 {
        margin-left: -3.5rem !important;
    }

    .m-xl-n15 {
        margin: -3.75rem !important;
    }

    .mt-xl-n15,
    .my-xl-n15 {
        margin-top: -3.75rem !important;
    }

    .mr-xl-n15,
    .mx-xl-n15 {
        margin-right: -3.75rem !important;
    }

    .mb-xl-n15,
    .my-xl-n15 {
        margin-bottom: -3.75rem !important;
    }

    .ml-xl-n15,
    .mx-xl-n15 {
        margin-left: -3.75rem !important;
    }

    .m-xl-n16 {
        margin: -4rem !important;
    }

    .mt-xl-n16,
    .my-xl-n16 {
        margin-top: -4rem !important;
    }

    .mr-xl-n16,
    .mx-xl-n16 {
        margin-right: -4rem !important;
    }

    .mb-xl-n16,
    .my-xl-n16 {
        margin-bottom: -4rem !important;
    }

    .ml-xl-n16,
    .mx-xl-n16 {
        margin-left: -4rem !important;
    }

    .m-xl-n17 {
        margin: -4.25rem !important;
    }

    .mt-xl-n17,
    .my-xl-n17 {
        margin-top: -4.25rem !important;
    }

    .mr-xl-n17,
    .mx-xl-n17 {
        margin-right: -4.25rem !important;
    }

    .mb-xl-n17,
    .my-xl-n17 {
        margin-bottom: -4.25rem !important;
    }

    .ml-xl-n17,
    .mx-xl-n17 {
        margin-left: -4.25rem !important;
    }

    .m-xl-n18 {
        margin: -4.5rem !important;
    }

    .mt-xl-n18,
    .my-xl-n18 {
        margin-top: -4.5rem !important;
    }

    .mr-xl-n18,
    .mx-xl-n18 {
        margin-right: -4.5rem !important;
    }

    .mb-xl-n18,
    .my-xl-n18 {
        margin-bottom: -4.5rem !important;
    }

    .ml-xl-n18,
    .mx-xl-n18 {
        margin-left: -4.5rem !important;
    }

    .m-xl-n19 {
        margin: -4.75rem !important;
    }

    .mt-xl-n19,
    .my-xl-n19 {
        margin-top: -4.75rem !important;
    }

    .mr-xl-n19,
    .mx-xl-n19 {
        margin-right: -4.75rem !important;
    }

    .mb-xl-n19,
    .my-xl-n19 {
        margin-bottom: -4.75rem !important;
    }

    .ml-xl-n19,
    .mx-xl-n19 {
        margin-left: -4.75rem !important;
    }

    .m-xl-n20 {
        margin: -5rem !important;
    }

    .mt-xl-n20,
    .my-xl-n20 {
        margin-top: -5rem !important;
    }

    .mr-xl-n20,
    .mx-xl-n20 {
        margin-right: -5rem !important;
    }

    .mb-xl-n20,
    .my-xl-n20 {
        margin-bottom: -5rem !important;
    }

    .ml-xl-n20,
    .mx-xl-n20 {
        margin-left: -5rem !important;
    }

    .m-xl-n21 {
        margin: -5.25rem !important;
    }

    .mt-xl-n21,
    .my-xl-n21 {
        margin-top: -5.25rem !important;
    }

    .mr-xl-n21,
    .mx-xl-n21 {
        margin-right: -5.25rem !important;
    }

    .mb-xl-n21,
    .my-xl-n21 {
        margin-bottom: -5.25rem !important;
    }

    .ml-xl-n21,
    .mx-xl-n21 {
        margin-left: -5.25rem !important;
    }

    .m-xl-n22 {
        margin: -5.5rem !important;
    }

    .mt-xl-n22,
    .my-xl-n22 {
        margin-top: -5.5rem !important;
    }

    .mr-xl-n22,
    .mx-xl-n22 {
        margin-right: -5.5rem !important;
    }

    .mb-xl-n22,
    .my-xl-n22 {
        margin-bottom: -5.5rem !important;
    }

    .ml-xl-n22,
    .mx-xl-n22 {
        margin-left: -5.5rem !important;
    }

    .m-xl-n23 {
        margin: -5.75rem !important;
    }

    .mt-xl-n23,
    .my-xl-n23 {
        margin-top: -5.75rem !important;
    }

    .mr-xl-n23,
    .mx-xl-n23 {
        margin-right: -5.75rem !important;
    }

    .mb-xl-n23,
    .my-xl-n23 {
        margin-bottom: -5.75rem !important;
    }

    .ml-xl-n23,
    .mx-xl-n23 {
        margin-left: -5.75rem !important;
    }

    .m-xl-n24 {
        margin: -6rem !important;
    }

    .mt-xl-n24,
    .my-xl-n24 {
        margin-top: -6rem !important;
    }

    .mr-xl-n24,
    .mx-xl-n24 {
        margin-right: -6rem !important;
    }

    .mb-xl-n24,
    .my-xl-n24 {
        margin-bottom: -6rem !important;
    }

    .ml-xl-n24,
    .mx-xl-n24 {
        margin-left: -6rem !important;
    }

    .m-xl-n25 {
        margin: -6.25rem !important;
    }

    .mt-xl-n25,
    .my-xl-n25 {
        margin-top: -6.25rem !important;
    }

    .mr-xl-n25,
    .mx-xl-n25 {
        margin-right: -6.25rem !important;
    }

    .mb-xl-n25,
    .my-xl-n25 {
        margin-bottom: -6.25rem !important;
    }

    .ml-xl-n25,
    .mx-xl-n25 {
        margin-left: -6.25rem !important;
    }

    .m-xl-n26 {
        margin: -6.5rem !important;
    }

    .mt-xl-n26,
    .my-xl-n26 {
        margin-top: -6.5rem !important;
    }

    .mr-xl-n26,
    .mx-xl-n26 {
        margin-right: -6.5rem !important;
    }

    .mb-xl-n26,
    .my-xl-n26 {
        margin-bottom: -6.5rem !important;
    }

    .ml-xl-n26,
    .mx-xl-n26 {
        margin-left: -6.5rem !important;
    }

    .m-xl-n27 {
        margin: -6.75rem !important;
    }

    .mt-xl-n27,
    .my-xl-n27 {
        margin-top: -6.75rem !important;
    }

    .mr-xl-n27,
    .mx-xl-n27 {
        margin-right: -6.75rem !important;
    }

    .mb-xl-n27,
    .my-xl-n27 {
        margin-bottom: -6.75rem !important;
    }

    .ml-xl-n27,
    .mx-xl-n27 {
        margin-left: -6.75rem !important;
    }

    .m-xl-n38 {
        margin: -7rem !important;
    }

    .mt-xl-n38,
    .my-xl-n38 {
        margin-top: -7rem !important;
    }

    .mr-xl-n38,
    .mx-xl-n38 {
        margin-right: -7rem !important;
    }

    .mb-xl-n38,
    .my-xl-n38 {
        margin-bottom: -7rem !important;
    }

    .ml-xl-n38,
    .mx-xl-n38 {
        margin-left: -7rem !important;
    }

    .m-xl-n29 {
        margin: -7.25rem !important;
    }

    .mt-xl-n29,
    .my-xl-n29 {
        margin-top: -7.25rem !important;
    }

    .mr-xl-n29,
    .mx-xl-n29 {
        margin-right: -7.25rem !important;
    }

    .mb-xl-n29,
    .my-xl-n29 {
        margin-bottom: -7.25rem !important;
    }

    .ml-xl-n29,
    .mx-xl-n29 {
        margin-left: -7.25rem !important;
    }

    .m-xl-n30 {
        margin: -7.5rem !important;
    }

    .mt-xl-n30,
    .my-xl-n30 {
        margin-top: -7.5rem !important;
    }

    .mr-xl-n30,
    .mx-xl-n30 {
        margin-right: -7.5rem !important;
    }

    .mb-xl-n30,
    .my-xl-n30 {
        margin-bottom: -7.5rem !important;
    }

    .ml-xl-n30,
    .mx-xl-n30 {
        margin-left: -7.5rem !important;
    }

    .m-xl-n31 {
        margin: -7.75rem !important;
    }

    .mt-xl-n31,
    .my-xl-n31 {
        margin-top: -7.75rem !important;
    }

    .mr-xl-n31,
    .mx-xl-n31 {
        margin-right: -7.75rem !important;
    }

    .mb-xl-n31,
    .my-xl-n31 {
        margin-bottom: -7.75rem !important;
    }

    .ml-xl-n31,
    .mx-xl-n31 {
        margin-left: -7.75rem !important;
    }

    .m-xl-n32 {
        margin: -8rem !important;
    }

    .mt-xl-n32,
    .my-xl-n32 {
        margin-top: -8rem !important;
    }

    .mr-xl-n32,
    .mx-xl-n32 {
        margin-right: -8rem !important;
    }

    .mb-xl-n32,
    .my-xl-n32 {
        margin-bottom: -8rem !important;
    }

    .ml-xl-n32,
    .mx-xl-n32 {
        margin-left: -8rem !important;
    }

    .m-xl-n33 {
        margin: -8.25rem !important;
    }

    .mt-xl-n33,
    .my-xl-n33 {
        margin-top: -8.25rem !important;
    }

    .mr-xl-n33,
    .mx-xl-n33 {
        margin-right: -8.25rem !important;
    }

    .mb-xl-n33,
    .my-xl-n33 {
        margin-bottom: -8.25rem !important;
    }

    .ml-xl-n33,
    .mx-xl-n33 {
        margin-left: -8.25rem !important;
    }

    .m-xl-n34 {
        margin: -8.5rem !important;
    }

    .mt-xl-n34,
    .my-xl-n34 {
        margin-top: -8.5rem !important;
    }

    .mr-xl-n34,
    .mx-xl-n34 {
        margin-right: -8.5rem !important;
    }

    .mb-xl-n34,
    .my-xl-n34 {
        margin-bottom: -8.5rem !important;
    }

    .ml-xl-n34,
    .mx-xl-n34 {
        margin-left: -8.5rem !important;
    }

    .m-xl-n35 {
        margin: -8.75rem !important;
    }

    .mt-xl-n35,
    .my-xl-n35 {
        margin-top: -8.75rem !important;
    }

    .mr-xl-n35,
    .mx-xl-n35 {
        margin-right: -8.75rem !important;
    }

    .mb-xl-n35,
    .my-xl-n35 {
        margin-bottom: -8.75rem !important;
    }

    .ml-xl-n35,
    .mx-xl-n35 {
        margin-left: -8.75rem !important;
    }

    .m-xl-n36 {
        margin: -9rem !important;
    }

    .mt-xl-n36,
    .my-xl-n36 {
        margin-top: -9rem !important;
    }

    .mr-xl-n36,
    .mx-xl-n36 {
        margin-right: -9rem !important;
    }

    .mb-xl-n36,
    .my-xl-n36 {
        margin-bottom: -9rem !important;
    }

    .ml-xl-n36,
    .mx-xl-n36 {
        margin-left: -9rem !important;
    }

    .m-xl-n37 {
        margin: -9.25rem !important;
    }

    .mt-xl-n37,
    .my-xl-n37 {
        margin-top: -9.25rem !important;
    }

    .mr-xl-n37,
    .mx-xl-n37 {
        margin-right: -9.25rem !important;
    }

    .mb-xl-n37,
    .my-xl-n37 {
        margin-bottom: -9.25rem !important;
    }

    .ml-xl-n37,
    .mx-xl-n37 {
        margin-left: -9.25rem !important;
    }

    .m-xl-n48 {
        margin: -9.5rem !important;
    }

    .mt-xl-n48,
    .my-xl-n48 {
        margin-top: -9.5rem !important;
    }

    .mr-xl-n48,
    .mx-xl-n48 {
        margin-right: -9.5rem !important;
    }

    .mb-xl-n48,
    .my-xl-n48 {
        margin-bottom: -9.5rem !important;
    }

    .ml-xl-n48,
    .mx-xl-n48 {
        margin-left: -9.5rem !important;
    }

    .m-xl-n39 {
        margin: -9.75rem !important;
    }

    .mt-xl-n39,
    .my-xl-n39 {
        margin-top: -9.75rem !important;
    }

    .mr-xl-n39,
    .mx-xl-n39 {
        margin-right: -9.75rem !important;
    }

    .mb-xl-n39,
    .my-xl-n39 {
        margin-bottom: -9.75rem !important;
    }

    .ml-xl-n39,
    .mx-xl-n39 {
        margin-left: -9.75rem !important;
    }

    .m-xl-n40 {
        margin: -10rem !important;
    }

    .mt-xl-n40,
    .my-xl-n40 {
        margin-top: -10rem !important;
    }

    .mr-xl-n40,
    .mx-xl-n40 {
        margin-right: -10rem !important;
    }

    .mb-xl-n40,
    .my-xl-n40 {
        margin-bottom: -10rem !important;
    }

    .ml-xl-n40,
    .mx-xl-n40 {
        margin-left: -10rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1400px) {
    .m-xxl-0 {
        margin: 0 !important;
    }

    .mt-xxl-0,
    .my-xxl-0 {
        margin-top: 0 !important;
    }

    .mr-xxl-0,
    .mx-xxl-0 {
        margin-right: 0 !important;
    }

    .mb-xxl-0,
    .my-xxl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xxl-0,
    .mx-xxl-0 {
        margin-left: 0 !important;
    }

    .m-xxl-1 {
        margin: 0.25rem !important;
    }

    .mt-xxl-1,
    .my-xxl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xxl-1,
    .mx-xxl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xxl-1,
    .my-xxl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xxl-1,
    .mx-xxl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xxl-2 {
        margin: 0.5rem !important;
    }

    .mt-xxl-2,
    .my-xxl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xxl-2,
    .mx-xxl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xxl-2,
    .my-xxl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xxl-2,
    .mx-xxl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xxl-3 {
        margin: 0.75rem !important;
    }

    .mt-xxl-3,
    .my-xxl-3 {
        margin-top: 0.75rem !important;
    }

    .mr-xxl-3,
    .mx-xxl-3 {
        margin-right: 0.75rem !important;
    }

    .mb-xxl-3,
    .my-xxl-3 {
        margin-bottom: 0.75rem !important;
    }

    .ml-xxl-3,
    .mx-xxl-3 {
        margin-left: 0.75rem !important;
    }

    .m-xxl-4 {
        margin: 1rem !important;
    }

    .mt-xxl-4,
    .my-xxl-4 {
        margin-top: 1rem !important;
    }

    .mr-xxl-4,
    .mx-xxl-4 {
        margin-right: 1rem !important;
    }

    .mb-xxl-4,
    .my-xxl-4 {
        margin-bottom: 1rem !important;
    }

    .ml-xxl-4,
    .mx-xxl-4 {
        margin-left: 1rem !important;
    }

    .m-xxl-5 {
        margin: 1.25rem !important;
    }

    .mt-xxl-5,
    .my-xxl-5 {
        margin-top: 1.25rem !important;
    }

    .mr-xxl-5,
    .mx-xxl-5 {
        margin-right: 1.25rem !important;
    }

    .mb-xxl-5,
    .my-xxl-5 {
        margin-bottom: 1.25rem !important;
    }

    .ml-xxl-5,
    .mx-xxl-5 {
        margin-left: 1.25rem !important;
    }

    .m-xxl-6 {
        margin: 1.5rem !important;
    }

    .mt-xxl-6,
    .my-xxl-6 {
        margin-top: 1.5rem !important;
    }

    .mr-xxl-6,
    .mx-xxl-6 {
        margin-right: 1.5rem !important;
    }

    .mb-xxl-6,
    .my-xxl-6 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xxl-6,
    .mx-xxl-6 {
        margin-left: 1.5rem !important;
    }

    .m-xxl-7 {
        margin: 1.75rem !important;
    }

    .mt-xxl-7,
    .my-xxl-7 {
        margin-top: 1.75rem !important;
    }

    .mr-xxl-7,
    .mx-xxl-7 {
        margin-right: 1.75rem !important;
    }

    .mb-xxl-7,
    .my-xxl-7 {
        margin-bottom: 1.75rem !important;
    }

    .ml-xxl-7,
    .mx-xxl-7 {
        margin-left: 1.75rem !important;
    }

    .m-xxl-8 {
        margin: 2rem !important;
    }

    .mt-xxl-8,
    .my-xxl-8 {
        margin-top: 2rem !important;
    }

    .mr-xxl-8,
    .mx-xxl-8 {
        margin-right: 2rem !important;
    }

    .mb-xxl-8,
    .my-xxl-8 {
        margin-bottom: 2rem !important;
    }

    .ml-xxl-8,
    .mx-xxl-8 {
        margin-left: 2rem !important;
    }

    .m-xxl-9 {
        margin: 2.25rem !important;
    }

    .mt-xxl-9,
    .my-xxl-9 {
        margin-top: 2.25rem !important;
    }

    .mr-xxl-9,
    .mx-xxl-9 {
        margin-right: 2.25rem !important;
    }

    .mb-xxl-9,
    .my-xxl-9 {
        margin-bottom: 2.25rem !important;
    }

    .ml-xxl-9,
    .mx-xxl-9 {
        margin-left: 2.25rem !important;
    }

    .m-xxl-10 {
        margin: 2.5rem !important;
    }

    .mt-xxl-10,
    .my-xxl-10 {
        margin-top: 2.5rem !important;
    }

    .mr-xxl-10,
    .mx-xxl-10 {
        margin-right: 2.5rem !important;
    }

    .mb-xxl-10,
    .my-xxl-10 {
        margin-bottom: 2.5rem !important;
    }

    .ml-xxl-10,
    .mx-xxl-10 {
        margin-left: 2.5rem !important;
    }

    .m-xxl-11 {
        margin: 2.75rem !important;
    }

    .mt-xxl-11,
    .my-xxl-11 {
        margin-top: 2.75rem !important;
    }

    .mr-xxl-11,
    .mx-xxl-11 {
        margin-right: 2.75rem !important;
    }

    .mb-xxl-11,
    .my-xxl-11 {
        margin-bottom: 2.75rem !important;
    }

    .ml-xxl-11,
    .mx-xxl-11 {
        margin-left: 2.75rem !important;
    }

    .m-xxl-12 {
        margin: 3rem !important;
    }

    .mt-xxl-12,
    .my-xxl-12 {
        margin-top: 3rem !important;
    }

    .mr-xxl-12,
    .mx-xxl-12 {
        margin-right: 3rem !important;
    }

    .mb-xxl-12,
    .my-xxl-12 {
        margin-bottom: 3rem !important;
    }

    .ml-xxl-12,
    .mx-xxl-12 {
        margin-left: 3rem !important;
    }

    .m-xxl-13 {
        margin: 3.25rem !important;
    }

    .mt-xxl-13,
    .my-xxl-13 {
        margin-top: 3.25rem !important;
    }

    .mr-xxl-13,
    .mx-xxl-13 {
        margin-right: 3.25rem !important;
    }

    .mb-xxl-13,
    .my-xxl-13 {
        margin-bottom: 3.25rem !important;
    }

    .ml-xxl-13,
    .mx-xxl-13 {
        margin-left: 3.25rem !important;
    }

    .m-xxl-14 {
        margin: 3.5rem !important;
    }

    .mt-xxl-14,
    .my-xxl-14 {
        margin-top: 3.5rem !important;
    }

    .mr-xxl-14,
    .mx-xxl-14 {
        margin-right: 3.5rem !important;
    }

    .mb-xxl-14,
    .my-xxl-14 {
        margin-bottom: 3.5rem !important;
    }

    .ml-xxl-14,
    .mx-xxl-14 {
        margin-left: 3.5rem !important;
    }

    .m-xxl-15 {
        margin: 3.75rem !important;
    }

    .mt-xxl-15,
    .my-xxl-15 {
        margin-top: 3.75rem !important;
    }

    .mr-xxl-15,
    .mx-xxl-15 {
        margin-right: 3.75rem !important;
    }

    .mb-xxl-15,
    .my-xxl-15 {
        margin-bottom: 3.75rem !important;
    }

    .ml-xxl-15,
    .mx-xxl-15 {
        margin-left: 3.75rem !important;
    }

    .m-xxl-16 {
        margin: 4rem !important;
    }

    .mt-xxl-16,
    .my-xxl-16 {
        margin-top: 4rem !important;
    }

    .mr-xxl-16,
    .mx-xxl-16 {
        margin-right: 4rem !important;
    }

    .mb-xxl-16,
    .my-xxl-16 {
        margin-bottom: 4rem !important;
    }

    .ml-xxl-16,
    .mx-xxl-16 {
        margin-left: 4rem !important;
    }

    .m-xxl-17 {
        margin: 4.25rem !important;
    }

    .mt-xxl-17,
    .my-xxl-17 {
        margin-top: 4.25rem !important;
    }

    .mr-xxl-17,
    .mx-xxl-17 {
        margin-right: 4.25rem !important;
    }

    .mb-xxl-17,
    .my-xxl-17 {
        margin-bottom: 4.25rem !important;
    }

    .ml-xxl-17,
    .mx-xxl-17 {
        margin-left: 4.25rem !important;
    }

    .m-xxl-18 {
        margin: 4.5rem !important;
    }

    .mt-xxl-18,
    .my-xxl-18 {
        margin-top: 4.5rem !important;
    }

    .mr-xxl-18,
    .mx-xxl-18 {
        margin-right: 4.5rem !important;
    }

    .mb-xxl-18,
    .my-xxl-18 {
        margin-bottom: 4.5rem !important;
    }

    .ml-xxl-18,
    .mx-xxl-18 {
        margin-left: 4.5rem !important;
    }

    .m-xxl-19 {
        margin: 4.75rem !important;
    }

    .mt-xxl-19,
    .my-xxl-19 {
        margin-top: 4.75rem !important;
    }

    .mr-xxl-19,
    .mx-xxl-19 {
        margin-right: 4.75rem !important;
    }

    .mb-xxl-19,
    .my-xxl-19 {
        margin-bottom: 4.75rem !important;
    }

    .ml-xxl-19,
    .mx-xxl-19 {
        margin-left: 4.75rem !important;
    }

    .m-xxl-20 {
        margin: 5rem !important;
    }

    .mt-xxl-20,
    .my-xxl-20 {
        margin-top: 5rem !important;
    }

    .mr-xxl-20,
    .mx-xxl-20 {
        margin-right: 5rem !important;
    }

    .mb-xxl-20,
    .my-xxl-20 {
        margin-bottom: 5rem !important;
    }

    .ml-xxl-20,
    .mx-xxl-20 {
        margin-left: 5rem !important;
    }

    .m-xxl-21 {
        margin: 5.25rem !important;
    }

    .mt-xxl-21,
    .my-xxl-21 {
        margin-top: 5.25rem !important;
    }

    .mr-xxl-21,
    .mx-xxl-21 {
        margin-right: 5.25rem !important;
    }

    .mb-xxl-21,
    .my-xxl-21 {
        margin-bottom: 5.25rem !important;
    }

    .ml-xxl-21,
    .mx-xxl-21 {
        margin-left: 5.25rem !important;
    }

    .m-xxl-22 {
        margin: 5.5rem !important;
    }

    .mt-xxl-22,
    .my-xxl-22 {
        margin-top: 5.5rem !important;
    }

    .mr-xxl-22,
    .mx-xxl-22 {
        margin-right: 5.5rem !important;
    }

    .mb-xxl-22,
    .my-xxl-22 {
        margin-bottom: 5.5rem !important;
    }

    .ml-xxl-22,
    .mx-xxl-22 {
        margin-left: 5.5rem !important;
    }

    .m-xxl-23 {
        margin: 5.75rem !important;
    }

    .mt-xxl-23,
    .my-xxl-23 {
        margin-top: 5.75rem !important;
    }

    .mr-xxl-23,
    .mx-xxl-23 {
        margin-right: 5.75rem !important;
    }

    .mb-xxl-23,
    .my-xxl-23 {
        margin-bottom: 5.75rem !important;
    }

    .ml-xxl-23,
    .mx-xxl-23 {
        margin-left: 5.75rem !important;
    }

    .m-xxl-24 {
        margin: 6rem !important;
    }

    .mt-xxl-24,
    .my-xxl-24 {
        margin-top: 6rem !important;
    }

    .mr-xxl-24,
    .mx-xxl-24 {
        margin-right: 6rem !important;
    }

    .mb-xxl-24,
    .my-xxl-24 {
        margin-bottom: 6rem !important;
    }

    .ml-xxl-24,
    .mx-xxl-24 {
        margin-left: 6rem !important;
    }

    .m-xxl-25 {
        margin: 6.25rem !important;
    }

    .mt-xxl-25,
    .my-xxl-25 {
        margin-top: 6.25rem !important;
    }

    .mr-xxl-25,
    .mx-xxl-25 {
        margin-right: 6.25rem !important;
    }

    .mb-xxl-25,
    .my-xxl-25 {
        margin-bottom: 6.25rem !important;
    }

    .ml-xxl-25,
    .mx-xxl-25 {
        margin-left: 6.25rem !important;
    }

    .m-xxl-26 {
        margin: 6.5rem !important;
    }

    .mt-xxl-26,
    .my-xxl-26 {
        margin-top: 6.5rem !important;
    }

    .mr-xxl-26,
    .mx-xxl-26 {
        margin-right: 6.5rem !important;
    }

    .mb-xxl-26,
    .my-xxl-26 {
        margin-bottom: 6.5rem !important;
    }

    .ml-xxl-26,
    .mx-xxl-26 {
        margin-left: 6.5rem !important;
    }

    .m-xxl-27 {
        margin: 6.75rem !important;
    }

    .mt-xxl-27,
    .my-xxl-27 {
        margin-top: 6.75rem !important;
    }

    .mr-xxl-27,
    .mx-xxl-27 {
        margin-right: 6.75rem !important;
    }

    .mb-xxl-27,
    .my-xxl-27 {
        margin-bottom: 6.75rem !important;
    }

    .ml-xxl-27,
    .mx-xxl-27 {
        margin-left: 6.75rem !important;
    }

    .m-xxl-38 {
        margin: 7rem !important;
    }

    .mt-xxl-38,
    .my-xxl-38 {
        margin-top: 7rem !important;
    }

    .mr-xxl-38,
    .mx-xxl-38 {
        margin-right: 7rem !important;
    }

    .mb-xxl-38,
    .my-xxl-38 {
        margin-bottom: 7rem !important;
    }

    .ml-xxl-38,
    .mx-xxl-38 {
        margin-left: 7rem !important;
    }

    .m-xxl-29 {
        margin: 7.25rem !important;
    }

    .mt-xxl-29,
    .my-xxl-29 {
        margin-top: 7.25rem !important;
    }

    .mr-xxl-29,
    .mx-xxl-29 {
        margin-right: 7.25rem !important;
    }

    .mb-xxl-29,
    .my-xxl-29 {
        margin-bottom: 7.25rem !important;
    }

    .ml-xxl-29,
    .mx-xxl-29 {
        margin-left: 7.25rem !important;
    }

    .m-xxl-30 {
        margin: 7.5rem !important;
    }

    .mt-xxl-30,
    .my-xxl-30 {
        margin-top: 7.5rem !important;
    }

    .mr-xxl-30,
    .mx-xxl-30 {
        margin-right: 7.5rem !important;
    }

    .mb-xxl-30,
    .my-xxl-30 {
        margin-bottom: 7.5rem !important;
    }

    .ml-xxl-30,
    .mx-xxl-30 {
        margin-left: 7.5rem !important;
    }

    .m-xxl-31 {
        margin: 7.75rem !important;
    }

    .mt-xxl-31,
    .my-xxl-31 {
        margin-top: 7.75rem !important;
    }

    .mr-xxl-31,
    .mx-xxl-31 {
        margin-right: 7.75rem !important;
    }

    .mb-xxl-31,
    .my-xxl-31 {
        margin-bottom: 7.75rem !important;
    }

    .ml-xxl-31,
    .mx-xxl-31 {
        margin-left: 7.75rem !important;
    }

    .m-xxl-32 {
        margin: 8rem !important;
    }

    .mt-xxl-32,
    .my-xxl-32 {
        margin-top: 8rem !important;
    }

    .mr-xxl-32,
    .mx-xxl-32 {
        margin-right: 8rem !important;
    }

    .mb-xxl-32,
    .my-xxl-32 {
        margin-bottom: 8rem !important;
    }

    .ml-xxl-32,
    .mx-xxl-32 {
        margin-left: 8rem !important;
    }

    .m-xxl-33 {
        margin: 8.25rem !important;
    }

    .mt-xxl-33,
    .my-xxl-33 {
        margin-top: 8.25rem !important;
    }

    .mr-xxl-33,
    .mx-xxl-33 {
        margin-right: 8.25rem !important;
    }

    .mb-xxl-33,
    .my-xxl-33 {
        margin-bottom: 8.25rem !important;
    }

    .ml-xxl-33,
    .mx-xxl-33 {
        margin-left: 8.25rem !important;
    }

    .m-xxl-34 {
        margin: 8.5rem !important;
    }

    .mt-xxl-34,
    .my-xxl-34 {
        margin-top: 8.5rem !important;
    }

    .mr-xxl-34,
    .mx-xxl-34 {
        margin-right: 8.5rem !important;
    }

    .mb-xxl-34,
    .my-xxl-34 {
        margin-bottom: 8.5rem !important;
    }

    .ml-xxl-34,
    .mx-xxl-34 {
        margin-left: 8.5rem !important;
    }

    .m-xxl-35 {
        margin: 8.75rem !important;
    }

    .mt-xxl-35,
    .my-xxl-35 {
        margin-top: 8.75rem !important;
    }

    .mr-xxl-35,
    .mx-xxl-35 {
        margin-right: 8.75rem !important;
    }

    .mb-xxl-35,
    .my-xxl-35 {
        margin-bottom: 8.75rem !important;
    }

    .ml-xxl-35,
    .mx-xxl-35 {
        margin-left: 8.75rem !important;
    }

    .m-xxl-36 {
        margin: 9rem !important;
    }

    .mt-xxl-36,
    .my-xxl-36 {
        margin-top: 9rem !important;
    }

    .mr-xxl-36,
    .mx-xxl-36 {
        margin-right: 9rem !important;
    }

    .mb-xxl-36,
    .my-xxl-36 {
        margin-bottom: 9rem !important;
    }

    .ml-xxl-36,
    .mx-xxl-36 {
        margin-left: 9rem !important;
    }

    .m-xxl-37 {
        margin: 9.25rem !important;
    }

    .mt-xxl-37,
    .my-xxl-37 {
        margin-top: 9.25rem !important;
    }

    .mr-xxl-37,
    .mx-xxl-37 {
        margin-right: 9.25rem !important;
    }

    .mb-xxl-37,
    .my-xxl-37 {
        margin-bottom: 9.25rem !important;
    }

    .ml-xxl-37,
    .mx-xxl-37 {
        margin-left: 9.25rem !important;
    }

    .m-xxl-48 {
        margin: 9.5rem !important;
    }

    .mt-xxl-48,
    .my-xxl-48 {
        margin-top: 9.5rem !important;
    }

    .mr-xxl-48,
    .mx-xxl-48 {
        margin-right: 9.5rem !important;
    }

    .mb-xxl-48,
    .my-xxl-48 {
        margin-bottom: 9.5rem !important;
    }

    .ml-xxl-48,
    .mx-xxl-48 {
        margin-left: 9.5rem !important;
    }

    .m-xxl-39 {
        margin: 9.75rem !important;
    }

    .mt-xxl-39,
    .my-xxl-39 {
        margin-top: 9.75rem !important;
    }

    .mr-xxl-39,
    .mx-xxl-39 {
        margin-right: 9.75rem !important;
    }

    .mb-xxl-39,
    .my-xxl-39 {
        margin-bottom: 9.75rem !important;
    }

    .ml-xxl-39,
    .mx-xxl-39 {
        margin-left: 9.75rem !important;
    }

    .m-xxl-40 {
        margin: 10rem !important;
    }

    .mt-xxl-40,
    .my-xxl-40 {
        margin-top: 10rem !important;
    }

    .mr-xxl-40,
    .mx-xxl-40 {
        margin-right: 10rem !important;
    }

    .mb-xxl-40,
    .my-xxl-40 {
        margin-bottom: 10rem !important;
    }

    .ml-xxl-40,
    .mx-xxl-40 {
        margin-left: 10rem !important;
    }

    .p-xxl-0 {
        padding: 0 !important;
    }

    .pt-xxl-0,
    .py-xxl-0 {
        padding-top: 0 !important;
    }

    .pr-xxl-0,
    .px-xxl-0 {
        padding-right: 0 !important;
    }

    .pb-xxl-0,
    .py-xxl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xxl-0,
    .px-xxl-0 {
        padding-left: 0 !important;
    }

    .p-xxl-1 {
        padding: 0.25rem !important;
    }

    .pt-xxl-1,
    .py-xxl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xxl-1,
    .px-xxl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xxl-1,
    .py-xxl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xxl-1,
    .px-xxl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xxl-2 {
        padding: 0.5rem !important;
    }

    .pt-xxl-2,
    .py-xxl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xxl-2,
    .px-xxl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xxl-2,
    .py-xxl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xxl-2,
    .px-xxl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xxl-3 {
        padding: 0.75rem !important;
    }

    .pt-xxl-3,
    .py-xxl-3 {
        padding-top: 0.75rem !important;
    }

    .pr-xxl-3,
    .px-xxl-3 {
        padding-right: 0.75rem !important;
    }

    .pb-xxl-3,
    .py-xxl-3 {
        padding-bottom: 0.75rem !important;
    }

    .pl-xxl-3,
    .px-xxl-3 {
        padding-left: 0.75rem !important;
    }

    .p-xxl-4 {
        padding: 1rem !important;
    }

    .pt-xxl-4,
    .py-xxl-4 {
        padding-top: 1rem !important;
    }

    .pr-xxl-4,
    .px-xxl-4 {
        padding-right: 1rem !important;
    }

    .pb-xxl-4,
    .py-xxl-4 {
        padding-bottom: 1rem !important;
    }

    .pl-xxl-4,
    .px-xxl-4 {
        padding-left: 1rem !important;
    }

    .p-xxl-5 {
        padding: 1.25rem !important;
    }

    .pt-xxl-5,
    .py-xxl-5 {
        padding-top: 1.25rem !important;
    }

    .pr-xxl-5,
    .px-xxl-5 {
        padding-right: 1.25rem !important;
    }

    .pb-xxl-5,
    .py-xxl-5 {
        padding-bottom: 1.25rem !important;
    }

    .pl-xxl-5,
    .px-xxl-5 {
        padding-left: 1.25rem !important;
    }

    .p-xxl-6 {
        padding: 1.5rem !important;
    }

    .pt-xxl-6,
    .py-xxl-6 {
        padding-top: 1.5rem !important;
    }

    .pr-xxl-6,
    .px-xxl-6 {
        padding-right: 1.5rem !important;
    }

    .pb-xxl-6,
    .py-xxl-6 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xxl-6,
    .px-xxl-6 {
        padding-left: 1.5rem !important;
    }

    .p-xxl-7 {
        padding: 1.75rem !important;
    }

    .pt-xxl-7,
    .py-xxl-7 {
        padding-top: 1.75rem !important;
    }

    .pr-xxl-7,
    .px-xxl-7 {
        padding-right: 1.75rem !important;
    }

    .pb-xxl-7,
    .py-xxl-7 {
        padding-bottom: 1.75rem !important;
    }

    .pl-xxl-7,
    .px-xxl-7 {
        padding-left: 1.75rem !important;
    }

    .p-xxl-8 {
        padding: 2rem !important;
    }

    .pt-xxl-8,
    .py-xxl-8 {
        padding-top: 2rem !important;
    }

    .pr-xxl-8,
    .px-xxl-8 {
        padding-right: 2rem !important;
    }

    .pb-xxl-8,
    .py-xxl-8 {
        padding-bottom: 2rem !important;
    }

    .pl-xxl-8,
    .px-xxl-8 {
        padding-left: 2rem !important;
    }

    .p-xxl-9 {
        padding: 2.25rem !important;
    }

    .pt-xxl-9,
    .py-xxl-9 {
        padding-top: 2.25rem !important;
    }

    .pr-xxl-9,
    .px-xxl-9 {
        padding-right: 2.25rem !important;
    }

    .pb-xxl-9,
    .py-xxl-9 {
        padding-bottom: 2.25rem !important;
    }

    .pl-xxl-9,
    .px-xxl-9 {
        padding-left: 2.25rem !important;
    }

    .p-xxl-10 {
        padding: 2.5rem !important;
    }

    .pt-xxl-10,
    .py-xxl-10 {
        padding-top: 2.5rem !important;
    }

    .pr-xxl-10,
    .px-xxl-10 {
        padding-right: 2.5rem !important;
    }

    .pb-xxl-10,
    .py-xxl-10 {
        padding-bottom: 2.5rem !important;
    }

    .pl-xxl-10,
    .px-xxl-10 {
        padding-left: 2.5rem !important;
    }

    .p-xxl-11 {
        padding: 2.75rem !important;
    }

    .pt-xxl-11,
    .py-xxl-11 {
        padding-top: 2.75rem !important;
    }

    .pr-xxl-11,
    .px-xxl-11 {
        padding-right: 2.75rem !important;
    }

    .pb-xxl-11,
    .py-xxl-11 {
        padding-bottom: 2.75rem !important;
    }

    .pl-xxl-11,
    .px-xxl-11 {
        padding-left: 2.75rem !important;
    }

    .p-xxl-12 {
        padding: 3rem !important;
    }

    .pt-xxl-12,
    .py-xxl-12 {
        padding-top: 3rem !important;
    }

    .pr-xxl-12,
    .px-xxl-12 {
        padding-right: 3rem !important;
    }

    .pb-xxl-12,
    .py-xxl-12 {
        padding-bottom: 3rem !important;
    }

    .pl-xxl-12,
    .px-xxl-12 {
        padding-left: 3rem !important;
    }

    .p-xxl-13 {
        padding: 3.25rem !important;
    }

    .pt-xxl-13,
    .py-xxl-13 {
        padding-top: 3.25rem !important;
    }

    .pr-xxl-13,
    .px-xxl-13 {
        padding-right: 3.25rem !important;
    }

    .pb-xxl-13,
    .py-xxl-13 {
        padding-bottom: 3.25rem !important;
    }

    .pl-xxl-13,
    .px-xxl-13 {
        padding-left: 3.25rem !important;
    }

    .p-xxl-14 {
        padding: 3.5rem !important;
    }

    .pt-xxl-14,
    .py-xxl-14 {
        padding-top: 3.5rem !important;
    }

    .pr-xxl-14,
    .px-xxl-14 {
        padding-right: 3.5rem !important;
    }

    .pb-xxl-14,
    .py-xxl-14 {
        padding-bottom: 3.5rem !important;
    }

    .pl-xxl-14,
    .px-xxl-14 {
        padding-left: 3.5rem !important;
    }

    .p-xxl-15 {
        padding: 3.75rem !important;
    }

    .pt-xxl-15,
    .py-xxl-15 {
        padding-top: 3.75rem !important;
    }

    .pr-xxl-15,
    .px-xxl-15 {
        padding-right: 3.75rem !important;
    }

    .pb-xxl-15,
    .py-xxl-15 {
        padding-bottom: 3.75rem !important;
    }

    .pl-xxl-15,
    .px-xxl-15 {
        padding-left: 3.75rem !important;
    }

    .p-xxl-16 {
        padding: 4rem !important;
    }

    .pt-xxl-16,
    .py-xxl-16 {
        padding-top: 4rem !important;
    }

    .pr-xxl-16,
    .px-xxl-16 {
        padding-right: 4rem !important;
    }

    .pb-xxl-16,
    .py-xxl-16 {
        padding-bottom: 4rem !important;
    }

    .pl-xxl-16,
    .px-xxl-16 {
        padding-left: 4rem !important;
    }

    .p-xxl-17 {
        padding: 4.25rem !important;
    }

    .pt-xxl-17,
    .py-xxl-17 {
        padding-top: 4.25rem !important;
    }

    .pr-xxl-17,
    .px-xxl-17 {
        padding-right: 4.25rem !important;
    }

    .pb-xxl-17,
    .py-xxl-17 {
        padding-bottom: 4.25rem !important;
    }

    .pl-xxl-17,
    .px-xxl-17 {
        padding-left: 4.25rem !important;
    }

    .p-xxl-18 {
        padding: 4.5rem !important;
    }

    .pt-xxl-18,
    .py-xxl-18 {
        padding-top: 4.5rem !important;
    }

    .pr-xxl-18,
    .px-xxl-18 {
        padding-right: 4.5rem !important;
    }

    .pb-xxl-18,
    .py-xxl-18 {
        padding-bottom: 4.5rem !important;
    }

    .pl-xxl-18,
    .px-xxl-18 {
        padding-left: 4.5rem !important;
    }

    .p-xxl-19 {
        padding: 4.75rem !important;
    }

    .pt-xxl-19,
    .py-xxl-19 {
        padding-top: 4.75rem !important;
    }

    .pr-xxl-19,
    .px-xxl-19 {
        padding-right: 4.75rem !important;
    }

    .pb-xxl-19,
    .py-xxl-19 {
        padding-bottom: 4.75rem !important;
    }

    .pl-xxl-19,
    .px-xxl-19 {
        padding-left: 4.75rem !important;
    }

    .p-xxl-20 {
        padding: 5rem !important;
    }

    .pt-xxl-20,
    .py-xxl-20 {
        padding-top: 5rem !important;
    }

    .pr-xxl-20,
    .px-xxl-20 {
        padding-right: 5rem !important;
    }

    .pb-xxl-20,
    .py-xxl-20 {
        padding-bottom: 5rem !important;
    }

    .pl-xxl-20,
    .px-xxl-20 {
        padding-left: 5rem !important;
    }

    .p-xxl-21 {
        padding: 5.25rem !important;
    }

    .pt-xxl-21,
    .py-xxl-21 {
        padding-top: 5.25rem !important;
    }

    .pr-xxl-21,
    .px-xxl-21 {
        padding-right: 5.25rem !important;
    }

    .pb-xxl-21,
    .py-xxl-21 {
        padding-bottom: 5.25rem !important;
    }

    .pl-xxl-21,
    .px-xxl-21 {
        padding-left: 5.25rem !important;
    }

    .p-xxl-22 {
        padding: 5.5rem !important;
    }

    .pt-xxl-22,
    .py-xxl-22 {
        padding-top: 5.5rem !important;
    }

    .pr-xxl-22,
    .px-xxl-22 {
        padding-right: 5.5rem !important;
    }

    .pb-xxl-22,
    .py-xxl-22 {
        padding-bottom: 5.5rem !important;
    }

    .pl-xxl-22,
    .px-xxl-22 {
        padding-left: 5.5rem !important;
    }

    .p-xxl-23 {
        padding: 5.75rem !important;
    }

    .pt-xxl-23,
    .py-xxl-23 {
        padding-top: 5.75rem !important;
    }

    .pr-xxl-23,
    .px-xxl-23 {
        padding-right: 5.75rem !important;
    }

    .pb-xxl-23,
    .py-xxl-23 {
        padding-bottom: 5.75rem !important;
    }

    .pl-xxl-23,
    .px-xxl-23 {
        padding-left: 5.75rem !important;
    }

    .p-xxl-24 {
        padding: 6rem !important;
    }

    .pt-xxl-24,
    .py-xxl-24 {
        padding-top: 6rem !important;
    }

    .pr-xxl-24,
    .px-xxl-24 {
        padding-right: 6rem !important;
    }

    .pb-xxl-24,
    .py-xxl-24 {
        padding-bottom: 6rem !important;
    }

    .pl-xxl-24,
    .px-xxl-24 {
        padding-left: 6rem !important;
    }

    .p-xxl-25 {
        padding: 6.25rem !important;
    }

    .pt-xxl-25,
    .py-xxl-25 {
        padding-top: 6.25rem !important;
    }

    .pr-xxl-25,
    .px-xxl-25 {
        padding-right: 6.25rem !important;
    }

    .pb-xxl-25,
    .py-xxl-25 {
        padding-bottom: 6.25rem !important;
    }

    .pl-xxl-25,
    .px-xxl-25 {
        padding-left: 6.25rem !important;
    }

    .p-xxl-26 {
        padding: 6.5rem !important;
    }

    .pt-xxl-26,
    .py-xxl-26 {
        padding-top: 6.5rem !important;
    }

    .pr-xxl-26,
    .px-xxl-26 {
        padding-right: 6.5rem !important;
    }

    .pb-xxl-26,
    .py-xxl-26 {
        padding-bottom: 6.5rem !important;
    }

    .pl-xxl-26,
    .px-xxl-26 {
        padding-left: 6.5rem !important;
    }

    .p-xxl-27 {
        padding: 6.75rem !important;
    }

    .pt-xxl-27,
    .py-xxl-27 {
        padding-top: 6.75rem !important;
    }

    .pr-xxl-27,
    .px-xxl-27 {
        padding-right: 6.75rem !important;
    }

    .pb-xxl-27,
    .py-xxl-27 {
        padding-bottom: 6.75rem !important;
    }

    .pl-xxl-27,
    .px-xxl-27 {
        padding-left: 6.75rem !important;
    }

    .p-xxl-38 {
        padding: 7rem !important;
    }

    .pt-xxl-38,
    .py-xxl-38 {
        padding-top: 7rem !important;
    }

    .pr-xxl-38,
    .px-xxl-38 {
        padding-right: 7rem !important;
    }

    .pb-xxl-38,
    .py-xxl-38 {
        padding-bottom: 7rem !important;
    }

    .pl-xxl-38,
    .px-xxl-38 {
        padding-left: 7rem !important;
    }

    .p-xxl-29 {
        padding: 7.25rem !important;
    }

    .pt-xxl-29,
    .py-xxl-29 {
        padding-top: 7.25rem !important;
    }

    .pr-xxl-29,
    .px-xxl-29 {
        padding-right: 7.25rem !important;
    }

    .pb-xxl-29,
    .py-xxl-29 {
        padding-bottom: 7.25rem !important;
    }

    .pl-xxl-29,
    .px-xxl-29 {
        padding-left: 7.25rem !important;
    }

    .p-xxl-30 {
        padding: 7.5rem !important;
    }

    .pt-xxl-30,
    .py-xxl-30 {
        padding-top: 7.5rem !important;
    }

    .pr-xxl-30,
    .px-xxl-30 {
        padding-right: 7.5rem !important;
    }

    .pb-xxl-30,
    .py-xxl-30 {
        padding-bottom: 7.5rem !important;
    }

    .pl-xxl-30,
    .px-xxl-30 {
        padding-left: 7.5rem !important;
    }

    .p-xxl-31 {
        padding: 7.75rem !important;
    }

    .pt-xxl-31,
    .py-xxl-31 {
        padding-top: 7.75rem !important;
    }

    .pr-xxl-31,
    .px-xxl-31 {
        padding-right: 7.75rem !important;
    }

    .pb-xxl-31,
    .py-xxl-31 {
        padding-bottom: 7.75rem !important;
    }

    .pl-xxl-31,
    .px-xxl-31 {
        padding-left: 7.75rem !important;
    }

    .p-xxl-32 {
        padding: 8rem !important;
    }

    .pt-xxl-32,
    .py-xxl-32 {
        padding-top: 8rem !important;
    }

    .pr-xxl-32,
    .px-xxl-32 {
        padding-right: 8rem !important;
    }

    .pb-xxl-32,
    .py-xxl-32 {
        padding-bottom: 8rem !important;
    }

    .pl-xxl-32,
    .px-xxl-32 {
        padding-left: 8rem !important;
    }

    .p-xxl-33 {
        padding: 8.25rem !important;
    }

    .pt-xxl-33,
    .py-xxl-33 {
        padding-top: 8.25rem !important;
    }

    .pr-xxl-33,
    .px-xxl-33 {
        padding-right: 8.25rem !important;
    }

    .pb-xxl-33,
    .py-xxl-33 {
        padding-bottom: 8.25rem !important;
    }

    .pl-xxl-33,
    .px-xxl-33 {
        padding-left: 8.25rem !important;
    }

    .p-xxl-34 {
        padding: 8.5rem !important;
    }

    .pt-xxl-34,
    .py-xxl-34 {
        padding-top: 8.5rem !important;
    }

    .pr-xxl-34,
    .px-xxl-34 {
        padding-right: 8.5rem !important;
    }

    .pb-xxl-34,
    .py-xxl-34 {
        padding-bottom: 8.5rem !important;
    }

    .pl-xxl-34,
    .px-xxl-34 {
        padding-left: 8.5rem !important;
    }

    .p-xxl-35 {
        padding: 8.75rem !important;
    }

    .pt-xxl-35,
    .py-xxl-35 {
        padding-top: 8.75rem !important;
    }

    .pr-xxl-35,
    .px-xxl-35 {
        padding-right: 8.75rem !important;
    }

    .pb-xxl-35,
    .py-xxl-35 {
        padding-bottom: 8.75rem !important;
    }

    .pl-xxl-35,
    .px-xxl-35 {
        padding-left: 8.75rem !important;
    }

    .p-xxl-36 {
        padding: 9rem !important;
    }

    .pt-xxl-36,
    .py-xxl-36 {
        padding-top: 9rem !important;
    }

    .pr-xxl-36,
    .px-xxl-36 {
        padding-right: 9rem !important;
    }

    .pb-xxl-36,
    .py-xxl-36 {
        padding-bottom: 9rem !important;
    }

    .pl-xxl-36,
    .px-xxl-36 {
        padding-left: 9rem !important;
    }

    .p-xxl-37 {
        padding: 9.25rem !important;
    }

    .pt-xxl-37,
    .py-xxl-37 {
        padding-top: 9.25rem !important;
    }

    .pr-xxl-37,
    .px-xxl-37 {
        padding-right: 9.25rem !important;
    }

    .pb-xxl-37,
    .py-xxl-37 {
        padding-bottom: 9.25rem !important;
    }

    .pl-xxl-37,
    .px-xxl-37 {
        padding-left: 9.25rem !important;
    }

    .p-xxl-48 {
        padding: 9.5rem !important;
    }

    .pt-xxl-48,
    .py-xxl-48 {
        padding-top: 9.5rem !important;
    }

    .pr-xxl-48,
    .px-xxl-48 {
        padding-right: 9.5rem !important;
    }

    .pb-xxl-48,
    .py-xxl-48 {
        padding-bottom: 9.5rem !important;
    }

    .pl-xxl-48,
    .px-xxl-48 {
        padding-left: 9.5rem !important;
    }

    .p-xxl-39 {
        padding: 9.75rem !important;
    }

    .pt-xxl-39,
    .py-xxl-39 {
        padding-top: 9.75rem !important;
    }

    .pr-xxl-39,
    .px-xxl-39 {
        padding-right: 9.75rem !important;
    }

    .pb-xxl-39,
    .py-xxl-39 {
        padding-bottom: 9.75rem !important;
    }

    .pl-xxl-39,
    .px-xxl-39 {
        padding-left: 9.75rem !important;
    }

    .p-xxl-40 {
        padding: 10rem !important;
    }

    .pt-xxl-40,
    .py-xxl-40 {
        padding-top: 10rem !important;
    }

    .pr-xxl-40,
    .px-xxl-40 {
        padding-right: 10rem !important;
    }

    .pb-xxl-40,
    .py-xxl-40 {
        padding-bottom: 10rem !important;
    }

    .pl-xxl-40,
    .px-xxl-40 {
        padding-left: 10rem !important;
    }

    .m-xxl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xxl-n1,
    .my-xxl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xxl-n1,
    .mx-xxl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xxl-n1,
    .my-xxl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xxl-n1,
    .mx-xxl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xxl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xxl-n2,
    .my-xxl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xxl-n2,
    .mx-xxl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xxl-n2,
    .my-xxl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xxl-n2,
    .mx-xxl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xxl-n3 {
        margin: -0.75rem !important;
    }

    .mt-xxl-n3,
    .my-xxl-n3 {
        margin-top: -0.75rem !important;
    }

    .mr-xxl-n3,
    .mx-xxl-n3 {
        margin-right: -0.75rem !important;
    }

    .mb-xxl-n3,
    .my-xxl-n3 {
        margin-bottom: -0.75rem !important;
    }

    .ml-xxl-n3,
    .mx-xxl-n3 {
        margin-left: -0.75rem !important;
    }

    .m-xxl-n4 {
        margin: -1rem !important;
    }

    .mt-xxl-n4,
    .my-xxl-n4 {
        margin-top: -1rem !important;
    }

    .mr-xxl-n4,
    .mx-xxl-n4 {
        margin-right: -1rem !important;
    }

    .mb-xxl-n4,
    .my-xxl-n4 {
        margin-bottom: -1rem !important;
    }

    .ml-xxl-n4,
    .mx-xxl-n4 {
        margin-left: -1rem !important;
    }

    .m-xxl-n5 {
        margin: -1.25rem !important;
    }

    .mt-xxl-n5,
    .my-xxl-n5 {
        margin-top: -1.25rem !important;
    }

    .mr-xxl-n5,
    .mx-xxl-n5 {
        margin-right: -1.25rem !important;
    }

    .mb-xxl-n5,
    .my-xxl-n5 {
        margin-bottom: -1.25rem !important;
    }

    .ml-xxl-n5,
    .mx-xxl-n5 {
        margin-left: -1.25rem !important;
    }

    .m-xxl-n6 {
        margin: -1.5rem !important;
    }

    .mt-xxl-n6,
    .my-xxl-n6 {
        margin-top: -1.5rem !important;
    }

    .mr-xxl-n6,
    .mx-xxl-n6 {
        margin-right: -1.5rem !important;
    }

    .mb-xxl-n6,
    .my-xxl-n6 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xxl-n6,
    .mx-xxl-n6 {
        margin-left: -1.5rem !important;
    }

    .m-xxl-n7 {
        margin: -1.75rem !important;
    }

    .mt-xxl-n7,
    .my-xxl-n7 {
        margin-top: -1.75rem !important;
    }

    .mr-xxl-n7,
    .mx-xxl-n7 {
        margin-right: -1.75rem !important;
    }

    .mb-xxl-n7,
    .my-xxl-n7 {
        margin-bottom: -1.75rem !important;
    }

    .ml-xxl-n7,
    .mx-xxl-n7 {
        margin-left: -1.75rem !important;
    }

    .m-xxl-n8 {
        margin: -2rem !important;
    }

    .mt-xxl-n8,
    .my-xxl-n8 {
        margin-top: -2rem !important;
    }

    .mr-xxl-n8,
    .mx-xxl-n8 {
        margin-right: -2rem !important;
    }

    .mb-xxl-n8,
    .my-xxl-n8 {
        margin-bottom: -2rem !important;
    }

    .ml-xxl-n8,
    .mx-xxl-n8 {
        margin-left: -2rem !important;
    }

    .m-xxl-n9 {
        margin: -2.25rem !important;
    }

    .mt-xxl-n9,
    .my-xxl-n9 {
        margin-top: -2.25rem !important;
    }

    .mr-xxl-n9,
    .mx-xxl-n9 {
        margin-right: -2.25rem !important;
    }

    .mb-xxl-n9,
    .my-xxl-n9 {
        margin-bottom: -2.25rem !important;
    }

    .ml-xxl-n9,
    .mx-xxl-n9 {
        margin-left: -2.25rem !important;
    }

    .m-xxl-n10 {
        margin: -2.5rem !important;
    }

    .mt-xxl-n10,
    .my-xxl-n10 {
        margin-top: -2.5rem !important;
    }

    .mr-xxl-n10,
    .mx-xxl-n10 {
        margin-right: -2.5rem !important;
    }

    .mb-xxl-n10,
    .my-xxl-n10 {
        margin-bottom: -2.5rem !important;
    }

    .ml-xxl-n10,
    .mx-xxl-n10 {
        margin-left: -2.5rem !important;
    }

    .m-xxl-n11 {
        margin: -2.75rem !important;
    }

    .mt-xxl-n11,
    .my-xxl-n11 {
        margin-top: -2.75rem !important;
    }

    .mr-xxl-n11,
    .mx-xxl-n11 {
        margin-right: -2.75rem !important;
    }

    .mb-xxl-n11,
    .my-xxl-n11 {
        margin-bottom: -2.75rem !important;
    }

    .ml-xxl-n11,
    .mx-xxl-n11 {
        margin-left: -2.75rem !important;
    }

    .m-xxl-n12 {
        margin: -3rem !important;
    }

    .mt-xxl-n12,
    .my-xxl-n12 {
        margin-top: -3rem !important;
    }

    .mr-xxl-n12,
    .mx-xxl-n12 {
        margin-right: -3rem !important;
    }

    .mb-xxl-n12,
    .my-xxl-n12 {
        margin-bottom: -3rem !important;
    }

    .ml-xxl-n12,
    .mx-xxl-n12 {
        margin-left: -3rem !important;
    }

    .m-xxl-n13 {
        margin: -3.25rem !important;
    }

    .mt-xxl-n13,
    .my-xxl-n13 {
        margin-top: -3.25rem !important;
    }

    .mr-xxl-n13,
    .mx-xxl-n13 {
        margin-right: -3.25rem !important;
    }

    .mb-xxl-n13,
    .my-xxl-n13 {
        margin-bottom: -3.25rem !important;
    }

    .ml-xxl-n13,
    .mx-xxl-n13 {
        margin-left: -3.25rem !important;
    }

    .m-xxl-n14 {
        margin: -3.5rem !important;
    }

    .mt-xxl-n14,
    .my-xxl-n14 {
        margin-top: -3.5rem !important;
    }

    .mr-xxl-n14,
    .mx-xxl-n14 {
        margin-right: -3.5rem !important;
    }

    .mb-xxl-n14,
    .my-xxl-n14 {
        margin-bottom: -3.5rem !important;
    }

    .ml-xxl-n14,
    .mx-xxl-n14 {
        margin-left: -3.5rem !important;
    }

    .m-xxl-n15 {
        margin: -3.75rem !important;
    }

    .mt-xxl-n15,
    .my-xxl-n15 {
        margin-top: -3.75rem !important;
    }

    .mr-xxl-n15,
    .mx-xxl-n15 {
        margin-right: -3.75rem !important;
    }

    .mb-xxl-n15,
    .my-xxl-n15 {
        margin-bottom: -3.75rem !important;
    }

    .ml-xxl-n15,
    .mx-xxl-n15 {
        margin-left: -3.75rem !important;
    }

    .m-xxl-n16 {
        margin: -4rem !important;
    }

    .mt-xxl-n16,
    .my-xxl-n16 {
        margin-top: -4rem !important;
    }

    .mr-xxl-n16,
    .mx-xxl-n16 {
        margin-right: -4rem !important;
    }

    .mb-xxl-n16,
    .my-xxl-n16 {
        margin-bottom: -4rem !important;
    }

    .ml-xxl-n16,
    .mx-xxl-n16 {
        margin-left: -4rem !important;
    }

    .m-xxl-n17 {
        margin: -4.25rem !important;
    }

    .mt-xxl-n17,
    .my-xxl-n17 {
        margin-top: -4.25rem !important;
    }

    .mr-xxl-n17,
    .mx-xxl-n17 {
        margin-right: -4.25rem !important;
    }

    .mb-xxl-n17,
    .my-xxl-n17 {
        margin-bottom: -4.25rem !important;
    }

    .ml-xxl-n17,
    .mx-xxl-n17 {
        margin-left: -4.25rem !important;
    }

    .m-xxl-n18 {
        margin: -4.5rem !important;
    }

    .mt-xxl-n18,
    .my-xxl-n18 {
        margin-top: -4.5rem !important;
    }

    .mr-xxl-n18,
    .mx-xxl-n18 {
        margin-right: -4.5rem !important;
    }

    .mb-xxl-n18,
    .my-xxl-n18 {
        margin-bottom: -4.5rem !important;
    }

    .ml-xxl-n18,
    .mx-xxl-n18 {
        margin-left: -4.5rem !important;
    }

    .m-xxl-n19 {
        margin: -4.75rem !important;
    }

    .mt-xxl-n19,
    .my-xxl-n19 {
        margin-top: -4.75rem !important;
    }

    .mr-xxl-n19,
    .mx-xxl-n19 {
        margin-right: -4.75rem !important;
    }

    .mb-xxl-n19,
    .my-xxl-n19 {
        margin-bottom: -4.75rem !important;
    }

    .ml-xxl-n19,
    .mx-xxl-n19 {
        margin-left: -4.75rem !important;
    }

    .m-xxl-n20 {
        margin: -5rem !important;
    }

    .mt-xxl-n20,
    .my-xxl-n20 {
        margin-top: -5rem !important;
    }

    .mr-xxl-n20,
    .mx-xxl-n20 {
        margin-right: -5rem !important;
    }

    .mb-xxl-n20,
    .my-xxl-n20 {
        margin-bottom: -5rem !important;
    }

    .ml-xxl-n20,
    .mx-xxl-n20 {
        margin-left: -5rem !important;
    }

    .m-xxl-n21 {
        margin: -5.25rem !important;
    }

    .mt-xxl-n21,
    .my-xxl-n21 {
        margin-top: -5.25rem !important;
    }

    .mr-xxl-n21,
    .mx-xxl-n21 {
        margin-right: -5.25rem !important;
    }

    .mb-xxl-n21,
    .my-xxl-n21 {
        margin-bottom: -5.25rem !important;
    }

    .ml-xxl-n21,
    .mx-xxl-n21 {
        margin-left: -5.25rem !important;
    }

    .m-xxl-n22 {
        margin: -5.5rem !important;
    }

    .mt-xxl-n22,
    .my-xxl-n22 {
        margin-top: -5.5rem !important;
    }

    .mr-xxl-n22,
    .mx-xxl-n22 {
        margin-right: -5.5rem !important;
    }

    .mb-xxl-n22,
    .my-xxl-n22 {
        margin-bottom: -5.5rem !important;
    }

    .ml-xxl-n22,
    .mx-xxl-n22 {
        margin-left: -5.5rem !important;
    }

    .m-xxl-n23 {
        margin: -5.75rem !important;
    }

    .mt-xxl-n23,
    .my-xxl-n23 {
        margin-top: -5.75rem !important;
    }

    .mr-xxl-n23,
    .mx-xxl-n23 {
        margin-right: -5.75rem !important;
    }

    .mb-xxl-n23,
    .my-xxl-n23 {
        margin-bottom: -5.75rem !important;
    }

    .ml-xxl-n23,
    .mx-xxl-n23 {
        margin-left: -5.75rem !important;
    }

    .m-xxl-n24 {
        margin: -6rem !important;
    }

    .mt-xxl-n24,
    .my-xxl-n24 {
        margin-top: -6rem !important;
    }

    .mr-xxl-n24,
    .mx-xxl-n24 {
        margin-right: -6rem !important;
    }

    .mb-xxl-n24,
    .my-xxl-n24 {
        margin-bottom: -6rem !important;
    }

    .ml-xxl-n24,
    .mx-xxl-n24 {
        margin-left: -6rem !important;
    }

    .m-xxl-n25 {
        margin: -6.25rem !important;
    }

    .mt-xxl-n25,
    .my-xxl-n25 {
        margin-top: -6.25rem !important;
    }

    .mr-xxl-n25,
    .mx-xxl-n25 {
        margin-right: -6.25rem !important;
    }

    .mb-xxl-n25,
    .my-xxl-n25 {
        margin-bottom: -6.25rem !important;
    }

    .ml-xxl-n25,
    .mx-xxl-n25 {
        margin-left: -6.25rem !important;
    }

    .m-xxl-n26 {
        margin: -6.5rem !important;
    }

    .mt-xxl-n26,
    .my-xxl-n26 {
        margin-top: -6.5rem !important;
    }

    .mr-xxl-n26,
    .mx-xxl-n26 {
        margin-right: -6.5rem !important;
    }

    .mb-xxl-n26,
    .my-xxl-n26 {
        margin-bottom: -6.5rem !important;
    }

    .ml-xxl-n26,
    .mx-xxl-n26 {
        margin-left: -6.5rem !important;
    }

    .m-xxl-n27 {
        margin: -6.75rem !important;
    }

    .mt-xxl-n27,
    .my-xxl-n27 {
        margin-top: -6.75rem !important;
    }

    .mr-xxl-n27,
    .mx-xxl-n27 {
        margin-right: -6.75rem !important;
    }

    .mb-xxl-n27,
    .my-xxl-n27 {
        margin-bottom: -6.75rem !important;
    }

    .ml-xxl-n27,
    .mx-xxl-n27 {
        margin-left: -6.75rem !important;
    }

    .m-xxl-n38 {
        margin: -7rem !important;
    }

    .mt-xxl-n38,
    .my-xxl-n38 {
        margin-top: -7rem !important;
    }

    .mr-xxl-n38,
    .mx-xxl-n38 {
        margin-right: -7rem !important;
    }

    .mb-xxl-n38,
    .my-xxl-n38 {
        margin-bottom: -7rem !important;
    }

    .ml-xxl-n38,
    .mx-xxl-n38 {
        margin-left: -7rem !important;
    }

    .m-xxl-n29 {
        margin: -7.25rem !important;
    }

    .mt-xxl-n29,
    .my-xxl-n29 {
        margin-top: -7.25rem !important;
    }

    .mr-xxl-n29,
    .mx-xxl-n29 {
        margin-right: -7.25rem !important;
    }

    .mb-xxl-n29,
    .my-xxl-n29 {
        margin-bottom: -7.25rem !important;
    }

    .ml-xxl-n29,
    .mx-xxl-n29 {
        margin-left: -7.25rem !important;
    }

    .m-xxl-n30 {
        margin: -7.5rem !important;
    }

    .mt-xxl-n30,
    .my-xxl-n30 {
        margin-top: -7.5rem !important;
    }

    .mr-xxl-n30,
    .mx-xxl-n30 {
        margin-right: -7.5rem !important;
    }

    .mb-xxl-n30,
    .my-xxl-n30 {
        margin-bottom: -7.5rem !important;
    }

    .ml-xxl-n30,
    .mx-xxl-n30 {
        margin-left: -7.5rem !important;
    }

    .m-xxl-n31 {
        margin: -7.75rem !important;
    }

    .mt-xxl-n31,
    .my-xxl-n31 {
        margin-top: -7.75rem !important;
    }

    .mr-xxl-n31,
    .mx-xxl-n31 {
        margin-right: -7.75rem !important;
    }

    .mb-xxl-n31,
    .my-xxl-n31 {
        margin-bottom: -7.75rem !important;
    }

    .ml-xxl-n31,
    .mx-xxl-n31 {
        margin-left: -7.75rem !important;
    }

    .m-xxl-n32 {
        margin: -8rem !important;
    }

    .mt-xxl-n32,
    .my-xxl-n32 {
        margin-top: -8rem !important;
    }

    .mr-xxl-n32,
    .mx-xxl-n32 {
        margin-right: -8rem !important;
    }

    .mb-xxl-n32,
    .my-xxl-n32 {
        margin-bottom: -8rem !important;
    }

    .ml-xxl-n32,
    .mx-xxl-n32 {
        margin-left: -8rem !important;
    }

    .m-xxl-n33 {
        margin: -8.25rem !important;
    }

    .mt-xxl-n33,
    .my-xxl-n33 {
        margin-top: -8.25rem !important;
    }

    .mr-xxl-n33,
    .mx-xxl-n33 {
        margin-right: -8.25rem !important;
    }

    .mb-xxl-n33,
    .my-xxl-n33 {
        margin-bottom: -8.25rem !important;
    }

    .ml-xxl-n33,
    .mx-xxl-n33 {
        margin-left: -8.25rem !important;
    }

    .m-xxl-n34 {
        margin: -8.5rem !important;
    }

    .mt-xxl-n34,
    .my-xxl-n34 {
        margin-top: -8.5rem !important;
    }

    .mr-xxl-n34,
    .mx-xxl-n34 {
        margin-right: -8.5rem !important;
    }

    .mb-xxl-n34,
    .my-xxl-n34 {
        margin-bottom: -8.5rem !important;
    }

    .ml-xxl-n34,
    .mx-xxl-n34 {
        margin-left: -8.5rem !important;
    }

    .m-xxl-n35 {
        margin: -8.75rem !important;
    }

    .mt-xxl-n35,
    .my-xxl-n35 {
        margin-top: -8.75rem !important;
    }

    .mr-xxl-n35,
    .mx-xxl-n35 {
        margin-right: -8.75rem !important;
    }

    .mb-xxl-n35,
    .my-xxl-n35 {
        margin-bottom: -8.75rem !important;
    }

    .ml-xxl-n35,
    .mx-xxl-n35 {
        margin-left: -8.75rem !important;
    }

    .m-xxl-n36 {
        margin: -9rem !important;
    }

    .mt-xxl-n36,
    .my-xxl-n36 {
        margin-top: -9rem !important;
    }

    .mr-xxl-n36,
    .mx-xxl-n36 {
        margin-right: -9rem !important;
    }

    .mb-xxl-n36,
    .my-xxl-n36 {
        margin-bottom: -9rem !important;
    }

    .ml-xxl-n36,
    .mx-xxl-n36 {
        margin-left: -9rem !important;
    }

    .m-xxl-n37 {
        margin: -9.25rem !important;
    }

    .mt-xxl-n37,
    .my-xxl-n37 {
        margin-top: -9.25rem !important;
    }

    .mr-xxl-n37,
    .mx-xxl-n37 {
        margin-right: -9.25rem !important;
    }

    .mb-xxl-n37,
    .my-xxl-n37 {
        margin-bottom: -9.25rem !important;
    }

    .ml-xxl-n37,
    .mx-xxl-n37 {
        margin-left: -9.25rem !important;
    }

    .m-xxl-n48 {
        margin: -9.5rem !important;
    }

    .mt-xxl-n48,
    .my-xxl-n48 {
        margin-top: -9.5rem !important;
    }

    .mr-xxl-n48,
    .mx-xxl-n48 {
        margin-right: -9.5rem !important;
    }

    .mb-xxl-n48,
    .my-xxl-n48 {
        margin-bottom: -9.5rem !important;
    }

    .ml-xxl-n48,
    .mx-xxl-n48 {
        margin-left: -9.5rem !important;
    }

    .m-xxl-n39 {
        margin: -9.75rem !important;
    }

    .mt-xxl-n39,
    .my-xxl-n39 {
        margin-top: -9.75rem !important;
    }

    .mr-xxl-n39,
    .mx-xxl-n39 {
        margin-right: -9.75rem !important;
    }

    .mb-xxl-n39,
    .my-xxl-n39 {
        margin-bottom: -9.75rem !important;
    }

    .ml-xxl-n39,
    .mx-xxl-n39 {
        margin-left: -9.75rem !important;
    }

    .m-xxl-n40 {
        margin: -10rem !important;
    }

    .mt-xxl-n40,
    .my-xxl-n40 {
        margin-top: -10rem !important;
    }

    .mr-xxl-n40,
    .mx-xxl-n40 {
        margin-right: -10rem !important;
    }

    .mb-xxl-n40,
    .my-xxl-n40 {
        margin-bottom: -10rem !important;
    }

    .ml-xxl-n40,
    .mx-xxl-n40 {
        margin-left: -10rem !important;
    }

    .m-xxl-auto {
        margin: auto !important;
    }

    .mt-xxl-auto,
    .my-xxl-auto {
        margin-top: auto !important;
    }

    .mr-xxl-auto,
    .mx-xxl-auto {
        margin-right: auto !important;
    }

    .mb-xxl-auto,
    .my-xxl-auto {
        margin-bottom: auto !important;
    }

    .ml-xxl-auto,
    .mx-xxl-auto {
        margin-left: auto !important;
    }
}

.px-3px {
    padding-left: 3px !important;
    padding-right: 3px !important;
}