/*          WIDTH  %        */
.w-10 {
    width: 10%;
}
.w-20{
    width: 20%;
}
.w-30{
    width: 30%;
}
.w-33{
    width: 33%;
}
.w-40{
    width: 40%;
}
.w-65{
    width: 65%;
}
.w-70{
    width: 70%;
}
.w-80{
    width: 80%;
}
.w-90{
    width: 90%;
}
.w-95{
    width: 95%;
}
.w-28rem{
    width: 28rem;
}
.w-40rem{
    width: 40rem!important;
}
.w-fit-content {
    width: fit-content;
}

/*         MIN-WIDTH          */
.min-width-50px{
    min-width: 50px;
}
.min-width-55px{
    min-width: 55px;
}
.min-width-65px{
    min-width: 65px;
}
.min-width-95px{
    min-width: 95px;
}
.min-width-70px{
    min-width: 70px;
}
.min-width-75px{
    min-width: 75px;
}
.min-width-80px{
    min-width: 80px;
}
.min-width-100px{
    min-width: 100px;
}
.min-width-120px{
    min-width: 120px;
}
.min-width-140px{
    min-width: 140px;
}
.min-width-150px{
    min-width: 150px;
}
.min-width-172px{
    min-width: 172px;
}
.min-width-174px{
    min-width: 174px;
}
.min-width-175px{
    min-width: 175px;
}
.min-width-225px{
    min-width: 225px;
}
/*         MAX-WIDTH          */
.max-height-629px{
    max-height: 629px;
}
.max-height-4rem{
    max-height: 4rem;
}


