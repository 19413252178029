.dataTables_wrapper .dataTable thead th{
    color: #000000 !important;
    
}
table{
    text-transform: uppercase;
}

div.dataTables_wrapper div.dataTables_length select{
    color: #3699FF !important;
    background-color: #E1F0FF !important;
    border: 0 !important;
}

/*.dataTables_wrapper .dataTables_pager .dataTables_length label{
color: #80808F !important;
}

div.dataTables_wrapper div.dataTables_info {
color: #80808F!important;
padding-top:24px;
}

div.dataTables_wrapper div.dataTables_length select{
    color: #3699FF !important;
    background-color: #E1F0FF !important;
    border: 0 !important;
    padding: 4px;
    font-size: 1.08rem;
    line-height: 1.5;
    border-radius: 0.42rem;    
    margin-left: 10px;
    margin-right: 10px;
    text-align-last:center;
}

thead{
    text-align-last:center;
}

/*.dataTables_wrapper table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before{
    padding:5px;
    padding-left:9px;
}*/
thead{
    text-align-last:center;
    white-space: nowrap;
}

.card-toolbar a.btn.btn-icon{
    /* color: #388E3C; */
    /* background-color: #E8F5E9; */
    border-color: transparent;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.card-toolbar a.btn.btn-icon i.flaticon-add{   
    padding-right: 10px;
}

.card-toolbar a.btn.btn-icon i.flaticon-add div{
    padding-right: 0px;
    padding-left: 0px;
}

div.dataTables_wrapper div.dataTables_info {
    color: #80808F!important;
    margin-top:7px;
    }

.logo{
    width: 50px;
    height: 50px;    
}