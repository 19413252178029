.dropdown-crm{
    .btn.btn-primary.dropdown-toggle {
        background-color: transparent;
        border-color: transparent;
        height: 30px;
        width: 30px;
        justify-content: center;
        display: flex;
        align-items: center;
        color: #464E5F;
        &::after{
            margin-left: 0;
            font-weight: 900;
            color: #B5B5C3!important;
        }
        &:active {
            background-color: #F3F6F9!important;
            border-color: transparent!important;
            &::after{
                color: #464E5F!important;
            }
        }
        &:hover {
            background-color: #F3F6F9;
            border-color: transparent;
            &::after{
                color: #464E5F;
            }
        }
    }
    &.show.dropdown{
        .dropdown-menu{
            padding: 0;
            .rh-proveedores, .cancelados, .rp{
                font-weight: 500;
                color: #7f7f7f;
                
            }
            .rh-proveedores{
                &:hover, &.dropdown-item.active{
                    background-color: #fbeff7;
                    color:#e44e93;
                    i {
                        color: #e44e93;
                    }
                }
            }
            .cancelados{
                &:hover, &.dropdown-item.active{
                    background-color: #fee9ec;
                    color:#F64E60;
                    i {
                        color: #F64E60;
                    }
                }
            }
            .rp{
                &:hover, &.dropdown-item.active{
                    background-color: #fff2d9;
                    color:#D08905;
                    i {
                        color: #D08905;
                    }
                }
            }
        }
    }
}
.dropdown-origenes{
    .dropdown-toggle.btn.btn-primary {
        background-color: #f3f6f9;
        color: #80808F;
        border: transparent;
        padding: 2.8px 5.6px;
        width: auto;
        margin: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 10.7px;
        font-weight: 500;
        &::after{
            display: none;
        }
        &:active {
            background-color: #e6e9eb!important;
            color: #80808F!important;
        }
    }
    &.show.dropdown{
        .dropdown-item{
            .label:hover{
                background-color: #e4e6ef;
            }
        }
    }
}
.dropdown-estatus-crm{
    .dropdown-toggle.btn.btn-primary {
        border: transparent;
        padding: 2.8px 5.6px;
        width: auto;
        margin: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 10.7px;
        font-weight: 500;
        &::after{
            display: none;
        }
    }
    &.show.dropdown{
        .dropdown-item{
            .label-light-detenido{
                background-color: #ECEFF1;
                color: #5F6A6A;
                &:hover{
                    background-color: #e2e4e5!important;
                }
            }
            .label-light-proceso{
                background-color: #ebf5ff;
                color: #318ce9;
                &:hover{
                    background-color: #e2f1fd!important;
                }
            }
            .label-light-danger:hover{
                background-color: #fdd7db;
            }
            .label-light-negociacion:hover{
                background-color: #e5dbd1;
            }
            .label-light-primary:hover{
                background-color: #cde5fd;
            }
        }
    }
}

.dropdown-historial-contactos{
    .dropdown-toggle.btn.btn-primary {
        background-color: #f1faff;
        color: #009ef7;
        border: transparent;
        padding: 0.4rem 0.6rem;
        width: auto;
        margin: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        letter-spacing: 0.2px;
        font-size: 1rem;
        &::after{
            color: #009ef7!important;
            font-size: 0.7rem;
        }
        &:active {
            background-color: #009ef7!important;
            color: white!important;
            &::after{
                color: white!important;
            }
        }
    }
    &.show.dropdown{
        .dropdown-menu.show{
            padding: 0;
            .solicitar-cita.dropdown-item, .agendar-cita, .nuevo-contacto, .historial{
                font-weight: 500;
                letter-spacing: 0.2px;
                color:#7f7f7f;
                padding: 6px 15px;
                i{
                    color: #B5B5C3;
                }
            }
            .solicitar-cita.dropdown-item:hover {
                color: #ed7d2b;
                background-color: #ffefe2;
                i {
                    color: #ed7d2b;
                }
            }
            .agendar-cita.dropdown-item:hover {
                color: #1B9386;
                background-color: #ddefed;
                i {
                    color: #1B9386;
                }
            }
            .nuevo-contacto.dropdown-item:hover {
                color: #6C259F;
                background-color: #e7ddf0;
                i {
                    color: #6C259F;
                }
            }
            
            .historial.dropdown-item:hover {
                color: #ffa800;
                background-color: #fff4de;
                i {
                    color: #ffa800;
                }
            }
        }
    }
}
.dropdown-cotizacion{
    .dropdown-toggle.btn.btn-primary {
        background-color: #f1faff;
        color: #009ef7;
        border: transparent;
        padding: 0.4rem 0.6rem;
        width: auto;
        margin: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        letter-spacing: 0.2px;
        font-size: 1rem;
        &::after{
            color: #009ef7!important;
            font-size: 0.7rem;
        }
        &:active {
            background-color: #009ef7!important;
            color: white!important;
            &::after{
                color: white!important;
            }
        }
    }
    &.show.dropdown{
        .dropdown-menu.show{
            padding: 0;
            .new, .filter, .historial{
                font-weight: 500;
                letter-spacing: 0.2px;
                color:#7f7f7f;
                padding: 6px 15px;
                i{
                    color: #B5B5C3;
                }
            }
            .new.dropdown-item:hover {
                color: #1B9386;
                background-color: #ddefed;
                i {
                    color: #1B9386;
                }
            }
            .filter.dropdown-item:hover {
                color: #3699ff;
                background-color: #e1f0ff;
                i {
                    color: #3699ff;
                }
            }
            .historial.dropdown-item:hover {
                color: #ffa800;
                background-color: #fff4de;
                i {
                    color: #ffa800;
                }
            }
        }
    }
}

.dropdown-container {
    button {
        border-color: transparent !important;
        height: 30px;
        width: 30px;
        justify-content: center;
        display: inline-flex;
        align-items: center;

        &:hover {
            background-color: #f3f6f9 !important;
        }

        &:active {
            background-color: #f3f6f9 !important;
        }
    }

    i {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
    }

    &__success {
        button {
            background-color: #E8F5E9 !important;

            i {
                color: #388E3C !important;

                &:hover {
                    color: #388E3C;
                }

                &:active {
                    color: #388E3C;
                }
            }
        }
    }

    &__info {
        button {
            background-color: #f1eaff !important;

            i {
                color: #8950FC !important;

                &:hover {
                    color: #8950FC;
                }

                &:active {
                    color: #8950FC;
                }
            }
        }
    }

    &__danger {
        button {
            background-color: #FFE2E5 !important;
            &::after{
                margin-left: 0px!important;
                color: #F64E60 !important;
            }
            i {
                color: #F64E60 !important;

                &:hover {
                    color: #F64E60;
                }

                &:active {
                    color: #F64E60;
                }
            }
        }
    }
}

.no-arrow{
    &::after{
        content: ''!important;
    }
    button{
        &::after{
            content: ''!important;
        }   
    }
}


.dropdown-table-contratados{
    .dropdown-toggle.btn.btn-primary {
        background-color: #E8F5E9;
        border: transparent;
        height: 30px;
        width: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &::after{
            color: #388E3C !important;
            font-size: 0.7rem;
            margin-left: 0;
            font-weight: 600;
        }
        &:active {
            background-color: #388E3C !important;
            color: white!important;
            &::after{
                color: white!important;
            }
        }
    }
    &.show.dropdown{
        .dropdown-menu.show{
            padding: 0;
            .item-contratados{
                font-weight: 500;
                letter-spacing: 0.2px;
                color:#7f7f7f;
                padding: 6px 15px;
                i{
                    color: #B5B5C3;
                }
            }
            .item-contratados.dropdown-item:hover {
                color: #388E3C;
                background-color: #E8F5E9;
                i {
                    color: #388E3C;
                }
            }
        }
    }
}

.dropdown-table-rechazados{
    .dropdown-toggle.btn.btn-primary {
        background-color: #FFE2E5;
        border: transparent;
        height: 30px;
        width: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &::after{
            color: #F64E60 !important;
            font-size: 0.7rem;
            margin-left: 0;
            font-weight: 600;
        }
        &:active {
            background-color: #F64E60 !important;
            color: white!important;
            &::after{
                color: white!important;
            }
        }
    }
    &.show.dropdown{
        .dropdown-menu.show{
            padding: 0;
            .item-rechazados{
                font-weight: 500;
                letter-spacing: 0.2px;
                color:#7f7f7f;
                padding: 6px 15px;
                i{
                    color: #B5B5C3;
                }
            }
            .item-rechazados.dropdown-item:hover {
                color: #F64E60;
                background-color: #FFE2E5;
                i {
                    color: #F64E60;
                }
            }
        }
    }
}
.dropdown-pagina-web{
    .dropdown-toggle.btn.btn-primary {
        background-color: #f1eaff;
        border: transparent;
        height: 30px;
        width: 30px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        &::after{
            color: #8950FC !important;
            font-size: 0.7rem;
            margin-left: 0;
            font-weight: 600;
        }
        &:active {
            background-color: #8950FC !important;
            color: white!important;
            &::after{
                color: white!important;
            }
        }
    }
    &.show.dropdown{
        .dropdown-menu.show{
            padding: 0;
            .item-pagina-web{
                font-weight: 500;
                letter-spacing: 0.2px;
                color:#7f7f7f;
                padding: 6px 15px;
                i{
                    color: #B5B5C3;
                }
            }
            .item-pagina-web.dropdown-item:hover {
                color: #8950FC;
                background-color: #f1eaff;
                i {
                    color: #8950FC;
                }
            }
        }
    }
}

.dropdown-options{
    .dropdown-toggle.btn.btn-primary {
        background-color: #f1faff;
        color: #009ef7;
        border: transparent;
        padding: 0.7rem 0.6rem;
        width: auto;
        margin: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        letter-spacing: 0.2px;
        font-size: 1rem;
        &::after{
            color: #009ef7!important;
            font-size: 0.7rem;
        }
        &:active {
            background-color: #009ef7!important;
            color: white!important;
            &::after{
                color: white!important;
            }
        }
    }
    &.show.dropdown{
        .dropdown-menu.show{
            padding: 0;
            .success, .info, .primary{
                font-weight: 500;
                letter-spacing: 0.2px;
                color:#7f7f7f;
                padding: 6px 15px;
                i{
                    color: #B5B5C3;
                }
            }
            .success.dropdown-item:hover {
                color: #388E3C;
                background-color: #E8F5E9;
                i {
                    color: #388E3C;
                }
            }
            .primary.dropdown-item:hover {
                color: #3699ff;
                background-color: #e1f0ff;
                i {
                    color: #3699ff;
                }
            }
            .info.dropdown-item:hover {
                color: #8950FC;
                background-color: #EEE5FF ;
                i {
                    color: #8950FC;
                }
            }
        }
    }
}