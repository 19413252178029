.card.card-custom {
    -webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
    border: 0; }
.card.card-custom > .card-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 70px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent; }
.card.card-custom > .card-header .card-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem;
    margin-left: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.card.card-custom > .card-header .card-title .card-icon {
    margin-right: 0.75rem;
    line-height: 0; }
.card.card-custom > .card-header .card-title .card-icon i {
    font-size: 1.25rem;
    color: #80808F;
    line-height: 0; }
.card.card-custom > .card-header .card-title .card-icon i:after, .card.card-custom > .card-header .card-title .card-icon i:before {
    line-height: 0; }
.card.card-custom > .card-header .card-title .card-icon .svg-icon svg {
    height: 24px;
    width: 24px; }
.card.card-custom > .card-header .card-title .card-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #80808F; }
.card.card-custom > .card-header .card-title .card-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease; }
.card.card-custom > .card-header .card-title,
.card.card-custom > .card-header .card-title .card-label {
    font-weight: 500;
    font-size: 1.275rem;
    color: #212121; }
.card.card-custom > .card-header .card-title .card-label {
    margin: 0 0.75rem 0 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.card.card-custom > .card-header .card-title small {
    color: #B5B5C3;
    font-size: 1rem; }
.card.card-custom > .card-header .card-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0.5rem 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
.card.card-custom > .card-header.card-header-tabs-line {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
.card.card-custom > .card-header.card-header-tabs-line .card-toolbar {
    margin: 0; }
.card.card-custom > .card-header.card-header-tabs-line .nav {
    border-bottom-color: transparent; }
.card.card-custom > .card-header.card-header-tabs-line .nav .nav-item {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch; }
.card.card-custom > .card-header.card-header-tabs-line .nav .nav-link {
    padding-top: 2rem;
    padding-bottom: 2rem; }
.card.card-custom > .card-header.card-header-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
.card.card-custom > .card-body {
    padding: 2rem 2.25rem; }
.card.card-custom > .card-footer {
    background-color: transparent; }
.card.card-custom.card-stretch {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%; }
.card.card-custom.card-stretch.gutter-b {
    height: calc(100% - 25px); }
.card.card-custom.card-stretch.card-stretch-half {
    height: 50%; }
.card.card-custom.card-stretch.card-stretch-half.gutter-b {
    height: calc(50% - 25px); }
.card.card-custom.card-stretch.card-stretch-third {
    height: 33.33%; }
.card.card-custom.card-stretch.card-stretch-third.gutter-b {
    height: calc(33.33% - 25px); }
.card.card-custom.card-stretch.card-stretch-fourth {
    height: 25%; }
.card.card-custom.card-stretch.card-stretch-fourth.gutter-b {
    height: calc(25% - 25px); }
.card.card-custom.card-fit > .card-header {
    border-bottom: 0; }
.card.card-custom.card-fit > .card-footer {
    border-top: 0; }
.card.card-custom.card-space {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
.card.card-custom.card-space > .card-header {
    padding-left: 0;
    padding-right: 0; }
.card.card-custom.card-space > form > .card-body,
.card.card-custom.card-space > .card-body {
    padding-left: 0;
    padding-right: 0; }
.card.card-custom.card-space > form > .card-footer,
.card.card-custom.card-space > .card-footer {
    padding-left: 0;
    padding-right: 0; }
.card.card-custom.card-sticky > .card-header {
    -webkit-transition: left 0.3s, right 0.3s, height 0.3s;
    transition: left 0.3s, right 0.3s, height 0.3s;
    min-height: 70px; }
.card-sticky-on .card.card-custom.card-sticky > .card-header {
    -webkit-transition: left 0.3s, right 0.3s, height 0.3s;
    transition: left 0.3s, right 0.3s, height 0.3s;
    position: fixed;
    -webkit-box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
    box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.1);
    z-index: 101;
    background: #ffffff; }
.card.card-custom.card-transparent {
    background-color: transparent; }
.card.card-custom.card-shadowless {
    -webkit-box-shadow: none;
    box-shadow: none; }
.card.card-custom.card-border {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ECF0F3; }
.card.card-custom .card-scroll {
    position: relative;
    overflow: auto; }
.card.card-custom.card-collapsed > form,
.card.card-custom.card-collapsed > .card-body {
    display: none; }
.card.card-custom .card-header .card-toolbar [data-card-tool="toggle"] i {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease; }
.card.card-custom.card-collapsed .card-header .card-toolbar [data-card-tool="toggle"] i, .card.card-custom.card-collapse .card-header .card-toolbar [data-card-tool="toggle"] i {
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
.card-spacer {
    padding: 2rem 2.25rem !important; }
.card-spacer-x {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important; }
.card-spacer-y {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }  
.card-rounded {
    border-radius: 0.42rem; }  
.card-rounded-top {
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem; }  
.card-rounded-bottom {
    border-bottom-left-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem; }
  
@media (max-width: 767.98px) {
    .card.card-custom > .card-header:not(.flex-nowrap) {
        min-height: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem; } }
.rounded-card {
    border-radius: 0.42rem; }
.rounded-card-top {
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem; }
.rounded-card-bottom {
    border-bottom-left-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem; }