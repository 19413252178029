.containerTable {
    margin: -1rem -1.4rem;
    border-radius: 20px;
}

.headerTable {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
    margin: 1rem;
    margin-bottom: -1rem;
    // background-color: ;
}

.TitleTable {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
}

.SubtitleTable {
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
}

.reloadTable {
    color: rgb(0, 168, 0);
}



.containerTitleColumn {
    background-color: #c6d4e1;
    text-align: center;
    font-weight: bold;
    color: #000;
    height: 50px;
}

.TitleColumn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CellContent {
    padding: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: #000;
    border: 2px solid #f5f5f5;
    margin-bottom: 0;
    margin-top: 0;
    max-height: fit-content;
    text-align: center;
    width: 50px;
}

.CellActions {
    width: 50px;
}

/* table{
    table-layout: fixed;
    width: 100%;
    border-radius: 5px;
    font-family: poppins;
}

td, th{
    word-wrap: break-word;
} */

.InputSearch {
    background-color: white;
}

.SortButton {
    border: none;
    font-size: small;
    background-color: transparent;
}

.SettingButton {
    border: none;
    font-size: small;
    background-color: transparent;
    color: #1976d2;
    border-radius: 5px;
}

.SettingButton:hover {
    background-color: #1976d2;
    color: white;
}

.SortButton:hover {
    color: #1976d2;
}

.menu-opciones {
    list-style: none;
}

.action-item-color {
    background-color: #7c9fc469;

}

.Button-action {
    padding: 5px;
    margin-bottom: 2px;
}

.redButton {
    background-color: #fd7369;
    border-radius: 5px;
    color: white;
}

.redButton:hover {
    cursor: pointer;
    background-color: #ffada7;
}

.blueButton {
    background-color: #73c0ff;
    border-radius: 5px;
    color: white;
}

.blueButton:hover {
    cursor: pointer;
    background-color: #a9d8ff;
}

.greenButton {
    background-color: #22d41c;
    border-radius: 5px;
    color: white;
}

.greenButton:hover {
    cursor: pointer;
    background-color: #87f784;
}