.timeline-badge.bg-danger-50 {
    background: #efd0d9 !important;
}
.timeline.timeline-danger:before {
    background-color: #fbeaec !important;
}
.timeline-label {
    font-size: 0.759rem !important;
}

.timeline.timeline-6 .timeline-items {
    margin: 0;
    padding: 0;
}

.timeline.timeline-6 .timeline-items .timeline-item {
    margin-left: 0;
    padding: 0;
    position: relative;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-media {
    position: absolute;
    top: 0;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ffffff;
    line-height: 0;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-media i {
    font-size: 1.4rem;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-media .svg-icon svg {
    height: 24px;
    width: 24px;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc {
    padding: 9px 0 0 44px;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc::before {
    content: '';
    position: absolute;
    width: 4px;
    height: calc(100% - 32px);
    background-color: #EBEDF3;
    border-radius: 6px;
    top: 40px;
    left: 16px;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-primary::before {
    background-color: #3699FF;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-primary::before {
    background-color: #E1F0FF;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-secondary::before {
    background-color: #E4E6EF;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-secondary::before {
    background-color: #EBEDF3;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-success::before {
    background-color: #388E3C;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-success::before {
    background-color: #E8F5E9;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-info::before {
    background-color: #8950FC;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-info::before {
    background-color: #EEE5FF;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-warning::before {
    background-color: #FFA800;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-warning::before {
    background-color: #FFF4DE;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-danger::before {
    background-color: #F64E60;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-danger::before {
    background-color: #FFE2E5;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-bg-gray-200::before {
    background-color: #ECF0F3;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light::before {
    background-color: #F3F6F9;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-light::before {
    background-color: #F3F6F9;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-dark::before {
    background-color: #181C32;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-dark::before {
    background-color: #D1D3E0;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-white::before {
    background-color: #ffffff;
}

.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc.timeline-desc-light-white::before {
    background-color: #ffffff;
}

.timeline.timeline-6 .timeline-items .timeline-item:last-child {
    border-left-color: transparent;
    padding-bottom: 0;
}

div#nuevo {
    &.timeline.timeline-4.timeline-justified .timeline-items .timeline-item:after {
        border-right: none;
        margin-right: 0px;
        border-left: none;
    }

    &.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge {
        top: 0.5rem;
    }

    &.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge>div {
        width: 8px;
        height: 8px;
    }
}

div#timeline-departamentos {
    &.timeline.timeline-6 .timeline-items .timeline-item .timeline-media {
        width: 30px;
        height: 30px;
    }

    &.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc::before {
        top: 35px;
        left: 13px;
    }

    &.timeline.timeline-6 .timeline-items .timeline-item .timeline-desc::before {
        height: calc(100% - 34px);
    }
}

div#amarrillo {
    &.timeline.timeline-4.timeline-justified .timeline-items .timeline-item:after {
        border-right: none;
        margin-right: 0px;
        border-left: none;
    }

    &.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge {
        top: 0.5rem;
        width: 40px;
        height: 40px;
    }

    &.timeline.timeline-4 .timeline-items .timeline-item .timeline-badge>div {
        width: 8px;
        height: 8px;
    }

    &.timeline.timeline-4.timeline-justified .timeline-items .timeline-item .timeline-badge {
        left: -15px;
    }

    &.timeline.timeline-4:after {
        bottom: 20px;
        top: 20px;
    }

    @media (max-width: 991.98px) {
        &.timeline.timeline-4:after {
            left: 14px !important;
        }
    }
}

.timeline-content {
    min-height: 50px;
}

.timeline.timeline-historial {
    position: relative;
}

.timeline.timeline-historial:before {
    content: '';
    position: absolute;
    left: 99px;
    width: 4px;
    top: 0;
    bottom: 0;
    background-color: #ECF0F3;
}

.timeline.timeline-historial .timeline-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-bottom: 1.5rem;
}

.timeline.timeline-historial .timeline-item:last-child {
    margin-bottom: 0;
}

.timeline.timeline-historial .timeline-item .timeline-label {
    width: 80px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    font-size: 1rem!important;
    font-weight: 500;
    position: relative;
    color: #464E5F;
    text-align: right;
}

.timeline.timeline-historial .timeline-item .timeline-badge {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background: white;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
    position: relative;
    margin-left: 11px;
}

.timeline.timeline-historial .timeline-item .timeline-badge span {
    display: block;
    border-radius: 100%;
    width: 6px;
    height: 6px;
    background-color: #ECF0F3;
}

.timeline.timeline-historial .timeline-item .timeline-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 11px;
}