.modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.modal-dialog{
    max-width: 65vw;
    @include down-screen($md){
        max-width: 100vw;
    }
}

.modal-content{
    background-color: #fcfcfa;
    flex-direction: column;
    border-radius:0.42rem;
    
}

.max-height{
    max-height: 90vh!important;
    overflow-y: scroll;
}