.aside {
    background-color: #252525;
}

.aside .aside-menu.ps>.ps__rail-x {
    background-color: transparent;
}

.aside .aside-menu.ps>.ps__rail-x:hover,
.aside .aside-menu.ps>.ps__rail-x:focus {
    opacity: 1;
    background-color: transparent;
}

.aside .aside-menu.ps>.ps__rail-x:hover>.ps__thumb-x,
.aside .aside-menu.ps>.ps__rail-x:focus>.ps__thumb-x {
    opacity: 1;
}

.aside .aside-menu.ps>.ps__rail-x>.ps__thumb-x {
    background-color: #637099;
    opacity: 1;
}

.aside .aside-menu.ps>.ps__rail-x>.ps__thumb-x:hover,
.aside .aside-menu.ps>.ps__rail-x>.ps__thumb-x:focus {
    opacity: 1;
    background-color: #637099;
}

.aside .aside-menu.ps>.ps__rail-y {
    background-color: transparent;
}

.aside .aside-menu.ps>.ps__rail-y:hover,
.aside .aside-menu.ps>.ps__rail-y:focus {
    background-color: transparent;
    opacity: 1;
}

.aside .aside-menu.ps>.ps__rail-y:hover>.ps__thumb-y,
.aside .aside-menu.ps>.ps__rail-y:focus>.ps__thumb-y {
    opacity: 1;
}

.aside .aside-menu.ps>.ps__rail-y>.ps__thumb-y {
    background: #637099;
    opacity: 1;
}

.aside .aside-menu.ps>.ps__rail-y>.ps__thumb-y:hover,
.aside .aside-menu.ps>.ps__rail-y>.ps__thumb-y:focus {
    opacity: 1;
    background: #637099;
}

.aside-menu {
    background-color: #252525;
}

.aside-menu .menu-nav>.menu-item>.menu-heading .menu-text,
.aside-menu .menu-nav>.menu-item>.menu-link .menu-text {
    color: #a2a3b7;
}

.aside-menu .menu-nav>.menu-item>.menu-heading .menu-icon,
.aside-menu .menu-nav>.menu-item>.menu-link .menu-icon {
    color: #494b74;
}

.aside-menu .menu-nav>.menu-item>.menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav>.menu-item>.menu-link .menu-icon.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #80808F;
}

.aside-menu .menu-nav>.menu-item>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
.aside-menu .menu-nav>.menu-item>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.aside-menu .menu-nav>.menu-item>.menu-heading .menu-bullet.menu-bullet-dot>span,
.aside-menu .menu-nav>.menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
    background-color: #5c5e81;
}

.aside-menu .menu-nav>.menu-item>.menu-heading .menu-bullet.menu-bullet-line>span,
.aside-menu .menu-nav>.menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
    background-color: #5c5e81;
}

.aside-menu .menu-nav>.menu-item>.menu-heading .menu-arrow,
.aside-menu .menu-nav>.menu-item>.menu-link .menu-arrow {
    color: #5c5e81;
}

.aside-menu .menu-nav>.menu-item.menu-item-open {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-heading,
.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link {
    background-color: #212122;
}

.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-text,
.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-text {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-icon,
.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-icon {
    color: #96daff;
}

.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #71b7ff;
}

.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-dot>span,
.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-dot>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-line>span,
.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-line>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-heading .menu-arrow,
.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-arrow {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item.menu-item-here {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-heading,
.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link {
    background-color: #212122;
}

.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-text,
.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-text {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-icon,
.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon {
    color: #96daff;
}

.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #71b7ff;
}

.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-dot>span,
.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-dot>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-line>span,
.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-line>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-heading .menu-arrow,
.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-arrow {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item.menu-item-active {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-heading,
.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link {
    background-color: #212122;
}

.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-text,
.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-text {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-icon,
.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon {
    color: #96daff;
}

.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #71b7ff;
}

.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-dot>span,
.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-line>span,
.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-line>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-heading .menu-arrow,
.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-arrow {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading,
.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link {
    background-color: #212122;
}

.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-text,
.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-text {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon,
.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon {
    color: #96daff;
}

.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #71b7ff;
}

.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-dot>span,
.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-dot>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-line>span,
.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-line>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-arrow,
.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-arrow {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item>.menu-arrow {
    color: #252525;
}

.aside-menu .menu-nav>.menu-section .menu-text {
    color: #4c4e6f;
}

.aside-menu .menu-nav>.menu-section .menu-icon {
    color: #4c4e6f;
}

.aside-menu .menu-nav>.menu-separator {
    border-bottom: 1px solid #4c4e6f;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-text,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-text {
    color: #9899ac;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-icon,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-icon {
    color: #494b74;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-icon.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #494b74;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-bullet.menu-bullet-dot>span,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
    background-color: #5c5e81;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-bullet.menu-bullet-line>span,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
    background-color: #5c5e81;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-heading .menu-arrow,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item>.menu-link .menu-arrow {
    color: #5c5e81;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link {
    background-color: #212122;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-text,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-text {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-icon,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-icon {
    color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #71b7ff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-dot>span,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-dot>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-line>span,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-line>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-heading .menu-arrow,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-arrow {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link {
    background-color: #212122;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading .menu-icon,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link .menu-icon {
    color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #71b7ff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-dot>span,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-dot>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-line>span,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-line>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-heading .menu-arrow,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-here>.menu-link .menu-arrow {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link {
    background-color: #212122;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-text,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-text {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-icon,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-icon {
    color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #71b7ff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-dot>span,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-line>span,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-line>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-arrow,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-arrow {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link {
    background-color: #212122;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-text,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-text {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon {
    color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg g [fill],
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #71b7ff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-dot>span,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-dot>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-line>span,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-line>span {
    background-color: #96daff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-arrow,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-arrow {
    color: #ffffff;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-section .menu-text {
    color: #4c4e6f;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-section .menu-icon {
    color: #4c4e6f;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-separator {
    border-bottom: 1px solid #4c4e6f;
}

@media (min-width: 992px) {
    .aside-minimize .aside-menu .menu-nav>.menu-item {
        background: transparent;
    }

    .aside-minimize .aside-menu .menu-nav>.menu-item>.menu-link>.menu-icon {
        color: #7c87a6;
    }

    .aside-minimize .aside-menu .menu-nav>.menu-item.menu-item-hover,
    .aside-minimize .aside-menu .menu-nav>.menu-item.menu-item-open {
        background: transparent !important;
    }

    .aside-minimize .aside-menu .menu-nav>.menu-item.menu-item-hover>.menu-link>.menu-icon,
    .aside-minimize .aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link>.menu-icon {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav>.menu-item.menu-item-here,
    .aside-minimize .aside-menu .menu-nav>.menu-item.menu-item-active {
        background: transparent !important;
    }

    .aside-minimize .aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link>.menu-icon,
    .aside-minimize .aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link>.menu-icon {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav>.menu-item:hover {
        background: transparent;
    }

    .aside-minimize .aside-menu .menu-nav>.menu-item:hover>.menu-link>.menu-icon {
        color: #96daff !important;
    }
}

@media (min-width: 992px) {

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu {
        background-color: #fff;
        -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
        box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-text,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-text {
        color: #696e92;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-icon,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-icon {
        color: #a8aabb;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-icon.svg-icon svg g [fill],
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-icon.svg-icon svg g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-icon.svg-icon svg g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
        fill: #a8aabb;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-icon.svg-icon svg:hover g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-bullet.menu-bullet-dot>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #a8aabb;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-bullet.menu-bullet-line>span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-bullet.menu-bullet-line>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-bullet.menu-bullet-line>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #a8aabb;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-arrow,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-heading .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item>.menu-link .menu-arrow {
        color: #a8aabb;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open {
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link {
        background-color: #F3F6F9;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-text,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-text {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-icon,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-icon {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg g [fill],
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
        fill: #71b7ff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg:hover g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-dot>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-line>span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-line>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-bullet.menu-bullet-line>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-arrow,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-heading .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-open>.menu-link .menu-arrow {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here {
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-heading,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-link,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-heading,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-link {
        background-color: #F3F6F9;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg g [fill],
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg:hover g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-dot>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-line>span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-line>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-heading .menu-bullet.menu-bullet-line>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-here>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active {
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link {
        background-color: #F3F6F9;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-text,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-text {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-icon,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-icon {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg g [fill],
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
        fill: #71b7ff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg:hover g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-line>span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-line>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-bullet.menu-bullet-line>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-arrow,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-heading .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item.menu-item-active>.menu-link .menu-arrow {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover {
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link {
        background-color: #F3F6F9;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-text,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-text {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg g [fill],
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
        fill: #71b7ff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg:hover g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-icon.svg-icon svg:hover g [fill],
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon.svg-icon svg:hover g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-dot>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-dot>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-dot>span {
        background-color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-line>span,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-line>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-bullet.menu-bullet-line>span,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-bullet.menu-bullet-line>span {
        background-color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-arrow,
    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-heading .menu-arrow,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-arrow {
        color: #96daff;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-section .menu-text,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-section .menu-text {
        color: #a4a7bd;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-section .menu-icon,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-section .menu-icon {
        color: #aaacc2;
    }

    .aside-minimize .aside-menu .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-separator,
    .aside-menu.aside-menu-dropdown .menu-nav .menu-item:not([data-menu-submenu-mode=accordion]).menu-item-hover>.menu-submenu .menu-subnav .menu-separator {
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    }
}

.aside .aside-menu>.menu-nav .menu-item>.menu-link:not(.menu-link-active):not(.menu-link-here):not(.menu-link-hover) [class^="flaticon2-"],
.aside .aside-menu>.menu-nav .menu-item>.menu-link:not(.menu-link-active):not(.menu-link-here):not(.menu-link-hover) [class*=" flaticon2-"] {
    color: #434d6b;
}