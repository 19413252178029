@media (min-width: 576px) {
    .w-xs-auto {
        width: auto !important;
    }

    .h-xs-auto {
        height: auto !important;
    }

    .min-w-xs-auto {
        min-width: auto !important;
    }

    .min-h-xs-auto {
        min-height: auto !important;
    }

    .max-h-xs-auto {
        max-height: auto !important;
    }

    .max-w-xs-auto {
        max-width: auto !important;
    }

    .w-xs-1px {
        width: 1px !important;
    }

    .h-xs-1px {
        height: 1px !important;
    }

    .min-w-xs-1px {
        min-width: 1px !important;
    }

    .min-h-xs-1px {
        min-height: 1px !important;
    }

    .max-h-xs-1px {
        max-height: 1px !important;
    }

    .max-w-xs-1px {
        max-width: 1px !important;
    }

    .w-xs-2px {
        width: 2px !important;
    }

    .h-xs-2px {
        height: 2px !important;
    }

    .min-w-xs-2px {
        min-width: 2px !important;
    }

    .min-h-xs-2px {
        min-height: 2px !important;
    }

    .max-h-xs-2px {
        max-height: 2px !important;
    }

    .max-w-xs-2px {
        max-width: 2px !important;
    }

    .w-xs-3px {
        width: 3px !important;
    }

    .h-xs-3px {
        height: 3px !important;
    }

    .min-w-xs-3px {
        min-width: 3px !important;
    }

    .min-h-xs-3px {
        min-height: 3px !important;
    }

    .max-h-xs-3px {
        max-height: 3px !important;
    }

    .max-w-xs-3px {
        max-width: 3px !important;
    }

    .w-xs-4px {
        width: 4px !important;
    }

    .h-xs-4px {
        height: 4px !important;
    }

    .min-w-xs-4px {
        min-width: 4px !important;
    }

    .min-h-xs-4px {
        min-height: 4px !important;
    }

    .max-h-xs-4px {
        max-height: 4px !important;
    }

    .max-w-xs-4px {
        max-width: 4px !important;
    }

    .w-xs-5px {
        width: 5px !important;
    }

    .h-xs-5px {
        height: 5px !important;
    }

    .min-w-xs-5px {
        min-width: 5px !important;
    }

    .min-h-xs-5px {
        min-height: 5px !important;
    }

    .max-h-xs-5px {
        max-height: 5px !important;
    }

    .max-w-xs-5px {
        max-width: 5px !important;
    }

    .w-xs-10px {
        width: 10px !important;
    }

    .h-xs-10px {
        height: 10px !important;
    }

    .min-w-xs-10px {
        min-width: 10px !important;
    }

    .min-h-xs-10px {
        min-height: 10px !important;
    }

    .max-h-xs-10px {
        max-height: 10px !important;
    }

    .max-w-xs-10px {
        max-width: 10px !important;
    }

    .w-xs-15px {
        width: 15px !important;
    }

    .h-xs-15px {
        height: 15px !important;
    }

    .min-w-xs-15px {
        min-width: 15px !important;
    }

    .min-h-xs-15px {
        min-height: 15px !important;
    }

    .max-h-xs-15px {
        max-height: 15px !important;
    }

    .max-w-xs-15px {
        max-width: 15px !important;
    }

    .w-xs-20px {
        width: 20px !important;
    }

    .h-xs-20px {
        height: 20px !important;
    }

    .min-w-xs-20px {
        min-width: 20px !important;
    }

    .min-h-xs-20px {
        min-height: 20px !important;
    }

    .max-h-xs-20px {
        max-height: 20px !important;
    }

    .max-w-xs-20px {
        max-width: 20px !important;
    }

    .w-xs-25px {
        width: 25px !important;
    }

    .h-xs-25px {
        height: 25px !important;
    }

    .min-w-xs-25px {
        min-width: 25px !important;
    }

    .min-h-xs-25px {
        min-height: 25px !important;
    }

    .max-h-xs-25px {
        max-height: 25px !important;
    }

    .max-w-xs-25px {
        max-width: 25px !important;
    }

    .w-xs-30px {
        width: 30px !important;
    }

    .h-xs-30px {
        height: 30px !important;
    }

    .min-w-xs-30px {
        min-width: 30px !important;
    }

    .min-h-xs-30px {
        min-height: 30px !important;
    }

    .max-h-xs-30px {
        max-height: 30px !important;
    }

    .max-w-xs-30px {
        max-width: 30px !important;
    }

    .w-xs-35px {
        width: 35px !important;
    }

    .h-xs-35px {
        height: 35px !important;
    }

    .min-w-xs-35px {
        min-width: 35px !important;
    }

    .min-h-xs-35px {
        min-height: 35px !important;
    }

    .max-h-xs-35px {
        max-height: 35px !important;
    }

    .max-w-xs-35px {
        max-width: 35px !important;
    }

    .w-xs-40px {
        width: 40px !important;
    }

    .h-xs-40px {
        height: 40px !important;
    }

    .min-w-xs-40px {
        min-width: 40px !important;
    }

    .min-h-xs-40px {
        min-height: 40px !important;
    }

    .max-h-xs-40px {
        max-height: 40px !important;
    }

    .max-w-xs-40px {
        max-width: 40px !important;
    }

    .w-xs-45px {
        width: 45px !important;
    }

    .h-xs-45px {
        height: 45px !important;
    }

    .min-w-xs-45px {
        min-width: 45px !important;
    }

    .min-h-xs-45px {
        min-height: 45px !important;
    }

    .max-h-xs-45px {
        max-height: 45px !important;
    }

    .max-w-xs-45px {
        max-width: 45px !important;
    }

    .w-xs-50px {
        width: 50px !important;
    }

    .h-xs-50px {
        height: 50px !important;
    }

    .min-w-xs-50px {
        min-width: 50px !important;
    }

    .min-h-xs-50px {
        min-height: 50px !important;
    }

    .max-h-xs-50px {
        max-height: 50px !important;
    }

    .max-w-xs-50px {
        max-width: 50px !important;
    }

    .w-xs-55px {
        width: 55px !important;
    }

    .h-xs-55px {
        height: 55px !important;
    }

    .min-w-xs-55px {
        min-width: 55px !important;
    }

    .min-h-xs-55px {
        min-height: 55px !important;
    }

    .max-h-xs-55px {
        max-height: 55px !important;
    }

    .max-w-xs-55px {
        max-width: 55px !important;
    }

    .w-xs-60px {
        width: 60px !important;
    }

    .h-xs-60px {
        height: 60px !important;
    }

    .min-w-xs-60px {
        min-width: 60px !important;
    }

    .min-h-xs-60px {
        min-height: 60px !important;
    }

    .max-h-xs-60px {
        max-height: 60px !important;
    }

    .max-w-xs-60px {
        max-width: 60px !important;
    }

    .w-xs-65px {
        width: 65px !important;
    }

    .h-xs-65px {
        height: 65px !important;
    }

    .min-w-xs-65px {
        min-width: 65px !important;
    }

    .min-h-xs-65px {
        min-height: 65px !important;
    }

    .max-h-xs-65px {
        max-height: 65px !important;
    }

    .max-w-xs-65px {
        max-width: 65px !important;
    }

    .w-xs-70px {
        width: 70px !important;
    }

    .h-xs-70px {
        height: 70px !important;
    }

    .min-w-xs-70px {
        min-width: 70px !important;
    }

    .min-h-xs-70px {
        min-height: 70px !important;
    }

    .max-h-xs-70px {
        max-height: 70px !important;
    }

    .max-w-xs-70px {
        max-width: 70px !important;
    }

    .w-xs-75px {
        width: 75px !important;
    }

    .h-xs-75px {
        height: 75px !important;
    }

    .min-w-xs-75px {
        min-width: 75px !important;
    }

    .min-h-xs-75px {
        min-height: 75px !important;
    }

    .max-h-xs-75px {
        max-height: 75px !important;
    }

    .max-w-xs-75px {
        max-width: 75px !important;
    }

    .w-xs-80px {
        width: 80px !important;
    }

    .h-xs-80px {
        height: 80px !important;
    }

    .min-w-xs-80px {
        min-width: 80px !important;
    }

    .min-h-xs-80px {
        min-height: 80px !important;
    }

    .max-h-xs-80px {
        max-height: 80px !important;
    }

    .max-w-xs-80px {
        max-width: 80px !important;
    }

    .w-xs-85px {
        width: 85px !important;
    }

    .h-xs-85px {
        height: 85px !important;
    }

    .min-w-xs-85px {
        min-width: 85px !important;
    }

    .min-h-xs-85px {
        min-height: 85px !important;
    }

    .max-h-xs-85px {
        max-height: 85px !important;
    }

    .max-w-xs-85px {
        max-width: 85px !important;
    }

    .w-xs-90px {
        width: 90px !important;
    }

    .h-xs-90px {
        height: 90px !important;
    }

    .min-w-xs-90px {
        min-width: 90px !important;
    }

    .min-h-xs-90px {
        min-height: 90px !important;
    }

    .max-h-xs-90px {
        max-height: 90px !important;
    }

    .max-w-xs-90px {
        max-width: 90px !important;
    }

    .w-xs-95px {
        width: 95px !important;
    }

    .h-xs-95px {
        height: 95px !important;
    }

    .min-w-xs-95px {
        min-width: 95px !important;
    }

    .min-h-xs-95px {
        min-height: 95px !important;
    }

    .max-h-xs-95px {
        max-height: 95px !important;
    }

    .max-w-xs-95px {
        max-width: 95px !important;
    }

    .w-xs-100px {
        width: 100px !important;
    }

    .h-xs-100px {
        height: 100px !important;
    }

    .min-w-xs-100px {
        min-width: 100px !important;
    }

    .min-h-xs-100px {
        min-height: 100px !important;
    }

    .max-h-xs-100px {
        max-height: 100px !important;
    }

    .max-w-xs-100px {
        max-width: 100px !important;
    }

    .w-xs-125px {
        width: 125px !important;
    }

    .h-xs-125px {
        height: 125px !important;
    }

    .min-w-xs-125px {
        min-width: 125px !important;
    }

    .min-h-xs-125px {
        min-height: 125px !important;
    }

    .max-h-xs-125px {
        max-height: 125px !important;
    }

    .max-w-xs-125px {
        max-width: 125px !important;
    }

    .w-xs-150px {
        width: 150px !important;
    }

    .h-xs-150px {
        height: 150px !important;
    }

    .min-w-xs-150px {
        min-width: 150px !important;
    }

    .min-h-xs-150px {
        min-height: 150px !important;
    }

    .max-h-xs-150px {
        max-height: 150px !important;
    }

    .max-w-xs-150px {
        max-width: 150px !important;
    }

    .w-xs-175px {
        width: 175px !important;
    }

    .h-xs-175px {
        height: 175px !important;
    }

    .min-w-xs-175px {
        min-width: 175px !important;
    }

    .min-h-xs-175px {
        min-height: 175px !important;
    }

    .max-h-xs-175px {
        max-height: 175px !important;
    }

    .max-w-xs-175px {
        max-width: 175px !important;
    }

    .w-xs-200px {
        width: 200px !important;
    }

    .h-xs-200px {
        height: 200px !important;
    }

    .min-w-xs-200px {
        min-width: 200px !important;
    }

    .min-h-xs-200px {
        min-height: 200px !important;
    }

    .max-h-xs-200px {
        max-height: 200px !important;
    }

    .max-w-xs-200px {
        max-width: 200px !important;
    }

    .w-xs-225px {
        width: 225px !important;
    }

    .h-xs-225px {
        height: 225px !important;
    }

    .min-w-xs-225px {
        min-width: 225px !important;
    }

    .min-h-xs-225px {
        min-height: 225px !important;
    }

    .max-h-xs-225px {
        max-height: 225px !important;
    }

    .max-w-xs-225px {
        max-width: 225px !important;
    }

    .w-xs-250px {
        width: 250px !important;
    }

    .h-xs-250px {
        height: 250px !important;
    }

    .min-w-xs-250px {
        min-width: 250px !important;
    }

    .min-h-xs-250px {
        min-height: 250px !important;
    }

    .max-h-xs-250px {
        max-height: 250px !important;
    }

    .max-w-xs-250px {
        max-width: 250px !important;
    }

    .w-xs-275px {
        width: 275px !important;
    }

    .h-xs-275px {
        height: 275px !important;
    }

    .min-w-xs-275px {
        min-width: 275px !important;
    }

    .min-h-xs-275px {
        min-height: 275px !important;
    }

    .max-h-xs-275px {
        max-height: 275px !important;
    }

    .max-w-xs-275px {
        max-width: 275px !important;
    }

    .w-xs-300px {
        width: 300px !important;
    }

    .h-xs-300px {
        height: 300px !important;
    }

    .min-w-xs-300px {
        min-width: 300px !important;
    }

    .min-h-xs-300px {
        min-height: 300px !important;
    }

    .max-h-xs-300px {
        max-height: 300px !important;
    }

    .max-w-xs-300px {
        max-width: 300px !important;
    }

    .w-xs-325px {
        width: 325px !important;
    }

    .h-xs-325px {
        height: 325px !important;
    }

    .min-w-xs-325px {
        min-width: 325px !important;
    }

    .min-h-xs-325px {
        min-height: 325px !important;
    }

    .max-h-xs-325px {
        max-height: 325px !important;
    }

    .max-w-xs-325px {
        max-width: 325px !important;
    }

    .w-xs-350px {
        width: 350px !important;
    }

    .h-xs-350px {
        height: 350px !important;
    }

    .min-w-xs-350px {
        min-width: 350px !important;
    }

    .min-h-xs-350px {
        min-height: 350px !important;
    }

    .max-h-xs-350px {
        max-height: 350px !important;
    }

    .max-w-xs-350px {
        max-width: 350px !important;
    }

    .w-xs-375px {
        width: 375px !important;
    }

    .h-xs-375px {
        height: 375px !important;
    }

    .min-w-xs-375px {
        min-width: 375px !important;
    }

    .min-h-xs-375px {
        min-height: 375px !important;
    }

    .max-h-xs-375px {
        max-height: 375px !important;
    }

    .max-w-xs-375px {
        max-width: 375px !important;
    }

    .w-xs-400px {
        width: 400px !important;
    }

    .h-xs-400px {
        height: 400px !important;
    }

    .min-w-xs-400px {
        min-width: 400px !important;
    }

    .min-h-xs-400px {
        min-height: 400px !important;
    }

    .max-h-xs-400px {
        max-height: 400px !important;
    }

    .max-w-xs-400px {
        max-width: 400px !important;
    }

    .w-xs-425px {
        width: 425px !important;
    }

    .h-xs-425px {
        height: 425px !important;
    }

    .min-w-xs-425px {
        min-width: 425px !important;
    }

    .min-h-xs-425px {
        min-height: 425px !important;
    }

    .max-h-xs-425px {
        max-height: 425px !important;
    }

    .max-w-xs-425px {
        max-width: 425px !important;
    }

    .w-xs-450px {
        width: 450px !important;
    }

    .h-xs-450px {
        height: 450px !important;
    }

    .min-w-xs-450px {
        min-width: 450px !important;
    }

    .min-h-xs-450px {
        min-height: 450px !important;
    }

    .max-h-xs-450px {
        max-height: 450px !important;
    }

    .max-w-xs-450px {
        max-width: 450px !important;
    }

    .w-xs-475px {
        width: 475px !important;
    }

    .h-xs-475px {
        height: 475px !important;
    }

    .min-w-xs-475px {
        min-width: 475px !important;
    }

    .min-h-xs-475px {
        min-height: 475px !important;
    }

    .max-h-xs-475px {
        max-height: 475px !important;
    }

    .max-w-xs-475px {
        max-width: 475px !important;
    }

    .w-xs-500px {
        width: 500px !important;
    }

    .h-xs-500px {
        height: 500px !important;
    }

    .min-w-xs-500px {
        min-width: 500px !important;
    }

    .min-h-xs-500px {
        min-height: 500px !important;
    }

    .max-h-xs-500px {
        max-height: 500px !important;
    }

    .max-w-xs-500px {
        max-width: 500px !important;
    }

    .w-xs-550px {
        width: 550px !important;
    }

    .h-xs-550px {
        height: 550px !important;
    }

    .min-w-xs-550px {
        min-width: 550px !important;
    }

    .min-h-xs-550px {
        min-height: 550px !important;
    }

    .max-h-xs-550px {
        max-height: 550px !important;
    }

    .max-w-xs-550px {
        max-width: 550px !important;
    }

    .w-xs-600px {
        width: 600px !important;
    }

    .h-xs-600px {
        height: 600px !important;
    }

    .min-w-xs-600px {
        min-width: 600px !important;
    }

    .min-h-xs-600px {
        min-height: 600px !important;
    }

    .max-h-xs-600px {
        max-height: 600px !important;
    }

    .max-w-xs-600px {
        max-width: 600px !important;
    }

    .w-xs-650px {
        width: 650px !important;
    }

    .h-xs-650px {
        height: 650px !important;
    }

    .min-w-xs-650px {
        min-width: 650px !important;
    }

    .min-h-xs-650px {
        min-height: 650px !important;
    }

    .max-h-xs-650px {
        max-height: 650px !important;
    }

    .max-w-xs-650px {
        max-width: 650px !important;
    }

    .w-xs-700px {
        width: 700px !important;
    }

    .h-xs-700px {
        height: 700px !important;
    }

    .min-w-xs-700px {
        min-width: 700px !important;
    }

    .min-h-xs-700px {
        min-height: 700px !important;
    }

    .max-h-xs-700px {
        max-height: 700px !important;
    }

    .max-w-xs-700px {
        max-width: 700px !important;
    }

    .w-xs-750px {
        width: 750px !important;
    }

    .h-xs-750px {
        height: 750px !important;
    }

    .min-w-xs-750px {
        min-width: 750px !important;
    }

    .min-h-xs-750px {
        min-height: 750px !important;
    }

    .max-h-xs-750px {
        max-height: 750px !important;
    }

    .max-w-xs-750px {
        max-width: 750px !important;
    }

    .w-xs-800px {
        width: 800px !important;
    }

    .h-xs-800px {
        height: 800px !important;
    }

    .min-w-xs-800px {
        min-width: 800px !important;
    }

    .min-h-xs-800px {
        min-height: 800px !important;
    }

    .max-h-xs-800px {
        max-height: 800px !important;
    }

    .max-w-xs-800px {
        max-width: 800px !important;
    }

    .w-xs-850px {
        width: 850px !important;
    }

    .h-xs-850px {
        height: 850px !important;
    }

    .min-w-xs-850px {
        min-width: 850px !important;
    }

    .min-h-xs-850px {
        min-height: 850px !important;
    }

    .max-h-xs-850px {
        max-height: 850px !important;
    }

    .max-w-xs-850px {
        max-width: 850px !important;
    }

    .w-xs-900px {
        width: 900px !important;
    }

    .h-xs-900px {
        height: 900px !important;
    }

    .min-w-xs-900px {
        min-width: 900px !important;
    }

    .min-h-xs-900px {
        min-height: 900px !important;
    }

    .max-h-xs-900px {
        max-height: 900px !important;
    }

    .max-w-xs-900px {
        max-width: 900px !important;
    }

    .w-xs-950px {
        width: 950px !important;
    }

    .h-xs-950px {
        height: 950px !important;
    }

    .min-w-xs-950px {
        min-width: 950px !important;
    }

    .min-h-xs-950px {
        min-height: 950px !important;
    }

    .max-h-xs-950px {
        max-height: 950px !important;
    }

    .max-w-xs-950px {
        max-width: 950px !important;
    }

    .w-xs-1000px {
        width: 1000px !important;
    }

    .h-xs-1000px {
        height: 1000px !important;
    }

    .min-w-xs-1000px {
        min-width: 1000px !important;
    }

    .min-h-xs-1000px {
        min-height: 1000px !important;
    }

    .max-h-xs-1000px {
        max-height: 1000px !important;
    }

    .max-w-xs-1000px {
        max-width: 1000px !important;
    }
}






@media (min-width: 360px) {
    .w-xxs-auto {
        width: auto !important;
    }

    .h-xxs-auto {
        height: auto !important;
    }

    .min-w-xxs-auto {
        min-width: auto !important;
    }

    .min-h-xxs-auto {
        min-height: auto !important;
    }

    .max-h-xxs-auto {
        max-height: auto !important;
    }

    .max-w-xxs-auto {
        max-width: auto !important;
    }

    .w-xxs-1px {
        width: 1px !important;
    }

    .h-xxs-1px {
        height: 1px !important;
    }

    .min-w-xxs-1px {
        min-width: 1px !important;
    }

    .min-h-xxs-1px {
        min-height: 1px !important;
    }

    .max-h-xxs-1px {
        max-height: 1px !important;
    }

    .max-w-xxs-1px {
        max-width: 1px !important;
    }

    .w-xxs-2px {
        width: 2px !important;
    }

    .h-xxs-2px {
        height: 2px !important;
    }

    .min-w-xxs-2px {
        min-width: 2px !important;
    }

    .min-h-xxs-2px {
        min-height: 2px !important;
    }

    .max-h-xxs-2px {
        max-height: 2px !important;
    }

    .max-w-xxs-2px {
        max-width: 2px !important;
    }

    .w-xxs-3px {
        width: 3px !important;
    }

    .h-xxs-3px {
        height: 3px !important;
    }

    .min-w-xxs-3px {
        min-width: 3px !important;
    }

    .min-h-xxs-3px {
        min-height: 3px !important;
    }

    .max-h-xxs-3px {
        max-height: 3px !important;
    }

    .max-w-xxs-3px {
        max-width: 3px !important;
    }

    .w-xxs-4px {
        width: 4px !important;
    }

    .h-xxs-4px {
        height: 4px !important;
    }

    .min-w-xxs-4px {
        min-width: 4px !important;
    }

    .min-h-xxs-4px {
        min-height: 4px !important;
    }

    .max-h-xxs-4px {
        max-height: 4px !important;
    }

    .max-w-xxs-4px {
        max-width: 4px !important;
    }

    .w-xxs-5px {
        width: 5px !important;
    }

    .h-xxs-5px {
        height: 5px !important;
    }

    .min-w-xxs-5px {
        min-width: 5px !important;
    }

    .min-h-xxs-5px {
        min-height: 5px !important;
    }

    .max-h-xxs-5px {
        max-height: 5px !important;
    }

    .max-w-xxs-5px {
        max-width: 5px !important;
    }

    .w-xxs-10px {
        width: 10px !important;
    }

    .h-xxs-10px {
        height: 10px !important;
    }

    .min-w-xxs-10px {
        min-width: 10px !important;
    }

    .min-h-xxs-10px {
        min-height: 10px !important;
    }

    .max-h-xxs-10px {
        max-height: 10px !important;
    }

    .max-w-xxs-10px {
        max-width: 10px !important;
    }

    .w-xxs-15px {
        width: 15px !important;
    }

    .h-xxs-15px {
        height: 15px !important;
    }

    .min-w-xxs-15px {
        min-width: 15px !important;
    }

    .min-h-xxs-15px {
        min-height: 15px !important;
    }

    .max-h-xxs-15px {
        max-height: 15px !important;
    }

    .max-w-xxs-15px {
        max-width: 15px !important;
    }

    .w-xxs-20px {
        width: 20px !important;
    }

    .h-xxs-20px {
        height: 20px !important;
    }

    .min-w-xxs-20px {
        min-width: 20px !important;
    }

    .min-h-xxs-20px {
        min-height: 20px !important;
    }

    .max-h-xxs-20px {
        max-height: 20px !important;
    }

    .max-w-xxs-20px {
        max-width: 20px !important;
    }

    .w-xxs-25px {
        width: 25px !important;
    }

    .h-xxs-25px {
        height: 25px !important;
    }

    .min-w-xxs-25px {
        min-width: 25px !important;
    }

    .min-h-xxs-25px {
        min-height: 25px !important;
    }

    .max-h-xxs-25px {
        max-height: 25px !important;
    }

    .max-w-xxs-25px {
        max-width: 25px !important;
    }

    .w-xxs-30px {
        width: 30px !important;
    }

    .h-xxs-30px {
        height: 30px !important;
    }

    .min-w-xxs-30px {
        min-width: 30px !important;
    }

    .min-h-xxs-30px {
        min-height: 30px !important;
    }

    .max-h-xxs-30px {
        max-height: 30px !important;
    }

    .max-w-xxs-30px {
        max-width: 30px !important;
    }

    .w-xxs-35px {
        width: 35px !important;
    }

    .h-xxs-35px {
        height: 35px !important;
    }

    .min-w-xxs-35px {
        min-width: 35px !important;
    }

    .min-h-xxs-35px {
        min-height: 35px !important;
    }

    .max-h-xxs-35px {
        max-height: 35px !important;
    }

    .max-w-xxs-35px {
        max-width: 35px !important;
    }

    .w-xxs-40px {
        width: 40px !important;
    }

    .h-xxs-40px {
        height: 40px !important;
    }

    .min-w-xxs-40px {
        min-width: 40px !important;
    }

    .min-h-xxs-40px {
        min-height: 40px !important;
    }

    .max-h-xxs-40px {
        max-height: 40px !important;
    }

    .max-w-xxs-40px {
        max-width: 40px !important;
    }

    .w-xxs-45px {
        width: 45px !important;
    }

    .h-xxs-45px {
        height: 45px !important;
    }

    .min-w-xxs-45px {
        min-width: 45px !important;
    }

    .min-h-xxs-45px {
        min-height: 45px !important;
    }

    .max-h-xxs-45px {
        max-height: 45px !important;
    }

    .max-w-xxs-45px {
        max-width: 45px !important;
    }

    .w-xxs-50px {
        width: 50px !important;
    }

    .h-xxs-50px {
        height: 50px !important;
    }

    .min-w-xxs-50px {
        min-width: 50px !important;
    }

    .min-h-xxs-50px {
        min-height: 50px !important;
    }

    .max-h-xxs-50px {
        max-height: 50px !important;
    }

    .max-w-xxs-50px {
        max-width: 50px !important;
    }

    .w-xxs-55px {
        width: 55px !important;
    }

    .h-xxs-55px {
        height: 55px !important;
    }

    .min-w-xxs-55px {
        min-width: 55px !important;
    }

    .min-h-xxs-55px {
        min-height: 55px !important;
    }

    .max-h-xxs-55px {
        max-height: 55px !important;
    }

    .max-w-xxs-55px {
        max-width: 55px !important;
    }

    .w-xxs-60px {
        width: 60px !important;
    }

    .h-xxs-60px {
        height: 60px !important;
    }

    .min-w-xxs-60px {
        min-width: 60px !important;
    }

    .min-h-xxs-60px {
        min-height: 60px !important;
    }

    .max-h-xxs-60px {
        max-height: 60px !important;
    }

    .max-w-xxs-60px {
        max-width: 60px !important;
    }

    .w-xxs-65px {
        width: 65px !important;
    }

    .h-xxs-65px {
        height: 65px !important;
    }

    .min-w-xxs-65px {
        min-width: 65px !important;
    }

    .min-h-xxs-65px {
        min-height: 65px !important;
    }

    .max-h-xxs-65px {
        max-height: 65px !important;
    }

    .max-w-xxs-65px {
        max-width: 65px !important;
    }

    .w-xxs-70px {
        width: 70px !important;
    }

    .h-xxs-70px {
        height: 70px !important;
    }

    .min-w-xxs-70px {
        min-width: 70px !important;
    }

    .min-h-xxs-70px {
        min-height: 70px !important;
    }

    .max-h-xxs-70px {
        max-height: 70px !important;
    }

    .max-w-xxs-70px {
        max-width: 70px !important;
    }

    .w-xxs-75px {
        width: 75px !important;
    }

    .h-xxs-75px {
        height: 75px !important;
    }

    .min-w-xxs-75px {
        min-width: 75px !important;
    }

    .min-h-xxs-75px {
        min-height: 75px !important;
    }

    .max-h-xxs-75px {
        max-height: 75px !important;
    }

    .max-w-xxs-75px {
        max-width: 75px !important;
    }

    .w-xxs-80px {
        width: 80px !important;
    }

    .h-xxs-80px {
        height: 80px !important;
    }

    .min-w-xxs-80px {
        min-width: 80px !important;
    }

    .min-h-xxs-80px {
        min-height: 80px !important;
    }

    .max-h-xxs-80px {
        max-height: 80px !important;
    }

    .max-w-xxs-80px {
        max-width: 80px !important;
    }

    .w-xxs-85px {
        width: 85px !important;
    }

    .h-xxs-85px {
        height: 85px !important;
    }

    .min-w-xxs-85px {
        min-width: 85px !important;
    }

    .min-h-xxs-85px {
        min-height: 85px !important;
    }

    .max-h-xxs-85px {
        max-height: 85px !important;
    }

    .max-w-xxs-85px {
        max-width: 85px !important;
    }

    .w-xxs-90px {
        width: 90px !important;
    }

    .h-xxs-90px {
        height: 90px !important;
    }

    .min-w-xxs-90px {
        min-width: 90px !important;
    }

    .min-h-xxs-90px {
        min-height: 90px !important;
    }

    .max-h-xxs-90px {
        max-height: 90px !important;
    }

    .max-w-xxs-90px {
        max-width: 90px !important;
    }

    .w-xxs-95px {
        width: 95px !important;
    }

    .h-xxs-95px {
        height: 95px !important;
    }

    .min-w-xxs-95px {
        min-width: 95px !important;
    }

    .min-h-xxs-95px {
        min-height: 95px !important;
    }

    .max-h-xxs-95px {
        max-height: 95px !important;
    }

    .max-w-xxs-95px {
        max-width: 95px !important;
    }

    .w-xxs-100px {
        width: 100px !important;
    }

    .h-xxs-100px {
        height: 100px !important;
    }

    .min-w-xxs-100px {
        min-width: 100px !important;
    }

    .min-h-xxs-100px {
        min-height: 100px !important;
    }

    .max-h-xxs-100px {
        max-height: 100px !important;
    }

    .max-w-xxs-100px {
        max-width: 100px !important;
    }

    .w-xxs-125px {
        width: 125px !important;
    }

    .h-xxs-125px {
        height: 125px !important;
    }

    .min-w-xxs-125px {
        min-width: 125px !important;
    }

    .min-h-xxs-125px {
        min-height: 125px !important;
    }

    .max-h-xxs-125px {
        max-height: 125px !important;
    }

    .max-w-xxs-125px {
        max-width: 125px !important;
    }

    .w-xxs-150px {
        width: 150px !important;
    }

    .h-xxs-150px {
        height: 150px !important;
    }

    .min-w-xxs-150px {
        min-width: 150px !important;
    }

    .min-h-xxs-150px {
        min-height: 150px !important;
    }

    .max-h-xxs-150px {
        max-height: 150px !important;
    }

    .max-w-xxs-150px {
        max-width: 150px !important;
    }

    .w-xxs-175px {
        width: 175px !important;
    }

    .h-xxs-175px {
        height: 175px !important;
    }

    .min-w-xxs-175px {
        min-width: 175px !important;
    }

    .min-h-xxs-175px {
        min-height: 175px !important;
    }

    .max-h-xxs-175px {
        max-height: 175px !important;
    }

    .max-w-xxs-175px {
        max-width: 175px !important;
    }

    .w-xxs-200px {
        width: 200px !important;
    }

    .h-xxs-200px {
        height: 200px !important;
    }

    .min-w-xxs-200px {
        min-width: 200px !important;
    }

    .min-h-xxs-200px {
        min-height: 200px !important;
    }

    .max-h-xxs-200px {
        max-height: 200px !important;
    }

    .max-w-xxs-200px {
        max-width: 200px !important;
    }

    .w-xxs-225px {
        width: 225px !important;
    }

    .h-xxs-225px {
        height: 225px !important;
    }

    .min-w-xxs-225px {
        min-width: 225px !important;
    }

    .min-h-xxs-225px {
        min-height: 225px !important;
    }

    .max-h-xxs-225px {
        max-height: 225px !important;
    }

    .max-w-xxs-225px {
        max-width: 225px !important;
    }

    .w-xxs-250px {
        width: 250px !important;
    }

    .h-xxs-250px {
        height: 250px !important;
    }

    .min-w-xxs-250px {
        min-width: 250px !important;
    }

    .min-h-xxs-250px {
        min-height: 250px !important;
    }

    .max-h-xxs-250px {
        max-height: 250px !important;
    }

    .max-w-xxs-250px {
        max-width: 250px !important;
    }

    .w-xxs-275px {
        width: 275px !important;
    }

    .h-xxs-275px {
        height: 275px !important;
    }

    .min-w-xxs-275px {
        min-width: 275px !important;
    }

    .min-h-xxs-275px {
        min-height: 275px !important;
    }

    .max-h-xxs-275px {
        max-height: 275px !important;
    }

    .max-w-xxs-275px {
        max-width: 275px !important;
    }

    .w-xxs-300px {
        width: 300px !important;
    }

    .h-xxs-300px {
        height: 300px !important;
    }

    .min-w-xxs-300px {
        min-width: 300px !important;
    }

    .min-h-xxs-300px {
        min-height: 300px !important;
    }

    .max-h-xxs-300px {
        max-height: 300px !important;
    }

    .max-w-xxs-300px {
        max-width: 300px !important;
    }

    .w-xxs-325px {
        width: 325px !important;
    }

    .h-xxs-325px {
        height: 325px !important;
    }

    .min-w-xxs-325px {
        min-width: 325px !important;
    }

    .min-h-xxs-325px {
        min-height: 325px !important;
    }

    .max-h-xxs-325px {
        max-height: 325px !important;
    }

    .max-w-xxs-325px {
        max-width: 325px !important;
    }

    .w-xxs-350px {
        width: 350px !important;
    }

    .h-xxs-350px {
        height: 350px !important;
    }

    .min-w-xxs-350px {
        min-width: 350px !important;
    }

    .min-h-xxs-350px {
        min-height: 350px !important;
    }

    .max-h-xxs-350px {
        max-height: 350px !important;
    }

    .max-w-xxs-350px {
        max-width: 350px !important;
    }

    .w-xxs-375px {
        width: 375px !important;
    }

    .h-xxs-375px {
        height: 375px !important;
    }

    .min-w-xxs-375px {
        min-width: 375px !important;
    }

    .min-h-xxs-375px {
        min-height: 375px !important;
    }

    .max-h-xxs-375px {
        max-height: 375px !important;
    }

    .max-w-xxs-375px {
        max-width: 375px !important;
    }

    .w-xxs-400px {
        width: 400px !important;
    }

    .h-xxs-400px {
        height: 400px !important;
    }

    .min-w-xxs-400px {
        min-width: 400px !important;
    }

    .min-h-xxs-400px {
        min-height: 400px !important;
    }

    .max-h-xxs-400px {
        max-height: 400px !important;
    }

    .max-w-xxs-400px {
        max-width: 400px !important;
    }

    .w-xxs-425px {
        width: 425px !important;
    }

    .h-xxs-425px {
        height: 425px !important;
    }

    .min-w-xxs-425px {
        min-width: 425px !important;
    }

    .min-h-xxs-425px {
        min-height: 425px !important;
    }

    .max-h-xxs-425px {
        max-height: 425px !important;
    }

    .max-w-xxs-425px {
        max-width: 425px !important;
    }

    .w-xxs-450px {
        width: 450px !important;
    }

    .h-xxs-450px {
        height: 450px !important;
    }

    .min-w-xxs-450px {
        min-width: 450px !important;
    }

    .min-h-xxs-450px {
        min-height: 450px !important;
    }

    .max-h-xxs-450px {
        max-height: 450px !important;
    }

    .max-w-xxs-450px {
        max-width: 450px !important;
    }

    .w-xxs-475px {
        width: 475px !important;
    }

    .h-xxs-475px {
        height: 475px !important;
    }

    .min-w-xxs-475px {
        min-width: 475px !important;
    }

    .min-h-xxs-475px {
        min-height: 475px !important;
    }

    .max-h-xxs-475px {
        max-height: 475px !important;
    }

    .max-w-xxs-475px {
        max-width: 475px !important;
    }

    .w-xxs-500px {
        width: 500px !important;
    }

    .h-xxs-500px {
        height: 500px !important;
    }

    .min-w-xxs-500px {
        min-width: 500px !important;
    }

    .min-h-xxs-500px {
        min-height: 500px !important;
    }

    .max-h-xxs-500px {
        max-height: 500px !important;
    }

    .max-w-xxs-500px {
        max-width: 500px !important;
    }

    .w-xxs-550px {
        width: 550px !important;
    }

    .h-xxs-550px {
        height: 550px !important;
    }

    .min-w-xxs-550px {
        min-width: 550px !important;
    }

    .min-h-xxs-550px {
        min-height: 550px !important;
    }

    .max-h-xxs-550px {
        max-height: 550px !important;
    }

    .max-w-xxs-550px {
        max-width: 550px !important;
    }

    .w-xxs-600px {
        width: 600px !important;
    }

    .h-xxs-600px {
        height: 600px !important;
    }

    .min-w-xxs-600px {
        min-width: 600px !important;
    }

    .min-h-xxs-600px {
        min-height: 600px !important;
    }

    .max-h-xxs-600px {
        max-height: 600px !important;
    }

    .max-w-xxs-600px {
        max-width: 600px !important;
    }

    .w-xxs-650px {
        width: 650px !important;
    }

    .h-xxs-650px {
        height: 650px !important;
    }

    .min-w-xxs-650px {
        min-width: 650px !important;
    }

    .min-h-xxs-650px {
        min-height: 650px !important;
    }

    .max-h-xxs-650px {
        max-height: 650px !important;
    }

    .max-w-xxs-650px {
        max-width: 650px !important;
    }

    .w-xxs-700px {
        width: 700px !important;
    }

    .h-xxs-700px {
        height: 700px !important;
    }

    .min-w-xxs-700px {
        min-width: 700px !important;
    }

    .min-h-xxs-700px {
        min-height: 700px !important;
    }

    .max-h-xxs-700px {
        max-height: 700px !important;
    }

    .max-w-xxs-700px {
        max-width: 700px !important;
    }

    .w-xxs-750px {
        width: 750px !important;
    }

    .h-xxs-750px {
        height: 750px !important;
    }

    .min-w-xxs-750px {
        min-width: 750px !important;
    }

    .min-h-xxs-750px {
        min-height: 750px !important;
    }

    .max-h-xxs-750px {
        max-height: 750px !important;
    }

    .max-w-xxs-750px {
        max-width: 750px !important;
    }

    .w-xxs-800px {
        width: 800px !important;
    }

    .h-xxs-800px {
        height: 800px !important;
    }

    .min-w-xxs-800px {
        min-width: 800px !important;
    }

    .min-h-xxs-800px {
        min-height: 800px !important;
    }

    .max-h-xxs-800px {
        max-height: 800px !important;
    }

    .max-w-xxs-800px {
        max-width: 800px !important;
    }

    .w-xxs-850px {
        width: 850px !important;
    }

    .h-xxs-850px {
        height: 850px !important;
    }

    .min-w-xxs-850px {
        min-width: 850px !important;
    }

    .min-h-xxs-850px {
        min-height: 850px !important;
    }

    .max-h-xxs-850px {
        max-height: 850px !important;
    }

    .max-w-xxs-850px {
        max-width: 850px !important;
    }

    .w-xxs-900px {
        width: 900px !important;
    }

    .h-xxs-900px {
        height: 900px !important;
    }

    .min-w-xxs-900px {
        min-width: 900px !important;
    }

    .min-h-xxs-900px {
        min-height: 900px !important;
    }

    .max-h-xxs-900px {
        max-height: 900px !important;
    }

    .max-w-xxs-900px {
        max-width: 900px !important;
    }

    .w-xxs-950px {
        width: 950px !important;
    }

    .h-xxs-950px {
        height: 950px !important;
    }

    .min-w-xxs-950px {
        min-width: 950px !important;
    }

    .min-h-xxs-950px {
        min-height: 950px !important;
    }

    .max-h-xxs-950px {
        max-height: 950px !important;
    }

    .max-w-xxs-950px {
        max-width: 950px !important;
    }

    .w-xxs-1000px {
        width: 1000px !important;
    }

    .h-xxs-1000px {
        height: 1000px !important;
    }

    .min-w-xxs-1000px {
        min-width: 1000px !important;
    }

    .min-h-xxs-1000px {
        min-height: 1000px !important;
    }

    .max-h-xxs-1000px {
        max-height: 1000px !important;
    }

    .max-w-xxs-1000px {
        max-width: 1000px !important;
    }
}





@media (min-width: 320px) {
    .w-xxxs-auto {
        width: auto !important;
    }

    .h-xxxs-auto {
        height: auto !important;
    }

    .min-w-xxxs-auto {
        min-width: auto !important;
    }

    .min-h-xxxs-auto {
        min-height: auto !important;
    }

    .max-h-xxxs-auto {
        max-height: auto !important;
    }

    .max-w-xxxs-auto {
        max-width: auto !important;
    }

    .w-xxxs-1px {
        width: 1px !important;
    }

    .h-xxxs-1px {
        height: 1px !important;
    }

    .min-w-xxxs-1px {
        min-width: 1px !important;
    }

    .min-h-xxxs-1px {
        min-height: 1px !important;
    }

    .max-h-xxxs-1px {
        max-height: 1px !important;
    }

    .max-w-xxxs-1px {
        max-width: 1px !important;
    }

    .w-xxxs-2px {
        width: 2px !important;
    }

    .h-xxxs-2px {
        height: 2px !important;
    }

    .min-w-xxxs-2px {
        min-width: 2px !important;
    }

    .min-h-xxxs-2px {
        min-height: 2px !important;
    }

    .max-h-xxxs-2px {
        max-height: 2px !important;
    }

    .max-w-xxxs-2px {
        max-width: 2px !important;
    }

    .w-xxxs-3px {
        width: 3px !important;
    }

    .h-xxxs-3px {
        height: 3px !important;
    }

    .min-w-xxxs-3px {
        min-width: 3px !important;
    }

    .min-h-xxxs-3px {
        min-height: 3px !important;
    }

    .max-h-xxxs-3px {
        max-height: 3px !important;
    }

    .max-w-xxxs-3px {
        max-width: 3px !important;
    }

    .w-xxxs-4px {
        width: 4px !important;
    }

    .h-xxxs-4px {
        height: 4px !important;
    }

    .min-w-xxxs-4px {
        min-width: 4px !important;
    }

    .min-h-xxxs-4px {
        min-height: 4px !important;
    }

    .max-h-xxxs-4px {
        max-height: 4px !important;
    }

    .max-w-xxxs-4px {
        max-width: 4px !important;
    }

    .w-xxxs-5px {
        width: 5px !important;
    }

    .h-xxxs-5px {
        height: 5px !important;
    }

    .min-w-xxxs-5px {
        min-width: 5px !important;
    }

    .min-h-xxxs-5px {
        min-height: 5px !important;
    }

    .max-h-xxxs-5px {
        max-height: 5px !important;
    }

    .max-w-xxxs-5px {
        max-width: 5px !important;
    }

    .w-xxxs-10px {
        width: 10px !important;
    }

    .h-xxxs-10px {
        height: 10px !important;
    }

    .min-w-xxxs-10px {
        min-width: 10px !important;
    }

    .min-h-xxxs-10px {
        min-height: 10px !important;
    }

    .max-h-xxxs-10px {
        max-height: 10px !important;
    }

    .max-w-xxxs-10px {
        max-width: 10px !important;
    }

    .w-xxxs-15px {
        width: 15px !important;
    }

    .h-xxxs-15px {
        height: 15px !important;
    }

    .min-w-xxxs-15px {
        min-width: 15px !important;
    }

    .min-h-xxxs-15px {
        min-height: 15px !important;
    }

    .max-h-xxxs-15px {
        max-height: 15px !important;
    }

    .max-w-xxxs-15px {
        max-width: 15px !important;
    }

    .w-xxxs-20px {
        width: 20px !important;
    }

    .h-xxxs-20px {
        height: 20px !important;
    }

    .min-w-xxxs-20px {
        min-width: 20px !important;
    }

    .min-h-xxxs-20px {
        min-height: 20px !important;
    }

    .max-h-xxxs-20px {
        max-height: 20px !important;
    }

    .max-w-xxxs-20px {
        max-width: 20px !important;
    }

    .w-xxxs-25px {
        width: 25px !important;
    }

    .h-xxxs-25px {
        height: 25px !important;
    }

    .min-w-xxxs-25px {
        min-width: 25px !important;
    }

    .min-h-xxxs-25px {
        min-height: 25px !important;
    }

    .max-h-xxxs-25px {
        max-height: 25px !important;
    }

    .max-w-xxxs-25px {
        max-width: 25px !important;
    }

    .w-xxxs-30px {
        width: 30px !important;
    }

    .h-xxxs-30px {
        height: 30px !important;
    }

    .min-w-xxxs-30px {
        min-width: 30px !important;
    }

    .min-h-xxxs-30px {
        min-height: 30px !important;
    }

    .max-h-xxxs-30px {
        max-height: 30px !important;
    }

    .max-w-xxxs-30px {
        max-width: 30px !important;
    }

    .w-xxxs-35px {
        width: 35px !important;
    }

    .h-xxxs-35px {
        height: 35px !important;
    }

    .min-w-xxxs-35px {
        min-width: 35px !important;
    }

    .min-h-xxxs-35px {
        min-height: 35px !important;
    }

    .max-h-xxxs-35px {
        max-height: 35px !important;
    }

    .max-w-xxxs-35px {
        max-width: 35px !important;
    }

    .w-xxxs-40px {
        width: 40px !important;
    }

    .h-xxxs-40px {
        height: 40px !important;
    }

    .min-w-xxxs-40px {
        min-width: 40px !important;
    }

    .min-h-xxxs-40px {
        min-height: 40px !important;
    }

    .max-h-xxxs-40px {
        max-height: 40px !important;
    }

    .max-w-xxxs-40px {
        max-width: 40px !important;
    }

    .w-xxxs-45px {
        width: 45px !important;
    }

    .h-xxxs-45px {
        height: 45px !important;
    }

    .min-w-xxxs-45px {
        min-width: 45px !important;
    }

    .min-h-xxxs-45px {
        min-height: 45px !important;
    }

    .max-h-xxxs-45px {
        max-height: 45px !important;
    }

    .max-w-xxxs-45px {
        max-width: 45px !important;
    }

    .w-xxxs-50px {
        width: 50px !important;
    }

    .h-xxxs-50px {
        height: 50px !important;
    }

    .min-w-xxxs-50px {
        min-width: 50px !important;
    }

    .min-h-xxxs-50px {
        min-height: 50px !important;
    }

    .max-h-xxxs-50px {
        max-height: 50px !important;
    }

    .max-w-xxxs-50px {
        max-width: 50px !important;
    }

    .w-xxxs-55px {
        width: 55px !important;
    }

    .h-xxxs-55px {
        height: 55px !important;
    }

    .min-w-xxxs-55px {
        min-width: 55px !important;
    }

    .min-h-xxxs-55px {
        min-height: 55px !important;
    }

    .max-h-xxxs-55px {
        max-height: 55px !important;
    }

    .max-w-xxxs-55px {
        max-width: 55px !important;
    }

    .w-xxxs-60px {
        width: 60px !important;
    }

    .h-xxxs-60px {
        height: 60px !important;
    }

    .min-w-xxxs-60px {
        min-width: 60px !important;
    }

    .min-h-xxxs-60px {
        min-height: 60px !important;
    }

    .max-h-xxxs-60px {
        max-height: 60px !important;
    }

    .max-w-xxxs-60px {
        max-width: 60px !important;
    }

    .w-xxxs-65px {
        width: 65px !important;
    }

    .h-xxxs-65px {
        height: 65px !important;
    }

    .min-w-xxxs-65px {
        min-width: 65px !important;
    }

    .min-h-xxxs-65px {
        min-height: 65px !important;
    }

    .max-h-xxxs-65px {
        max-height: 65px !important;
    }

    .max-w-xxxs-65px {
        max-width: 65px !important;
    }

    .w-xxxs-70px {
        width: 70px !important;
    }

    .h-xxxs-70px {
        height: 70px !important;
    }

    .min-w-xxxs-70px {
        min-width: 70px !important;
    }

    .min-h-xxxs-70px {
        min-height: 70px !important;
    }

    .max-h-xxxs-70px {
        max-height: 70px !important;
    }

    .max-w-xxxs-70px {
        max-width: 70px !important;
    }

    .w-xxxs-75px {
        width: 75px !important;
    }

    .h-xxxs-75px {
        height: 75px !important;
    }

    .min-w-xxxs-75px {
        min-width: 75px !important;
    }

    .min-h-xxxs-75px {
        min-height: 75px !important;
    }

    .max-h-xxxs-75px {
        max-height: 75px !important;
    }

    .max-w-xxxs-75px {
        max-width: 75px !important;
    }

    .w-xxxs-80px {
        width: 80px !important;
    }

    .h-xxxs-80px {
        height: 80px !important;
    }

    .min-w-xxxs-80px {
        min-width: 80px !important;
    }

    .min-h-xxxs-80px {
        min-height: 80px !important;
    }

    .max-h-xxxs-80px {
        max-height: 80px !important;
    }

    .max-w-xxxs-80px {
        max-width: 80px !important;
    }

    .w-xxxs-85px {
        width: 85px !important;
    }

    .h-xxxs-85px {
        height: 85px !important;
    }

    .min-w-xxxs-85px {
        min-width: 85px !important;
    }

    .min-h-xxxs-85px {
        min-height: 85px !important;
    }

    .max-h-xxxs-85px {
        max-height: 85px !important;
    }

    .max-w-xxxs-85px {
        max-width: 85px !important;
    }

    .w-xxxs-90px {
        width: 90px !important;
    }

    .h-xxxs-90px {
        height: 90px !important;
    }

    .min-w-xxxs-90px {
        min-width: 90px !important;
    }

    .min-h-xxxs-90px {
        min-height: 90px !important;
    }

    .max-h-xxxs-90px {
        max-height: 90px !important;
    }

    .max-w-xxxs-90px {
        max-width: 90px !important;
    }

    .w-xxxs-95px {
        width: 95px !important;
    }

    .h-xxxs-95px {
        height: 95px !important;
    }

    .min-w-xxxs-95px {
        min-width: 95px !important;
    }

    .min-h-xxxs-95px {
        min-height: 95px !important;
    }

    .max-h-xxxs-95px {
        max-height: 95px !important;
    }

    .max-w-xxxs-95px {
        max-width: 95px !important;
    }

    .w-xxxs-100px {
        width: 100px !important;
    }

    .h-xxxs-100px {
        height: 100px !important;
    }

    .min-w-xxxs-100px {
        min-width: 100px !important;
    }

    .min-h-xxxs-100px {
        min-height: 100px !important;
    }

    .max-h-xxxs-100px {
        max-height: 100px !important;
    }

    .max-w-xxxs-100px {
        max-width: 100px !important;
    }

    .w-xxxs-125px {
        width: 125px !important;
    }

    .h-xxxs-125px {
        height: 125px !important;
    }

    .min-w-xxxs-125px {
        min-width: 125px !important;
    }

    .min-h-xxxs-125px {
        min-height: 125px !important;
    }

    .max-h-xxxs-125px {
        max-height: 125px !important;
    }

    .max-w-xxxs-125px {
        max-width: 125px !important;
    }

    .w-xxxs-150px {
        width: 150px !important;
    }

    .h-xxxs-150px {
        height: 150px !important;
    }

    .min-w-xxxs-150px {
        min-width: 150px !important;
    }

    .min-h-xxxs-150px {
        min-height: 150px !important;
    }

    .max-h-xxxs-150px {
        max-height: 150px !important;
    }

    .max-w-xxxs-150px {
        max-width: 150px !important;
    }

    .w-xxxs-175px {
        width: 175px !important;
    }

    .h-xxxs-175px {
        height: 175px !important;
    }

    .min-w-xxxs-175px {
        min-width: 175px !important;
    }

    .min-h-xxxs-175px {
        min-height: 175px !important;
    }

    .max-h-xxxs-175px {
        max-height: 175px !important;
    }

    .max-w-xxxs-175px {
        max-width: 175px !important;
    }

    .w-xxxs-200px {
        width: 200px !important;
    }

    .h-xxxs-200px {
        height: 200px !important;
    }

    .min-w-xxxs-200px {
        min-width: 200px !important;
    }

    .min-h-xxxs-200px {
        min-height: 200px !important;
    }

    .max-h-xxxs-200px {
        max-height: 200px !important;
    }

    .max-w-xxxs-200px {
        max-width: 200px !important;
    }

    .w-xxxs-225px {
        width: 225px !important;
    }

    .h-xxxs-225px {
        height: 225px !important;
    }

    .min-w-xxxs-225px {
        min-width: 225px !important;
    }

    .min-h-xxxs-225px {
        min-height: 225px !important;
    }

    .max-h-xxxs-225px {
        max-height: 225px !important;
    }

    .max-w-xxxs-225px {
        max-width: 225px !important;
    }

    .w-xxxs-250px {
        width: 250px !important;
    }

    .h-xxxs-250px {
        height: 250px !important;
    }

    .min-w-xxxs-250px {
        min-width: 250px !important;
    }

    .min-h-xxxs-250px {
        min-height: 250px !important;
    }

    .max-h-xxxs-250px {
        max-height: 250px !important;
    }

    .max-w-xxxs-250px {
        max-width: 250px !important;
    }

    .w-xxxs-275px {
        width: 275px !important;
    }

    .h-xxxs-275px {
        height: 275px !important;
    }

    .min-w-xxxs-275px {
        min-width: 275px !important;
    }

    .min-h-xxxs-275px {
        min-height: 275px !important;
    }

    .max-h-xxxs-275px {
        max-height: 275px !important;
    }

    .max-w-xxxs-275px {
        max-width: 275px !important;
    }

    .w-xxxs-300px {
        width: 300px !important;
    }

    .h-xxxs-300px {
        height: 300px !important;
    }

    .min-w-xxxs-300px {
        min-width: 300px !important;
    }

    .min-h-xxxs-300px {
        min-height: 300px !important;
    }

    .max-h-xxxs-300px {
        max-height: 300px !important;
    }

    .max-w-xxxs-300px {
        max-width: 300px !important;
    }

    .w-xxxs-325px {
        width: 325px !important;
    }

    .h-xxxs-325px {
        height: 325px !important;
    }

    .min-w-xxxs-325px {
        min-width: 325px !important;
    }

    .min-h-xxxs-325px {
        min-height: 325px !important;
    }

    .max-h-xxxs-325px {
        max-height: 325px !important;
    }

    .max-w-xxxs-325px {
        max-width: 325px !important;
    }

    .w-xxxs-350px {
        width: 350px !important;
    }

    .h-xxxs-350px {
        height: 350px !important;
    }

    .min-w-xxxs-350px {
        min-width: 350px !important;
    }

    .min-h-xxxs-350px {
        min-height: 350px !important;
    }

    .max-h-xxxs-350px {
        max-height: 350px !important;
    }

    .max-w-xxxs-350px {
        max-width: 350px !important;
    }

    .w-xxxs-375px {
        width: 375px !important;
    }

    .h-xxxs-375px {
        height: 375px !important;
    }

    .min-w-xxxs-375px {
        min-width: 375px !important;
    }

    .min-h-xxxs-375px {
        min-height: 375px !important;
    }

    .max-h-xxxs-375px {
        max-height: 375px !important;
    }

    .max-w-xxxs-375px {
        max-width: 375px !important;
    }

    .w-xxxs-400px {
        width: 400px !important;
    }

    .h-xxxs-400px {
        height: 400px !important;
    }

    .min-w-xxxs-400px {
        min-width: 400px !important;
    }

    .min-h-xxxs-400px {
        min-height: 400px !important;
    }

    .max-h-xxxs-400px {
        max-height: 400px !important;
    }

    .max-w-xxxs-400px {
        max-width: 400px !important;
    }

    .w-xxxs-425px {
        width: 425px !important;
    }

    .h-xxxs-425px {
        height: 425px !important;
    }

    .min-w-xxxs-425px {
        min-width: 425px !important;
    }

    .min-h-xxxs-425px {
        min-height: 425px !important;
    }

    .max-h-xxxs-425px {
        max-height: 425px !important;
    }

    .max-w-xxxs-425px {
        max-width: 425px !important;
    }

    .w-xxxs-450px {
        width: 450px !important;
    }

    .h-xxxs-450px {
        height: 450px !important;
    }

    .min-w-xxxs-450px {
        min-width: 450px !important;
    }

    .min-h-xxxs-450px {
        min-height: 450px !important;
    }

    .max-h-xxxs-450px {
        max-height: 450px !important;
    }

    .max-w-xxxs-450px {
        max-width: 450px !important;
    }

    .w-xxxs-475px {
        width: 475px !important;
    }

    .h-xxxs-475px {
        height: 475px !important;
    }

    .min-w-xxxs-475px {
        min-width: 475px !important;
    }

    .min-h-xxxs-475px {
        min-height: 475px !important;
    }

    .max-h-xxxs-475px {
        max-height: 475px !important;
    }

    .max-w-xxxs-475px {
        max-width: 475px !important;
    }

    .w-xxxs-500px {
        width: 500px !important;
    }

    .h-xxxs-500px {
        height: 500px !important;
    }

    .min-w-xxxs-500px {
        min-width: 500px !important;
    }

    .min-h-xxxs-500px {
        min-height: 500px !important;
    }

    .max-h-xxxs-500px {
        max-height: 500px !important;
    }

    .max-w-xxxs-500px {
        max-width: 500px !important;
    }

    .w-xxxs-550px {
        width: 550px !important;
    }

    .h-xxxs-550px {
        height: 550px !important;
    }

    .min-w-xxxs-550px {
        min-width: 550px !important;
    }

    .min-h-xxxs-550px {
        min-height: 550px !important;
    }

    .max-h-xxxs-550px {
        max-height: 550px !important;
    }

    .max-w-xxxs-550px {
        max-width: 550px !important;
    }

    .w-xxxs-600px {
        width: 600px !important;
    }

    .h-xxxs-600px {
        height: 600px !important;
    }

    .min-w-xxxs-600px {
        min-width: 600px !important;
    }

    .min-h-xxxs-600px {
        min-height: 600px !important;
    }

    .max-h-xxxs-600px {
        max-height: 600px !important;
    }

    .max-w-xxxs-600px {
        max-width: 600px !important;
    }

    .w-xxxs-650px {
        width: 650px !important;
    }

    .h-xxxs-650px {
        height: 650px !important;
    }

    .min-w-xxxs-650px {
        min-width: 650px !important;
    }

    .min-h-xxxs-650px {
        min-height: 650px !important;
    }

    .max-h-xxxs-650px {
        max-height: 650px !important;
    }

    .max-w-xxxs-650px {
        max-width: 650px !important;
    }

    .w-xxxs-700px {
        width: 700px !important;
    }

    .h-xxxs-700px {
        height: 700px !important;
    }

    .min-w-xxxs-700px {
        min-width: 700px !important;
    }

    .min-h-xxxs-700px {
        min-height: 700px !important;
    }

    .max-h-xxxs-700px {
        max-height: 700px !important;
    }

    .max-w-xxxs-700px {
        max-width: 700px !important;
    }

    .w-xxxs-750px {
        width: 750px !important;
    }

    .h-xxxs-750px {
        height: 750px !important;
    }

    .min-w-xxxs-750px {
        min-width: 750px !important;
    }

    .min-h-xxxs-750px {
        min-height: 750px !important;
    }

    .max-h-xxxs-750px {
        max-height: 750px !important;
    }

    .max-w-xxxs-750px {
        max-width: 750px !important;
    }

    .w-xxxs-800px {
        width: 800px !important;
    }

    .h-xxxs-800px {
        height: 800px !important;
    }

    .min-w-xxxs-800px {
        min-width: 800px !important;
    }

    .min-h-xxxs-800px {
        min-height: 800px !important;
    }

    .max-h-xxxs-800px {
        max-height: 800px !important;
    }

    .max-w-xxxs-800px {
        max-width: 800px !important;
    }

    .w-xxxs-850px {
        width: 850px !important;
    }

    .h-xxxs-850px {
        height: 850px !important;
    }

    .min-w-xxxs-850px {
        min-width: 850px !important;
    }

    .min-h-xxxs-850px {
        min-height: 850px !important;
    }

    .max-h-xxxs-850px {
        max-height: 850px !important;
    }

    .max-w-xxxs-850px {
        max-width: 850px !important;
    }

    .w-xxxs-900px {
        width: 900px !important;
    }

    .h-xxxs-900px {
        height: 900px !important;
    }

    .min-w-xxxs-900px {
        min-width: 900px !important;
    }

    .min-h-xxxs-900px {
        min-height: 900px !important;
    }

    .max-h-xxxs-900px {
        max-height: 900px !important;
    }

    .max-w-xxxs-900px {
        max-width: 900px !important;
    }

    .w-xxxs-950px {
        width: 950px !important;
    }

    .h-xxxs-950px {
        height: 950px !important;
    }

    .min-w-xxxs-950px {
        min-width: 950px !important;
    }

    .min-h-xxxs-950px {
        min-height: 950px !important;
    }

    .max-h-xxxs-950px {
        max-height: 950px !important;
    }

    .max-w-xxxs-950px {
        max-width: 950px !important;
    }

    .w-xxxs-1000px {
        width: 1000px !important;
    }

    .h-xxxs-1000px {
        height: 1000px !important;
    }

    .min-w-xxxs-1000px {
        min-width: 1000px !important;
    }

    .min-h-xxxs-1000px {
        min-height: 1000px !important;
    }

    .max-h-xxxs-1000px {
        max-height: 1000px !important;
    }

    .max-w-xxxs-1000px {
        max-width: 1000px !important;
    }
}