.btn-contacto{
    background-color:#ebf5ff;
    width: 28px!important;
    height: 28px!important;
    i{
        color:#318ce9;
    }
    &:hover, &:active, &:focus{
        background-color:#318ce9;
        i{
            color:white;
        }
    }
}

.btn.btn-primary.dropdown-toggle:after{
    display:none;
}

.btn-negociacion{
    background-color:#EFEBE9;
    width: 28px!important;
    height: 28px!important;
    i{
        color:#5D4037;
    }
    &:hover, &:active, &:focus{
        background-color:#5D4037;
        i{
            color:white;
        }
    }
}
.btn-detenidos{
    background-color:#ECEFF1;
    width: 28px!important;
    height: 28px!important;
    i{
        color:#5F6A6A;
    }
    &:hover, &:active, &:focus{
        background-color:#5F6A6A;
        i{
            color:white;
        }
    }
}
.btn-rh-edit{
    background-color:#fbeff7;
    i{
        color:#e44e93;
    }
    &:hover, &:active, &:focus{
        background-color:#e44e93;
        i{
            color:white;
        }
    }
}
.btn-rp{
    background-color:#fff2d9;
    i{
        color:#D08905 ;
    }
    &:hover, &:active, &:focus{
        background-color:#D08905 ;
        i{
            color:white;
        }
    }
}

.btn.btn-light-primary2 {
    color: #009ef7;
    border-color: #ecf8ff;
    background-color: #ecf8ff;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.btn.btn-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #009ef7
}

.btn.btn-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-light-primary2.active,
.btn.btn-light-primary2.show,
.btn.btn-light-primary2:active:not(.btn-active),
.btn.btn-light-primary2:focus:not(.btn-active),
.btn.btn-light-primary2:hover:not(.btn-active),
.show>.btn.btn-light-primary2 {
    color: #fff;
    border-color: #009ef7;
    background-color: #009ef7 !important
}

.btn.btn-light-primary2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn.btn-primary2 {
    color: #fff;
    border-color: #009ef7;
    background-color: #009ef7
}

.btn.btn-primary2 i {
    color: #fff
}

.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-primary2.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-primary2,
.btn-check:checked+.btn.btn-primary2,
.btn.btn-primary2.active,
.btn.btn-primary2.show,
.btn.btn-primary2:active:not(.btn-active),
.btn.btn-primary2:focus:not(.btn-active),
.btn.btn-primary2:hover:not(.btn-active),
.show>.btn.btn-primary2 {
    color: #fff;
    border-color: #0095e8;
    background-color: #0095e8
}

.btn-check:active+.btn.btn-primary2 i,
.btn-check:checked+.btn.btn-primary2 i,
.btn.btn-primary2.active i,
.btn.btn-primary2.show i,
.btn.btn-primary2:active:not(.btn-active) i,
.btn.btn-primary2:focus:not(.btn-active) i,
.btn.btn-primary2:hover:not(.btn-active) i,
.show>.btn.btn-primary2 i {
    color: #fff
}

.btn-check:active+.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-primary2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-primary2.dropdown-toggle:after,
.btn.btn-primary2.active.dropdown-toggle:after,
.btn.btn-primary2.show.dropdown-toggle:after,
.btn.btn-primary2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-primary2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-primary2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-primary2.dropdown-toggle:after {
    color: #fff
}

.text-primary2 {
    color: #007af0 !important;
}

.svg-icon.svg-icon-primary2 svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #007af0
}

.svg-icon.svg-icon-primary2 svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-primary2,
.btn-check:checked+.btn.btn-active-light-primary2,
.btn.btn-active-light-primary2.active,
.btn.btn-active-light-primary2.show,
.btn.btn-active-light-primary2:active:not(.btn-active),
.btn.btn-active-light-primary2:focus:not(.btn-active),
.btn.btn-active-light-primary2:hover:not(.btn-active),
.show>.btn.btn-active-light-primary2 {
    color: #009ef7;
    border-color: #ecf8ff;
    background-color: #ecf8ff
}

.btn-check:active+.btn.btn-active-light-primary2 i,
.btn-check:checked+.btn.btn-active-light-primary2 i,
.btn.btn-active-light-primary2.active i,
.btn.btn-active-light-primary2.show i,
.btn.btn-active-light-primary2:active:not(.btn-active) i,
.btn.btn-active-light-primary2:focus:not(.btn-active) i,
.btn.btn-active-light-primary2:hover:not(.btn-active) i,
.show>.btn.btn-active-light-primary2 i {
    color: #009ef7
}

.btn-check:active+.btn.btn-active-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #009ef7
}

.btn-check:active+.btn.btn-active-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-primary2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-light-primary2.dropdown-toggle:after,
.btn.btn-active-light-primary2.active.dropdown-toggle:after,
.btn.btn-active-light-primary2.show.dropdown-toggle:after,
.btn.btn-active-light-primary2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-primary2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-primary2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-light-primary2.dropdown-toggle:after {
    color: #009ef7
}

.btn.btn-active-light-primary2.btn-outline:not(.btn-outline-default) {
    border-color: #009ef7 !important
}

.btn.btn-color-primary2 {
    color: #009ef7
}

.btn.btn-color-primary2 i {
    color: #009ef7
}

.btn.btn-color-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #009ef7
}

.btn.btn-color-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-color-primary2.dropdown-toggle:after {
    color: #009ef7
}

.symbol.symbol-light-primary2 .symbol-label {
    color: #009ef7;
    background-color: #ecf8ff;
}

.symbol.symbol-light-success2 .symbol-label {
    color: #26A69A;
    background-color: #e4f8f7;
}

.table-notas thead {
    font-weight: 600;
    color: #212121 !important;
    font-size: 1rem;
    letter-spacing: 0.1rem;
}

.table-notas td {
    border-top: 1px dashed #ECF0F3;
}

.btn.btn-danger {
    color: #fff;
    border-color: #f1416c;
    background-color: #f1416c
}

.btn.btn-danger i {
    color: #fff
}

.btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-danger.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-danger,
.btn-check:checked+.btn.btn-danger,
.btn.btn-danger.active,
.btn.btn-danger.show,
.btn.btn-danger:active:not(.btn-active),
.btn.btn-danger:focus:not(.btn-active),
.btn.btn-danger:hover:not(.btn-active),
.show>.btn.btn-danger {
    color: #fff;
    border-color: #d9214e;
    background-color: #d9214e
}

.btn-check:active+.btn.btn-danger i,
.btn-check:checked+.btn.btn-danger i,
.btn.btn-danger.active i,
.btn.btn-danger.show i,
.btn.btn-danger:active:not(.btn-active) i,
.btn.btn-danger:focus:not(.btn-active) i,
.btn.btn-danger:hover:not(.btn-active) i,
.show>.btn.btn-danger i {
    color: #fff
}

.btn-check:active+.btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-danger.dropdown-toggle:after,
.btn-check:checked+.btn.btn-danger.dropdown-toggle:after,
.btn.btn-danger.active.dropdown-toggle:after,
.btn.btn-danger.show.dropdown-toggle:after,
.btn.btn-danger:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-danger:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-danger:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-danger.dropdown-toggle:after {
    color: #fff
}

.btn.btn-light-danger {
    color: #f1416c;
    border-color: #fff5f8;
    background-color: #fff5f8
}

.btn.btn-light-danger i {
    color: #f1416c
}

.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #f1416c
}

.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-light-danger.dropdown-toggle:after {
    color: #f1416c
}

.btn-check:active+.btn.btn-light-danger,
.btn-check:checked+.btn.btn-light-danger,
.btn.btn-light-danger.active,
.btn.btn-light-danger.show,
.btn.btn-light-danger:active:not(.btn-active),
.btn.btn-light-danger:focus:not(.btn-active),
.btn.btn-light-danger:hover:not(.btn-active),
.show>.btn.btn-light-danger {
    color: #fff;
    border-color: #f1416c;
    background-color: #f1416c
}

.btn-check:active+.btn.btn-light-danger i,
.btn-check:checked+.btn.btn-light-danger i,
.btn.btn-light-danger.active i,
.btn.btn-light-danger.show i,
.btn.btn-light-danger:active:not(.btn-active) i,
.btn.btn-light-danger:focus:not(.btn-active) i,
.btn.btn-light-danger:hover:not(.btn-active) i,
.show>.btn.btn-light-danger i {
    color: #fff
}

.btn-check:active+.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-light-danger.dropdown-toggle:after,
.btn-check:checked+.btn.btn-light-danger.dropdown-toggle:after,
.btn.btn-light-danger.active.dropdown-toggle:after,
.btn.btn-light-danger.show.dropdown-toggle:after,
.btn.btn-light-danger:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-light-danger:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-light-danger:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-light-danger.dropdown-toggle:after {
    color: #fff
}
///////////////////////////////////////////////////////////////////////////////////

.btn.btn-light-danger {
    color: #f1416c;
    border-color: #fff5f8;
    background-color: #fff5f8
}

.btn.btn-light-danger i {
    color: #f1416c
}

.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #f1416c
}

.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-light-danger.dropdown-toggle:after {
    color: #f1416c
}

.btn-check:active+.btn.btn-light-danger,
.btn-check:checked+.btn.btn-light-danger,
.btn.btn-light-danger.active,
.btn.btn-light-danger.show,
.btn.btn-light-danger:active:not(.btn-active),
.btn.btn-light-danger:focus:not(.btn-active),
.btn.btn-light-danger:hover:not(.btn-active),
.show>.btn.btn-light-danger {
    color: #fff;
    border-color: #f1416c;
    background-color: #f1416c
}

.btn-check:active+.btn.btn-light-danger i,
.btn-check:checked+.btn.btn-light-danger i,
.btn.btn-light-danger.active i,
.btn.btn-light-danger.show i,
.btn.btn-light-danger:active:not(.btn-active) i,
.btn.btn-light-danger:focus:not(.btn-active) i,
.btn.btn-light-danger:hover:not(.btn-active) i,
.show>.btn.btn-light-danger i {
    color: #fff
}

.btn-check:active+.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-light-danger.dropdown-toggle:after,
.btn-check:checked+.btn.btn-light-danger.dropdown-toggle:after,
.btn.btn-light-danger.active.dropdown-toggle:after,
.btn.btn-light-danger.show.dropdown-toggle:after,
.btn.btn-light-danger:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-light-danger:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-light-danger:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-light-danger.dropdown-toggle:after {
    color: #fff
}
///////////////////////////////////////////////////////////////////////////////////

.btn.btn-bg-danger {
    border-color: #f1416c;
    background-color: #f1416c
}

.btn-check:active+.btn.btn-active-danger,
.btn-check:checked+.btn.btn-active-danger,
.btn.btn-active-danger.active,
.btn.btn-active-danger.show,
.btn.btn-active-danger:active:not(.btn-active),
.btn.btn-active-danger:focus:not(.btn-active),
.btn.btn-active-danger:hover:not(.btn-active),
.show>.btn.btn-active-danger {
    color: #fff;
    border-color: #f1416c;
    background-color: #f1416c
}

.btn-check:active+.btn.btn-active-danger i,
.btn-check:checked+.btn.btn-active-danger i,
.btn.btn-active-danger.active i,
.btn.btn-active-danger.show i,
.btn.btn-active-danger:active:not(.btn-active) i,
.btn.btn-active-danger:focus:not(.btn-active) i,
.btn.btn-active-danger:hover:not(.btn-active) i,
.show>.btn.btn-active-danger i {
    color: #fff
}

.btn-check:active+.btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-danger.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-danger.dropdown-toggle:after,
.btn.btn-active-danger.active.dropdown-toggle:after,
.btn.btn-active-danger.show.dropdown-toggle:after,
.btn.btn-active-danger:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-danger:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-danger:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-danger.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-active-light-danger,
.btn-check:checked+.btn.btn-active-light-danger,
.btn.btn-active-light-danger.active,
.btn.btn-active-light-danger.show,
.btn.btn-active-light-danger:active:not(.btn-active),
.btn.btn-active-light-danger:focus:not(.btn-active),
.btn.btn-active-light-danger:hover:not(.btn-active),
.show>.btn.btn-active-light-danger {
    color: #f1416c;
    border-color: #fff5f8;
    background-color: #fff5f8
}

.btn-check:active+.btn.btn-active-light-danger i,
.btn-check:checked+.btn.btn-active-light-danger i,
.btn.btn-active-light-danger.active i,
.btn.btn-active-light-danger.show i,
.btn.btn-active-light-danger:active:not(.btn-active) i,
.btn.btn-active-light-danger:focus:not(.btn-active) i,
.btn.btn-active-light-danger:hover:not(.btn-active) i,
.show>.btn.btn-active-light-danger i {
    color: #f1416c
}

.btn-check:active+.btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #f1416c
}

.btn-check:active+.btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-danger:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-danger.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-light-danger.dropdown-toggle:after,
.btn.btn-active-light-danger.active.dropdown-toggle:after,
.btn.btn-active-light-danger.show.dropdown-toggle:after,
.btn.btn-active-light-danger:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-danger:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-danger:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-light-danger.dropdown-toggle:after {
    color: #f1416c
}

.btn.btn-active-light-danger.btn-outline:not(.btn-outline-default) {
    border-color: #f1416c !important
}

.btn.btn-color-danger {
    color: #f1416c
}

.btn.btn-color-danger i {
    color: #f1416c
}

.btn.btn-color-danger .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #f1416c
}

.btn.btn-color-danger .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-color-danger.dropdown-toggle:after {
    color: #f1416c
}

.btn-success2 {
    color: #000;
    background-color: #26A69A;
    border-color: #26A69A;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075)
}

.btn-success2:hover {
    color: #000;
    background-color: #6ad59b;
    border-color: #62d295
}

.btn-check:focus+.btn-success2,
.btn-success2:focus {
    color: #000;
    background-color: #6ad59b;
    border-color: #62d295;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075), 0 0 0 .25rem rgba(68, 174, 116, .5)
}

.btn-check:active+.btn-success2,
.btn-check:checked+.btn-success2,
.btn-success2.active,
.btn-success2:active,
.show>.btn-success2.dropdown-toggle {
    color: #000;
    background-color: #73d7a1;
    border-color: #62d295
}

.btn-check:active+.btn-success2:focus,
.btn-check:checked+.btn-success2:focus,
.btn-success2.active:focus,
.btn-success2:active:focus,
.show>.btn-success2.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125), 0 0 0 .25rem rgba(68, 174, 116, .5)
}

.btn-success2.disabled,
.btn-success2:disabled {
    color: #000;
    background-color: #26A69A;
    border-color: #26A69A
}

.btn.btn-success2 {
    color: #fff;
    border-color: #26A69A;
    background-color: #26A69A
}

.btn.btn-success2 i {
    color: #fff
}

.btn.btn-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn.btn-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-success2.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-success2,
.btn-check:checked+.btn.btn-success2,
.btn.btn-success2.active,
.btn.btn-success2.show,
.btn.btn-success2:active:not(.btn-active),
.btn.btn-success2:focus:not(.btn-active),
.btn.btn-success2:hover:not(.btn-active),
.show>.btn.btn-success2 {
    color: #fff;
    border-color: #47be7d;
    background-color: #47be7d
}

.btn-check:active+.btn.btn-success2 i,
.btn-check:checked+.btn.btn-success2 i,
.btn.btn-success2.active i,
.btn.btn-success2.show i,
.btn.btn-success2:active:not(.btn-active) i,
.btn.btn-success2:focus:not(.btn-active) i,
.btn.btn-success2:hover:not(.btn-active) i,
.show>.btn.btn-success2 i {
    color: #fff
}

.btn-check:active+.btn.btn-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-success2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-success2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-success2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-success2.dropdown-toggle:after,
.btn.btn-success2.active.dropdown-toggle:after,
.btn.btn-success2.show.dropdown-toggle:after,
.btn.btn-success2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-success2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-success2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-success2.dropdown-toggle:after {
    color: #fff
}

.btn.btn-light-success2 {
    color: #26A69A;
    border-color: #e4f8f7;
    background-color: #e4f8f7
}

.btn.btn-light-success2 i {
    color: #26A69A
}

.btn.btn-light-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #26A69A
}

.btn.btn-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-light-success2.dropdown-toggle:after {
    color: #26A69A
}

.btn-check:active+.btn.btn-light-success2,
.btn-check:checked+.btn.btn-light-success2,
.btn.btn-light-success2.active,
.btn.btn-light-success2.show,
.btn.btn-light-success2:active:not(.btn-active),
.btn.btn-light-success2:focus:not(.btn-active),
.btn.btn-light-success2:hover:not(.btn-active),
.show>.btn.btn-light-success2 {
    color: #fff;
    border-color: #26A69A;
    background-color: #26A69A
}

.btn-check:active+.btn.btn-light-success2 i,
.btn-check:checked+.btn.btn-light-success2 i,
.btn.btn-light-success2.active i,
.btn.btn-light-success2.show i,
.btn.btn-light-success2:active:not(.btn-active) i,
.btn.btn-light-success2:focus:not(.btn-active) i,
.btn.btn-light-success2:hover:not(.btn-active) i,
.show>.btn.btn-light-success2 i {
    color: #fff
}

.btn-check:active+.btn.btn-light-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-light-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-success2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-light-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-light-success2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-light-success2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-light-success2.dropdown-toggle:after,
.btn.btn-light-success2.active.dropdown-toggle:after,
.btn.btn-light-success2.show.dropdown-toggle:after,
.btn.btn-light-success2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-light-success2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-light-success2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-light-success2.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-active-light-success2,
.btn-check:checked+.btn.btn-active-light-success2,
.btn.btn-active-light-success2.active,
.btn.btn-active-light-success2.show,
.btn.btn-active-light-success2:active:not(.btn-active),
.btn.btn-active-light-success2:focus:not(.btn-active),
.btn.btn-active-light-success2:hover:not(.btn-active),
.show>.btn.btn-active-light-success2 {
    color: #26A69A;
    border-color: #e4f8f7;
    background-color: #e4f8f7
}

.btn-check:active+.btn.btn-active-light-success2 i,
.btn-check:checked+.btn.btn-active-light-success2 i,
.btn.btn-active-light-success2.active i,
.btn.btn-active-light-success2.show i,
.btn.btn-active-light-success2:active:not(.btn-active) i,
.btn.btn-active-light-success2:focus:not(.btn-active) i,
.btn.btn-active-light-success2:hover:not(.btn-active) i,
.show>.btn.btn-active-light-success2 i {
    color: #26A69A
}

.btn-check:active+.btn.btn-active-light-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-success2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #26A69A
}

.btn-check:active+.btn.btn-active-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-success2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-success2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-light-success2.dropdown-toggle:after,
.btn.btn-active-light-success2.active.dropdown-toggle:after,
.btn.btn-active-light-success2.show.dropdown-toggle:after,
.btn.btn-active-light-success2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-success2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-success2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-light-success2.dropdown-toggle:after {
    color: #26A69A
}

.btn.btn-active-light-success2.btn-outline:not(.btn-outline-default) {
    border-color: #26A69A !important
}

.btn.btn-color-success2 {
    color: #26A69A
}

.btn.btn-color-success2 i {
    color: #26A69A
}

.btn.btn-color-success2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #26A69A
}

.btn.btn-color-success2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-color-success2.dropdown-toggle:after {
    color: #26A69A
}

.btn.btn-color-info2 {
    color: #7239ea
}

.btn.btn-color-info2 i {
    color: #7239ea
}

.btn.btn-color-info2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #7239ea
}

.btn.btn-color-info2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-color-info2.dropdown-toggle:after {
    color: #7239ea
}

.btn-check:active+.btn.btn-active-light-info2,
.btn-check:checked+.btn.btn-active-light-info2,
.btn.btn-active-light-info2.active,
.btn.btn-active-light-info2.show,
.btn.btn-active-light-info2:active:not(.btn-active),
.btn.btn-active-light-info2:focus:not(.btn-active),
.btn.btn-active-light-info2:hover:not(.btn-active),
.show>.btn.btn-active-light-info2 {
    color: #7239ea;
    border-color: #f8f5ff;
    background-color: #f8f5ff;
}

.btn-check:active+.btn.btn-active-light-info2 i,
.btn-check:checked+.btn.btn-active-light-info2 i,
.btn.btn-active-light-info2.active i,
.btn.btn-active-light-info2.show i,
.btn.btn-active-light-info2:active:not(.btn-active) i,
.btn.btn-active-light-info2:focus:not(.btn-active) i,
.btn.btn-active-light-info2:hover:not(.btn-active) i,
.show>.btn.btn-active-light-info2 i {
    color: #7239ea
}

.btn-check:active+.btn.btn-active-light-info2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-info2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-info2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #7239ea
}

.btn-check:active+.btn.btn-active-light-info2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-info2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-info2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-info2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-info2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-light-info2.dropdown-toggle:after,
.btn.btn-active-light-info2.active.dropdown-toggle:after,
.btn.btn-active-light-info2.show.dropdown-toggle:after,
.btn.btn-active-light-info2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-info2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-info2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-light-info2.dropdown-toggle:after {
    color: #7239ea
}

.btn.btn-active-light-info2.btn-outline:not(.btn-outline-default) {
    border-color: #7239ea !important
}

.btn {
    outline: none !important;
    vertical-align: middle;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn:hover:not(.btn-text),
.btn:focus:not(.btn-text),
.btn.focus {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn:hover:not(.btn-text) i,
.btn:focus:not(.btn-text) i,
.btn.focus i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn.disabled,
.btn:disabled {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn.disabled i,
.btn:disabled i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn:not(:disabled):not(.disabled):active:not(.btn-text),
.btn:not(:disabled):not(.disabled).active,
.show>.btn.dropdown-toggle {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn:not(:disabled):not(.disabled).active i,
.show>.btn.dropdown-toggle i {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.btn:focus:not(.btn-shadow):not(.btn-shadow-hover) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn.btn-square {
    border-radius: 0;
}

.btn.btn-pill {
    border-radius: 2rem;
}

.btn.btn-text {
    cursor: text;
}

.btn:not(.btn-text) {
    cursor: pointer;
}

.btn.btn-borderless {
    border-color: transparent;
}

.btn i {
    font-size: 1.3rem;
    padding-right: 0.35rem;
    vertical-align: middle;
    line-height: 1;
}

.btn i:after,
.btn i:before {
    line-height: 0;
}

.btn .svg-icon {
    margin-right: 0.5rem;
}

.btn .svg-icon svg {
    height: 18px;
    width: 18px;
}

.btn.btn-xs i {
    font-size: 1rem;
    padding-right: 0.3rem;
}

.btn.btn-xs .svg-icon {
    margin-right: 0.3rem;
}

.btn.btn-xs .svg-icon svg {
    height: 16px;
    width: 16px;
}

.btn.btn-sm i,
.btn-group-sm>.btn i {
    font-size: 1.3rem;
    padding-right: 0.35rem;
}

.btn.btn-sm .svg-icon,
.btn-group-sm>.btn .svg-icon {
    margin-right: 0.35rem;
}

.btn.btn-sm .svg-icon svg,
.btn-group-sm>.btn .svg-icon svg {
    height: 16px;
    width: 16px;
}

.btn.btn-lg i,
.btn-group-lg>.btn i {
    font-size: 1.5rem;
    padding-right: 0.75rem;
}

.btn.btn-lg .svg-icon,
.btn-group-lg>.btn .svg-icon {
    margin-right: 0.75rem;
}

.btn.btn-lg .svg-icon svg,
.btn-group-lg>.btn .svg-icon svg {
    height: 20px;
    width: 20px;
}

.btn.btn-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    height: calc(1.5em + 1.3rem + 2px);
    width: calc(1.5em + 1.3rem + 2px);
}

.btn.btn-icon.btn-xs {
    height: 24px;
    width: 24px;
}

.btn.btn-icon.btn-sm,
.btn-group-sm>.btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: calc(1.35em + 1.1rem + 2px);
}

.btn.btn-icon.btn-lg,
.btn-group-lg>.btn.btn-icon {
    height: calc(1.5em + 1.65rem + 2px);
    width: calc(1.5em + 1.65rem + 2px);
}

.btn.btn-icon.btn-circle {
    border-radius: 50%;
}

.btn.btn-icon i {
    padding: 0;
    margin: 0;
}

.btn.btn-icon [class^="socicon-"]:before,
.btn.btn-icon [class*=" socicon-"]:before {
    line-height: 1;
}

.btn.btn-icon .svg-icon {
    margin: 0;
    padding: 0;
}

.btn.btn-fh {
    padding-top: 0;
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.btn.btn-fh.btn {
    height: calc(1.5em + 1.3rem + 2px);
}

.btn.btn-fh.btn-sm,
.btn-group-sm>.btn.btn-fh {
    height: calc(1.35em + 1.1rem + 2px);
}

.btn.btn-fh.btn-lg,
.btn-group-lg>.btn.btn-fh {
    height: calc(1.5em + 1.65rem + 2px);
}

// btn.btn-light-orange 
.btn.btn-light-orange {
    color: #D08905;
    background-color: #EFEBE9;
    border-color: transparent;
}

.btn.btn-light-orange i {
    color: #D08905;
}

.btn.btn-light-orange .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D08905;
}

.btn.btn-light-orange .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-orange.dropdown-toggle:after {
    color: #D08905;
}

.btn.btn-light-orange:hover:not(.btn-text),
.btn.btn-light-orange:focus:not(.btn-text),
.btn.btn-light-orange.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #D08905;
    border-color: transparent;
}

.btn.btn-light-orange:hover:not(.btn-text) i,
.btn.btn-light-orange:focus:not(.btn-text) i,
.btn.btn-light-orange.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn.btn-light-orange:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-orange:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-orange.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-orange:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-orange:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-orange.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-orange:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-orange:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-orange.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-light-orange.disabled,
.btn.btn-light-orange:disabled {
    color: #D08905;
    background-color: #EEE5FF;
    border-color: transparent;
}

.btn.btn-light-orange.disabled i,
.btn.btn-light-orange:disabled i {
    color: #D08905;
}

.btn.btn-light-orange.disabled .svg-icon svg g [fill],
.btn.btn-light-orange:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D08905;
}

.btn.btn-light-orange.disabled .svg-icon svg:hover g [fill],
.btn.btn-light-orange:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-orange.disabled.dropdown-toggle:after,
.btn.btn-light-orange:disabled.dropdown-toggle:after {
    color: #D08905;
}

.btn.btn-light-orange:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-orange:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-orange.dropdown-toggle,
.show .btn.btn-light-orange.btn-dropdown {
    color: #ffffff;
    background-color: #D08905;
    border-color: transparent;
}

.btn.btn-light-orange:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-light-orange:not(:disabled):not(.disabled).active i,
.show>.btn.btn-light-orange.dropdown-toggle i,
.show .btn.btn-light-orange.btn-dropdown i {
    color: #ffffff;
}

.btn.btn-light-orange:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-orange:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-light-orange.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-orange.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-orange:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-orange:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-light-orange.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-orange.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-orange:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-orange:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-light-orange.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-orange.btn-dropdown.dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-hover-text-orange:hover:not(.btn-text),
.btn.btn-hover-text-orange:focus:not(.btn-text),
.btn.btn-hover-text-orange.focus:not(.btn-text) {
    color: #D08905 !important;
}

.btn.btn-hover-text-orange:hover:not(.btn-text) i,
.btn.btn-hover-text-orange:focus:not(.btn-text) i,
.btn.btn-hover-text-orange.focus:not(.btn-text) i {
    color: #D08905 !important;
}

.btn.btn-hover-text-orange:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-orange:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-orange.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D08905 !important;
}

.btn.btn-hover-text-orange:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-orange:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-orange.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-orange:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-orange:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-orange.focus:not(.btn-text).dropdown-toggle:after {
    color: #D08905 !important;
}

.btn.btn-hover-text-orange:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-text-orange:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-text-orange.dropdown-toggle,
.show .btn.btn-hover-text-orange.btn-dropdown {
    color: #D08905 !important;
}

.btn.btn-hover-text-orange:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-hover-text-orange:not(:disabled):not(.disabled).active i,
.show>.btn.btn-hover-text-orange.dropdown-toggle i,
.show .btn.btn-hover-text-orange.btn-dropdown i {
    color: #D08905 !important;
}

.btn.btn-hover-text-orange:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-orange:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-hover-text-orange.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-orange.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #D08905 !important;
}

.btn.btn-hover-text-orange:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-orange:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-text-orange.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-orange.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-orange:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-orange:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-hover-text-orange.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-orange.btn-dropdown.dropdown-toggle:after {
    color: #D08905 !important;
}

.btn.btn-light-pink {
    color: #e44e93;
    background-color: #fbeff7;
    border-color: transparent;
}

.btn.btn-light-pink i {
    color: #e44e93;
}

.btn.btn-light-pink .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e44e93;
}

.btn.btn-light-pink .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pink.dropdown-toggle:after {
    color: #e44e93;
}

.btn.btn-light-pink:hover:not(.btn-text),
.btn.btn-light-pink:focus:not(.btn-text),
.btn.btn-light-pink.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #e44e93;
    border-color: transparent;
}

.btn.btn-light-pink:hover:not(.btn-text) i,
.btn.btn-light-pink:focus:not(.btn-text) i,
.btn.btn-light-pink.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn.btn-light-pink:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-pink:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-pink.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-pink:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-pink:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-pink.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pink:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-pink:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-pink.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-light-pink.disabled,
.btn.btn-light-pink:disabled {
    color: #e44e93;
    background-color: #fbeff7;
    border-color: transparent;
}

.btn.btn-light-pink.disabled i,
.btn.btn-light-pink:disabled i {
    color: #e44e93;
}

.btn.btn-light-pink.disabled .svg-icon svg g [fill],
.btn.btn-light-pink:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e44e93;
}

.btn.btn-light-pink.disabled .svg-icon svg:hover g [fill],
.btn.btn-light-pink:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pink.disabled.dropdown-toggle:after,
.btn.btn-light-pink:disabled.dropdown-toggle:after {
    color: #e44e93;
}

.btn.btn-light-pink:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-pink:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-pink.dropdown-toggle,
.show .btn.btn-light-pink.btn-dropdown {
    color: #ffffff;
    background-color: #e44e93;
    border-color: transparent;
}

.btn.btn-light-pink:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-light-pink:not(:disabled):not(.disabled).active i,
.show>.btn.btn-light-pink.dropdown-toggle i,
.show .btn.btn-light-pink.btn-dropdown i {
    color: #ffffff;
}

.btn.btn-light-pink:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-pink:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-light-pink.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-pink.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-pink:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-pink:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-light-pink.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-pink.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-pink:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-pink:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-light-pink.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-pink.btn-dropdown.dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-hover-text-pink:hover:not(.btn-text),
.btn.btn-hover-text-pink:focus:not(.btn-text),
.btn.btn-hover-text-pink.focus:not(.btn-text) {
    color: #e44e93 !important;
}

.btn.btn-hover-text-pink:hover:not(.btn-text) i,
.btn.btn-hover-text-pink:focus:not(.btn-text) i,
.btn.btn-hover-text-pink.focus:not(.btn-text) i {
    color: #e44e93 !important;
}

.btn.btn-hover-text-pink:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-pink:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-pink.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e44e93 !important;
}

.btn.btn-hover-text-pink:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-pink:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-pink.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-pink:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-pink:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-pink.focus:not(.btn-text).dropdown-toggle:after {
    color: #e44e93 !important;
}

.btn.btn-hover-text-pink:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-text-pink:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-text-pink.dropdown-toggle,
.show .btn.btn-hover-text-pink.btn-dropdown {
    color: #e44e93 !important;
}

.btn.btn-hover-text-pink:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-hover-text-pink:not(:disabled):not(.disabled).active i,
.show>.btn.btn-hover-text-pink.dropdown-toggle i,
.show .btn.btn-hover-text-pink.btn-dropdown i {
    color: #e44e93 !important;
}

.btn.btn-hover-text-pink:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-pink:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-hover-text-pink.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-pink.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #e44e93 !important;
}

.btn.btn-hover-text-pink:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-pink:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-text-pink.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-pink.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-pink:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-pink:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-hover-text-pink.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-pink.btn-dropdown.dropdown-toggle:after {
    color: #e44e93 !important;
}

.btn.btn-light-gray {
    color: #5F6A6A;
    background-color: #ECEFF1;
    border-color: transparent;
}

.btn.btn-light-gray i {
    color: #5F6A6A;
}

.btn.btn-light-gray .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5F6A6A;
}

.btn.btn-light-gray .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-gray.dropdown-toggle:after {
    color: #5F6A6A;
}

.btn.btn-light-gray:hover:not(.btn-text),
.btn.btn-light-gray:focus:not(.btn-text),
.btn.btn-light-gray.focus:not(.btn-text) {
    color: #ffffff;
    background-color: #5F6A6A;
    border-color: transparent;
}

.btn.btn-light-gray:hover:not(.btn-text) i,
.btn.btn-light-gray:focus:not(.btn-text) i,
.btn.btn-light-gray.focus:not(.btn-text) i {
    color: #ffffff;
}

.btn.btn-light-gray:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-gray:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-gray.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-gray:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-gray:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-gray.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-gray:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-gray:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-gray.focus:not(.btn-text).dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-light-gray.disabled,
.btn.btn-light-gray:disabled {
    color: #5F6A6A;
    background-color: #EEE5FF;
    border-color: transparent;
}

.btn.btn-light-gray.disabled i,
.btn.btn-light-gray:disabled i {
    color: #5F6A6A;
}

.btn.btn-light-gray.disabled .svg-icon svg g [fill],
.btn.btn-light-gray:disabled .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5F6A6A;
}

.btn.btn-light-gray.disabled .svg-icon svg:hover g [fill],
.btn.btn-light-gray:disabled .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-gray.disabled.dropdown-toggle:after,
.btn.btn-light-gray:disabled.dropdown-toggle:after {
    color: #5F6A6A;
}

.btn.btn-light-gray:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-light-gray:not(:disabled):not(.disabled).active,
.show>.btn.btn-light-gray.dropdown-toggle,
.show .btn.btn-light-gray.btn-dropdown {
    color: #ffffff;
    background-color: #5F6A6A;
    border-color: transparent;
}

.btn.btn-light-gray:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-light-gray:not(:disabled):not(.disabled).active i,
.show>.btn.btn-light-gray.dropdown-toggle i,
.show .btn.btn-light-gray.btn-dropdown i {
    color: #ffffff;
}

.btn.btn-light-gray:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-light-gray:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-light-gray.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-light-gray.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff;
}

.btn.btn-light-gray:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-light-gray:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-light-gray.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-light-gray.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-light-gray:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-light-gray:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-light-gray.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-light-gray.btn-dropdown.dropdown-toggle:after {
    color: #ffffff;
}

.btn.btn-hover-text-gray:hover:not(.btn-text),
.btn.btn-hover-text-gray:focus:not(.btn-text),
.btn.btn-hover-text-gray.focus:not(.btn-text) {
    color: #5F6A6A !important;
}

.btn.btn-hover-text-gray:hover:not(.btn-text) i,
.btn.btn-hover-text-gray:focus:not(.btn-text) i,
.btn.btn-hover-text-gray.focus:not(.btn-text) i {
    color: #5F6A6A !important;
}

.btn.btn-hover-text-gray:hover:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-gray:focus:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-gray.focus:not(.btn-text) .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5F6A6A !important;
}

.btn.btn-hover-text-gray:hover:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-gray:focus:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-gray.focus:not(.btn-text) .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-gray:hover:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-gray:focus:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-gray.focus:not(.btn-text).dropdown-toggle:after {
    color: #5F6A6A !important;
}

.btn.btn-hover-text-gray:not(:disabled):not(.disabled):active:not(.btn-text),
.btn.btn-hover-text-gray:not(:disabled):not(.disabled).active,
.show>.btn.btn-hover-text-gray.dropdown-toggle,
.show .btn.btn-hover-text-gray.btn-dropdown {
    color: #5F6A6A !important;
}

.btn.btn-hover-text-gray:not(:disabled):not(.disabled):active:not(.btn-text) i,
.btn.btn-hover-text-gray:not(:disabled):not(.disabled).active i,
.show>.btn.btn-hover-text-gray.dropdown-toggle i,
.show .btn.btn-hover-text-gray.btn-dropdown i {
    color: #5F6A6A !important;
}

.btn.btn-hover-text-gray:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
.btn.btn-hover-text-gray:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
.show>.btn.btn-hover-text-gray.dropdown-toggle .svg-icon svg g [fill],
.show .btn.btn-hover-text-gray.btn-dropdown .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #5F6A6A !important;
}

.btn.btn-hover-text-gray:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
.btn.btn-hover-text-gray:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
.show>.btn.btn-hover-text-gray.dropdown-toggle .svg-icon svg:hover g [fill],
.show .btn.btn-hover-text-gray.btn-dropdown .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.btn.btn-hover-text-gray:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
.btn.btn-hover-text-gray:not(:disabled):not(.disabled).active.dropdown-toggle:after,
.show>.btn.btn-hover-text-gray.dropdown-toggle.dropdown-toggle:after,
.show .btn.btn-hover-text-gray.btn-dropdown.dropdown-toggle:after {
    color: #5F6A6A !important;
}

.btn.btn-light-primary2 {
    color: #009ef7;
    border-color: #ecf8ff;
    background-color: #ecf8ff;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.btn.btn-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #009ef7
}

.btn.btn-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-light-primary2.active,
.btn.btn-light-primary2.show,
.btn.btn-light-primary2:active:not(.btn-active),
.btn.btn-light-primary2:focus:not(.btn-active),
.btn.btn-light-primary2:hover:not(.btn-active),
.show>.btn.btn-light-primary2 {
    color: #fff;
    border-color: #009ef7;
    background-color:#009ef7!important
}

.btn.btn-light-primary2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-light-primary2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}
.btn.btn-primary2 {
    color: #fff;
    border-color: #009ef7;
    background-color: #009ef7
}

.btn.btn-primary2 i {
    color: #fff
}

.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-primary2.dropdown-toggle:after {
    color: #fff
}

.btn-check:active+.btn.btn-primary2,
.btn-check:checked+.btn.btn-primary2,
.btn.btn-primary2.active,
.btn.btn-primary2.show,
.btn.btn-primary2:active:not(.btn-active),
.btn.btn-primary2:focus:not(.btn-active),
.btn.btn-primary2:hover:not(.btn-active),
.show>.btn.btn-primary2 {
    color: #fff;
    border-color: #0095e8;
    background-color: #0095e8
}

.btn-check:active+.btn.btn-primary2 i,
.btn-check:checked+.btn.btn-primary2 i,
.btn.btn-primary2.active i,
.btn.btn-primary2.show i,
.btn.btn-primary2:active:not(.btn-active) i,
.btn.btn-primary2:focus:not(.btn-active) i,
.btn.btn-primary2:hover:not(.btn-active) i,
.show>.btn.btn-primary2 i {
    color: #fff
}

.btn-check:active+.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-primary2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff
}

.btn-check:active+.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-primary2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-primary2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-primary2.dropdown-toggle:after,
.btn.btn-primary2.active.dropdown-toggle:after,
.btn.btn-primary2.show.dropdown-toggle:after,
.btn.btn-primary2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-primary2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-primary2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-primary2.dropdown-toggle:after {
    color: #fff
}

.text-primary2{
    color: #007af0!important;
}

.svg-icon.svg-icon-primary2 svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #007af0
}

.svg-icon.svg-icon-primary2 svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-primary2,
.btn-check:checked+.btn.btn-active-light-primary2,
.btn.btn-active-light-primary2.active,
.btn.btn-active-light-primary2.show,
.btn.btn-active-light-primary2:active:not(.btn-active),
.btn.btn-active-light-primary2:focus:not(.btn-active),
.btn.btn-active-light-primary2:hover:not(.btn-active),
.show>.btn.btn-active-light-primary2 {
    color: #009ef7;
    border-color: #ecf8ff;
    background-color: #ecf8ff
}

.btn-check:active+.btn.btn-active-light-primary2 i,
.btn-check:checked+.btn.btn-active-light-primary2 i,
.btn.btn-active-light-primary2.active i,
.btn.btn-active-light-primary2.show i,
.btn.btn-active-light-primary2:active:not(.btn-active) i,
.btn.btn-active-light-primary2:focus:not(.btn-active) i,
.btn.btn-active-light-primary2:hover:not(.btn-active) i,
.show>.btn.btn-active-light-primary2 i {
    color: #009ef7
}

.btn-check:active+.btn.btn-active-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.active .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.show .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #009ef7
}

.btn-check:active+.btn.btn-active-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked+.btn.btn-active-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn.btn-active-light-primary2:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show>.btn.btn-active-light-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn-check:active+.btn.btn-active-light-primary2.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-light-primary2.dropdown-toggle:after,
.btn.btn-active-light-primary2.active.dropdown-toggle:after,
.btn.btn-active-light-primary2.show.dropdown-toggle:after,
.btn.btn-active-light-primary2:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-primary2:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-light-primary2:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-light-primary2.dropdown-toggle:after {
    color: #009ef7
}

.btn.btn-active-light-primary2.btn-outline:not(.btn-outline-default) {
    border-color: #009ef7 !important
}

.btn.btn-color-primary2 {
    color: #009ef7
}

.btn.btn-color-primary2 i {
    color: #009ef7
}

.btn.btn-color-primary2 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #009ef7
}

.btn.btn-color-primary2 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill .3s ease
}

.btn.btn-color-primary2.dropdown-toggle:after {
    color: #009ef7
}
.symbol.symbol-light-primary2 .symbol-label {
    color: #009ef7;
    background-color: #ecf8ff;
}

.nav.nav-pills.nav-primary2 .show>.nav-link,
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active) {
    color: #009ef7;
    background-color: transparent;
}

.nav.nav-pills.nav-primary2 .show>.nav-link .nav-text,
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active) .nav-text {
    color: #009ef7;
}

.nav.nav-pills.nav-primary2 .show>.nav-link .nav-icon i,
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active) .nav-icon i {
    color: #009ef7;
}

.nav.nav-pills.nav-primary2 .show>.nav-link .nav-icon .svg-icon svg g [fill],
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active) .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #009ef7;
}

.nav.nav-pills.nav-primary2 .show>.nav-link .nav-icon .svg-icon svg:hover g [fill],
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active) .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.nav.nav-pills.nav-primary2 .show>.nav-link:after,
.nav.nav-pills.nav-primary2 .nav-link:hover:not(.active):after {
    color: #009ef7;
}

.nav.nav-pills.nav-primary2 .nav-link.active {
    color: #ffffff;
    background-color: #0095e8;
}

.nav.nav-pills.nav-primary2 .nav-link.active .nav-text {
    color: #ffffff;
}

.nav.nav-pills.nav-primary2 .nav-link.active .nav-icon i {
    color: #ffffff;
}

.nav.nav-pills.nav-primary2 .nav-link.active .nav-icon .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #ffffff !important;
}

.nav.nav-pills.nav-primary2 .nav-link.active .nav-icon .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.nav.nav-pills.nav-primary2 .nav-link.active:after {
    color: #ffffff;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover .navi-text {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover .navi-icon i {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover .navi-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover .navi-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link:hover .navi-arrow {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active .navi-text {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active .navi-icon i {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active .navi-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active .navi-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active .navi-arrow {
    color: #009ef7;
}

.navi.navi-accent.navi-primary2 .navi-item .navi-link.active:before {
    background-color: #009ef7;
}
.bg-light-primary2{
    background-color: #ecf8ff;
}

a.text-hover-primary2, .text-hover-primary2 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

a.text-hover-primary2:hover, .text-hover-primary2:hover {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #009ef7 !important;
}

a.text-hover-primary2:hover i, .text-hover-primary2:hover i {
    color: #009ef7 !important;
}

a.text-hover-primary2:hover .svg-icon svg g [fill], .text-hover-primary2:hover .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #009ef7;
}

a.text-hover-primary2:hover .svg-icon svg:hover g [fill], .text-hover-primary2:hover .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}

.hoverable:hover a.text-hover-primary2, .hoverable:hover .text-hover-primary2 {
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    color: #009ef7 !important;
}

.hoverable:hover a.text-hover-primary2 i, .hoverable:hover .text-hover-primary2 i {
    color: #009ef7 !important;
}

.hoverable:hover a.text-hover-primary2 .svg-icon svg g [fill], .hoverable:hover .text-hover-primary2 .svg-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #009ef7 !important;
}

.hoverable:hover a.text-hover-primary2 .svg-icon svg:hover g [fill], .hoverable:hover .text-hover-primary2 .svg-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
}
.btn.btn-flex {
    display: inline-flex;
    align-items: flex-end;
}