.table-solicitud-factura{
    tbody, tfoot, td, th, thead, tr{
        border: 0;
    }
    tr > td{
        border-bottom: 1px dashed #ECF0F3;
    }
    .table-tbody{
        border-top: 1.4px solid #d0b9fe;
        tr:nth-last-child(1){
            border-bottom: 1px solid transparent;
        }
    }
}